import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { Base, MdFixedContainer } from "@/common/layout/ResponsiveClasses";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import { WideRfqItemContainer } from "@/contractor/pages/home/project/components/project-list/ProjectListItem.styles";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { AddAllInventoryItemsCheckbox } from "./components/checkboxes/AddAllInventoryItemsCheckbox";
import { AddInventoryItemCheckbox } from "./components/checkboxes/AddInventoryItemCheckbox";
import { AddInventoryItemGroupCheckbox } from "./components/checkboxes/AddInventoryItemGroupCheckbox";
import { InventoryItemExistingStock } from "./components/inventory-item-existing-stock/InventoryItemExistingStock";
import { InventoryItemName } from "./components/inventory-item-name/InventoryItemName";
import { InventoryItemUom } from "./components/inventory-item-uom/InventoryItemUom";

const TotalItemCount = tw.div`
  text-gray-600 font-medium text-sm
`;

export const useAddInventoryItemsConfiguration = () => {
  const configuration: Array<GridCol<InventoryItemFieldsFragment>> =
    useMemo(() => {
      return [
        {
          wrapper: Base,
          item: ({ item }) => <AddInventoryItemCheckbox item={item} />,
          group: (category) => (
            <AddInventoryItemGroupCheckbox items={category.items} />
          ),
          position: "center",
        },
        {
          wrapper: WideRfqItemContainer,
          item: ({ item }) => <InventoryItemName item={item} />,
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
            />
          ),
          headerFn: () => (
            <>
              <AddAllInventoryItemsCheckbox />
              <FormattedMessage id="WAREHOUSE_ITEMS" />
            </>
          ),
          includesCounter: true,
        },
        {
          header: <FormattedMessage id="UOM" />,
          wrapper: MdFixedContainer,
          position: "center",
          item: ({ item }) => <InventoryItemUom item={item} />,
          group: (category) => (
            <TotalItemCount>
              <FormattedMessage
                id="PRODUCTS_N"
                values={{ value: category.items.length }}
              />
            </TotalItemCount>
          ),
        },
        {
          header: <FormattedMessage id="IN_STOCK" />,
          wrapper: MdFixedContainer,
          position: "center",
          item: ({ item }) => <InventoryItemExistingStock item={item} />,
        },
        {
          header: <FormattedMessage id="AVERAGE_PRICE" />,
          wrapper: MdFixedContainer,
          position: "center",
          item: ({ item }) => (
            <Price
              className="text-sm"
              price={getInventoryItemUnitPrice(item)}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          ),
        },
      ];
    }, []);

  return configuration;
};
