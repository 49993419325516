import { useCallback, useMemo } from "react";

export const useTableHeaders = () => {
  const btnBaseStyle = useMemo(
    () => `
    cursor-pointer transition-all rounded-full w-[17px] h-[17px] grid justify-self-end
    mt-[5px] mr-2 z-10
  `,
    [],
  );

  const headerWithAddButton = useCallback(
    (header: string) => `
    <div class="grid grid-flow-col justify-between text-center p-1 w-full pl-2">
      <span class="colHeader columnSorting sortAction pt-1.5">${header}</span>
      <span class="${btnBaseStyle} mr-3 bg-blue-800 text-white hover:text-white hover:bg-blue-500">+<span>
    </div>
  `,
    [btnBaseStyle],
  );

  const additionalHeader = useCallback(
    (header: string) => `
    <div class="grid grid-cols-1fr-auto text-center p-1 w-full bg-green-100">
      <span class="colHeader columnSorting sortAction pt-1.5 text-center w-full text-green-800">${header}</span>
      <span class="${btnBaseStyle} bg-red-500 text-white hover:text-red-500 hover:bg-red-300">-<span>
    </div>
  `,
    [btnBaseStyle],
  );

  const regularHeader = useCallback(
    (header: string) => `
    <div class="text-center px-1 pt-0.5 mt-2 truncate" title="${header}">
      <span class="colHeader columnSorting sortAction" class="pt-1.5 ">${header}</span>
    </div>
  `,
    [],
  );

  const rowHeader = useCallback(
    (header: string, invalidRow?: boolean) => `
    <div class="text-center p-1 ${
      invalidRow ? "bg-red-200 rounded" : ""
    }" title="${header}">
      ${header}
    </div>
  `,
    [],
  );

  return {
    headerWithAddButton,
    additionalHeader,
    regularHeader,
    rowHeader,
  };
};
