import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { TaxableReleaseItem } from "@/common/components/taxable-release-item/TaxableReleaseItem";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  MdFixedContainer,
  RequestedItemsContainer,
  SmdFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { GroupByCostCodeToggle } from "@/contractor/pages/home/release/components/group-by-costcode/GroupByCostCodeToggle";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import {
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { ErrorOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceExtPriceInput } from "./invoice-ext-price-input/InvoiceExtPriceInput";
import { InvoiceItemQuantityInput } from "./invoice-item-quantity-input/InvoiceItemQuantityInput";
import { InvoiceItemReceivedQuantity } from "./invoice-item-received-quantity/InvoiceItemReceivedQuantity";
import { InvoiceReleaseItemCheckbox } from "./invoice-release-item-checkbox/InvoiceReleaseItemCheckbox";
import { InvoiceReleaseItemCostCodeCheckbox } from "./invoice-release-item-cost-code-checkbox/InvoiceReleaseItemCostCodeCheckbox";
import { InvoiceReleaseItemMaterial } from "./invoice-release-item-material/InvoiceReleaseItemMaterial";
import { InvoiceReleaseItemUnitPriceInput } from "./invoice-release-item-unit-price-input/InvoiceReleaseItemUnitPriceInput";
import { InvoiceReleaseItemWholeSelectionCheckbox } from "./invoice-release-item-whole-selection-checkbox/InvoiceReleaseItemWholeSelectionCheckbox";
import { InvoiceReleaseItemZoneCheckbox } from "./invoice-release-item-zone-checkbox/InvoiceReleaseItemZoneCheckbox";
import { InvoicedItemExtPriceGroup } from "./invoiced-item-ext-price-group/InvoicedItemExtPriceGroup";

type GetClassNameFn = (item: ExpandedReleaseItem) => string | undefined;

const FixedContainer = tw(SmdFixedContainer)`line-clamp-2 xs:basis-1/2 xs:w-52`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckboxContainer = tw(XxsFixedContainer)`pl-0`;
const InputContainer = tw(SmdFixedContainer)`
  3xl:w-32 3xl:basis-32
  2xl:w-22 2xl:basis-22
  xl:w-19 xl:basis-19
  lg:w-18 lg:basis-18
`;

export const useInvoiceReleaseConfiguration = ({
  release,
  readonly = false,
  items = [],
  includeToggle = false,
  includeInvoiceQty = true,
  getCounterInnerClassName,
  getMaterialViewClassName,
}: {
  release?: ReleaseFieldsFragment | null;
  readonly?: boolean;
  items?: ExpandedReleaseItem[];
  includeToggle?: boolean;
  includeInvoiceQty?: boolean;
  getCounterInnerClassName?: GetClassNameFn;
  getMaterialViewClassName?: GetClassNameFn;
}): Array<GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>> => {
  const intl = useIntl();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { connectedSourceSystem } = useOrgSettings();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { useInvoiceItemPrice } = useInvoiceMatchedOrder();

  return useMemo(
    () => [
      {
        wrapper: CheckboxContainer,
        position: "center",
        item: ({ item }) => <InvoiceReleaseItemCheckbox item={item} />,
        subgroup: (category) => (
          <InvoiceReleaseItemCostCodeCheckbox costCode={category} />
        ),
        group: (category) => <InvoiceReleaseItemZoneCheckbox zone={category} />,
        header: <InvoiceReleaseItemWholeSelectionCheckbox items={items} />,
        hidden: readonly,
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <InvoiceReleaseItemMaterial
            item={item}
            count={item.orderIndex || count}
            extraDetails={
              <If isTrue={hasOrderItemPoItemReferences(item, release)}>
                <Tooltip
                  id="cannot-post-invoice-against-item"
                  element={<ErrorOutlineOutlined className="text-red-500" />}
                >
                  <FormattedMessage
                    id="YOU_CANNOT_POST_INVOICE_AGAINST_ITEM"
                    values={{
                      sourceSystem: connectedSourceSystem
                        ? intl.$t({
                            id: `INTEGRATION_${connectedSourceSystem}`,
                          })
                        : "",
                    }}
                  />
                </Tooltip>
              </If>
            }
            classes={{
              counterInner: getCounterInnerClassName?.(item),
              materialView: getMaterialViewClassName?.(item),
            }}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEM_DESCRIPTION" />
            <If isTrue={includeToggle}>
              <GroupByCostCodeToggle />
            </If>
          </>
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        header: <FormattedMessage id="INVOICE_ORDERED" />,
        wrapper: FixedContainer,
        position: "center",
        item: ({ item }) => {
          const itemIsLumpSum = isLumpSumItem(item);
          return (
            <ValueUnit
              value={new DecimalSafe(item.quantityDecimal)
                .mul(itemIsLumpSum ? (item.unitPrice ?? 0) : 1)
                .toString()}
              uom={item.uom}
              renderAsPrice={itemIsLumpSum}
            />
          );
        },
        hideGroupIfEmpty: true,
        hideSubGroupIfEmpty: true,
      },
      {
        wrapper: InputContainer,
        item: ({ item }) => <InvoiceItemReceivedQuantity item={item} />,
        position: "center",
        header: <FormattedMessage id="RECEIVED" />,
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        hidden: !release?.type.requireDeliverySlip,
      },
      {
        header: (
          <FormattedMessage
            id={useInvoiceItemPrice ? "INVOICE_UNIT_PRICE" : "UNIT_PRICE"}
          />
        ),
        wrapper: InputContainer,
        position: "center",
        item: ({ item }) => {
          if (isLumpSumItem(item)) {
            return (
              <Tooltip
                element={<InfoOutlined className="text-blue-500" />}
                id="lump-sum-unit-price-tooltip"
              >
                <FormattedMessage id="LUMP_SUM_ITEM_UNIT_PRICE_TOOLTIP" />
              </Tooltip>
            );
          }
          return (
            <ReadonlyField>
              <InvoiceReleaseItemUnitPriceInput item={item} />
            </ReadonlyField>
          );
        },
        hidden: !hasPermissions,
      },
      ...(includeInvoiceQty
        ? [
            {
              header: <FormattedMessage id="INVOICE_QUANTITY" />,
              wrapper: InputContainer,
              position: "center",
              item: ({ item, readonly }) => (
                <InvoiceItemQuantityInput item={item} readonly={readonly} />
              ),
            } as GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>,
          ]
        : []),
      ...(includeInvoiceQty
        ? [
            {
              header: <FormattedMessage id="INVOICE_EXT_PRICE" />,
              wrapper: MdFixedContainer,
              position: "center",
              item: ({ item }) => <InvoiceExtPriceInput item={item} />,
              subgroup: (category) => (
                <InvoicedItemExtPriceGroup items={category.items} />
              ),
              hidden: !hasPermissions,
            } as GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>,
          ]
        : []),
      {
        wrapper: XxsFixedContainer,
        position: "center",
        header: <FormattedMessage id="TAX" />,
        item: ({ item }) => <TaxableReleaseItem item={item} />,
      },
    ],
    [
      connectedSourceSystem,
      hasOrderItemPoItemReferences,
      hasPermissions,
      includeInvoiceQty,
      includeToggle,
      intl,
      items,
      readonly,
      release,
      useInvoiceItemPrice,
      getCounterInnerClassName,
      getMaterialViewClassName,
    ],
  );
};
