import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { getInventoryItemFromTransaction } from "../../../../../../../utils/getInventoryItemFromTransaction";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
  inventoryItemId: string;
};

export const TransactionQuantity = ({ item, inventoryItemId }: Props) => {
  const inventoryItem = useMemo(
    () => getInventoryItemFromTransaction(item, inventoryItemId),
    [inventoryItemId, item],
  );
  return (
    <NotNullableRenderer value={inventoryItem}>
      <ValueUnit
        classNames={{
          container: "text-xs",
        }}
        value={inventoryItem?.quantity}
        uom={inventoryItem?.item.uom}
      />
    </NotNullableRenderer>
  );
};
