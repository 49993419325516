import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import tw from "tailwind-styled-components";
import { useInventoryWizardTransaction } from "../../../hooks/useInventoryWizardTransaction";
import { TransactionHistoryTransfersHeader } from "./components/transaction-history-transfers-header/TransactionHistoryTransfersHeader";
import { TransactionHistoryTransfersList } from "./components/transaction-history-transfers-list/TransactionHistoryTransfersList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryTransfers = () => {
  const { loading, transaction } = useInventoryWizardTransaction();

  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <Loader loading={loading}>
        <Container>
          <TransactionHistoryTransfersHeader transaction={transaction} />
          <TransactionHistoryTransfersList transaction={transaction} />
        </Container>
      </Loader>
    </PaginationProvider>
  );
};
