import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { TransferDatePicker } from "@/contractor/pages/home/inventory-management/pages/current-stock/components/transfer-date-picker/TransferDatePicker";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { InventoryTransferFormValues } from "../inventory-transfer-form/InventoryTransferForm";

const Container = tw.div`
  grid grid-flow-col mb-6 justify-start border
  gap-4 items-center w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
`;

const BackButton = tw(IconButton)`
  rounded-lg bg-blue-800 text-white text-2xl p-1
`;

export const InventoryTransferHeader = () => {
  const intl = useIntl();

  const { watch } = useFormContext<InventoryTransferFormValues>();
  const originWarehouseId = watch("originWarehouseId");
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container>
      <BackButton onClick={handleBack}>
        <ChevronLeftOutlined />
      </BackButton>
      <FormattedMessage id="TRANSFER_FROM" />
      <WarehouseSelectorControlled
        name="originWarehouseId"
        placeholder={intl.$t({ id: "FROM" })}
        trackedWarehousesOnly
        required
      />
      <FormattedMessage id="TRANSFER_TO" />
      <WarehouseSelectorControlled
        name="destinationWarehouseId"
        placeholder={intl.$t({ id: "TO" })}
        disabledOptionIds={[originWarehouseId]}
        required
        trackedWarehousesOnly
      />
      <TransferDatePicker
        name="date"
        label={intl.$t({ id: "TRANSACTION_DATE" })}
      />
    </Container>
  );
};
