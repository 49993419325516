import { InstructionInput, TaxType } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type CreateBuyoutFromQuoteFormValues = {
  businessLocationId: string;
  projectId: string;
  vendorId: string;
  releaseTypeId: string;
  poNumber: string;
  netAmount: string;
  orderDate: Date | null | undefined;
  paymentTerm: number | undefined;
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  clearCustomTaxAmount: boolean;
  total: string;
  vendorContactIds: string[] | null;
  instructions: InstructionInput | undefined;
  description: string | null | undefined;
  taxType: TaxType | null;
  taxCodeId: string | undefined;
  additionalChargesAllowance: string | undefined;
};

type CreateBuyoutForm = UseFormReturn<
  CreateBuyoutFromQuoteFormValues,
  unknown,
  CreateBuyoutFromQuoteFormValues
>;

export const CreateBuyoutFromQuoteForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const createBuyoutFromQuoteForm: CreateBuyoutForm =
    useForm<CreateBuyoutFromQuoteFormValues>({
      defaultValues: {
        businessLocationId: "",
        projectId: "",
        vendorId: "",
        releaseTypeId: "",
        poNumber: "",
        description: undefined,
        orderDate: undefined,
        customTaxAmount: undefined,
        taxRate: undefined,
        netAmount: "0",
        total: "0",
        additionalChargesAllowance: undefined,
        instructions: {
          text: "",
          assetUrls: [],
        },
        vendorContactIds: null,
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...createBuyoutFromQuoteForm}>{children}</FormProvider>;
};
