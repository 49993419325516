import { InventoryTransactionFieldsFragment } from "@/generated/graphql";
import { useTransactionHeaderOriginTo } from "../../../hooks/useTransactionHeaderOriginTo";
import { TransactionHeaderItemOrigin } from "../../../TransactionHeaderItemOrigin";

type Props = {
  item: InventoryTransactionFieldsFragment;
};

export const TransactionHeaderOriginTo = ({ item }: Props) => {
  const to = useTransactionHeaderOriginTo(item);
  return <TransactionHeaderItemOrigin item={to} />;
};
