import { LOCAL_STORAGE_KEYS } from "@/common/const";
import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUnspecifiedZone } from "@/common/hooks/useUnspecifiedZone";
import { getZonesByReleaseItems } from "@/common/utils/cost-codes-and-zones/getZonesByReleaseItems";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useReleaseItemsGrouping } from "@/common/utils/hooks/useReleaseItemsGrouping";
import { readValue, setValue } from "@/common/utils/localStorage";
import { monetarySplit } from "@/common/utils/monetarySplit";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePriceCalculation } from "../hooks/usePriceCalculation";
import { useCategoryWeights } from "../pages/specify-details/hooks/useCategoryWeights";
import {
  ReleaseItemFilter,
  ReleaseItemsFilters,
} from "../types/ReleaseItemsFilters";
import { filterReleaseItems } from "../utils/filters/filterReleaseItems";
import { ExpandedReleaseItem, useRelease } from "./ReleaseProvider";

export type ZoneCategory = CategoryState<CategoryState<ExpandedReleaseItem>>;

type SortFunctionType = (
  a: ExpandedReleaseItem,
  b: ExpandedReleaseItem,
) => number;

type ProviderContextType = {
  zones: ZoneCategory[];
  toggleZone: (name: string) => void;
  toggleCostCode: (costCodeId: string, zoneId: string) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;
  setSortFunction: Dispatch<SetStateAction<SortFunctionType | undefined>>;
  items: ExpandedReleaseItem[];
  filter: ReleaseItemsFilters;
  setFilter: (filters: ReleaseItemsFilters) => void;
  filteredTags: string[];
  setFilteredTags: (tagIds: string[]) => void;
  receivedSoFarSubtotal: Decimal;
  receivedSoFarAdditionalCharges: Decimal;
  receivedSoFarSalesTax: Decimal;
  invoicedSubtotal: Decimal;
  invoicedAdditionalCharges: Decimal;
  invoicedSalesTax: Decimal;
  extPriceSubtotal: Decimal;
  extPriceAdditionalCharges: Decimal;
  extPriceSalesTax: Decimal;
  extPriceSalesVariance: Decimal;
  extPriceTaxRate: Decimal;
  noPrices: boolean;
};

type Props = {
  items: ExpandedReleaseItem[];
  children: React.ReactNode;
  // props used for supporting the phase codes release print
  // to be removed after adjusting the print to display the current state of the page
  defaultGroupedByCostCodes?: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  zones: [],
  toggleZone: NoFunction,
  toggleCostCode: NoFunction,
  groupedByCostCode: false,
  setGroupedByCostCode: NoFunction,
  setSortFunction: NoFunction,
  items: [],
  filter: {
    [ReleaseItemFilter.FULLY_RECEIVED]: undefined,
    [ReleaseItemFilter.FULLY_INVOICED]: undefined,
  },
  setFilter: NoFunction,
  filteredTags: [],
  setFilteredTags: NoFunction,
  receivedSoFarSubtotal: new DecimalSafe(0),
  receivedSoFarAdditionalCharges: new DecimalSafe(0),
  receivedSoFarSalesTax: new DecimalSafe(0),
  invoicedSubtotal: new DecimalSafe(0),
  invoicedAdditionalCharges: new DecimalSafe(0),
  invoicedSalesTax: new DecimalSafe(0),
  extPriceSubtotal: new DecimalSafe(0),
  extPriceAdditionalCharges: new DecimalSafe(0),
  extPriceSalesTax: new DecimalSafe(0),
  extPriceSalesVariance: new DecimalSafe(0),
  extPriceTaxRate: new DecimalSafe(0),
  noPrices: false,
});

export const ReleaseItemsZoneProvider: FC<Props> = ({
  children,
  items,
  defaultGroupedByCostCodes,
}) => {
  const { release, timeTbdFilter, filterDates } = useRelease();
  const [zones, setZones] = useState<ZoneCategory[]>([]);
  const initialValue = readValue<boolean>(
    LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE,
    true,
  );
  const [groupedByCostCode, setGroupedByCostCode] = useState(
    Boolean(defaultGroupedByCostCodes ?? initialValue),
  );
  const [customSortFn, setSortFunction] = useState<
    SortFunctionType | undefined
  >();
  const { getWeights } = useCategoryWeights();

  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { toggleCategory } = useToggleCategory(zones, setZones);
  const { getProjectCodes, getFilteredProjectItems } = useReleaseItemsGrouping({
    items,
  });
  const { calcExtPrice } = usePriceCalculation();

  const [filter, setFilter] = useState<ReleaseItemsFilters>({
    [ReleaseItemFilter.FULLY_RECEIVED]: undefined,
    [ReleaseItemFilter.FULLY_INVOICED]: undefined,
  });
  const [filteredTags, setFilteredTags] = useState<string[]>([]);

  useEffect(() => {
    if (defaultGroupedByCostCodes !== undefined) {
      setGroupedByCostCode(defaultGroupedByCostCodes);
    }
  }, [defaultGroupedByCostCodes]);

  useEffect(() => {
    if (!groupedByCostCode) {
      const sortFunction =
        customSortFn ?? ((a, b) => (a.position || 0) - (b.position || 0));
      const sortedItems = items
        .sort(sortFunction)
        .map((item, index) => ({ ...item, orderIndex: index + 1 }));
      const filteredItems = filterReleaseItems({
        items: sortedItems,
        filter,
        filteredTags,
        timeTbdFilter,
        filterDates,
        isReleaseTbd: release?.timeTBD,
        releaseTime: release?.time,
      });
      setZones([
        {
          id: unassignedZone.id,
          name: unassignedZone.name,
          isOpened: true,
          items: [
            {
              id: unassignedCostCode.id,
              name: unassignedCostCode.description,
              isOpened: true,
              parentId: unassignedZone.id,
              items: [...filteredItems],
            },
          ],
        },
      ]);
      return;
    }
    const zoneGroups = getZonesByReleaseItems(items, unassignedZone);
    let orderIndexCounter = 0;
    const zoneCategories: ZoneCategory[] = zoneGroups.map((zone) => {
      const newZone = {
        id: zone.id,
        name: zone.name,
        isOpened: true,
      };
      const projectCodes = getProjectCodes(zone);

      const mappedCost: CategoryState<ExpandedReleaseItem>[] = projectCodes
        .map((costCode) => {
          const newCostCode = {
            id: costCode.id,
            name: costCode.description,
            isOpened: true,
            parentId: zone.id,
          };
          const filteredSubgroupItems = (
            getFilteredProjectItems(zone, costCode) as ExpandedReleaseItem[]
          ).map((item) => {
            orderIndexCounter++;
            return { ...item, orderIndex: orderIndexCounter };
          });
          const projectItems = filterReleaseItems({
            items: filteredSubgroupItems,
            filter,
            filteredTags,
            timeTbdFilter,
            filterDates,
            isReleaseTbd: release?.timeTBD,
            releaseTime: release?.time,
          });

          return {
            ...newCostCode,
            items: [...projectItems],
          };
        })
        .filter((c) => c.items.length > 0);
      return {
        ...newZone,
        items: mappedCost,
      };
    });

    const weightedWeights = getWeights(release, zoneCategories);
    let counter = 0;
    zoneCategories.forEach((zoneCategory) =>
      zoneCategory.items.forEach((costCodeCategory) => {
        costCodeCategory.weightedAmount = weightedWeights[counter].toNumber();
        counter++;
      }),
    );
    setZones(zoneCategories);
  }, [
    items,
    unassignedZone,
    unassignedCostCode,
    groupedByCostCode,
    getProjectCodes,
    getFilteredProjectItems,
    filter,
    filteredTags,
    customSortFn,
    release,
    calcExtPrice,
    timeTbdFilter,
    filterDates,
    getWeights,
  ]);

  const toggleCostCode = useCallback(
    (costCodeId: string, zoneId: string) => {
      const zoneIndex = zones.findIndex((z) => z.id === zoneId);
      const zone = zones[zoneIndex];
      const costCodeIndex = zone.items.findIndex((c) => c.id === costCodeId);
      const costCode = zone.items[costCodeIndex];
      setZones([
        ...zones.slice(0, zoneIndex),
        {
          ...zone,
          items: [
            ...zone.items.slice(0, costCodeIndex),
            { ...costCode, isOpened: !costCode.isOpened },
            ...zone.items.slice(costCodeIndex + 1),
          ],
        },
        ...zones.slice(zoneIndex + 1),
      ]);
    },
    [zones],
  );

  const setGroupedByCostCodeAndUpdateLocalStorage = useCallback(
    (grouped: boolean) => {
      setGroupedByCostCode(grouped);
      setValue(LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE, grouped);
    },
    [],
  );

  const filteredItems = useMemo(
    () =>
      filterReleaseItems({
        items,
        filter,
        filteredTags,
        timeTbdFilter,
        filterDates,
        isReleaseTbd: release?.timeTBD,
        releaseTime: release?.time,
      }),
    [
      items,
      filter,
      filteredTags,
      timeTbdFilter,
      filterDates,
      release?.timeTBD,
      release?.time,
    ],
  );

  const receivedSoFarSubtotal = useMemo(
    () =>
      filteredItems
        .filter((item) => item.isIncluded)
        .reduce(
          (acc, i) =>
            acc.add(calcExtPrice(i.receivedQuantityDecimal, i.unitPrice)),
          new DecimalSafe(0).toDP(2),
        ),
    [filteredItems, calcExtPrice],
  );

  const invoicedSubtotal = useMemo(
    () =>
      filteredItems
        .filter((item) => item.isIncluded)
        .reduce(
          (acc, i) => acc.add(calcExtPrice(i.invoicedQuantity, i.unitPrice)),
          new DecimalSafe(0),
        )
        .toDP(2),
    [filteredItems, calcExtPrice],
  );

  const extPriceSubtotal = useMemo(
    () =>
      filteredItems
        .filter((item) => item.isIncluded)
        .reduce(
          (acc, item) =>
            acc.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
          new DecimalSafe(0).toDP(2),
        ),
    [filteredItems, calcExtPrice],
  );

  const invoicedAdditionalCharges = useMemo(
    () =>
      (release?.invoices ?? [])
        .reduce(
          (acc, invoice) => acc.plus(invoice.chargesAmount ?? 0),
          new DecimalSafe(0),
        )
        .toDP(2),
    [release?.invoices],
  );

  const extPriceAdditionalCharges = useMemo(
    () =>
      (release?.additionalCharges ?? [])
        .reduce((acc, i) => acc.add(i.amount), new DecimalSafe(0))
        .toDP(2),
    [release?.additionalCharges],
  );

  const receivedSoFarAdditionalCharges = useMemo(() => {
    const split = monetarySplit(
      new DecimalSafe(extPriceAdditionalCharges || 0),
      [receivedSoFarSubtotal, extPriceSubtotal.minus(receivedSoFarSubtotal)],
    );
    return split[0].toDP(2);
  }, [extPriceAdditionalCharges, receivedSoFarSubtotal, extPriceSubtotal]);

  const extPriceSalesTax = useMemo(
    () => new DecimalSafe(release?.taxAmount ?? 0).toDP(2),
    [release?.taxAmount],
  );

  const extPriceSalesVariance = useMemo(
    () => new DecimalSafe(release?.taxVariance ?? 0).toDP(2),
    [release?.taxVariance],
  );

  const invoicedSalesTax = useMemo(
    () =>
      (release?.invoices ?? [])
        .reduce(
          (acc, invoice) => acc.plus(invoice.taxAmount ?? 0),
          new DecimalSafe(0),
        )
        .toDP(2),
    [release?.invoices],
  );

  const receivedSoFarSalesTax = useMemo(() => {
    const split = monetarySplit(new DecimalSafe(release?.taxAmount || 0), [
      receivedSoFarSubtotal,
      extPriceSubtotal.minus(receivedSoFarSubtotal),
    ]);
    return split[0].toDP(2);
  }, [extPriceSubtotal, receivedSoFarSubtotal, release?.taxAmount]);

  const extPriceTaxRate = useMemo(
    () => new DecimalSafe(release?.taxRate || 0).mul(100).toDP(3),
    [release?.taxRate],
  );

  const noPrices = useMemo(() => items.every((i) => !i.unitPrice), [items]);

  return (
    <ProviderContext.Provider
      value={{
        zones,
        toggleZone: toggleCategory,
        toggleCostCode,
        groupedByCostCode,
        setGroupedByCostCode: setGroupedByCostCodeAndUpdateLocalStorage,
        setSortFunction,
        items,
        filter,
        setFilter,
        filteredTags,
        setFilteredTags,
        receivedSoFarSubtotal,
        receivedSoFarAdditionalCharges,
        receivedSoFarSalesTax,
        invoicedSubtotal,
        invoicedAdditionalCharges,
        invoicedSalesTax,
        extPriceSubtotal,
        extPriceAdditionalCharges,
        extPriceSalesTax,
        extPriceSalesVariance,
        extPriceTaxRate,
        noPrices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseItemsZoneProvider = (): ProviderContextType =>
  useContext(ProviderContext);
