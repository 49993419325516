import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MdFixedContainer,
  SmWideContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleaseItemMaterial } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseItemMaterial";
import { InvoicedItemExtPriceGroup } from "@/contractor/pages/home/invoices/pages/invoice-verification/components/matched-order/components/invoice-release-items/components/invoiced-item-ext-price-group/InvoicedItemExtPriceGroup";
import { GroupByCostCodeToggle } from "@/contractor/pages/home/release/components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemUnitPrice } from "@/contractor/pages/home/release/release-items-list/components/ReleaseItemUnitPrice";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import {
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const RequestedItemsContainer = tw(Base)`col-span-full truncate flex-1`;
const FloatingText = tw.span`absolute w-96 text-center text-xs text-gray-600`;

export const useReceiptReleaseDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { calcExtPrice } = usePriceCalculation();
  const { hasPhaseCodes } = useOrgSettings();

  const configuration: Array<
    GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterial
            count={count}
            item={item}
            projectId={release?.project?.id}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_RELEASES" />
            <GroupByCostCodeToggle />
          </>
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <TagPicker values={item.tags} visibleTags={1} readonly />;
        },
        hideGroupIfEmpty: true,
        hideSubGroupIfEmpty: true,
      },
      {
        wrapper: XsFixedContainer,
        item: ({ item }) => {
          const uom = item.uom || item.projectItem?.estimateUom;
          return item.isIncluded ? (
            <ValueUnit value={item.quantityDecimal} uom={uom} />
          ) : (
            <FormattedMessage
              id="NOT_INCLUDED_BY_VENDOR"
              tagName={FloatingText}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
        hideGroupIfEmpty: true,
        hideSubGroupIfEmpty: true,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item, index }) => (
          <If isTrue={item.isIncluded}>
            <ReleaseItemUnitPrice item={item} index={index} />
          </If>
        ),
        header: <FormattedMessage id="UNIT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
      },
      {
        header: <FormattedMessage id="EXT_PRICE" />,
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <Price
              className="text-xs"
              price={calcExtPrice(item.quantityDecimal, item.unitPrice)}
            />
          </If>
        ),
        subgroup: (category) => (
          <InvoicedItemExtPriceGroup items={category.items} />
        ),
      },
    ];
  }, [calcExtPrice, hasPermissions, release, hasPhaseCodes]);

  const itemFn = useCallback((item: ReleaseItemFieldsFragment) => {
    return {
      className: `${item.isIncluded ? "" : "bg-gray-100"}`,
    };
  }, []);

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release],
  );

  return { configuration, items, itemFn };
};
