import Handsontable from "handsontable";
import { COLUMN_TYPE } from "../enums/columnType";
import { useSpreadSheetStore } from "../store/useSpreadSheetStore";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "./getPhysicalRowIndex";

export const getCellValueWithPrefill = (
  hotInstance: Handsontable | null | undefined,
  row: number,
  column: COLUMN_TYPE,
) => {
  if (!hotInstance) {
    return "";
  }
  const getState = useSpreadSheetStore.getState().getStateWithPrefillChanges;
  const updatedRows = getState(hotInstance?.getData()) as Record<
    string,
    string | number
  >[];

  const rowIndex = getPhysicalRowIndex(hotInstance, row);
  const columnIndex = getPhysicalColumnIndex(hotInstance, column);

  return String(updatedRows[rowIndex][columnIndex]);
};
