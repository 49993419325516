import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import {
  EstimatedItemFieldsFragment,
  ProjectItemExtendedFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useProjectItems } from "./useProjectItems";

type Props = {
  columns: Array<
    GridCol<
      ProjectItemFieldsFragment | ProjectItemExtendedFieldsFragment,
      EstimatedItemFieldsFragment
    >
  >;
  loading?: boolean;
};

export const AddEstimatedItemListItems: FC<Props> = ({ columns, loading }) => {
  const { groupedByZones, toggleZone, toggleCostCode } = useProjectItemsZones();
  const { getExpandedItems, filteredZones } = useProjectItems();

  return (
    <GridTable
      configuration={{
        container: ZoneItemsContainer,
        columns,
        classNames: {
          container: "flex-1 overflow-scroll",
          header: "top-0",
          category: "top-10 lg:top-[38px]",
          subCategory: !groupedByZones ? "top-[38px]" : "top-[72px]",
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      items={filteredZones}
      readonly={true}
      hideGroup={!groupedByZones}
      expandedItems={getExpandedItems}
      loading={loading}
    />
  );
};
