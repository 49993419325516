import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  InventoryItemFieldsFragment,
  InventoryItemStrippedFieldsFragment,
  InventorySummaryDocument,
  Manufacturer,
  OrgMaterialFieldsFragment,
  UomFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { useMaterialsStore } from "../../org-items/pages/materials/stores/useMaterialsStore";

type OrgMaterialFieldsFragmentWithManufacturer = OrgMaterialFieldsFragment & {
  manufacturer?: Manufacturer;
};

type InventoryItemsState = {
  inventoryItems: InventoryItemFieldsFragment[];
  setInventoryItems: (inventoryItems: InventoryItemFieldsFragment[]) => void;
  loading: boolean;
  currentWarehouseId: string | undefined;
  setCurrentWarehouseId: (warehouseId: string | undefined) => void;
  error: Error | null;
  itemsByWarehouse: Record<string, InventoryItemFieldsFragment[]>;
  fetchInventoryItems: (force?: boolean) => Promise<void>;
  reset: () => void;
};

const hydrateInventoryMaterials = (
  inventoryMaterials: InventoryItemStrippedFieldsFragment[],
  materials: OrgMaterialFieldsFragmentWithManufacturer[],
  uoms: UomFieldsFragment[],
) => {
  return inventoryMaterials.map((item) => {
    const material = materials.find(
      (material) => material.id === item.orgMaterial.id,
    );
    return {
      ...item,
      orgMaterial: material ?? item.orgMaterial,
      uom: uoms.find((uom) => uom.id === item.uom.id) ?? item.uom,
      state: item.state || [],
    } as InventoryItemFieldsFragment;
  });
};

const filterInventoryItemsByWarehouse = (
  inventoryItems: InventoryItemFieldsFragment[],
  warehouseId: string,
) => {
  return inventoryItems.map((item) => {
    const statesForWarehouse = item.state.filter(
      (state) => state.warehouse.id === warehouseId,
    );
    return {
      ...item,
      state: statesForWarehouse,
    };
  });
};

export const useInventoryItemsStore = create<InventoryItemsState>()(
  devtools((set, get) => ({
    inventoryItems: [],
    itemsByWarehouse: {},
    loading: false,
    currentWarehouseId: undefined,
    setCurrentWarehouseId: (warehouseId: string | undefined) =>
      set({ currentWarehouseId: warehouseId }),
    error: null,
    setInventoryItems: (items: InventoryItemStrippedFieldsFragment[]) => {
      const { materials } = useMaterialsStore.getState();
      const { uoms, warehouses } = useStartupDataStore.getState();
      const inventoryItems = hydrateInventoryMaterials(
        items,
        materials,
        uoms,
      ).filter((item) => item.orgMaterial.material);

      const itemsByWarehouse = warehouses.reduce(
        (acc, warehouse) => {
          acc[warehouse.id] = filterInventoryItemsByWarehouse(
            inventoryItems,
            warehouse.id,
          );
          return acc;
        },
        {} as Record<string, InventoryItemFieldsFragment[]>,
      );

      set({
        inventoryItems,
        itemsByWarehouse: itemsByWarehouse,
        loading: false,
      });
    },
    fetchInventoryItems: async (force = false) => {
      if (!force && (get().inventoryItems.length > 0 || get().loading)) {
        return;
      }

      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, error: null });
      try {
        await client
          .query({
            query: InventorySummaryDocument,
            fetchPolicy: force ? "network-only" : "cache-first",
          })
          .then((inventoryResult) => {
            get().setInventoryItems(
              inventoryResult.data.viewer.org.inventoryItems,
            );
          });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
    reset: () =>
      set({
        inventoryItems: [],
        loading: false,
        currentWarehouseId: undefined,
        error: null,
      }),
  })),
);

useMaterialsStore.subscribe((state) => {
  const startupData = useStartupDataStore.getState();
  if (state.materials.length > 0 && startupData.warehouses.length > 0) {
    useInventoryItemsStore.getState().fetchInventoryItems();
  }
});
