import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useSorting } from "@/common/stores/hooks/useSorting";
import { ObjectType } from "@/common/stores/useSortingStore";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import { useInvoiceCreation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceCreationProvider";
import {
  CreateInvoicesInput,
  InvoiceFieldsFragment,
  InvoiceSummaryFieldsFragment,
  InvoiceType,
  QueryInvoicesFilter,
  QueryInvoicesSortBy,
  UpdateInvoicesInput,
  useArchiveInvoicesMutation,
  useInvoicesQuery,
  useUpdateInvoicesMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionArrayPromise,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";
import { useArchiveInvoice } from "../hooks/useArchiveInvoice";

type ProviderContextType = {
  invoices: InvoiceSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  paginationArgs: PaginationArgs;
  page: number;
  isFiltered: boolean;
  createInvoices: (
    input: CreateInvoicesInput,
  ) => Promise<InvoiceFieldsFragment[] | undefined>;
  archiveInvoice: (invoiceId: string) => Promise<boolean>;
  archiveInvoices: (invoiceIds: string[]) => Promise<boolean>;
  updateInvoices: (input: UpdateInvoicesInput) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
  refetchInvoices: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  invoices: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  paginationArgs: {},
  page: 0,
  isFiltered: false,
  createInvoices: NoFunctionArrayPromise,
  archiveInvoice: NoFunctionBooleanPromise,
  archiveInvoices: NoFunctionBooleanPromise,
  updateInvoices: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
  refetchInvoices: NoFunctionPromise,
});

export const DEFAULT_INVOICES_FILTER: QueryInvoicesFilter = {
  archived: false,
  closedProjects: false,
};

export const InvoicesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage } = usePagination();
  const { sortEntity } = useSorting(ObjectType.Invoice);
  const { setError } = useGlobalError();

  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>(
    undefined,
  );
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createInvoices: createInvoicesMutation, creating } =
    useInvoiceCreation();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );
  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setFiltersQueryParams(updatedFilter);
      setFilter(updatedFilter);
      removeValue(LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER);
      setValue(
        [orgId || "", LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER],
        updatedFilter,
      );
    },
    [orgId, setFiltersQueryParams],
  );

  useEffect(() => {
    const localStorageSettings = readValueFromKeys<QueryInvoicesFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER],
      LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER,
    ]) as QueryInvoicesFilter;
    setFilter({
      ...DEFAULT_INVOICES_FILTER,
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId]);

  useEffect(() => {
    if (page !== 0 && data?.invoices.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useInvoicesQuery({
    variables: {
      filter: {
        ...filter,
        type: InvoiceType.Invoice,
      },
      ...paginationArgs,
      sort: sortEntity
        ? {
            by: sortEntity.field as QueryInvoicesSortBy,
            descending: sortEntity.descending,
          }
        : undefined,
    },
    skip: !filter,
    fetchPolicy: "cache-and-network",
  });

  const { archiveInvoice: archiveInvoiceFn } = useArchiveInvoice({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.invoices) {
      setPageInfo(data.invoices.pageInfo);
    }
  }, [data, setPageInfo]);

  const createInvoices = async (input: CreateInvoicesInput) => {
    const createdInvoices = await createInvoicesMutation(input);
    await refetch();
    return createdInvoices;
  };

  const [updateInvoicesMutation] = useUpdateInvoicesMutation();
  const updateInvoices = useCallback(
    async (input: UpdateInvoicesInput): Promise<boolean> => {
      try {
        const { data, errors } = await updateInvoicesMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        await refetch();
        return Boolean(!!data?.updateInvoices && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateInvoicesMutation, refetch],
  );

  const archiveInvoice = async (id: string) => {
    return await archiveInvoiceFn(id);
  };

  const [archiveInvoicesMutation] = useArchiveInvoicesMutation();
  const archiveInvoices = useCallback(
    async (ids: string[]) => {
      try {
        const { data, errors } = await archiveInvoicesMutation({
          variables: {
            ids,
          },
        });
        setError(errors);
        await refetch();
        return Boolean(!!data?.archiveInvoices && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [archiveInvoicesMutation, refetch, setError],
  );

  const refetchInvoices = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        invoices: data?.invoices?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.invoices?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        paginationArgs,
        page,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createInvoices,
        archiveInvoice,
        archiveInvoices,
        updateInvoices,
        exportEnabled,
        setExportEnabled,
        creating,
        refetchInvoices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoices = (): ProviderContextType =>
  useContext(ProviderContext);
