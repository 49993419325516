import { useMemo } from "react";
import { useIntl } from "react-intl";

enum ItemizationValues {
  Auto = "auto",
  Yes = "yes",
  No = "no",
}

export const encodeItemizationValue = (value?: boolean | null) => {
  if (value === true) {
    return ItemizationValues.Yes;
  }
  if (value === false) {
    return ItemizationValues.No;
  }
  return ItemizationValues.Auto;
};

export const decodeItemizationValue = (value: ItemizationValues) => {
  if (value === ItemizationValues.Yes) {
    return true;
  }
  if (value === ItemizationValues.No) {
    return false;
  }
  return undefined;
};

export const useItemizationSelector = () => {
  const intl = useIntl();

  const options = useMemo(
    () => [
      { value: ItemizationValues.Auto, label: intl.$t({ id: "AUTO" }) },
      { value: ItemizationValues.Yes, label: intl.$t({ id: "YES" }) },
      { value: ItemizationValues.No, label: intl.$t({ id: "NO" }) },
    ],
    [intl],
  );
  return { options };
};
