import {
  InventoryTransactionFieldsFragment,
  InventoryTransactionType,
} from "@/generated/graphql";
import { WarehouseOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TransactionOrigin } from "../../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: InventoryTransactionFieldsFragment;
};

export const useTransactionHeaderOriginFrom = ({ item }: Props) => {
  const intl = useIntl();

  const from: TransactionOrigin | null | undefined = useMemo(() => {
    switch (item.type) {
      case InventoryTransactionType.Adjustment:
      case InventoryTransactionType.Transfer:
      case InventoryTransactionType.Outflow:
        return {
          typeName: intl.$t({ id: "FROM" }),
          name: item.origin?.name,
          icon: WarehouseOutlined,
        };
      case InventoryTransactionType.Inflow:
        return {
          typeName: intl.$t({ id: "VENDOR" }),
          name: item.deliverySlip?.release?.sellerOrgLocation?.org?.name,
          org: item.deliverySlip?.release?.sellerOrgLocation?.org,
        };

      default:
        return null;
    }
  }, [
    item.type,
    item.origin?.name,
    item.deliverySlip?.release?.sellerOrgLocation?.org,
    intl,
  ]);

  return from;
};
