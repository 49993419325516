import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  ExtraOption,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useMemo } from "react";

const getItemDetails = (detailText: string, colorClass: string) => {
  const itemContent = document.createElement("span");
  itemContent.className = "text-gray-600 font-light text-xs select-none mr-10";
  itemContent.innerHTML = detailText;
  const bubble = document.createElement("i");
  bubble.className = `absolute top-1/2 -translate-y-1/2 right-1 ${colorClass} fa-solid fa-circle`;
  itemContent.appendChild(bubble);
  return itemContent;
};

export const useBuyoutItemsDecorator = () => {
  const { buyout } = useContractorBuyout();
  const { estimatedItems } = useProjectStore();
  const { formatCostCode } = useProjectCostCodes();
  const { materials } = useMaterials();
  const { getFormattedMaterialName } = useTableHelpers();
  const { hasPhaseCodes } = useOrgSettings();
  const { uoms } = useUomOptions();
  const extraOptions = useMemo(() => {
    const estimates =
      estimatedItems
        .filter(
          (estItem) =>
            estItem.sellerOrgLocation?.id === buyout?.sellerOrgLocation?.id,
        )
        .map((estItem) => {
          const material = materials.find((m) => m.id === estItem.material.id);

          const estimatedItemDetails = getItemDetails(
            `⟮${estItem?.quantityDecimal} ${material?.defaultEstimateUom.pluralDescription}⟯`,
            "text-blue-500",
          );

          return {
            name: material ? getFormattedMaterialName(material) : "",
            content: estimatedItemDetails,
            value: estItem.id,
            prefilling: [
              [COLUMN_TYPE.EstimatedItemId, estItem.id],
              [COLUMN_TYPE.Quantity, estItem?.quantityDecimal],
              ...(hasPhaseCodes
                ? [[COLUMN_TYPE.PhaseCode, estItem?.tags[0]?.name]]
                : [[COLUMN_TYPE.CostCode, formatCostCode(estItem?.costCode)]]),
              [
                COLUMN_TYPE.UOM,
                uoms.find((u) => u.id === estItem?.uom.id)?.pluralDescription,
              ],
              [COLUMN_TYPE.Manufacturer, estItem?.manufacturer?.name],
              [COLUMN_TYPE.PrefilledPrice, estItem?.unitPrice],
            ],
          } as ExtraOption;
        }) || [];
    return estimates;
  }, [
    buyout,
    materials,
    getFormattedMaterialName,
    formatCostCode,
    hasPhaseCodes,
    estimatedItems,
    uoms,
  ]);

  const extraColumns = useMemo(() => {
    return [
      {
        header: "",
        columnId: "projectItem.estimatedItems.0.id",
        columnType: COLUMN_TYPE.EstimatedItemId,
        metadataColumn: true,
      },
    ] as SpreadSheetConfig[];
  }, []);

  return {
    extraOptions,
    extraColumns,
  };
};
