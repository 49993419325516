import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { InventoryLedgerHeader } from "./components/inventory-ledger-header/InventoryLedgerHeader";
import { InventoryLedgerList } from "./components/inventory-ledger-list/InventoryLedgerList";

const InventoryLedgerWithProvider = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "CURRENT_STOCK" })}</title>
      </Helmet>
      <InventoryLedgerHeader />
      <InventoryLedgerList />
    </>
  );
};

export const InventoryLedger = () => (
  <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <InventoryLedgerWithProvider />
  </PaginationProvider>
);
