import { InventoryTransactionFieldsFragment } from "@/generated/graphql";
import { useTransactionSymbol } from "./hooks/useTransactionSymbol";

type Props = {
  item: Pick<InventoryTransactionFieldsFragment, "type">;
};

export const TransactionSymbol = ({ item }: Props) => {
  const { Icon, className } = useTransactionSymbol(item);
  return <Icon className={className} />;
};
