import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useTransactionHistoryModal } from "./hooks/useTransactionHistoryModal";

export const TRANSACTION_HISTORY_MODAL_ID = "transaction-history-modal";

const TransactionHistoryModalWithProvider = () => {
  const { pages, onClose, visible } = useTransactionHistoryModal();

  return (
    <WizardModal
      id={TRANSACTION_HISTORY_MODAL_ID}
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={visible}
      pages={pages}
      onClose={onClose}
    />
  );
};

export const TransactionHistoryModal = () => (
  <NestedStepperProvider>
    <TransactionHistoryModalWithProvider />
  </NestedStepperProvider>
);
