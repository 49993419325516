import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DateTimePickerControlled } from "@/common/components/picker/components/DateTimePickerControlled";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { VendorPickerControlled } from "@/common/components/vendor-picker/VendorPickerControlled";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useReceipt } from "../../providers/ReceiptProvider";
import { useReceiptCreateReleaseHeader } from "../../stores/useReceiptCreateReleaseHeader";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";

const Container = tw.div`w-full grid grid-cols-3 grid-row-2 gap-2 gap-y-4 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;

export const ReceiptCreateReleaseHeader: FC = () => {
  const { setValue, watch, getValues } =
    useFormContext<ReceiptCreateReleaseFormValues>();
  const intl = useIntl();
  const { hasReleaseRecorded } = useReceipt();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const {
    vendors,
    findOrderTypeByLocationId,
    findDefaultPaymentTermByLocationId,
  } = useVendors();
  const { defaultOrderType } = useOrderTypeOptions();

  const { setProjectId, setVendorId } = useReceiptCreateReleaseHeader(
    useShallow((state) => ({
      setProjectId: state.setProjectId,
      setVendorId: state.setVendorId,
    })),
  );
  const resetProjectStore = useProjectStore((state) => state.resetStore);

  const projectId = watch("projectId");
  const vendorId = watch("vendorId");

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    if (!projectId) {
      resetProjectStore();
    }
  }, [projectId, resetProjectStore]);

  useEffect(() => {
    setProjectId(projectId);
    return () => {
      setProjectId(undefined);
    };
  }, [projectId, setProjectId]);

  useEffect(() => {
    setVendorId(vendorId);
    return () => {
      setVendorId(undefined);
    };
  }, [vendorId, setVendorId]);

  useEffect(() => {
    if (defaultOrderType && !getValues("orderTypeId")) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue, getValues]);

  useEffect(() => {
    if (projects.length === 1 && !hasReleaseRecorded) {
      setValue("projectId", projects[0].id);
    }
  }, [hasReleaseRecorded, projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1 && !hasReleaseRecorded) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [hasReleaseRecorded, setValue, vendors]);

  useEffect(() => {
    if (vendorId && !hasReleaseRecorded) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId && !hasReleaseRecorded) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, hasReleaseRecorded, setValue, vendorId]);

  useEffect(() => {
    if (vendorId && !hasReleaseRecorded) {
      const paymentTerm = findDefaultPaymentTermByLocationId(vendorId);
      if (!hasReleaseRecorded) {
        setValue("paymentTerm", paymentTerm?.toString() ?? "30");
      }
    }
  }, [
    findDefaultPaymentTermByLocationId,
    hasReleaseRecorded,
    setValue,
    vendorId,
  ]);

  return (
    <Container>
      <SelectControlled
        name="projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        rules={{
          required: !hasReleaseRecorded,
        }}
        disabled={hasReleaseRecorded}
      />
      <VendorPickerControlled
        required
        disabled={hasReleaseRecorded && !!vendorId}
        contactsFilter={(c) => c.receivesInvoiceNotifications}
      />
      <OrderTypePickerControlled name="orderTypeId" />
      <DateTimePickerControlled
        name="issueDate"
        label={intl.$t({ id: "FULFILLMENT_DATE" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
      />
    </Container>
  );
};
