import { FooterHeaderText } from "@/common/components/grid-table/styles/Footer.styles";
import { Price } from "@/common/components/price/Price";
import { FormattedMessage } from "react-intl";
import {
  TransactionFooterCell,
  TransactionFooterCellHeader,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";
import { useTransactionHistoryInflowsFooterOrderedPrice } from "./useTransactionHistoryInflowsFooterOrderedPrice";

export const TransactionHistoryInflowsFooterOrderedPrice = () => {
  const { subtotal, additionalChargeAmount, salesTax, total } =
    useTransactionHistoryInflowsFooterOrderedPrice();

  return (
    <TransactionFooterCol>
      <TransactionFooterCellHeader>
        <FormattedMessage id="ORDERED" tagName={FooterHeaderText} />
      </TransactionFooterCellHeader>
      <TransactionFooterCell>
        <Price price={subtotal} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell>
        <Price price={salesTax} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell>
        <Price price={additionalChargeAmount} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell>
        <Price price={total} className="text-end" />
      </TransactionFooterCell>
    </TransactionFooterCol>
  );
};
