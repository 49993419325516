import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryTransaction } from "./useInventoryTransaction";

export const useInventoryWizardTransaction = (opts?: {
  skipStoreUpdates?: boolean;
}) => {
  const { args, setArgs } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
      setArgs: state.setArgs,
    })),
  );

  const transactionId = useMemo(
    () => getWizardArg<string>(args, "transactionId"),
    [args],
  );
  const { loading, transaction } = useInventoryTransaction({ transactionId });

  useEffect(() => {
    if (
      !opts?.skipStoreUpdates &&
      transaction &&
      args.releaseId !== transaction?.deliverySlip?.release?.id
    ) {
      setArgs({
        releaseId: transaction?.deliverySlip?.release?.id || "",
      });
    }
  }, [
    args.releaseId,
    opts?.skipStoreUpdates,
    setArgs,
    transaction,
    transaction?.deliverySlip?.release?.id,
  ]);

  return {
    loading,
    transaction,
  };
};
