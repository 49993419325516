import { useMemo } from "react";
import { useIntl } from "react-intl";
import { FormattedCostCode } from "../stores/useStartupDataStore";

export const UNSPECIFIED_COST_CODE_ID = "-1";

export const useUnspecifiedCostCode = () => {
  const intl = useIntl();
  const unassignedCostCode: FormattedCostCode = useMemo(() => {
    return {
      id: UNSPECIFIED_COST_CODE_ID,
      code: "",
      description: intl.$t({ id: "UNSPECIFIED" }),
      formatted: intl.$t({ id: "UNSPECIFIED" }),
      deletedAt: null,
      inUse: false,
      mappings: [],
    };
  }, [intl]);

  return {
    unassignedCostCode,
  };
};
