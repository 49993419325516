import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ViewType } from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { LUMP_SUM_UOM } from "@/common/const";
import { useAddQuoteItems } from "@/common/hooks/add-missing-items-to-order/useAddQuoteItems";
import { usePredictedTax } from "@/common/hooks/usePredictedTax";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { generateUUID } from "@/common/utils/uuidUtils";
import { routes } from "@/config/routes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { usePrefillDefaultTaxable } from "@/contractor/pages/home/release/hooks/usePrefillDefaultTaxable";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { ServiceType } from "@/generated/graphql";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { useCopyQuotePrices } from "../../hooks/useCopyQuotePrices";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";
import { ItemizedQuoteTableItem } from "./CreateOrderFromQuoteItemizedView";

export const useCreateOrderFromQuoteDetails = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openDialog, cancelDialog } = useDialog();
  const { findOrderTypeByLocationId } = useVendors();
  const { release, loading: loadingRelease, releaseId } = useRelease();
  const { quoteDocument } = useQuoteDocument();
  const { setHasChanges } = useReleaseStore();
  const { defaultOrderType } = useOrderTypeOptions();
  const { setTaxInput, predictedTaxRate } = usePredictedTax();
  const { spreadsheetData, handsonInstance } = useColumnMapper();
  const { addQuoteItems } = useAddQuoteItems();
  const { calcTableTotal, findMaterialByName } = useTableHelpers();
  const { copyPriceFromQuote } = useCopyQuotePrices();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { itemized, setItemized } = useOrderFromQuote();
  const { calcExtPrice } = usePriceCalculation();
  const { total } = useFormPriceCalculation();
  const { getCodePartOfCostCode } = useProjectCostCodes();

  const form = useFormContext<CreateOrderFromQuoteFormValues>();
  const { watch, setValue, getValues } = form;

  const [itemizedQuoteTableItems, setItemizedQuoteTableItems] = useState<
    ItemizedQuoteTableItem[]
  >([]);

  const projectId = watch("projectId");
  const sellerOrgLocationId = watch("vendorId");
  const willCall = watch("willCall");
  const warehouseId = watch("fulfillmentLocationId");
  const initialized = useRef(false);

  const { taxable } = usePrefillDefaultTaxable();

  useSetCurrentProjectId(projectId);

  const getItemizedQuoteTableItems = useCallback(() => {
    if (release?.items.length) {
      return release.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(
            item.projectItem?.material.material.name ?? item.name ?? "",
          );

          return {
            ...item,
            id: item.id,
            name: item.name ?? "",
            material: matchingOrgMaterial,
            costCode: item?.costCode?.code ?? undefined,
            notes: item.instructions?.text ?? "",
            UOM: item?.uom?.pluralDescription ?? item?.uom?.mnemonic,
            unitPrice: item.unitPrice ?? undefined,
            quantityDecimal: item.quantityDecimal ?? "",
            extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
            taxable,
          };
        });
    }

    if (!quoteDocument?.items) {
      return [];
    }

    const quoteItems = quoteDocument.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description ?? "");
      const isLumpSum = isLumpSumUomText(item.uom);

      return {
        id: item.id,
        quantityDecimal: (isLumpSum ? item.unitPrice : item.quantity) ?? "",
        name: item.description ?? "",
        material: matchingOrgMaterial,
        costCode:
          release?.costCode?.code ??
          getCodePartOfCostCode(matchingOrgMaterial?.costCode?.id),
        UOM:
          item.uom ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
        extPrice: calcExtPrice(item.quantity, item.unitPrice ?? undefined),
        taxable,
      };
    });
    const totalAmount = quoteItems
      .reduce(
        (agg, item) =>
          agg.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
        new DecimalSafe(0),
      )
      .toNumber();
    if (!totalAmount && quoteDocument.subtotal) {
      quoteItems.push({
        quantityDecimal: quoteDocument.subtotal,
        unitPrice: "1",
        UOM: LUMP_SUM_UOM,
        costCode: "",
        material: undefined,
        name: intl.$t({ id: "SUBTOTAL_AMOUNT" }),
        id: generateUUID(),
        extPrice: calcExtPrice("1", quoteDocument.subtotal),
        taxable,
      });
    }
    return quoteItems;
  }, [
    release?.items,
    release?.costCode?.code,
    quoteDocument?.items,
    quoteDocument?.subtotal,
    findMaterialByName,
    calcExtPrice,
    taxable,
    intl,
    getCodePartOfCostCode,
  ]);

  useEffect(() => {
    setItemizedQuoteTableItems(getItemizedQuoteTableItems());
  }, [getItemizedQuoteTableItems]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      const tableTotal = calcTableTotal(data);
      const newNetAmount = `${tableTotal}`;
      if (!itemized && tableTotal === 0 && quoteDocument?.subtotal) {
        setValue("netAmount", quoteDocument.subtotal);
      } else if (newNetAmount !== getValues("netAmount")) {
        setValue("netAmount", newNetAmount);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calcTableTotal, setHasChanges, setValue, itemized, quoteDocument],
  );

  useEffect(() => {
    const hasRelease = !loadingRelease && releaseId;
    setValue(
      "businessLocationId",
      getValues("businessLocationId") ||
        release?.project?.location?.id ||
        (hasRelease ? null : quoteDocument?.orgLocation?.id) ||
        "",
    );
    setValue(
      "customTaxAmount",
      getValues("customTaxAmount") ||
        release?.customTaxAmount ||
        (!hasRelease && !getValues("taxRate")
          ? quoteDocument?.taxAmount
          : "") ||
        undefined,
    );
    setValue(
      "projectId",
      getValues("projectId") ||
        release?.project?.id ||
        (!loadingRelease
          ? quoteDocument?.predictedProject?.id ||
            searchParams.get("projectId") ||
            ""
          : "") ||
        "",
    );
    setValue(
      "vendorId",
      getValues("vendorId") ||
        release?.sellerOrgLocation?.id ||
        (hasRelease ? null : quoteDocument?.predictedSellerOrgLocation?.id) ||
        "",
    );
    setValue(
      "paymentTerm",
      getValues("paymentTerm") ||
        release?.paymentTerm ||
        (!loadingRelease ? quoteDocument?.paymentTerm : "") ||
        undefined,
    );
    setValue("poNumber", getValues("poNumber") || release?.poNumber || "");
    setValue(
      "vendorContactIds",
      getValues("vendorContactIds") ||
        (release?.vendorContacts.length === 1
          ? release?.vendorContacts?.map((vc) => vc.id)
          : []) ||
        [],
    );
    if (release?.time) {
      setValue("orderDate", defaultReleaseDate(getUTCDate(release.time)));
    }
    setValue("timeTBD", getValues("timeTBD") ?? release?.timeTBD);
    if (release?.additionalCharges) {
      setValue("additionalCharges", release.additionalCharges);
    }
    if (release?.items.length === 1 && release?.items[0].costCode) {
      setValue("costCodeId", release.items[0].costCode.id);
    }
    setValue("taxRate", getValues("taxRate") || release?.taxRate || undefined);
    setValue("taxVariance", getValues("taxVariance") || release?.taxVariance);
    if (release?.type) {
      setValue("orderTypeId", release.type.id);
    } else {
      if (sellerOrgLocationId) {
        const orderTypeId = findOrderTypeByLocationId(sellerOrgLocationId);
        if (orderTypeId) {
          setValue("orderTypeId", orderTypeId);
        }
      }
      if (defaultOrderType) {
        setValue("orderTypeId", defaultOrderType.id);
      }
    }
    setValue("instructions", {
      text: release?.instructions?.text ?? "",
      assetUrls: release?.instructions?.assets.map((asset) => asset.url) ?? [],
    });
    setValue(
      "willCall",
      release?.id
        ? !release?.includeServices.includes(ServiceType.Delivery)
        : false,
    );
    setValue(
      "vendorStocking",
      release?.id
        ? release?.includeServices.includes(ServiceType.Stocking)
        : false,
    );
  }, [
    getValues,
    loadingRelease,
    quoteDocument?.orgLocation?.id,
    quoteDocument?.paymentTerm,
    quoteDocument?.predictedProject?.id,
    quoteDocument?.predictedSellerOrgLocation?.id,
    quoteDocument?.taxAmount,
    release?.additionalCharges,
    release?.customTaxAmount,
    release?.items,
    release?.paymentTerm,
    release?.poNumber,
    release?.project?.id,
    release?.project?.location?.id,
    release?.sellerOrgLocation?.id,
    release?.taxRate,
    release?.taxVariance,
    release?.time,
    release?.type,
    release?.timeTBD,
    release?.vendorContacts,
    releaseId,
    searchParams,
    setValue,
    release?.instructions?.text,
    release?.instructions?.assets,
    release?.id,
    release?.includeServices,
    sellerOrgLocationId,
    defaultOrderType,
    findOrderTypeByLocationId,
  ]);

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal, itemized]);

  useEffect(() => {
    setTaxInput({
      projectId,
      sellerOrgLocationId,
      willCall,
      warehouseId: willCall
        ? undefined
        : projectId !== warehouseId && warehouseId !== PROJECT_ADDRESS_ID
          ? warehouseId || undefined
          : undefined,
    });
  }, [projectId, sellerOrgLocationId, willCall, warehouseId, setTaxInput]);

  useEffect(() => {
    const taxRate = getValues("taxRate");
    if (
      predictedTaxRate &&
      (taxRate === undefined || taxRate === "") &&
      !quoteDocument?.taxAmount &&
      !release
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "NO" }),
        confirmButtonText: intl.$t({ id: "YES" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "OVERWRITE_EXISTING_SALES_TAX_QUESTION" }),
        text: intl.$t(
          {
            id: "OVERWRITE_EXISTING_SALES_TAX",
          },
          {
            value: formatCurrency(
              new DecimalSafe(predictedTaxRate).mul(getValues("netAmount")),
              {
                minimumFractionDigits: 2,
              },
            ),
          },
        ),
        handleConfirm: () => {
          setValue("taxRate", predictedTaxRate);
        },
        handleCancel: () => {
          setValue("taxRate", "0");
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCurrency, intl, predictedTaxRate, setValue, release]);

  const handleClick = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [release, navigate]);

  const handleToggleItemizedView = useCallback(
    (viewItemized: ViewType) => {
      if (release) {
        setItemized(viewItemized === ViewType.Itemized);
      } else if (viewItemized === ViewType.Itemized) {
        openDialog({
          includeWarningIcon: true,
          title: intl.$t({ id: "RESCAN_QUOTE" }),
          text: intl.$t({ id: "RESCAN_QUOTE_TEXT" }),
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          customButtonText: intl.$t({ id: "CLEAR_ORDER" }),
          handleCustomButtonAction: () => {
            setItemized(true);
            setItemizedQuoteTableItems([]);
            cancelDialog?.();
          },
          confirmButtonText: intl.$t({ id: "RESCAN" }),
          handleConfirm: () => {
            setItemized(true);
            setItemizedQuoteTableItems([]);
            setTimeout(() => {
              setItemizedQuoteTableItems(getItemizedQuoteTableItems());
            });
            cancelDialog?.();
          },
        });
      } else {
        setItemized(false);
      }
    },
    [
      setItemized,
      setItemizedQuoteTableItems,
      getItemizedQuoteTableItems,
      openDialog,
      cancelDialog,
      intl,
      release,
    ],
  );

  useEffect(() => {
    if (
      release &&
      release.items.length === 0 &&
      !initialized.current &&
      !handsonInstance?.isDestroyed
    ) {
      addQuoteItems();
      initialized.current = true;
    }
  }, [addQuoteItems, handsonInstance?.isDestroyed, release]);

  return {
    handleToggleItemizedView,
    itemized,
    handleClick,
    copyPriceFromQuote,
    addQuoteItems,
    itemizedQuoteTableItems,
    total,
    updateSubtotal,
  };
};
