import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  LinkBudgetInput,
  UpdateBudgetLinkInput,
  UpdateProjectInput,
  useLinkBudgetMutation,
  useProjectAllowanceQuery,
  useSyncBudgetMutation,
  useUnlinkBudgetMutation,
  useUpdateBudgetLinkMutation,
  useUpdateProjectAllowanceMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../store/useProjectStore";
import { useBudgetStore } from "../store/useBudgetStore";

type ProjectBudgetInput = Pick<
  UpdateProjectInput,
  "budgetAllowance" | "restrictCostCodes"
>;

export const useProjectBudget = () => {
  const { id } = useParams();
  const { data, error, loading } = useProjectAllowanceQuery({
    variables: { id: id ?? "" },
    skip: !id,
  });
  const { setError } = useGlobalError();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const [updateProjectMutation] = useUpdateProjectAllowanceMutation();
  const {
    updateProjectStoreData,
    allowance,
    zones,
    currentProjectId,
    budgetLink,
  } = useProjectStore(
    useShallow((state) => ({
      updateProjectStoreData: state.updateStoreData,
      allowance: state.allowance,
      zones: state.zones,
      currentProjectId: state.currentProjectId,
      budgetLink: state.budgetLink,
    })),
  );

  const { updateStoreData: updateBudgetStoreData, isZoneSpecificBudget } =
    useBudgetStore(
      useShallow((state) => ({
        updateStoreData: state.updateStoreData,
        isZoneSpecificBudget: state.isZoneSpecificBudget,
      })),
    );

  useErrorEffect(error);

  const updateProjectBudget = useCallback(
    async (input: ProjectBudgetInput) => {
      try {
        const { data: updatedProject, errors } = await updateProjectMutation({
          variables: {
            input: {
              id: id ?? "",
              ...input,
              budgetAllowance: {
                ...input.budgetAllowance,
                tags: budgetLink?.autoSync
                  ? undefined
                  : (input.budgetAllowance?.tags ?? []),
                costCodes: budgetLink?.autoSync
                  ? undefined
                  : (input.budgetAllowance?.costCodes ?? []),
              },
            },
          },
        });
        setError(errors);
        if (updatedProject?.updateProject) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_ALLOWANCE_UPDATED_SUCCESSFULLY",
            }),
          );

          updateProjectStoreData(updatedProject?.updateProject);
          updateBudgetStoreData(allowance);
          return true;
        }
      } catch (errors) {
        setError(errors);
      }
      return false;
    },
    [
      updateProjectMutation,
      id,
      budgetLink?.autoSync,
      setError,
      setSuccessAlert,
      intl,
      updateProjectStoreData,
      updateBudgetStoreData,
      allowance,
    ],
  );

  const [linkBudgetMutation, { loading: linkBudgetLoading }] =
    useLinkBudgetMutation();
  const linkBudget = useCallback(
    async (input: LinkBudgetInput) => {
      try {
        const { data, errors } = await linkBudgetMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.linkBudget) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_LINKED_SUCCESSFULLY",
            }),
          );

          updateProjectStoreData({
            budgetLink: data?.linkBudget,
            allowance: data?.linkBudget?.project?.allowance,
          });
        }
        return data;
      } catch (error) {
        setError(error);
      }
    },
    [
      linkBudgetMutation,
      updateProjectStoreData,
      setError,
      intl,
      setSuccessAlert,
    ],
  );

  const [syncBudgetMutation, { loading: syncBudgetLoading }] =
    useSyncBudgetMutation();
  const syncBudget = useCallback(
    async (budgetLinkId: string) => {
      try {
        const { data, errors } = await syncBudgetMutation({
          variables: {
            budgetLinkId,
          },
        });
        setError(errors);
        if (data?.syncBudget?.budgetLink) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_SYNCED_SUCCESSFULLY",
            }),
          );

          updateProjectStoreData({
            allowance: data?.syncBudget?.allowance,
            budgetLink: data?.syncBudget?.budgetLink,
            costCodes: data?.syncBudget?.costCodes,
          });
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      syncBudgetMutation,
      updateProjectStoreData,
      setError,
      intl,
      setSuccessAlert,
    ],
  );

  const [unlinkBudgetMutation, { loading: unlinkBudgetLoading }] =
    useUnlinkBudgetMutation();
  const unlinkBudget = useCallback(
    async (budgetLinkId: string) => {
      try {
        const { data, errors } = await unlinkBudgetMutation({
          variables: {
            budgetLinkId,
          },
        });
        setError(errors);
        if (data?.unlinkBudget) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_UNLINKED_SUCCESSFULLY",
            }),
          );

          updateProjectStoreData({
            budgetLink: undefined,
          });
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      unlinkBudgetMutation,
      updateProjectStoreData,
      setError,
      intl,
      setSuccessAlert,
    ],
  );

  const [updateBudgetLinkMutation, { loading: updateBudgetLinkLoading }] =
    useUpdateBudgetLinkMutation();
  const updateBudgetLink = useCallback(
    async (input: UpdateBudgetLinkInput) => {
      try {
        const { data, errors } = await updateBudgetLinkMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.updateBudgetLink) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_UPDATED_SUCCESSFULLY",
            }),
          );

          updateProjectStoreData({
            budgetLink: data?.updateBudgetLink,
            allowance: data?.updateBudgetLink?.project.allowance,
            costCodes: data?.updateBudgetLink?.project.costCodes,
          });
          updateBudgetStoreData(
            data?.updateBudgetLink?.project.allowance ?? allowance,
          );
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      updateBudgetLinkMutation,
      updateProjectStoreData,
      updateBudgetStoreData,
      setError,
      intl,
      setSuccessAlert,
      allowance,
    ],
  );

  return {
    budget: data?.project?.allowance ?? null,
    loading,
    updateProjectBudget,
    updateBudgetLink,
    updateBudgetLinkLoading,
    unlinkBudget,
    unlinkBudgetLoading,
    linkBudget,
    linkBudgetLoading,
    syncBudget,
    syncBudgetLoading,
    allowance,
    zones,
    currentProjectId,
    budgetLink,
    isZoneSpecificBudget,
  };
};
