import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptFooterState } from "../../../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../../../types/ReceiptMatchedOrderViewState";

const Container = tw.div`
  flex flex-col justify-center items-center py-16
  border-dashed border border-gray-600 rounded-3xl mx-4
`;
const Row = tw.div`flex flex-row`;
const InfoIcon = tw(InfoOutlined)`text-gray-500 w-6 h-6 mr-2`;
const Title = tw.div`text-lg font-medium mb-3`;
const Description = tw.div`text-sm text-gray-600 font-normal text-center mb-10 px-8`;

export const NoReleaseForReceiptInfo: FC = () => {
  const { setFooterState, setMatchedOrderViewState } = useReceipt();

  return (
    <Container>
      <Row>
        <InfoIcon />
        <FormattedMessage id="MATCH_RECEIPT_TO_AN_ORDER" tagName={Title} />
      </Row>
      <FormattedMessage
        id="MATCH_RECEIPT_TO_AN_ORDER_DESCRIPTION"
        tagName={Description}
      />
      <Row>
        <PrimaryButton
          className="mr-2 w-[200px] rounded-[90px]"
          onClick={() => {
            setFooterState(ReceiptFooterState.MATCH_ORDER);
            setMatchedOrderViewState(ReceiptMatchedOrderViewState.MATCH_ORDER);
          }}
        >
          <FormattedMessage id="SELECT_ORDER_MANUALLY_IN_FM" />
        </PrimaryButton>
        <PrimaryButton
          className="w-[180px] rounded-[90px]"
          onClick={() => {
            setFooterState(ReceiptFooterState.DEFAULT);
            setMatchedOrderViewState(ReceiptMatchedOrderViewState.CREATE_ORDER);
          }}
          testId="create-new-order-from-receipt"
        >
          <FormattedMessage id="CREATE_NEW_ORDER_FROM_RECEIPT_CONTENT" />
        </PrimaryButton>
      </Row>
    </Container>
  );
};
