import { useSelectKickbackUsers } from "@/common/hooks/useSelectKickbackUsers";
import { getUserName } from "@/common/utils/users/getUserName";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  InvoiceFieldsFragment,
  UsersUserFieldsFragment,
} from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

const VENDOR_CONTACTS_STRING_MAX_LENGTH = 20;
export const VENDOR_CONTACTS_FAKE_ID = "VENDOR_CONTACTS_FAKE_ID";

export const useInvoiceKickbackForm = ({
  invoice,
  users,
}: {
  invoice: InvoiceFieldsFragment | null;
  users: UsersUserFieldsFragment[];
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { watch, setValue } = useFormContext();
  const assigneeIds = watch("assigneeIds") as string[];

  useSelectKickbackUsers({
    createdById: invoice?.release?.createdBy?.id,
    users,
    hasRelease: !!invoice?.release,
  });

  useEffect(() => {
    if (
      assigneeIds.some((id) => id === VENDOR_CONTACTS_FAKE_ID) &&
      assigneeIds.length > 1
    ) {
      if (assigneeIds[assigneeIds.length - 1] === VENDOR_CONTACTS_FAKE_ID) {
        setValue("assigneeIds", [VENDOR_CONTACTS_FAKE_ID]);
      } else {
        setValue(
          "assigneeIds",
          assigneeIds.filter((id) => id !== VENDOR_CONTACTS_FAKE_ID),
        );
      }
    }
  }, [assigneeIds, setValue]);

  const kickbackUserOptions = useMemo(() => {
    let vendorContacts = invoice?.release?.preferredVendor?.contacts
      .filter((contact) => contact.receivesInvoiceNotifications)
      .map((contact) => contact.name)
      .join(", ");
    if (
      vendorContacts &&
      vendorContacts.length > VENDOR_CONTACTS_STRING_MAX_LENGTH
    ) {
      vendorContacts = `${vendorContacts?.substring(0, VENDOR_CONTACTS_STRING_MAX_LENGTH)}...`;
    }

    return [
      ...(invoice?.release?.preferredVendor && vendorContacts
        ? [
            {
              id: VENDOR_CONTACTS_FAKE_ID,
              label: vendorContacts,
              isOrderWatcher: false,
              group: intl.$t({ id: "EXTERNAL" }),
            },
          ]
        : []),
      ...(users.map((user) => {
        const isOrderWatcher = release?.watchers.some(
          (releaseWatcher) => releaseWatcher.id === user.id,
        );
        return {
          id: user.id,
          label: getUserName(user),
          isOrderWatcher,
          group: intl.$t({ id: "INTERNAL_TEAM_MEMBERS" }),
        };
      }) || []),
    ];
  }, [intl, invoice?.release?.preferredVendor, users, release?.watchers]);

  return {
    kickbackUserOptions,
  };
};
