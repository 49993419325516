import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useBuyoutStore } from "../../../store/useBuyoutStore";

export const useProjectItems = () => {
  const { project } = useProject();
  const { estimatedItemsMap } = useProjectMaps(project);
  const buyout = useBuyoutStore((state) => state.buyout);
  const { expandedItems } = useEstimatedItems();
  const { zones } = useProjectItemsZones();

  // We need to filter out estimated items that were already added and with inappropriate vendors
  const condition = useMemo(
    () =>
      (project?.items.filter(
        (item) =>
          item.estimatedItems.some((estimatedItem) => {
            const ei = estimatedItemsMap.get(estimatedItem.id);
            return (
              ei?.sellerOrgLocation?.id == null ||
              ei?.sellerOrgLocation?.id === buyout?.sellerOrgLocation.id
            );
          }) &&
          !buyout?.items.some(
            (buyoutItem) =>
              buyoutItem.description === item.material.material.name,
          ),
      ).length || 0) > 0,
    [
      buyout?.items,
      buyout?.sellerOrgLocation.id,
      estimatedItemsMap,
      project?.items,
    ],
  );

  const getExpandedItems = useCallback(
    (item: ProjectItemFieldsFragment) => {
      const estimatedItems = item.estimatedItems;
      if (
        expandedItems.some(
          (i) =>
            i.id === item.id &&
            (!i.zoneId ||
              i.zoneId === UNSPECIFIED_ZONE_ID ||
              estimatedItems.find((e) => e.zone?.id === i.zoneId)),
        ) &&
        estimatedItems.length > 1
      ) {
        return estimatedItems.map(() => item);
      }

      return [];
    },
    [expandedItems],
  );

  const filteredZones = useMemo(
    () =>
      zones
        .map((zone) => ({
          ...zone,
          items: zone.items
            .map((item) => ({
              ...item,
              items: item.items.filter(
                (subItem) =>
                  subItem.estimatedItems.some(
                    (estimatedItem) =>
                      estimatedItem?.sellerOrgLocation?.id == null ||
                      estimatedItem.sellerOrgLocation.id ===
                        buyout?.sellerOrgLocation?.id,
                  ) &&
                  !buyout?.items.some(
                    (buyoutItem) =>
                      buyoutItem.description === subItem.material.material.name,
                  ),
              ),
            }))
            .filter((item) => item.items.length),
        }))
        .filter((zone) => zone.items.length),

    [buyout?.items, buyout?.sellerOrgLocation?.id, zones],
  );

  return { project, condition, getExpandedItems, filteredZones };
};
