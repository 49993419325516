import { getTotalTaxAmount } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { extPriceCalculation } from "../hooks/usePriceCalculation";
import { UpdateReleaseNomenclature } from "./useReleaseStore";

export const getReleaseNetAmount = (
  items?: ReleaseFieldsFragment["items"],
  opts?: { taxable: boolean },
): Decimal =>
  (items || []).reduce((acc, item) => {
    const extPrice =
      !opts?.taxable || item.taxable
        ? acc.add(extPriceCalculation(item.quantityDecimal, item.unitPrice))
        : acc;

    return extPrice;
  }, new DecimalSafe(0));
export const getReleaseAdditionalCharges = (
  charges: Pick<ReleaseFieldsFragment["additionalCharges"][0], "amount">[],
) =>
  charges.reduce(
    (acc, charge) => acc.add(new DecimalSafe(charge.amount)),
    new DecimalSafe(0),
  );

const getReleaseSubtotalWithCharges = (release: ReleaseFieldsFragment) =>
  getReleaseNetAmount(release.items).plus(
    getReleaseAdditionalCharges(release.additionalCharges),
  );

const getReleaseTaxableNetAmountWithCharges = (
  release: ReleaseFieldsFragment,
) =>
  getReleaseNetAmount(release.items, { taxable: true }).plus(
    getReleaseAdditionalCharges(release.additionalCharges),
  );

export const getReleaseTotal = (release: ReleaseFieldsFragment) => {
  const netAmountWithCharges = getReleaseSubtotalWithCharges(release);
  const taxableNetAmountWithCharges =
    getReleaseTaxableNetAmountWithCharges(release);
  const taxVariance = new DecimalSafe(release.taxVariance || 0);
  const taxAmount = getTotalTaxAmount({
    ...release,
    netAmount: taxableNetAmountWithCharges.toString(),
  });
  return netAmountWithCharges.plus(taxAmount).plus(taxVariance);
};

export const applyInputChanges = (
  release: ReleaseFieldsFragment,
  input: UpdateContractorReleaseInput,
  nomenclature: UpdateReleaseNomenclature = {
    taxCodes: [],
    orderTypes: [],
  },
): ReleaseFieldsFragment => {
  const { taxCodes, orderTypes } = nomenclature;
  const orderType =
    orderTypes.find((type) => type.id === input.typeId) || release.type;
  const taxCode = input.clearTaxCode
    ? null
    : taxCodes.find((code) => code.id === input.taxCodeId) || release.taxCode;

  return {
    ...release,
    type: orderType || release.type,
    paymentTerm: input.paymentTerm ?? release.paymentTerm,
    taxType: input.taxType || release.taxType,
    taxCode: taxCode,
    taxRate: input.customTaxAmount
      ? Number(getReleaseTaxableNetAmountWithCharges(release))
        ? new DecimalSafe(input.customTaxAmount)
            .div(getReleaseTaxableNetAmountWithCharges(release))
            .toString()
        : "0"
      : input.taxRate || release.taxRate,
    customTaxAmount: Object.hasOwn(input, "customTaxAmount")
      ? input.customTaxAmount
      : input.clearCustomTaxAmount
        ? null
        : release.customTaxAmount,
    taxAmount:
      input.customTaxAmount || input.taxRate || taxCode
        ? new DecimalSafe(taxCode ? taxCode.rate : input.taxRate || 0)
            .mul(getReleaseTaxableNetAmountWithCharges(release))
            .toString()
        : release.taxAmount,
    taxVariance: input.clearTaxVariance
      ? null
      : input.taxVariance || release.taxVariance,
    additionalCharges:
      input.additionalCharges?.map((charge, index) => ({
        ...charge,
        id: charge.id || index.toString(),
        amount: charge.amount.toString(),
      })) || release.additionalCharges,
    items: release.items
      .filter((item) => !input.removedItems?.includes(item.id))
      .map((item) => {
        const updatedItem = input.updates?.find(
          (update) => update.releaseItemId === item.id,
        );
        if (!updatedItem) {
          return item;
        }
        return {
          ...item,
          unitPrice: updatedItem.unitPrice || item.unitPrice,
          quantityDecimal: updatedItem.quantityDecimal || item.quantityDecimal,
          taxable: updatedItem.taxable ?? item.taxable,
        };
      }),
  };
};
