import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliveryPhotos } from "@/common/components/release-images/ReleaseDeliveryPhotos";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { EDITABLE_RELEASE_STATUSES } from "@/common/const";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import {
  AuthorizationStatus,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { SpecifyCostCode } from "../../../../../../common/components/specify-cost-code/SpecifyCostCode";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useReleaseNavigation } from "../../../releases/pages/deliveries/hooks/useReleaseNavigation";
import { ReleaseBreadcrumbs } from "../../components/ReleaseBreadcrumbs";
import { ReleaseDescription } from "../../components/ReleaseDescription";
import { ReleaseActionsProvider } from "../../providers/ReleaseActionsProvider";
import { ReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "../../providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "../../release-items-list/ReleaseItemList";
import { useReleaseStore } from "../../store/useReleaseStore";
import { AddNoteSidePanel } from "../specify-details/components/add-note-btn/AddNoteSidePanel";
import { AddQuoteSidePanel } from "../specify-details/components/add-quote-btn/AddQuoteSidePanel";
import { ImportMaterialsDrawer } from "../specify-details/components/import-from-file/ImportReleaseItemsDrawer";
import { ReleaseRejectReasonView } from "../specify-details/footer-actions/reject-reason/ReleaseRejectReasonView";
import { useReleaseDetailsConfiguration } from "./ReleaseDetails.configuration";
import { ReleaseDetailsFooter } from "./ReleaseDetailsFooter";
import { ReleaseDetailsHeader } from "./ReleaseDetailsHeader";
import { ReleaseInvoices } from "./ReleaseInvoices";
import { ReleaseReceipts } from "./ReleaseReceipts";

const Container = tw.div`pb-15`;

const ReleaseDetailsWithProvider: FC = () => {
  const { release, refetch } = useRelease();
  const releaseDetailsConfiguration = useReleaseDetailsConfiguration(release);
  const navigate = useNavigate();
  const setHasChanges = useReleaseStore((state) => state.setHasChanges);
  const { getReleaseEditPath } = useReleaseNavigation();

  useEffect(() => {
    refetch();
    setHasChanges(false);
  }, [refetch, setHasChanges]);

  useEffect(() => {
    if (
      release &&
      checkReleaseStatus(release, EDITABLE_RELEASE_STATUSES) &&
      release.permissions.submit === AuthorizationStatus.Authorized
    ) {
      const path = getReleaseEditPath({
        id: release.id,
        status: release.status,
        itemCount: release.items.length,
        noteDocument: release.noteDocument,
        quoteDocument: release.quoteDocument,
      });
      navigate(
        generatePath(path, {
          deliveryId: release.id,
          noteDocumentId: release.noteDocument?.id || null,
          quoteDocumentId: release.quoteDocument?.id || null,
        }),
        { replace: true },
      );
    }
  }, [release, getReleaseEditPath, navigate]);

  const itemFn = useCallback(
    (item: ReleaseItemFieldsFragment) => {
      if (release?.deletedAt) {
        return {
          className: "bg-gray-100",
        };
      }
      return {
        className: `${item.isIncluded ? "" : "bg-gray-100"}`,
      };
    },
    [release?.deletedAt],
  );
  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseActionsProvider>
      <Container>
        <ReleaseBreadcrumbs canGroupPrintData />
        <ReleaseDetailsHeader />
        <ReleaseItemList
          columns={releaseDetailsConfiguration}
          classNames={{
            header: "top-[32px]",
            category: "top-[36px] lg:top-[77px]",
            subCategory: (groupedByZones: boolean) =>
              groupedByZones
                ? "top-[76px] lg:top-[110px]"
                : "top-[36px] lg:top-[80px]",
          }}
          itemFn={itemFn}
        />
        <Instructions
          instruction={release.instructions}
          projectId={release.project?.id}
        >
          <SpecifyCostCode
            costCode={release.costCode}
            containerClassName="my-2"
          />
          <ReleaseDescription release={release} disableEdit />
        </Instructions>
        <ReleaseDeliverySlips deliverySlips={release.deliverySlips} />
        <ReleaseDeliveryPhotos release={release} />
        <ReleaseInvoices release={release} />
        <ReleaseReceipts release={release} />
        <ReleaseRejectReasonView rejectionReason={release?.rejectionReason} />
        <QuoteDocumentPanel
          quoteDocument={release.quoteDocument}
          quote={release.quote}
          readonly
          customRedirect={() => {
            navigate(
              generatePath(routes.editDeliveryFromQuote, {
                quoteDocumentId: release.quoteDocument?.id,
                deliveryId: release.id,
              }),
            );
          }}
        >
          {release.deletedAt ||
          release.quoteDocument ||
          !!release.poLink?.immutable ? null : (
            <AddQuoteSidePanel />
          )}
        </QuoteDocumentPanel>
        <NoteDocumentPanel
          noteDocument={release.noteDocument}
          readonly
          customRedirect={() => {
            navigate(
              generatePath(routes.editDeliveryFromNote, {
                noteDocumentId: release.noteDocument?.id,
                deliveryId: release.id,
              }),
            );
          }}
        >
          {release.deletedAt ||
          release.noteDocument ||
          !!release.poLink?.immutable ? null : (
            <AddNoteSidePanel />
          )}
        </NoteDocumentPanel>
        <ImportMaterialsDrawer />
        <ReleaseDetailsFooter />
      </Container>
    </ReleaseActionsProvider>
  );
};

export const ReleaseDetails = () => {
  const { release } = useRelease();

  useSetCurrentProjectId(release?.project?.id);

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release?.items],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={items}
    >
      <ReleaseItemsZoneProvider items={items}>
        <ReleaseDetailsWithProvider />
      </ReleaseItemsZoneProvider>
    </ReleaseUpdateProvider>
  );
};
