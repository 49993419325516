import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { routes } from "@/config/routes";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useInvoiceVerification } from "../../../../../invoice-verification/providers/InvoiceVerificationProvider";
import { useSplitInvoiceDocument } from "../../../../hooks/useSplitInvoiceDocument";
import { useInvoiceCreation } from "../../../../providers/InvoiceCreationProvider";
import { useInvoiceSequence } from "../../../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../../../providers/InvoicesProvider";
import { useSplittingInvoicesWizard } from "../../../splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceFormProps } from "../types/InvoiceFormProps";

export const useInvoiceForm = ({
  releaseId,
  onClose,
}: Pick<InvoiceFormProps, "releaseId" | "onClose">) => {
  const navigate = useNavigate();

  const { splitInvoiceDocument } = useSplitInvoiceDocument();
  const { createInvoices } = useInvoices();
  const { assets } = useUploadAssets();
  const { openWizard } = useSplittingInvoicesWizard();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { locationId } = useInvoiceCreation();
  const { startSequence } = useInvoiceSequence();

  const save = useCallback(async () => {
    if (!assets || !locationId) {
      return;
    }
    const createdInvoices: InvoiceFieldsFragment[] = [];

    await Promise.all(
      assets.map(async (asset) => {
        const pageRanges = await splitInvoiceDocument({
          assetUrl: asset.url,
        });
        if (!pageRanges) {
          return;
        }
        // automatically create invoices if there is only one page range and only one invoice
        if (
          assets.length > 1 ||
          (pageRanges.length === 1 && pageRanges[0].start === 1)
        ) {
          const invoices = await createInvoices({
            assetUrl: asset.url,
            pageRanges,
            orgLocationId: locationId,
          });

          if (releaseId && invoices) {
            await Promise.all(
              invoices?.map((invoice) =>
                updateInvoice({
                  id: invoice.id,
                  releaseId: releaseId,
                }),
              ),
            );
          }
          if (invoices) {
            createdInvoices.push(...invoices);
          }
        } else {
          openWizard({
            initialPage: 0,
            urls: assets.map((asset) => asset.url),
            pageRanges,
            locationId,
          });
        }
      }),
    );

    if (createdInvoices && createdInvoices.length) {
      if (createdInvoices.length === 1) {
        const firstInvoice = createdInvoices[0];
        navigate(
          generatePath(routes.invoiceVerification, {
            invoiceId: firstInvoice.id,
          }),
        );
      } else {
        startSequence(createdInvoices);
      }
    }
    onClose();
  }, [
    assets,
    createInvoices,
    locationId,
    navigate,
    onClose,
    openWizard,
    releaseId,
    splitInvoiceDocument,
    startSequence,
    updateInvoice,
  ]);

  const saveCorrection = useCallback(async () => {
    if (!!invoice && !!assets[0]) {
      await updateInvoice(
        {
          id: invoice?.id,
          assetUrl: assets[0].url,
        },
        { includeDocuments: true },
      );
      onClose();
    }
  }, [invoice, assets, updateInvoice, onClose]);

  return {
    save,
    saveCorrection,
  };
};
