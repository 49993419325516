import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { getInventoryItemFromTransaction } from "../../../../../../../utils/getInventoryItemFromTransaction";
import { TransactionHistoryItemName } from "../../TransactionHistory.styles";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
  inventoryItemId: string;
};

export const TransactionExtPrice = ({ item, inventoryItemId }: Props) => {
  const { calcExtPrice } = usePriceCalculation();
  const inventoryItem = useMemo(
    () => getInventoryItemFromTransaction(item, inventoryItemId),
    [inventoryItemId, item],
  );
  const extPrice = useMemo(
    () => calcExtPrice(inventoryItem?.quantity, inventoryItem?.unitPrice),
    [inventoryItem, calcExtPrice],
  );

  return (
    <NotNullableRenderer value={inventoryItem}>
      <TransactionHistoryItemName>
        <Price price={extPrice} />
      </TransactionHistoryItemName>
    </NotNullableRenderer>
  );
};
