import { DateView } from "@/common/utils/dates/DateView";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const DateItem = tw.div`flex items-center justify-center text-sm font-medium text-center`;

type Props = {
  rfq: Pick<RfqsRfqFieldsFragment, "createdAt">;
};

export const RfqCreateDate: FC<Props> = ({ rfq }) => {
  return (
    <DateItem>
      <DateView date={rfq.createdAt} />
    </DateItem>
  );
};
