import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useCallback, useState } from "react";

import { useReceipt } from "../providers/ReceiptProvider";
import { ReceiptFooterState } from "../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../types/ReceiptMatchedOrderViewState";

export const useAssignOrderToReceipt = () => {
  const { setError } = useGlobalError();
  const {
    receipt,
    setFooterState,
    updateReceipt,
    setMatchedOrderViewState,
    selectedRelease,
  } = useReceipt();

  const [isAssigningOrderToReceipt, setIsAssigningOrderToReceipt] =
    useState(false);

  const assignOrderToReceipt = useCallback(async () => {
    if (!receipt || !selectedRelease) {
      return false;
    }
    try {
      setIsAssigningOrderToReceipt(true);
      const result = await updateReceipt({
        id: receipt.id,
        releaseId: selectedRelease.id ?? undefined,
      });
      if (result?.updateReceipt) {
        setMatchedOrderViewState(ReceiptMatchedOrderViewState.CREATE_ORDER);
        setFooterState(ReceiptFooterState.DEFAULT);
        return true;
      }
      return false;
    } catch (error) {
      setError(error);
    } finally {
      setIsAssigningOrderToReceipt(false);
    }
  }, [
    receipt,
    selectedRelease,
    setError,
    setFooterState,
    setMatchedOrderViewState,
    updateReceipt,
  ]);

  return { assignOrderToReceipt, isAssigningOrderToReceipt };
};
