import { If } from "@/common/components/if/If";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getIsNativeSalesTax } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useUser } from "@/common/providers/UserProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ReleaseStatus,
  ServiceType,
  TransactionKind,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { ReleaseGroups } from "../release-details/ReleaseGroups";
import { ReleaseVendorContactSelector } from "./ReleaseVendorContactSelector";
import { ReleaseVendorSelector } from "./ReleaseVendorSelector";
import { ReleaseDateTimePicker } from "./components/release-date-time-picker/ReleaseDateTimePicker";
import { SiteContactPicker } from "./components/site-contact-picker/components/SiteContactPicker";
import { useCanEditReleaseVendor } from "./hooks/useCanEditReleaseVendor";
import { useReleasePredictedPoNumber } from "./hooks/useReleasePredictedPoNumber";

const inputProps = {
  className: "text-sm",
};

const inputLabelProps = {
  classes: {
    shrink: "text-base",
    root: "truncate text-sm max-w-full",
  },
};

export const DeliveryInformation = () => {
  const { release } = useRelease();
  const { predictedPoNumber, loading } = useReleasePredictedPoNumber(
    release?.id || "",
  );
  const { updateRelease } = useReleaseUpdate();
  const { requestedDate, setRequestedDate } = useReleaseActions();
  const { includeOrderTypes, getOrderType } = useOrderTypeOptions();
  const { viewer } = useUser();
  const { canEditVendor } = useCanEditReleaseVendor();

  const update = async (poNumber?: string) => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release.id,
        version: release.version,
        poNumber,
      };

      await updateRelease(input);
    }
  };

  const onDateConfirm = async (date: Date | null | undefined) => {
    if (release) {
      const isTBD = date === undefined;
      const requestedDate = defaultReleaseDate(date);
      setRequestedDate(requestedDate ?? null);
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
      };

      input.requestedTime = !isTBD ? requestedDate.getTime() : undefined;
      input.timeTBD = isTBD;
      await updateRelease(input);
    }
  };

  const predictedPoNumberPlaceholder = useMemo(() => {
    if (
      checkReleaseStatus(release, [
        ReleaseStatus.Draft,
        ReleaseStatus.Reserved,
        ReleaseStatus.AwaitingApproval,
        ReleaseStatus.Rejected,
      ])
    ) {
      return predictedPoNumber || undefined;
    }
  }, [predictedPoNumber, release]);

  const setOrderTypeId = useCallback(
    (id: string | null) => {
      const isColumnTaxMode = getIsNativeSalesTax(getOrderType(id));

      updateRelease({
        releaseId: release?.id || "",
        version: release?.version,
        typeId: id,
        clearTaxCode: !isColumnTaxMode,
        clearCustomTaxAmount: isColumnTaxMode,
      });
    },
    [getOrderType, release?.id, release?.version, updateRelease],
  );

  return (
    <>
      <FormControl fullWidth>
        <If
          isTrue={
            release?.includeServices.includes(ServiceType.Delivery) ||
            release?.type.transactionKind === TransactionKind.Purchase
          }
        >
          <WarehouseSelector
            value={release?.warehouse?.id}
            projectAddress={release?.project?.address}
            onChange={(warehouseId) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                warehouseId:
                  warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
                clearWarehouse: warehouseId === PROJECT_ADDRESS_ID,
              })
            }
            inputProps={inputProps}
            includeWarehouseInName
          />
        </If>
      </FormControl>
      <If isTrue={canEditVendor}>
        <ReleaseVendorSelector />
      </If>
      <If isTrue={includeOrderTypes}>
        <FormControl fullWidth>
          <OrderTypePicker
            orderTypeId={release?.type.id}
            setOrderTypeId={setOrderTypeId}
            disabled={!!release?.buyout}
            inputProps={inputProps}
          />
        </FormControl>
      </If>
      <PoNumberingSettingsCheck alternativeCondition={!!release?.poNumber}>
        <FormControl fullWidth>
          <ReleasePONumber
            update={(_, poNumber?: string) => update(poNumber)}
            release={release}
            sourceSystem={release?.poLink?.sourceSystem}
            autoSize
            placeholder={predictedPoNumberPlaceholder}
            loading={loading}
            size="medium"
            inputProps={inputProps}
          />
        </FormControl>
      </PoNumberingSettingsCheck>
      <FormControl fullWidth>
        <SiteContactPicker
          value={release?.siteContact?.id}
          onChange={(contactId) =>
            updateRelease({
              releaseId: release?.id || "",
              version: release?.version,
              siteContactId: contactId || undefined,
              clearSiteContact: !contactId,
            })
          }
          countryCode={viewer?.org.hqAddress?.country}
          inputProps={inputProps}
          InputLabelProps={inputLabelProps}
        />
      </FormControl>
      <ReleaseVendorContactSelector />
      <FormControl fullWidth>
        <ReleaseDateTimePicker value={requestedDate} onChange={onDateConfirm} />
      </FormControl>
      <ReleaseGroups includeLabel />
    </>
  );
};
