import { InventoryTransactionType } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useTransactionTypePicker = () => {
  const intl = useIntl();
  const options = useMemo(
    () => [
      { value: null, label: intl.$t({ id: "ALL_TRANSACTIONS" }) },
      {
        value: InventoryTransactionType.Inflow,
        label: intl.$t({ id: "INVENTORY_TRANSACTION_TYPE_INFLOW" }),
      },
      {
        value: InventoryTransactionType.Outflow,
        label: intl.$t({ id: "INVENTORY_TRANSACTION_TYPE_OUTFLOW" }),
      },
      {
        value: InventoryTransactionType.Transfer,
        label: intl.$t({ id: "INVENTORY_TRANSACTION_TYPE_TRANSFER" }),
      },
      {
        value: InventoryTransactionType.Adjustment,
        label: intl.$t({ id: "INVENTORY_TRANSACTION_TYPE_ADJUSTMENT" }),
      },
    ],
    [intl],
  );

  return { options };
};
