import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { textRenderer } from "handsontable/renderers";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { useRenderHelpers } from "./useRenderHelpers";

export const useUnitPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getBuyoutItem } = useTableHelpers();
  const {
    applyTooltip,
    applyClasses,
    checkReadOnly,
    formatPrice,
    addIconWithTooltip,
  } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = formatPrice(row, instance, value);

      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      applyClasses(td, "htCenter htMiddle relative");
      checkReadOnly(td, row, instance, cellProperties);
      const rows = instance?.getData();

      const buyoutItem = getBuyoutItem({
        id: rows[row][
          getPhysicalColumnIndex(instance, COLUMN_TYPE.BuyoutItemId)
        ],
        material:
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });

      if (buyoutItem) {
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t({ id: "BUYOUT_ITEM_PRICE_NOTICE" }),
          iconClasses: "text-green-800 hover:text-green-600",
        });
      }

      const hasExtPriceColumn = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );
      if (hasExtPriceColumn !== -1) {
        return;
      }
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const priceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.UnitPrice,
      );
      const extendedPrice =
        quantityIndex !== -1 && priceIndex !== -1
          ? rows[row][quantityIndex] * rows[row][priceIndex]
          : 0;
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPrice = document.createElement("div");
        extPrice.className = "text-gray-500 text-3xs select-none mb-1";
        const formattedValue = extendedPrice
          ? formatCurrency(extendedPrice)
          : "";
        extPrice.innerText = intl.$t(
          { id: "EXT_WITH_PRICE" },
          { price: formattedValue },
        );
        td.appendChild(extPrice);

        applyTooltip(
          extPrice,
          intl.$t({ id: "EXT_PRICE_TOOLTIP" }, { price: formattedValue }),
        );
      }
    },
    [
      formatPrice,
      applyClasses,
      checkReadOnly,
      applyTooltip,
      intl,
      formatCurrency,
      getBuyoutItem,
      addIconWithTooltip,
    ],
  );

  return renderer;
};
