import { getTaxRate } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { RELEASE } from "@/common/queries/release";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  InvoiceStatus,
  UomsDocument,
  UpdateContractorReleaseItemInput,
  UpdateReceiptMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useReceiptSequence } from "../../receipts/providers/ReceiptsSequenceProvider";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useIsReceiptMissingCostCodes } from "../hooks/useIsReceiptMissingCostCodes";
import { useReceipt } from "./ReceiptProvider";

type ProviderContextType = {
  syncUpdateReleaseFromReceipt: (
    values: ReceiptCreateReleaseFormValues,
    options: { markAsApproved: boolean },
  ) => Promise<boolean>;
  saving: boolean;
  approving: boolean;
  updateRelease: (
    values: ReceiptCreateReleaseFormValues,
    { markAsApproved }: { markAsApproved: boolean },
  ) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncUpdateReleaseFromReceipt: NoFunctionBooleanPromise,
  saving: false,
  approving: false,
  updateRelease: NoFunctionBooleanPromise,
});

export const ReceiptUpdateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { hasPhaseCodes } = useOrgSettings();
  const { sequenceActive, navigateToNextSequence } = useReceiptSequence();
  const { projectCostCodes } = useProjectCostCodes();
  const { getSyncedRelease, validateItems } = useSyncReleaseItems();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { updateMaterials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const [approving, setApproving] = useState(false);
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { receipt, updateReceipt, isItemized, approveReceipt, itemized } =
    useReceipt();
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();
  const { getIsReceiptMissingCostCodes } = useIsReceiptMissingCostCodes();

  const syncUpdateReleaseFromReceipt = async (
    values: ReceiptCreateReleaseFormValues,
    options: { markAsApproved: boolean },
  ) => {
    const isValid = await validateItems({ requirePrice: true });
    if (!isValid) {
      return false;
    }

    if (options.markAsApproved) {
      setApproving(true);
    } else {
      setSaving(true);
    }
    const { addedItems, removedItems, updates } = await getSyncedRelease();

    if (release) {
      try {
        const { errors } = await updateReleaseMutation({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems,
              updates,
              removedItems,
              taxRate: getTaxRate(values),
              customTaxAmount: values.customTaxAmount || undefined,
              clearTaxRate: !!values.customTaxAmount,
              clearCustomTaxAmount: !values.customTaxAmount,
              assignDefaultCostCodes: false,
              prefillPrices: false,
              typeId: values.orderTypeId || undefined,
              requestedTime: values.issueDate?.getTime(),
              paymentTerm: values.paymentTerm
                ? Number(values.paymentTerm)
                : undefined,
              taxVariance: values.taxVariance,
              clearTaxVariance: !values.taxVariance,
              instructions: values.instructions,
              description: values.description || "",
              sellerOrgLocationId: RELEASE_DRAFT_STATUSES.includes(
                release.status,
              )
                ? values.vendorId
                : undefined,
              costCodeId: itemized
                ? (release.costCode?.id ?? undefined)
                : ((hasPhaseCodes ? values.phaseCodeId : values.costCodeId) ??
                  undefined),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            { query: RELEASE, variables: { id: release?.id ?? "" } },
            { query: UomsDocument },
          ],
        });
        setError(errors);
        if (!errors) {
          if (
            receipt?.status === InvoiceStatus.AwaitingApproval &&
            options.markAsApproved
          ) {
            const hasMissingCostCodes = getIsReceiptMissingCostCodes(values);
            await approveReceipt(
              { invoiceId: receipt?.id },
              { hasMissingCostCodes },
            );
          }
          if (receipt?.id && receipt.issueDate !== values.issueDate) {
            updateReceipt({
              id: receipt?.id,
              issueDate: values.issueDate?.getTime(),
            });
          }
          if (sequenceActive) {
            navigateToNextSequence();
          }
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      } finally {
        setSaving(false);
        setApproving(false);
      }
    }

    return true;
  };

  const updateRelease = async (
    values: ReceiptCreateReleaseFormValues,
    { markAsApproved }: { markAsApproved: boolean },
  ) => {
    try {
      if (markAsApproved) {
        setApproving(true);
      } else {
        setSaving(true);
      }

      const initiallyItemized = isItemized(receipt);

      const nonItemizedTotalItem = (await getLumpSumReleaseItem(values))?.[0];

      const costCode = projectCostCodes.find(
        (c) => c.id === values.costCodeId,
      )?.id;

      if (!initiallyItemized && release?.items.length) {
        await updateMaterials({
          updates: [
            {
              orgMaterialId: release?.items[0].projectItem?.material.id || "",
              costCodeId: costCode,
            },
          ],
        });
      }

      const updates: UpdateContractorReleaseItemInput[] = [
        {
          quantityDecimal: values.netAmount.toString(),
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          releaseItemId: release?.items?.[0]?.id || "",
          costCodeId: values.costCodeId,
        },
      ];

      const { errors } = await updateReleaseMutation({
        variables: {
          input: initiallyItemized
            ? {
                releaseId: release?.id || "",
                requestedTime: values.issueDate?.getTime(),
                updates: undefined,
                removedItems: release?.items.map((item) => item.id),
                addedItems: [nonItemizedTotalItem],
                taxRate: getTaxRate(values),
                customTaxAmount: values.customTaxAmount || undefined,
                clearTaxRate: !!values.customTaxAmount,
                version: release?.version,
                typeId: values.orderTypeId || undefined,
                assignDefaultCostCodes: false,
                paymentTerm: values.paymentTerm
                  ? Number(values.paymentTerm)
                  : undefined,
                taxVariance: values.taxVariance,
                clearTaxVariance: !values.taxVariance,
                instructions: values.instructions,
                description: values.description || "",
                sellerOrgLocationId:
                  release && RELEASE_DRAFT_STATUSES.includes(release.status)
                    ? values.vendorId
                    : undefined,
              }
            : {
                releaseId: release?.id || "",
                requestedTime: values.issueDate?.getTime(),
                updates: release?.items.length ? updates : undefined,
                addedItems: !release?.items.length
                  ? [nonItemizedTotalItem]
                  : undefined,
                taxRate: getTaxRate(values),
                customTaxAmount: values.customTaxAmount || undefined,
                clearTaxRate: !!values.customTaxAmount,
                version: release?.version,
                typeId: values.orderTypeId,
                taxVariance: values.taxVariance,
                clearTaxVariance: !values.taxVariance,
                instructions: values.instructions || undefined,
                description: values.description || "",
                sellerOrgLocationId:
                  release && RELEASE_DRAFT_STATUSES.includes(release.status)
                    ? values.vendorId
                    : undefined,
              },
        },
        awaitRefetchQueries: true,
      });
      setError(errors);
      let receiptUpdateResult: UpdateReceiptMutation | null | undefined = null;
      if (!errors && receipt?.id) {
        receiptUpdateResult = await updateReceipt({
          id: receipt?.id,
          issueDate: values.issueDate?.getTime(),
        });
      }
      if (!errors && receiptUpdateResult?.updateReceipt) {
        if (
          receipt?.status === InvoiceStatus.AwaitingApproval &&
          markAsApproved
        ) {
          const hasMissingCostCodes = getIsReceiptMissingCostCodes(values);
          await approveReceipt(
            { invoiceId: receipt?.id },
            { hasMissingCostCodes },
          );
        }
        if (receipt?.id && receipt.issueDate !== values.issueDate) {
          updateReceipt({
            id: receipt?.id,
            issueDate: values.issueDate?.getTime(),
          });
        }
        if (sequenceActive) {
          navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
        }
      }

      return Boolean(!errors && receiptUpdateResult?.updateReceipt);
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setSaving(false);
      setApproving(false);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncUpdateReleaseFromReceipt,
        saving: saving || updating,
        updateRelease,
        approving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptUpdateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
