import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { rowIsChecked } from "@/common/components/spreadsheet-table/utils/rowIsChecked";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import usePreventUnsavedChanges from "@/common/hooks/usePreventUnsavedChanges";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { EstimatedPricesProvider } from "@/common/providers/EstimatedPricesProvider";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { EstimatedItemsProvider } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import {
  ContractorBuyoutProvider,
  useContractorBuyout,
} from "../../../buyout/providers/ContractorBuyoutProvider";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { ReleaseBreadcrumbs } from "../../components/ReleaseBreadcrumbs";
import { ReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../store/useReleaseStore";
import { useSpecifyDetailsConfiguration } from "./SpecifyDetails.configuration";
import { SpecifyDetailsFooter } from "./SpecifyDetailsFooter";
import { SpecifyDetailsHeader } from "./SpecifyDetailsHeader";
import { AddNoteSidePanel } from "./components/add-note-btn/AddNoteSidePanel";
import { AddQuoteSidePanel } from "./components/add-quote-btn/AddQuoteSidePanel";
import { ImportMaterialsDrawer } from "./components/import-from-file/ImportReleaseItemsDrawer";
import { useReleaseItemsToSubmit } from "./hooks/useReleaseItemsToSubmit";
import { useSyncReleaseItems } from "./hooks/useSyncReleaseItems";
import { useReleaseFooterActionsStore } from "./store/useReleaseFooterActionsStore";

const Container = tw.div``;

const SpecifyReleaseDetailsWithProviders = () => {
  const { release, loading } = useRelease();
  const { loading: loadingBuyout } = useContractorBuyout();
  const spreadsheetConfig = useSpecifyDetailsConfiguration(release);
  const { saving, syncReleaseItems } = useSyncReleaseItems();
  const { hasChanges, setHasChanges, updateStoreItems } = useReleaseStore(
    useShallow((state) => ({
      hasChanges: state.hasChanges,
      setHasChanges: state.setHasChanges,
      updateStoreItems: state.updateStoreItems,
    })),
  );
  const navigate = useNavigate();
  const items = useReleaseItemsToSubmit();
  const setUpdateDialogVisible = useReleaseFooterActionsStore(
    (state) => state.setUpdateDialogVisible,
  );
  const { submitUpdate } = useReleaseUpdate();

  useSetCurrentProjectId(release?.project?.id);

  useEffect(() => {
    setHasChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  const { setGlobalVendorId } = useVendorPrices();

  const hasChangesAndReleaseNotDeleted = useMemo(
    () => hasChanges && !release?.deletedAt,
    [hasChanges, release?.deletedAt],
  );

  usePreventUnsavedChanges({
    hasChanges: hasChangesAndReleaseNotDeleted,
    onSave: async () => {
      if (
        release?.status === ReleaseStatus.Scheduled ||
        release?.status === ReleaseStatus.Requested ||
        release?.status === ReleaseStatus.PartiallyReceived ||
        release?.status === ReleaseStatus.Received
      ) {
        setUpdateDialogVisible(true);
        return false;
      }
      const result = await syncReleaseItems();
      if (result) {
        return await submitUpdate(result.version);
      }
      return false;
    },
  });

  useEffect(() => {
    if (setGlobalVendorId && release?.sellerOrgLocation?.id) {
      setGlobalVendorId(release?.sellerOrgLocation?.id);
    }
  }, [release?.sellerOrgLocation?.id, setGlobalVendorId]);

  const onSpreadSheetChange = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      updateStoreItems(
        items
          ?.filter((item) => data.find((row) => row.id === item.id))
          .map((item) => {
            const updatedItem = data.find((i) => i.id === item.id);
            if (!updatedItem) {
              return item;
            }
            return {
              ...item,
              unitPrice: updatedItem[COLUMN_TYPE.PrefilledPrice],
              quantityDecimal: updatedItem[COLUMN_TYPE.Quantity],
              taxable: rowIsChecked(updatedItem, COLUMN_TYPE.Taxable),
            };
          })
          .concat(
            data
              .filter((row) => !row.id && !rowIsEmpty(row))
              .map((item, index) => {
                return {
                  id: index.toString(),
                  unitPrice: item[COLUMN_TYPE.PrefilledPrice],
                  quantityDecimal: item[COLUMN_TYPE.Quantity],
                  taxable: rowIsChecked(item, COLUMN_TYPE.Taxable),
                } as ExpandedReleaseItem;
              }),
          ),
      );
    },
    [items, setHasChanges, updateStoreItems],
  );

  useEffect(() => {
    if (release?.items) {
      updateStoreItems(release.items);
    }
  }, [release?.items, updateStoreItems]);

  if (!release || !items || loading || loadingBuyout) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <Container>
        <ReleaseBreadcrumbs sub="SPECIFY_RELEASE_DETAILS" editableMode />
        <SpecifyDetailsHeader release={release} />

        <SpreadSheetTable
          items={items}
          columns={spreadsheetConfig}
          saving={saving}
          height="600px"
          onChanges={onSpreadSheetChange}
        />
        <QuoteDocumentPanel
          quoteDocument={release.quoteDocument}
          quote={release.quote}
          readonly
          customRedirect={() => {
            navigate(
              generatePath(routes.editDeliveryFromQuote, {
                quoteDocumentId: release.quoteDocument?.id,
                deliveryId: release.id,
              }),
            );
          }}
        >
          {release.quoteDocument ? null : <AddQuoteSidePanel />}
        </QuoteDocumentPanel>
        <NoteDocumentPanel
          noteDocument={release.noteDocument}
          readonly
          customRedirect={() => {
            navigate(
              generatePath(routes.editDeliveryFromNote, {
                noteDocumentId: release.noteDocument?.id,
                deliveryId: release.id,
              }),
            );
          }}
        >
          {release.noteDocument ? null : <AddNoteSidePanel />}
        </NoteDocumentPanel>
        <ImportMaterialsDrawer />
        <SpecifyDetailsFooter items={items} />
      </Container>
    </ReleaseItemsZoneProvider>
  );
};

export const SpecifyReleaseDetails = () => {
  const { release } = useRelease();
  const spreadsheetConfig = useSpecifyDetailsConfiguration(release);

  if (!release) {
    return <Loader loading />;
  }

  return (
    <EstimatedItemsProvider>
      <EstimatedPricesProvider projectId={release?.project?.id}>
        <ContractorBuyoutProvider id={release.buyout?.id}>
          <ColumnMapperProvider config={spreadsheetConfig}>
            <SpecifyReleaseDetailsWithProviders />
          </ColumnMapperProvider>
        </ContractorBuyoutProvider>
      </EstimatedPricesProvider>
    </EstimatedItemsProvider>
  );
};
