import { OrderSelectorFilters } from "@/contractor/pages/home/common/order-selector-filters/OrderSelectorFilters";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useReceiptOrderFilters } from "../../../hooks/useReceiptOrderFilters";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptSelectModeHeader } from "../../receipt-select-mode-header/ReceiptSelectModeHeader";

const Container = tw.div`w-full`;

export const ReceiptOrderFilters: FC = () => {
  const { setSelectedRelease, selectedRelease } = useReceipt();
  const { filter } = useReceiptOrderFilters();

  return (
    <Container>
      <ReceiptSelectModeHeader />
      <OrderSelectorFilters
        onOrderChange={(rel) => setSelectedRelease(rel ?? null)}
        initialRelease={selectedRelease}
        releasesFilter={filter}
      />
    </Container>
  );
};
