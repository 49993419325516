import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import { useProjectTags } from "../hooks/useProjectTags";

export const BudgetTabNavigation = () => {
  const intl = useIntl();
  const { id } = useParams();
  const { tags, phaseCodes } = useProjectTags();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "BY_COST_CODES" }),
        path: generatePath(routes.projectBudget, { id }),
      },
      ...(tags.length || phaseCodes.length
        ? [
            {
              label: intl.$t({ id: "BY_TAGS" }),
              path: generatePath(routes.budgetByTags, { id }),
            },
          ]
        : []),
    ],
    [intl, id, tags.length, phaseCodes.length],
  );

  return <Tabs tabs={tabs} type="secondary" className="top-1" />;
};
