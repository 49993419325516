import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useSpreadSheetWarning } from "@/common/components/spreadsheet-table/hooks/useSpreadSheetWarning";
import { ValidatorFn } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import {
  ValidatorContextType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useCallback } from "react";

export const useInventoryTransferTableValidators = () => {
  const { showWarning } = useSpreadSheetWarning();
  const { spreadsheetData } = useColumnMapper();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();

  const checkIfExistingUniqueInventoryItem = useCallback(
    (row: Record<string, string>, rowIndex: number) => {
      if (rowIsEmpty(row)) {
        return true;
      }

      const material = row[COLUMN_TYPE.Material];
      const uom = row[COLUMN_TYPE.UOM];
      const item = findInventoryItemByNameAndUom(material, uom);

      if (!material || !uom || !item) {
        showWarning(`VALIDATION_ERROR_SHEETS_INVENTORY_ITEM_NOT_FOUND`, {
          row: rowIndex + 1,
        });
        return false;
      }

      const hasDuplicate = spreadsheetData.some(
        (otherRow, otherIndex) =>
          otherIndex !== rowIndex &&
          otherRow[COLUMN_TYPE.Material] === material &&
          otherRow[COLUMN_TYPE.UOM] === uom,
      );

      if (hasDuplicate) {
        showWarning(`VALIDATION_ERROR_SHEETS_DUPLICATE_INVENTORY_ITEM`, {
          row: rowIndex + 1,
        });
        return false;
      }

      return true;
    },
    [spreadsheetData, showWarning, findInventoryItemByNameAndUom],
  );

  const checkIfQuantityIsGreaterThanZero = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = Number(getCellValue(row, column));
      if (Number.isNaN(value) || value <= 0) {
        showWarning(
          `VALIDATION_ERROR_SHEETS_QUANTITY_IS_NOT_GREATER_THAN_ZERO`,
          {
            row: rowIndex + 1,
          },
        );
        return false;
      }
      return true;
    },
    [showWarning],
  );

  const checkIfQuantityIsLessThanRemainingQuantity = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = Number(getCellValue(row, column));
      const existingInventoryItem = findInventoryItemByNameAndUom(
        row[COLUMN_TYPE.Material],
        row[COLUMN_TYPE.UOM],
      );
      if (
        existingInventoryItem
          ? value >
            getInventoryItemRemainingQuantity(existingInventoryItem).toNumber()
          : false
      ) {
        showWarning(
          `VALIDATION_ERROR_SHEETS_QUANTITY_IS_GREATER_THAN_REMAINING_QUANTITY`,
          { row: rowIndex + 1 },
        );
        return false;
      }
      return true;
    },
    [showWarning, findInventoryItemByNameAndUom],
  );

  const validateRowValues = useCallback(
    async (columns: COLUMN_TYPE[]) => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      const filteredRows = spreadsheetData.filter((row) => !rowIsEmpty(row));

      return filteredRows.every((row, index) =>
        columns.every((column) => {
          switch (column) {
            case COLUMN_TYPE.Material:
              return checkIfExistingUniqueInventoryItem(row, index);
            case COLUMN_TYPE.Quantity:
              return (
                checkIfQuantityIsGreaterThanZero(row, index, column) &&
                checkIfQuantityIsLessThanRemainingQuantity(row, index, column)
              );
            default:
              return true;
          }
        }),
      );
    },
    [
      spreadsheetData,
      checkIfQuantityIsGreaterThanZero,
      checkIfQuantityIsLessThanRemainingQuantity,
      checkIfExistingUniqueInventoryItem,
    ],
  );

  const existingUniqueInventoryItemValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row: rowIndex } = this;
      const currentRow = spreadsheetData[rowIndex];

      if (rowIsEmpty(currentRow)) {
        callback(true);
        return;
      }

      const material = currentRow[COLUMN_TYPE.Material];
      const uom = currentRow[COLUMN_TYPE.UOM];
      const item = findInventoryItemByNameAndUom(material, uom);

      if (!material || !uom || !item) {
        callback(false);
        return;
      }

      const duplicateRow = spreadsheetData.find((row, index) => {
        if (index === rowIndex) {
          return false;
        }

        return (
          row[COLUMN_TYPE.Material] === material && row[COLUMN_TYPE.UOM] === uom
        );
      });

      callback(!duplicateRow);
    },
    [spreadsheetData, findInventoryItemByNameAndUom],
  );

  return {
    validateRowValues,
    existingUniqueInventoryItemValidator,
  };
};
