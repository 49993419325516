import { routes } from "@/config/routes";
import { WithMainTabNavigation } from "@/contractor/pages/home/common/navigation/WithMainTabNavigation";
import { WithInventoryTabNavigation } from "./common/components/inventory-management-tab-navigation/WithInventoryTabNavigation";
import { CurrentStock } from "./pages/current-stock/CurrentStock";
import { InventoryAdjustment } from "./pages/current-stock/inventory-adjustment/InventoryAdjustment";
import { InventoryTransfer } from "./pages/current-stock/inventory-transfer/InventoryTransfer";
import { InventoryLedger } from "./pages/inventory-ledger/InventoryLedger";

export const inventoryRoutes = [
  {
    path: routes.currentStock,
    element: (
      <WithMainTabNavigation>
        <WithInventoryTabNavigation />
      </WithMainTabNavigation>
    ),
    children: [
      {
        path: routes.currentStock,
        element: <CurrentStock />,
      },
      {
        path: routes.inventoryLedger,
        element: <InventoryLedger />,
      },
    ],
  },
  {
    path: routes.inventoryAdjustment,
    element: <InventoryAdjustment />,
  },
  {
    path: routes.inventoryTransfer,
    element: <InventoryTransfer />,
  },
];
