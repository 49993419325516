import { FooterHeaderText } from "@/common/components/grid-table/styles/Footer.styles";
import { Price } from "@/common/components/price/Price";
import { FormattedMessage } from "react-intl";
import {
  TransactionFooterCell,
  TransactionFooterCellHeader,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";
import { useTransactionHistoryInflowsFooterReceivedPrice } from "./useTransactionHistoryInflowsFooterReceivedPrice";

export const TransactionHistoryInflowsFooterReceivedPrice = () => {
  const { subtotal, additionalChargeAmount, salesTax } =
    useTransactionHistoryInflowsFooterReceivedPrice();

  return (
    <TransactionFooterCol>
      <TransactionFooterCellHeader>
        <FormattedMessage id="RECEIVED" tagName={FooterHeaderText} />
      </TransactionFooterCellHeader>
      <TransactionFooterCell>
        <Price price={subtotal} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell>
        <Price price={salesTax} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell>
        <Price price={additionalChargeAmount} className="text-end" />
      </TransactionFooterCell>
      <TransactionFooterCell></TransactionFooterCell>
    </TransactionFooterCol>
  );
};
