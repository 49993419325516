import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryItemsStore } from "../store/useInventoryItemsStore";

export const useInventoryItems = () => {
  const {
    loading,
    itemsByWarehouse,
    currentWarehouseId,
    setCurrentWarehouseId,
    reset,
  } = useInventoryItemsStore(
    useShallow((state) => ({
      inventoryItems: state.inventoryItems,
      loading: state.loading,
      itemsByWarehouse: state.itemsByWarehouse,
      currentWarehouseId: state.currentWarehouseId,
      setCurrentWarehouseId: state.setCurrentWarehouseId,
      reset: state.reset,
    })),
  );

  const inventoryItems = useMemo(() => {
    if (!currentWarehouseId) {
      return [];
    }
    return itemsByWarehouse[currentWarehouseId] || [];
  }, [itemsByWarehouse, currentWarehouseId]);

  return {
    inventoryItems,
    loading,
    setCurrentWarehouseId,
    reset,
  };
};
