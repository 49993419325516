import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import tw from "tailwind-styled-components";

export const ItemsToDelivery = tw.div`
  flex justify-center text-base items-center
`;

export const RfqNumberContainer = tw.div`
  grid grid-cols-auto-1fr gap-2 items-center justify-items-start
`;

export const LinkIconContainer = tw.div`
  w-6 mr-2
`;

export const QuotesOutlinedButton = tw(OutlinedButton)`
  min-w-20 min-h-8
`;

export const QuotesPrimaryButton = tw(PrimaryButton)`
  min-w-20 min-h-8
`;
