import { InventoryItemStateFieldsFragment } from "@/generated/graphql";

export const sortInventoryItems = (
  a: InventoryItemStateFieldsFragment,
  b: InventoryItemStateFieldsFragment,
) => {
  return (
    a.transactionNumber - b.transactionNumber || a.itemPosition - b.itemPosition
  );
};
