import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { TransactionItemOrigin } from "../../../TransactionItemOrigin";
import { useTransactionOriginFrom } from "../../../hooks/useTransactionOriginFrom";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const TransactionOriginFrom = (props: Props) => {
  const from = useTransactionOriginFrom(props);
  return <TransactionItemOrigin item={from} />;
};
