import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { Close } from "@mui/icons-material";
import { DialogActions, Modal as MuiModal } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { OutlinedButton } from "../button/OutlinedButton";
import { PrimaryButton } from "../button/PrimaryButton";
import { If } from "../if/If";
import { Loader } from "../loader/Loader";
import { useNestedStepper } from "../stepper/NestedStepper";
import { WizardModalFooterButton } from "./components/WizardModalFooterButton";
import { WizardModalHeaderBreadcrumb } from "./components/WizardModalHeaderBreadcrumb";
import { useWizardStore } from "./store/useWizardStore";

const ModalContainer = tw.div`flex flex-row flex-1 bg-white rounded-3xl overflow-hidden`;
const TopContainer = tw.div`flex flex-1 flex-col pt-5 overflow-hidden`;
const HeaderBackground = tw.div`fixed top-5 left-20 right-20 bg-white px-5 pt-5 overflow-hidden rounded-t-3xl z-70`;
const Header = tw.div`w-[100%] h-20 flex flex-row bg-gray-200 items-center px-5 rounded-3xl`;
const Breadcrumbs = tw.div`flex flex-1 flex-row items-center`;
const CloseButton = tw.button``;
const BottomActionsStyled = tw(
  DialogActions,
)`flex flex-row h-24 px-10 bg-gray-100 fixed bottom-5 left-20 right-20 rounded-b-3xl z-70`;
const BottomActionsView = tw.div`flex flex-[2]`;
const Dots = tw.div`h-[18px] flex flex-row space-x-3`;
const Dot = tw.button`h-[18px] w-[18px] rounded-full border border-blue-800`;
const Content = tw.div`z-50 px-5 pb-24 flex flex-1 flex-col overflow-y-scroll`;
const SidePanelContainer = tw.div`z-50`;

export enum WizardModalMode {
  SINGLE_PAGE = "SINGLE_PAGE",
  MULTIPLE_PAGES = "MULTIPLE_PAGES",
}

export type NestedWizardModalPage = {
  component: React.ReactNode;
  classNames?: {
    wrapper?: string;
    footer?: string;
    header?: string;
    innerHeader?: string;
    modal?: string;
  };

  hideModal?: boolean;
  hideHeader?: boolean;
  onModalClose?: () => void;

  onPreviousClick?: () => void;
  onPreviousClickDisabled?: boolean;
  onPreviousLabel?: string;
  onPreviousClassName?: string;
  onNextClick?: () => Promise<void>;
  onNextLabel?: string;
  onNextClickDisabled?: boolean;
  onNextClassName?: string;
  onCloseClick?: () => void;
  onCloseClickDisabled?: boolean;
  onCloseClassName?: string;

  footerContent?: { node: React.ReactNode; className?: string };
  footerDotsConfig?: {
    selectedIndex?: number;
    count?: number;
    hidden?: boolean;
  };
  footerButtonsConfig?: WizardModalFooterButton[];
  hideFooter?: boolean;
  customHeader?: React.ReactNode;

  leftSidePanel?: React.ReactNode;
  rightSidePanel?: React.ReactNode;
};

export type WizardModalPage = {
  title?: string | null;
  pages: NestedWizardModalPage[];
  completed?: boolean;
  feature?: IntegrationFeature;
  hideHeader?: boolean;
};

type Props = {
  opened: boolean;
  mode: WizardModalMode;
  pages: WizardModalPage[];
  muiModalClassName?: string;
  onClose: () => void;
  loading?: boolean;
  id?: string;
};

export const WizardModal: FC<Props> = ({
  opened,
  mode,
  pages,
  muiModalClassName = "flex flex-1 px-20 py-5",
  onClose,
  loading = false,
  id,
}) => {
  const [transitioning, setTransitioning] = useState(false);
  const [closing, setClosing] = useState(false);
  const intl = useIntl();
  const { step, nestedStep, setNestedStep } = useNestedStepper();
  const { hide, defaultStep } = useWizardStore(
    useShallow((state) => ({
      hide: state.hide,
      defaultStep: state.defaultStep,
    })),
  );

  useEffect(() => {
    if (defaultStep !== undefined) {
      setNestedStep(defaultStep);
    }
  }, [defaultStep, setNestedStep]);

  const currentPage = pages[step];
  const currentNestedPage = currentPage?.pages?.[nestedStep];

  const isSinglePage = useMemo(
    () => pages.length === 1 || mode === WizardModalMode.SINGLE_PAGE,
    [mode, pages.length],
  );
  const shouldUseConfigFooterButtons = useMemo(
    () =>
      mode === WizardModalMode.MULTIPLE_PAGES || currentPage?.pages.length > 1,
    [currentPage?.pages.length, mode],
  );

  const onNextClick = useCallback(async () => {
    setTransitioning(true);
    await currentNestedPage.onNextClick?.();
    setTransitioning(false);
  }, [currentNestedPage]);

  const onCloseClick = useCallback(async () => {
    setClosing(true);
    await currentNestedPage.onCloseClick?.();
    setClosing(false);
    if (id) {
      hide(id);
    }
  }, [currentNestedPage, hide, id]);

  if (!currentPage || !currentNestedPage) {
    return null;
  }

  if (currentNestedPage.hideModal) {
    return (
      <MuiModal
        open={opened}
        onClose={currentNestedPage.onModalClose ?? onClose}
        className="flex items-center justify-center rounded-xl"
      >
        <>{currentNestedPage.component}</>
      </MuiModal>
    );
  }
  return (
    <MuiModal
      open={opened}
      onClose={currentNestedPage.onModalClose ?? onClose}
      className={muiModalClassName}
    >
      <ModalContainer className={currentNestedPage.classNames?.modal}>
        <Loader loading={loading}>
          <If isTrue={currentNestedPage.leftSidePanel}>
            <SidePanelContainer>
              {currentNestedPage.leftSidePanel}
            </SidePanelContainer>
          </If>
          <TopContainer
            className={`${
              isSinglePage ||
              currentNestedPage.hideHeader ||
              currentPage.hideHeader
                ? ""
                : "mt-20"
            } ${currentNestedPage.classNames?.header} `}
          >
            <If
              isTrue={
                (!isSinglePage &&
                  !currentNestedPage.hideHeader &&
                  !currentPage.hideHeader) ||
                currentNestedPage.customHeader
              }
            >
              <HeaderBackground>
                <Header className={currentNestedPage.classNames?.innerHeader}>
                  {currentNestedPage.customHeader || (
                    <>
                      <Breadcrumbs>
                        {pages.map((page, index) => (
                          <WizardModalHeaderBreadcrumb
                            pages={pages}
                            key={index}
                            title={page.title}
                            index={index}
                          />
                        ))}
                      </Breadcrumbs>
                      <CloseButton onClick={onClose}>
                        <Close />
                      </CloseButton>
                    </>
                  )}
                </Header>
              </HeaderBackground>
            </If>
            <Content
              className={`${isSinglePage ? "mb-24 pb-5" : "mb-10"}${
                currentNestedPage.classNames?.wrapper || ""
              }`}
            >
              {currentNestedPage.component}
            </Content>
          </TopContainer>
          <If isTrue={currentNestedPage.rightSidePanel}>
            <SidePanelContainer>
              {currentNestedPage.rightSidePanel}
            </SidePanelContainer>
          </If>
          <If isTrue={!currentNestedPage.hideFooter}>
            <BottomActionsStyled
              className={currentNestedPage.classNames?.footer}
            >
              <BottomActionsView
                className={`justify-start ${currentNestedPage.footerContent?.className}`}
              >
                <If isTrue={currentNestedPage.footerContent}>
                  {currentNestedPage.footerContent?.node}
                </If>
              </BottomActionsView>
              <If
                isTrue={
                  currentPage?.pages.length > 1 &&
                  !currentNestedPage.footerDotsConfig?.hidden
                }
              >
                <BottomActionsView className="justify-center">
                  <If
                    isTrue={
                      currentPage?.pages.length > 1 &&
                      !!currentNestedPage.footerDotsConfig
                    }
                  >
                    <Dots>
                      {[
                        ...Array(
                          currentNestedPage.footerDotsConfig?.count ?? 0,
                        ),
                      ].map((_, nestedPageIndex) => (
                        <Dot
                          key={nestedPageIndex}
                          className={
                            nestedPageIndex ===
                            (currentNestedPage.footerDotsConfig
                              ?.selectedIndex ?? 0)
                              ? "bg-blue-800"
                              : ""
                          }
                          onClick={() =>
                            nestedPageIndex <= nestedStep
                              ? setNestedStep(nestedPageIndex)
                              : undefined
                          }
                        />
                      ))}
                    </Dots>
                  </If>
                </BottomActionsView>
              </If>
              <BottomActionsView className="justify-end">
                <If isTrue={currentNestedPage.footerButtonsConfig}>
                  {currentNestedPage.footerButtonsConfig
                    ?.filter((b) => !b.hidden)
                    .map((button, key) => (
                      <WizardModalFooterButton {...button} key={key} />
                    ))}
                </If>
                <If isTrue={!currentNestedPage.footerButtonsConfig}>
                  <If isTrue={shouldUseConfigFooterButtons}>
                    <If isTrue={currentNestedPage.onCloseClick}>
                      <OutlinedButton
                        className={`w-40 max-w-60 ${
                          !!currentNestedPage.onPreviousClick ||
                          !!currentNestedPage.onNextClick
                            ? "mr-2"
                            : ""
                        } ${currentNestedPage.onCloseClassName}`}
                        onClick={() => currentNestedPage.onCloseClick?.()}
                        disabled={
                          currentNestedPage.onCloseClickDisabled ?? false
                        }
                      >
                        {intl.$t({ id: "CLOSE" })}
                      </OutlinedButton>
                    </If>
                    <If
                      isTrue={
                        currentNestedPage.onPreviousClick &&
                        !(isSinglePage && nestedStep === 0)
                      }
                    >
                      <OutlinedButton
                        className={`w-40 max-w-60 ${
                          currentNestedPage.onNextClick ? "mr-2" : ""
                        } ${currentNestedPage.onPreviousClassName}`}
                        onClick={() => currentNestedPage.onPreviousClick?.()}
                        disabled={
                          currentNestedPage.onPreviousClickDisabled ?? false
                        }
                      >
                        {currentNestedPage.onPreviousLabel ||
                          intl.$t({ id: "PREVIOUS" })}
                      </OutlinedButton>
                    </If>
                    <If
                      isTrue={
                        currentNestedPage.onNextClick &&
                        !(
                          mode === WizardModalMode.SINGLE_PAGE &&
                          nestedStep === currentPage?.pages.length - 1
                        )
                      }
                    >
                      <PrimaryButton
                        className={`w-40 max-w-60 ${currentNestedPage.onNextClassName}`}
                        onClick={onNextClick}
                        disabled={
                          currentNestedPage.onNextClickDisabled ?? false
                        }
                        loading={transitioning}
                      >
                        {currentNestedPage.onNextLabel ||
                          intl.$t({ id: "NEXT" })}
                      </PrimaryButton>
                    </If>
                  </If>
                  <If
                    isTrue={
                      !shouldUseConfigFooterButtons ||
                      (mode === WizardModalMode.SINGLE_PAGE &&
                        nestedStep === currentPage?.pages.length - 1)
                    }
                  >
                    <PrimaryButton
                      className="mr-2 w-40 max-w-60"
                      onClick={async () => {
                        await onCloseClick();
                        onClose();
                      }}
                      loading={closing}
                    >
                      {intl.$t({ id: "CLOSE" })}
                    </PrimaryButton>
                  </If>
                  <If
                    isTrue={
                      !shouldUseConfigFooterButtons ||
                      (mode === WizardModalMode.SINGLE_PAGE &&
                        nestedStep === currentPage?.pages.length - 1)
                    }
                  >
                    <PrimaryButton
                      className="w-40 max-w-60"
                      onClick={async () => {
                        await onNextClick();
                        onClose();
                      }}
                      disabled={currentNestedPage.onCloseClickDisabled ?? false}
                      loading={transitioning}
                    >
                      {intl.$t({
                        id:
                          mode === WizardModalMode.SINGLE_PAGE
                            ? "SAVE"
                            : "CLOSE",
                      })}
                    </PrimaryButton>
                  </If>
                </If>
              </BottomActionsView>
            </BottomActionsStyled>
          </If>
        </Loader>
      </ModalContainer>
    </MuiModal>
  );
};
