import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { ReleaseStatus } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

import { useReceipt } from "../../../providers/ReceiptProvider";
import { BlankRelease } from "./BlankRelease";
import { ReceiptOrderFilters } from "./ReceiptOrderFilters";
import { useReceiptReleaseDetailsConfiguration } from "./ReceiptReleaseDeatails.configuration";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll flex flex-col gap-4 px-5`;
const ListContainer = tw.div`w-full`;
const LoaderStyled = tw(Loader)`mt-20`;

const ReceiptOrderSelectorWithProviders: FC = () => {
  const { release, loading } = useRelease();
  const { configuration, items, itemFn } =
    useReceiptReleaseDetailsConfiguration(release);

  if (!release || loading) {
    return <LoaderStyled loading={loading} />;
  }

  if (
    release &&
    release.status === ReleaseStatus.Reserved &&
    !release.items.length
  ) {
    return <BlankRelease />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={items}
    >
      <ReleaseItemsZoneProvider items={items}>
        <ListContainer>
          <ReleaseItemList
            columns={configuration}
            classNames={{
              header: "top-[48px] z-10",
              category: "top-[36px] lg:top-[102px]",
              subCategory: (groupedByZones: boolean) =>
                groupedByZones
                  ? "top-[76px] lg:top-[144px]"
                  : "top-[36px] lg:top-[102px]",
            }}
            itemFn={itemFn}
          />
        </ListContainer>
      </ReleaseItemsZoneProvider>
    </ReleaseUpdateProvider>
  );
};

export const ReceiptOrderSelectorView: FC = () => {
  const { selectedRelease } = useReceipt();

  return (
    <Container>
      <ReceiptOrderFilters />
      <If isTrue={selectedRelease}>
        <ReleaseProvider id={selectedRelease?.id}>
          <ReceiptOrderSelectorWithProviders />
        </ReleaseProvider>
      </If>
    </Container>
  );
};
