import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { useGetWarehouseById } from "@/common/hooks/useGetWarehouseById";
import { SiteContactFieldsFragment } from "@/generated/graphql";
import { Address } from "@/types/Address";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Address as AddressView } from "../../address/Address";
import { PhoneView } from "../../phone-input/PhoneView";
import { InfoHeaderItem } from "./InfoHeaderItem";
import { InfoHeaderItemTitle } from "./InfoHeaderItem.styles";

const Details = tw.div`text-xs md:text-sm line-clamp-3 flex-col gap-1`;
const ListContent = tw.div`grid grid-flow-row justify-start items-start gap-1`;
const Name = tw.div`text-sm font-medium`;
const Content = tw.div`grid grid-cols-[auto_1fr] md:grid-cols-auto gap-2`;
const ThinLabel = tw.div`text-xs font-light`;
const SiteContactContainer = tw.div`flex gap-5 text-xs pt-1`;
const SiteContactHeader = tw.div`font-light`;
const SiteContactName = tw.div``;
const SiteContactPhone = tw.span`font-light`;
const SiteContact = tw.div`flex gap-1`;

type Props = {
  title?: string | React.JSX.Element;
  name?: string | React.JSX.Element;
  orgName?: string;
  logoImageUrl?: string | null;
  smallLogo?: boolean;
  address?: Address | null;
  includeAddress?: boolean;
  includeAddressLabel?: boolean;
  navTo?: string;
  warehouseId?: string;
  hideInMobile?: boolean;
  siteContact?: SiteContactFieldsFragment | null;
  useDefaultTitlePosition?: boolean;
  jobNumber?: string | null;
};

export const OrgDetailsHeaderItem: FC<Props> = ({
  title,
  name,
  orgName,
  logoImageUrl,
  smallLogo,
  address,
  includeAddress = true,
  includeAddressLabel,
  warehouseId,
  navTo,
  siteContact,
  hideInMobile = false,
  useDefaultTitlePosition = true,
  jobNumber,
}) => {
  const warehouse = useGetWarehouseById(warehouseId);

  return (
    <InfoHeaderItem
      title={useDefaultTitlePosition ? title : undefined}
      hideInMobile={hideInMobile}
      classes={{ content: logoImageUrl ? "justify-start" : undefined }}
    >
      <If isTrue={logoImageUrl}>
        <OrgLogo
          logoImageUrl={logoImageUrl}
          name={orgName as string}
          width={smallLogo ? 40 : 50}
          className="hidden 2xl:grid"
        />
      </If>
      <Content>
        <ListContent>
          <If isTrue={!useDefaultTitlePosition}>
            <InfoHeaderItemTitle>{title}</InfoHeaderItemTitle>
          </If>
          <If isTrue={name}>
            <If isTrue={navTo}>
              <LinkLike to={navTo} forwardEvent>
                {name}
              </LinkLike>
            </If>
            <If isTrue={!navTo}>
              <Name>{name}</Name>
            </If>
          </If>
          <If isTrue={orgName}>
            <Details>{orgName}</Details>
          </If>
          <If isTrue={includeAddress}>
            <Details>
              <If isTrue={warehouse}>
                <FormattedMessage
                  id="FORMATTED_WAREHOUSE_NAME"
                  values={{ name: warehouse?.name }}
                />{" "}
                -{" "}
              </If>
              <If isTrue={address || includeAddressLabel}>
                <ThinLabel>
                  <FormattedMessage id="FULFILLMENT_LOCATION" />:{" "}
                </ThinLabel>
              </If>
              <AddressView
                address={warehouse?.address ?? address}
                customEmptyText="NOT_SPECIFIED"
              />
            </Details>
          </If>
          <If isTrue={jobNumber}>
            <Details>
              <FormattedMessage
                id="JOB_NUMBER_WITH_NO_AND_COLON"
                values={{ jobNumber }}
              />
            </Details>
          </If>
          <If isTrue={siteContact}>
            <SiteContactContainer>
              <SiteContact>
                <FormattedMessage
                  id="PROJECT_SITE_CONTACT"
                  tagName={SiteContactHeader}
                />
                :<SiteContactName>{siteContact?.name}</SiteContactName>
                <If isTrue={siteContact?.phone}>
                  <SiteContactPhone>
                    <PhoneView value={siteContact?.phone ?? ""} />
                  </SiteContactPhone>
                </If>
              </SiteContact>
            </SiteContactContainer>
          </If>
        </ListContent>
      </Content>
    </InfoHeaderItem>
  );
};
