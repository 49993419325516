import { FileMapper } from "@/common/components/upload-file-mapper/FileMapper";
import { FieldMappingTargetProperty, FieldPreview } from "@/generated/graphql";
import { FC } from "react";
import { useMaterialFileUpload } from "./MaterialFileUploadProvider";

type FMColumn = {
  name: string;
  details?: string;
  value: FieldMappingTargetProperty;
  required: boolean;
};

export const FM_COLUMNS: FMColumn[] = [
  {
    name: "NAME",
    details: "NAME_DETAILS",
    value: FieldMappingTargetProperty.Name,
    required: true,
  },
  {
    name: "PART_NUMBER",
    details: "PART_NUMBER_DETAILS",
    value: FieldMappingTargetProperty.PartNumber,
    required: false,
  },
  {
    name: "UOM",
    details: "UOM_DETAILS",
    value: FieldMappingTargetProperty.Uom,
    required: true,
  },
  {
    name: "QUANTITY",
    details: "QUANTITY_DETAILS",
    value: FieldMappingTargetProperty.Quantity,
    required: true,
  },
  {
    name: "UNIT_PRICE",
    details: "UNIT_PRICE_DETAILS",
    value: FieldMappingTargetProperty.UnitPrice,
    required: false,
  },
  {
    name: "SECTION_NAME",
    details: "SECTION_NAME_DETAILS",
    value: FieldMappingTargetProperty.SectionName,
    required: false,
  },
  {
    name: "COST_CODE",
    details: "COST_CODE_DETAILS",
    value: FieldMappingTargetProperty.CostCode,
    required: false,
  },
  {
    name: "ZONE",
    details: "ZONE_DETAILS",
    value: FieldMappingTargetProperty.Zone,
    required: false,
  },
  {
    name: "MANUFACTURER",
    details: "MANUFACTURER_DETAILS",
    value: FieldMappingTargetProperty.Manufacturer,
    required: false,
  },
];

type MaterialFileMapperProps = {
  mappingMode: boolean;
  setMappingMode: (value: boolean) => void;
  fieldPreviews: FieldPreview[];
};

export const MaterialFileMapper: FC<MaterialFileMapperProps> = ({
  mappingMode,
  setMappingMode,
  fieldPreviews,
}) => {
  const { assignedColumns, setAssignedColumns } = useMaterialFileUpload();

  return (
    <FileMapper
      mappingMode={mappingMode}
      setMappingMode={setMappingMode}
      fieldPreviews={fieldPreviews}
      columns={FM_COLUMNS}
      assignedColumns={assignedColumns}
      setAssignedColumns={setAssignedColumns}
    />
  );
};
