import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

export const useSpreadSheetWarning = () => {
  const { setWarningAlert } = useSnackbar();
  const showWarning = useCallback(
    (textId: string, values: Record<string, React.ReactNode>) => {
      setWarningAlert(
        <FormattedMessage
          id={textId}
          values={{
            ...values,
            b: (...chunks) => <strong>{chunks}</strong>,
          }}
        />,
      );
    },
    [setWarningAlert],
  );

  return {
    showWarning,
  };
};
