import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptFooterState } from "../../../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../../../types/ReceiptMatchedOrderViewState";

export const useReceiptSelectModeHeader = () => {
  const intl = useIntl();
  const { matchedOrderViewState, setMatchedOrderViewState, setFooterState } =
    useReceipt();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const options = useMemo(() => {
    const buttons = [
      {
        label: intl.$t({ id: "RECEIPT_ORDER_SELECT_INFO" }),
        onClick: () => {
          setFooterState(ReceiptFooterState.MATCH_ORDER);
          setMatchedOrderViewState(ReceiptMatchedOrderViewState.MATCH_ORDER);
          handleClose();
        },
        selected:
          matchedOrderViewState === ReceiptMatchedOrderViewState.MATCH_ORDER,
      },
      {
        label: intl.$t({ id: "CREATE_NEW_ORDER_FROM_RECEIPT_CONTENT" }),
        onClick: () => {
          setFooterState(ReceiptFooterState.DEFAULT);
          setMatchedOrderViewState(ReceiptMatchedOrderViewState.CREATE_ORDER);
          handleClose();
        },
        selected:
          matchedOrderViewState === ReceiptMatchedOrderViewState.CREATE_ORDER,
      },
    ];

    return buttons;
  }, [
    intl,
    matchedOrderViewState,
    setMatchedOrderViewState,
    handleClose,
    setFooterState,
  ]);

  return { options, anchorEl, handleClose, handleClick };
};
