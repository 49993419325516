import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useRenderHelpers } from "@/common/components/spreadsheet-table/renderers/useRenderHelpers";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useInventoryItemUomRenderer = () => {
  const intl = useIntl();
  const { applyTooltip, removeTooltip } = useRenderHelpers();
  const { inventoryItems } = useInventoryItems();
  const { findMaterialByName } = useTableHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      if (!value) {
        removeTooltip(td);
        return;
      }

      const materialName =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
        ];
      const material = findMaterialByName(materialName);
      const availableUoms = inventoryItems
        .filter((item) => item.orgMaterial.id === material?.id)
        .map((item) => item.uom.pluralDescription);

      const quantity =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
        ];

      applyTooltip(
        td,
        intl.$t(
          {
            id:
              Number(quantity) >= 0 || !quantity
                ? "EXISTING_INVENTORY_ITEM_UOMS"
                : "ALLOWED_INVENTORY_ITEM_UOMS",
          },
          {
            uom: value,
            uoms: availableUoms.join(", "),
          },
        ),
      );
    },
    [applyTooltip, intl, inventoryItems, findMaterialByName, removeTooltip],
  );

  return renderer;
};
