import { useGetOrCreateLumpSumMaterial } from "@/common/components/import-external-po/hooks/useGetOrCreateLumpSumMaterial";
import { DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC } from "@/common/const";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  AddToReleaseItemInput,
  CreateStandaloneReleaseInput,
  OrgMaterialFieldsFragment,
  QuoteStatus,
  RfqQuotesFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import {
  AuxQuoteItem,
  ExpandedAuxiliaryQuoteItem,
  ExpandedQuoteItem,
  QuoteItem,
} from "../../../providers/RfqQuotesProvider";

type Props = {
  rfq: RfqQuotesFieldsFragment | undefined | null;
  selectedQuotes: QuoteItem[];
  allQuoteItems: ExpandedQuoteItem[];
  selectedAuxiliaryQuoteItems: AuxQuoteItem[];
  auxiliaryItems: ExpandedAuxiliaryQuoteItem[];
};

export const useGetOrderFromQuote = () => {
  const { materials, updateMaterials } = useMaterials();
  const { getLumpSumOrgSKU } = useGetOrCreateLumpSumMaterial();

  const getMaterialByName = useCallback(
    (name: string | null | undefined) => {
      return materials.find((m) => m.material.name === name);
    },
    [materials],
  );

  const getAuxiliaryItems = useCallback(
    async (
      selectedAuxiliaryQuoteItems: AuxQuoteItem[],
      auxiliaryItems: ExpandedAuxiliaryQuoteItem[],
    ) => {
      const missingSelectedAuxiliaryItems = auxiliaryItems.filter(
        (item) =>
          selectedAuxiliaryQuoteItems.some((a) => a.itemId === item.id) &&
          !getMaterialByName(item.description),
      );

      let createdMaterials: OrgMaterialFieldsFragment[] = [];
      if (missingSelectedAuxiliaryItems.length > 0) {
        createdMaterials = (await updateMaterials({
          addedMaterials: missingSelectedAuxiliaryItems.map((item) => {
            const rowIsLumpSum = isLumpSumUomText(item.uom?.pluralDescription);
            if (rowIsLumpSum) {
              return {
                newOrgCatalogSKU: getLumpSumOrgSKU(),
              };
            }
            return {
              newOrgCatalogSKU: {
                name: item.description || "",
                defaultUom:
                  item.uom?.pluralDescription ||
                  DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC,
                defaultManufacturerId: item.manufacturer?.id,
              },
            };
          }),
        })) as OrgMaterialFieldsFragment[];
      }

      const allMaterials = [...materials, ...createdMaterials];
      return auxiliaryItems
        .filter((item) =>
          allMaterials.some(() => getMaterialByName(item.description)),
        )
        .map(
          (item) =>
            ({
              projectItem: {
                orgCatalogSkuId: getMaterialByName(item.description)?.material
                  .id,
                estimateUom:
                  getMaterialByName(item.description)?.defaultEstimateUom
                    .pluralDescription || DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC,
              },
              quantityDecimal: item.quantityDecimal,
              unitPrice: item.unitPrice,
            }) as AddToReleaseItemInput,
        );
    },
    [materials, getMaterialByName, getLumpSumOrgSKU, updateMaterials],
  );

  return useCallback(
    async ({
      rfq,
      selectedQuotes,
      allQuoteItems,
      selectedAuxiliaryQuoteItems,
      auxiliaryItems,
    }: Props): Promise<CreateStandaloneReleaseInput> => {
      const selectedRfqQuote = rfq?.quotes.find((quote) =>
        selectedQuotes.some(
          (selectedQuoteItem) => selectedQuoteItem.quoteId === quote.id,
        ),
      );

      const items = selectedQuotes
        .filter((selectedQuoteItem) => {
          const quoteItem = allQuoteItems.find(
            (item) => item.id === selectedQuoteItem.quoteItemId,
          );
          return quoteItem?.status !== QuoteStatus.Withdrawn;
        })
        .map((selectedQuoteItem) => {
          const quoteItem = allQuoteItems.find(
            (item) => item.id === selectedQuoteItem.quoteItemId,
          );
          const rfqItem = rfq?.items.find(
            (item) => item.id === selectedQuoteItem.rfqItemId,
          );
          return {
            projectItem: {
              orgCatalogSkuId: rfqItem?.projectItem.material.material.id,
              estimateUom: rfqItem?.projectItem.estimateUom.pluralDescription,
            },
            quantityDecimal: quoteItem?.quantityDecimal,
            unitPrice: quoteItem?.unitPrice,
          } as AddToReleaseItemInput;
        });

      const allSelectedAuxiliaryItems = await getAuxiliaryItems(
        selectedAuxiliaryQuoteItems,
        auxiliaryItems,
      );

      return {
        projectId: rfq?.project.id,
        sellerOrgLocationId: selectedRfqQuote?.sellerOrgLocation.id,
        items: [...items, ...allSelectedAuxiliaryItems],
        quoteId: selectedQuotes[0]?.quoteId,
        assignDefaultCostCodes: false,
      };
    },
    [getAuxiliaryItems],
  );
};
