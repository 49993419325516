import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, removeValue, setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import {
  InvoiceStatus,
  InvoiceSummaryFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router";

type InvoiceSummary = Pick<
  InvoiceSummaryFieldsFragment,
  | "number"
  | "issueDate"
  | "createdAt"
  | "total"
  | "release"
  | "predictedSellerOrgLocation"
> & {
  folders?: { id: string; name: string }[];
};

type InvoiceSequenceCsvData = {
  folders?: {
    id: string;
    name: string;
  }[];
  number?: string | null | undefined;
  issueDate?: number | null | undefined;
  createdAt: number | null | undefined;
  total?: string | null | undefined;
  release?: {
    sequenceNumber?: number | null | undefined;
    poNumber?: string | null | undefined;
    type: {
      name: string | null | undefined;
    };
    project?: {
      jobNumber?: string | null | undefined;
    } | null;
    sellerOrgLocation?: {
      org?: {
        name: string | null | undefined;
      };
    } | null;
  } | null;
  predictedSellerOrgLocation?: {
    org?: {
      name: string | null | undefined;
    };
  } | null;
  predictedProject?: {
    jobNumber?: string | null | undefined;
  } | null;
};

export type InvoiceSequenceData = InvoiceSequenceCsvData & {
  id: string;
  version?: number;
  status: InvoiceStatus;
  link?: {
    failedAt?: number | null;
  } | null;
};

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedInvoices: InvoiceSequenceData[];
  setSelectedInvoices: (
    invoices: InvoiceSequenceData[],
    save?: boolean,
  ) => void;
  sequenceActive: boolean;
  startSequence: (invoices?: InvoiceSequenceData[]) => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: { navigateToInvoices: boolean }) => void;
  redirectToInvoices: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedInvoices: [],
  setSelectedInvoices: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToInvoices: NoFunction,
});

export const InvoiceSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedInvoices, setSelectedInvoices] = useState<
    InvoiceSequenceData[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
      [],
    ) as InvoiceSequenceData[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );

  const invoiceCsvDataToSequence = useCallback(
    (invoice: InvoiceSummary): InvoiceSequenceCsvData => {
      return {
        folders:
          invoice.folders?.map((f) => ({ id: f.id, name: f.name })) ?? [],
        createdAt: invoice.createdAt,
        issueDate: invoice.issueDate,
        release: invoice.release
          ? {
              sequenceNumber: invoice.release?.sequenceNumber,
              poNumber: invoice.release?.poNumber,
              type: {
                name: invoice.release?.type?.name,
              },
              project: {
                jobNumber: invoice.release?.project?.jobNumber,
              },
              sellerOrgLocation: {
                org: {
                  name: invoice.release?.sellerOrgLocation?.org.name,
                },
              },
            }
          : undefined,
        predictedSellerOrgLocation: {
          org: {
            name: invoice.predictedSellerOrgLocation?.org?.name,
          },
        },
        number: invoice.number,
        total: invoice.total,
      };
    },
    [],
  );

  const invoiceToSequence = useCallback(
    (invoice: InvoiceSequenceData): InvoiceSequenceData => ({
      id: invoice.id,
      status: invoice.status,
      link: invoice.link ? { failedAt: invoice.link?.failedAt } : undefined,
      ...invoiceCsvDataToSequence(invoice as InvoiceSummary),
    }),
    [invoiceCsvDataToSequence],
  );

  const setSelectedInvoicesAndUpdateLocalStorage = useCallback(
    (invoices: InvoiceSequenceData[]) => {
      setSelectedInvoices(invoices.map(invoiceToSequence));
      setValue(
        LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
        invoices,
      );
    },
    [invoiceToSequence],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [],
  );

  const setStepAndUpdateLocalStorage = useCallback((step: number) => {
    setStep(step);
    setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, step);
  }, []);

  const startSequence = useCallback(
    (invoices?: InvoiceSequenceData[]) => {
      const invoicesInSequence = invoices || selectedInvoices;
      setStepAndUpdateLocalStorage(0);
      setSelectedInvoicesAndUpdateLocalStorage(invoicesInSequence);
      setSequenceActiveAndUpdateLocalStorage(true);
      setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, true);
      const sequenceStep = readValue(
        LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP,
        0,
      ) as number;
      const firstInvoice = invoicesInSequence[sequenceStep];
      if (firstInvoice) {
        setValue(
          LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE,
          location.search,
        );
        navigate(
          generatePath(routes.invoiceVerification, {
            invoiceId: firstInvoice.id,
          }),
        );
      }
    },
    [
      location.search,
      navigate,
      selectedInvoices,
      setSelectedInvoicesAndUpdateLocalStorage,
      setSequenceActiveAndUpdateLocalStorage,
      setStepAndUpdateLocalStorage,
    ],
  );

  const stopSequence = useCallback(() => {
    setSelectedInvoicesAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP);
  }, [
    setSelectedInvoicesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedInvoices.map((i) => i.id),
    [selectedInvoices],
  );

  const redirectToInvoices = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    stopSequence();
    navigate(generatePath(`${routes.invoices}${redirectParams}`));
  }, [navigate, stopSequence]);

  const navigateToNextSequence = useCallback(
    ({ navigateToInvoices } = { navigateToInvoices: false }) => {
      if (sequenceActive) {
        if (sequenceIds.length <= step + 1) {
          redirectToInvoices();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          navigate(
            generatePath(routes.invoiceVerification, {
              invoiceId: sequenceIds[step + 1],
            }),
          );
        }
      } else if (navigateToInvoices) {
        redirectToInvoices();
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      redirectToInvoices,
      setStepAndUpdateLocalStorage,
      navigate,
    ],
  );

  const setSelectedInvoicesAndSaveToLocalStorage = useCallback(
    (invoices: InvoiceSequenceData[], saveToLocalStorage = false) => {
      setSelectedInvoices(invoices.map(invoiceToSequence));
      if (saveToLocalStorage) {
        setValue(
          LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
          invoices,
        );
        if (invoices.length > 1) {
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, true);
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, 0);
        }
      }
    },
    [invoiceToSequence],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedInvoices,
        setSelectedInvoices: setSelectedInvoicesAndSaveToLocalStorage,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToInvoices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceSequence = (): ProviderContextType =>
  useContext(ProviderContext);
