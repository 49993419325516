import { InventoryTransactionType, ServiceType } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";

export const useTransactionHeaderTransactionFulfillmentDate = ({
  transaction,
}: InventoryTransactionProps) => {
  const intl = useIntl();
  const props = useMemo(() => {
    switch (transaction?.type) {
      case InventoryTransactionType.Adjustment:
      case InventoryTransactionType.Transfer:
        return {
          labels: {
            date: transaction.date,
            dateLabel: intl.$t({ id: "TRANSACTION_DATE" }),
            info: intl.$t({
              id: `INVENTORY_TRANSACTION_TYPE_${transaction?.type}`,
            }),
          },
          classes: {
            pill: "bg-turquoise-500 text-white",
          },
        };
      case InventoryTransactionType.Inflow:
      case InventoryTransactionType.Outflow:
        const isDelivery =
          transaction.deliverySlip?.release?.includeServices.includes(
            ServiceType.Delivery,
          ) || false;
        const isStocking =
          transaction.deliverySlip?.release?.includeServices.includes(
            ServiceType.Stocking,
          ) || false;

        const type = intl.$t({
          id: isDelivery ? "VENDOR_DELIVERY" : "CLIENT_DELIVERY",
        });
        return {
          labels: {
            date: transaction.deliverySlip?.release?.time,
            dateLabel: intl.$t({ id: "NEXT_DELIVERY" }),
            info: `${intl.$t({ id: "DELIVERY" })}: ${type}`,
          },
          classes: {
            pill: isDelivery || isStocking ? "bg-yellow-600" : "bg-green-300",
          },
        };

      default:
        return {};
    }
  }, [
    intl,
    transaction?.date,
    transaction?.deliverySlip?.release?.includeServices,
    transaction?.deliverySlip?.release?.time,
    transaction?.type,
  ]);

  return { props };
};
