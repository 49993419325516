import { LocationSelectorControlled } from "@/common/components/location-selector/LocationSelectorControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";

export const ProjectLocationSelector = ({
  disabled,
  name,
}: Pick<ControlledProps, "name" | "disabled">) => {
  return (
    <LocationSelectorControlled
      name={name}
      permission="createProject"
      testId="form-locations-selector"
      disabled={disabled}
    />
  );
};
