import { useMemo } from "react";
import { RfqsBuyoutsProps } from "./RfqsBuyouts";

export const useRfqsBuyouts = ({ rfq }: RfqsBuyoutsProps) => {
  const allBuyouts = useMemo(
    () => rfq.quotes.map((quote) => quote.buyout).filter((buyout) => !!buyout),
    [rfq],
  );

  const firstBuyout = useMemo(() => allBuyouts[0], [allBuyouts]);

  const restBuyouts = useMemo(
    () => (allBuyouts.length ? allBuyouts.slice(1) : []),
    [allBuyouts],
  );

  return { allBuyouts, firstBuyout, restBuyouts };
};
