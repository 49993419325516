import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrentStockItemContainer } from "../../CurrentStockList.styles";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";

const ExtPriceContainer = tw.div`text-gray-600 text-[9px]`;

export const CurrentStockAveragePrice = ({
  item,
}: CurrentStockListItemProps) => {
  return (
    <NotNullableRenderer value={item?.averagePrice}>
      <CurrentStockItemContainer>
        <Price price={item?.averagePrice} maximumFractionDigits={2} />
        <FormattedMessage
          id="EXT_WITH_PRICE"
          values={{
            price: (
              <Price price={item?.averageExtPrice} maximumFractionDigits={2} />
            ),
          }}
          tagName={ExtPriceContainer}
        />
      </CurrentStockItemContainer>
    </NotNullableRenderer>
  );
};
