import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../../providers/ReleaseProvider";
import { useOrderItemPoItemReferences } from "./useOrderItemPoItemReferences";

export const useReleaseItemsToSubmit = () => {
  const { release } = useRelease();
  const intl = useIntl();
  const { setMetadata } = useColumnMapper();
  const { connectedSourceSystem } = useOrgSettings();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { vendors, getVendorCode } = useVendors();

  return useMemo(() => {
    return (
      release?.items.map((item) => {
        if (
          hasOrderItemPoItemReferences(item, release) &&
          item.position !== undefined &&
          item.position !== null
        ) {
          setMetadata(item.position, {
            warningIconTooltipText: intl.$t(
              { id: "ITEM_WILL_NEED_TO_BE_MANUALLY_ADDED_TO_EXTERNAL_PO" },
              { sourceSystem: connectedSourceSystem },
            ),
          });
        }

        const estimatedItems =
          item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          ) ?? [];

        if (estimatedItems.length === 0 && item.uom) {
          estimatedItems.push({
            id: item.id,
            quantityDecimal: item.quantityDecimal,
            zone: item.zone,
            tags: item.tags,
            uom: item.uom,
          });
        }

        const itemVendor = item.sellerOrgLocation?.id
          ? vendors.find(
              (vendor) =>
                vendor.sellerOrgLocation.id === item.sellerOrgLocation?.id,
            )
          : null;

        return {
          ...item.projectItem,
          ...item,
          material: {
            ...item.projectItem?.material,
            material: {
              ...item.projectItem?.material.material,
              name: item.name,
            },
          },
          estimatedItems,
          vendorName: itemVendor
            ? vendorLabelFormatter(itemVendor.sellerOrgLocation, [], {
                vendorCode: getVendorCode(itemVendor),
              })
            : "",
        } as ExpandedReleaseItem;
      }) ?? []
    );
  }, [
    connectedSourceSystem,
    getVendorCode,
    hasOrderItemPoItemReferences,
    intl,
    release,
    setMetadata,
    vendors,
  ]);
};
