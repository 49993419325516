import { QueryInventoryTransactionsFilter } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  filter: QueryInventoryTransactionsFilter;
  setFilter: (step: QueryInventoryTransactionsFilter) => void;
};

export const useInventoryLedgerStore = create<State>()(
  devtools((set) => ({
    filter: {},
    setFilter: (filter: QueryInventoryTransactionsFilter) =>
      set(() => ({
        filter,
      })),
  })),
);
