import { QuoteDocumentProvider } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { CreateOrderFromQuote } from "./create-order-from-quote/CreateOrderFromQuote";
import { CreateOrderFromQuoteForm } from "./create-order-from-quote/components/CreateOrderFromQuoteForm";

export const OrderFromQuote = () => {
  return (
    <CreateOrderFromQuoteForm>
      <QuoteDocumentProvider>
        <CreateOrderFromQuote />
      </QuoteDocumentProvider>
    </CreateOrderFromQuoteForm>
  );
};
