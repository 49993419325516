import { DecimalSafe } from "@/common/utils/decimalSafe";
import { AddToBuyoutItemInput } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

export type AddToBuyoutItem = AddToBuyoutItemInput & {
  itemId: string;
  estimatedItemIds: string[];
  unitPrice?: string;
  isIncluded: boolean;
};

type ContextType = {
  updates: AddToBuyoutItem[];
  setUpdates: (updates: AddToBuyoutItem[]) => void;
};

const ProjectItemContext = createContext<ContextType>({
  updates: [],
  setUpdates: NoFunction,
});

export const BuyoutProjectItemsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [bulkUpdates, setBulkUpdates] = useState<AddToBuyoutItem[]>([]);

  const setUpdateForBulkUpdate = (updates: AddToBuyoutItem[]) => {
    const updatedBulkUpdates = bulkUpdates.map((bu) => {
      const newItem =
        updates.find(
          (u) =>
            u.itemId === bu.itemId &&
            u.estimatedItemIds.some((ei) => bu.estimatedItemIds.includes(ei)),
        ) || ({} as AddToBuyoutItem);
      return {
        ...bu,
        ...newItem,
        estimatedItemIds: newItem.estimatedItemIds
          ? [...newItem.estimatedItemIds, ...bu.estimatedItemIds]
          : [...bu.estimatedItemIds],
        quantityDecimal: new DecimalSafe(newItem.quantityDecimal || 0)
          .add(bu?.quantityDecimal || 0)
          .toString(),
      };
    });
    const existingUpdates = bulkUpdates.filter((u) =>
      updates.some(
        (existing) =>
          existing.itemId === u.itemId &&
          existing.estimatedItemIds.some((ei) =>
            u.estimatedItemIds.includes(ei),
          ),
      ),
    );
    const newBulkUpdates = [
      ...updatedBulkUpdates,
      ...updates.filter(
        (u) =>
          !existingUpdates.some((existing) => existing.itemId === u.itemId),
      ),
    ].filter((u) => u.isIncluded);

    setBulkUpdates(newBulkUpdates);
  };

  return (
    <ProjectItemContext.Provider
      value={{
        updates: bulkUpdates,
        setUpdates: setUpdateForBulkUpdate,
      }}
    >
      {children}
    </ProjectItemContext.Provider>
  );
};

export const useBuyoutProjectItemsWithUpdates = () => {
  return useContext(ProjectItemContext);
};
