export const MESSAGES_SEGMENT = "/messages";

export const routes = {
  // Admin
  admin: "/admin",
  orgProfile: "/admin/organization",
  orgSettings: "/admin/organization/settings",
  orgNotifications: "/admin/organization/notifications",
  billing: "/admin/billing",
  locations: "/admin/locations",
  users: "/admin/users",
  userRoles: "/admin/user-roles",
  vendors: "/admin/vendors",
  vendorsWithId: "/admin/vendors/:vendorId",
  orgs: "/admin/orgs",
  gmvReport: "/admin/gmv-report",
  assignManufacturer: "/admin/materials/assign/:id",
  accounting: "/admin/accounting",
  costStructure: "/admin/cost-structure",
  costTypes: "/admin/cost-structure/cost-types",
  equipment: "/admin/cost-structure/equipment",
  serviceCodes: "/admin/cost-structure/service-codes",
  taxCodes: "/admin/cost-structure/tax-codes",
  paymentMethods: "/admin/cost-structure/payment-methods",
  itemDatabase: "/admin/items",
  itemPrices: "/admin/items/prices",
  adminOrders: "/admin/orders",
  orderApproval: "/admin/orders/order-approval",
  invoiceApproval: "/admin/orders/invoice-approval",
  connections: "/admin/connections",
  warehouses: "/admin/warehouses",

  // Project
  project: "/home/project/:id",
  projectEstimatedItems: "/home/project/:id/estimated-items",
  projectBudget: "/home/project/:id/budget",
  budgetByTags: "/home/project/:id/budget/by-tags",
  projectBudgetReport: "/home/project/:id/budget-report",
  projectDetails: "/home/project/:id/details",
  projectZones: "/home/project/:id/zones",
  projectTags: "/home/project/:id/tags",
  projectMaterials: "/home/project/:id/materials",
  addToProject: "/home/project/:id/add",

  // Home
  home: "/home",
  projects: "/home/projects",
  quotes: "/home/quotes",
  me: "/home/me",
  userNotifications: "/home/me/notifications",
  requisitions: "/home/requisitions",

  // Contractor RFQ
  rfqById: "/home/rfq/:id",
  addToRfq: "/home/rfq/:id/add",
  rfqCheckout: "/home/rfq/:id/checkout",
  rfqFromNote: "/home/rfq/from-note/:noteDocumentId",
  editRfqFromNote: "/home/rfq/from-note/:noteDocumentId/:id",
  bidsWithId: "/home/rfq/:id/bids",
  bidMessaging: "/home/rfq/:id/bids/:bidId" + MESSAGES_SEGMENT,

  // Contractor buyouts
  buyouts: "/home/buyouts",
  buyout: "/home/buyouts/:id",
  addToBuyout: "/home/buyouts/:id/add",
  buyoutMessages: "/home/buyouts/:id" + MESSAGES_SEGMENT,
  buyoutDeliveries: "/home/buyouts/:id/deliveries",
  buyoutFromQuote: "/home/buyouts/from-quote/:quoteDocumentId",
  editBuyoutFromQuote: "/home/buyouts/from-quote/:quoteDocumentId/:buyoutId",
  specifyBuyoutDetails: "/home/buyouts/:id/specify-details",

  // Contractor deliveries
  deliveries: "/home/deliveries",
  delivery: "/home/deliveries/:deliveryId",
  deliveryMessages: "/home/deliveries/:deliveryId" + MESSAGES_SEGMENT,
  addProductsToRelease: "/home/deliveries/:deliveryId/products",
  specifyDeliveryDetails: "/home/deliveries/:deliveryId/specify-details",

  deliverySlips: "/home/deliveries/delivery-slips",
  deliverySlipVerification: "/home/deliveries/delivery-slips/:deliverySlipId",
  deliverySlipMatchOrder:
    "/home/deliveries/delivery-slips/:deliverySlipId/match-order",
  deliverySlipReceiveOrder:
    "/home/deliveries/delivery-slips/:deliverySlipId/receive-order",
  deliveryFromQuote: "/home/deliveries/from-quote/:quoteDocumentId",
  editDeliveryFromQuote:
    "/home/deliveries/from-quote/:quoteDocumentId/:deliveryId",
  editDeliveryFromQuoteMessages:
    "/home/deliveries/from-quote/:quoteDocumentId/:deliveryId" +
    MESSAGES_SEGMENT,
  deliveryFromNote: "/home/deliveries/from-note/:noteDocumentId",
  editDeliveryFromNote:
    "/home/deliveries/from-note/:noteDocumentId/:deliveryId",
  editDeliveryFromNoteMessages:
    "/home/deliveries/from-note/:noteDocumentId/:deliveryId" + MESSAGES_SEGMENT,

  // Contractor invoices
  emailInvoices: "/home/invoices/emails",
  invoices: "/home/invoices",
  invoiceVerification: "/home/invoices/verification/:invoiceId",
  invoiceDownload: "/home/invoices/download/:invoiceId", // Link that is accessible from ERP

  // Contractor receipts
  receipts: "/home/receipts",
  processReceipt: "/home/receipts/:receiptId/process",

  // Contractor inventory management
  currentStock: "/home/inventory-management",
  inventoryAdjustment: "/home/inventory-management/adjustment",
  inventoryTransfer: "/home/inventory-management/transfer",
  inventoryLedger: "/home/inventory-management/inventory-ledger",

  // Distributor quotes
  distributorBid: "/home/distributor/quote/:quoteId",
  distributorBidMessages: "/home/distributor/quote/:quoteId" + MESSAGES_SEGMENT,

  // Distributor buyouts
  distributorBuyout: "/home/distributor/buyouts/:id",
  distributorBuyoutMessages: "/home/distributor/buyouts/:id" + MESSAGES_SEGMENT,
  distributorBuyoutDeliveries: "/home/distributor/buyouts/:id/deliveries",

  // Distributor deliveries
  distributorDelivery: "/home/distributor/deliveries/:deliveryId",
  distributorDeliveryMessages:
    "/home/distributor/deliveries/:deliveryId" + MESSAGES_SEGMENT,
  distributorDeliveryRecord:
    "/home/distributor/deliveries/:deliveryId/delivery-record",

  // Distributor invoices
  distributorInvoice: "/home/distributor/invoices/:invoiceId",
};
