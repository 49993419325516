import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  ConnectNodeInput,
  DisconnectNodeInput,
  ProjectDocument,
  useConnectProjectMutation,
  useDisconnectProjectMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import React, { FC, createContext, useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useProjectStore } from "../store/useProjectStore";

type ProviderContextType = {
  connectProject: (input: ConnectNodeInput) => Promise<boolean>;
  disconnectProject: (input: DisconnectNodeInput) => Promise<boolean>;
  connecting: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  connectProject: NoFunctionBooleanPromise,
  disconnectProject: NoFunctionBooleanPromise,
  connecting: false,
});

export const AgaveExternalProjectProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { setSuccessAlert } = useSnackbar();
  const updateProjectStoreData = useProjectStore(
    (state) => state.updateStoreData,
  );

  const [connectProjectMutation, { loading: connecting }] =
    useConnectProjectMutation();
  const connectProject = useCallback(
    async (input: ConnectNodeInput) => {
      try {
        const { data } = await connectProjectMutation({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: ProjectDocument,
              variables: { id: input.nodeId, excludePhantoms: true },
            },
          ],
        });

        if (data?.connectProject) {
          updateProjectStoreData({
            budgetLink: data?.connectProject.budgetLink,
            costCodes: data?.connectProject.costCodes,
            allowance: data?.connectProject.allowance,
          });
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_CONNECTED_SUCCESSFULLY",
            }),
          );
        }

        return !!data?.connectProject;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      connectProjectMutation,
      intl,
      setError,
      setSuccessAlert,
      updateProjectStoreData,
    ],
  );

  const [disconnectProjectMutation] = useDisconnectProjectMutation();
  const disconnectProject = useCallback(
    async (input: DisconnectNodeInput) => {
      try {
        const { data } = await disconnectProjectMutation({
          variables: {
            input,
          },
        });

        if (data?.disconnectProject) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_DISCONNECTED_SUCCESSFULLY",
            }),
          );
          updateProjectStoreData({
            budgetLink: null,
            costCodes: data?.disconnectProject.costCodes,
          });
        }

        return !!data?.disconnectProject;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      disconnectProjectMutation,
      intl,
      setError,
      setSuccessAlert,
      updateProjectStoreData,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        connectProject,
        disconnectProject,
        connecting,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveExternalProjectProvider = () =>
  useContext(ProviderContext);
