import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  TagFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useProjectTags } from "../../../project/hooks/useProjectTags";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item?: ExpandedReleaseItem;
  readonly?: boolean;
  visibleTags?: number;
};

export const ReleaseTagsPicker: FC<Props> = ({
  item,
  visibleTags = 3,
  readonly,
}) => {
  const { release, project, updateRelease } = useReleaseUpdate();
  const [selectedTags, setSelectedTags] = useState<TagFieldsFragment[]>([]);
  const { allTags } = useProjectTags();

  const handleChange = useCallback(
    async (tags: string[]) => {
      if (release) {
        if (item?.id) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            skipConfirmation: true,
            updates: [
              {
                releaseItemId: item.id,
                tags: tags.slice(-1),
              },
            ],
          };
          await updateRelease(input);
        } else {
          setSelectedTags(
            tags
              .slice(-1)
              .map(
                (tag) => allTags.find((t) => t.id === tag) as TagFieldsFragment,
              ),
          );
        }
      }
    },
    [release, item?.id, updateRelease, allTags],
  );

  if (!release) {
    return null;
  }

  return (
    <TagPicker
      options={allTags}
      values={item?.id ? (item?.tags ?? selectedTags) : selectedTags}
      handleChange={handleChange}
      visibleTags={visibleTags}
      project={project}
      readonly={readonly}
    />
  );
};
