import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInventoryItemTransactionsQuery } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { WarehouseInventoryItem } from "../../../../pages/current-stock/types/WarehouseInventoryItem";
import { useInventoryItemTransactionsFilter } from "./useInventoryItemTransactionsFilter";

export const useInventoryItemTransactions = ({
  inventoryItem,
}: {
  inventoryItem: WarehouseInventoryItem | undefined;
}) => {
  const { filter } = useInventoryItemTransactionsFilter();
  const { setPageInfo, paginationArgs } = usePagination();
  const { data, loading, error, refetch } = useInventoryItemTransactionsQuery({
    variables: {
      ...paginationArgs,
      filter: {
        ...filter,
        inventoryItemId: inventoryItem?.id ?? "",
        warehouseID: inventoryItem?.warehouse.id ?? "",
      },
    },
    skip: !inventoryItem,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.inventoryItemTransactions) {
      setPageInfo(data.inventoryItemTransactions.pageInfo);
    }
  }, [data, setPageInfo]);

  const inventoryItemTransactions = useMemo(
    () => data?.inventoryItemTransactions?.edges.map((e) => e.node) ?? [],
    [data],
  );

  useErrorEffect(error);

  return {
    inventoryItemTransactions,
    loading,
    error: !!error,
    totalCount: data?.inventoryItemTransactions?.totalCount ?? 0,
    refetch,
  };
};
