import { InventoryTransactionType } from "@/generated/graphql";
import { useMemo } from "react";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";

export const useTransactionHeaderTransactionCreationDetails = ({
  transaction,
}: InventoryTransactionProps) => {
  const createdByLabel = useMemo(() => {
    switch (transaction?.type) {
      case InventoryTransactionType.Adjustment:
      case InventoryTransactionType.Transfer:
        return "ENTERED_BY";
      case InventoryTransactionType.Inflow:
      case InventoryTransactionType.Outflow:
        return "CREATED_BY";
    }
  }, [transaction?.type]);

  return { createdByLabel };
};
