import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { useInstruction } from "@/common/hooks/useInsruction";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { ReceiptCreateRelease } from "./ReceiptCreateRelease";
import { ReceiptOrderHeader } from "./ReceiptOrderHeader";
import { ReceiptNonItemizedView } from "./non-itemized/ReceiptNonItemizedView";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll flex flex-col gap-4 px-3`;
const InnerContainer = tw.div``;
const ViewContainer = tw.div<{ $show: boolean }>`
  ${({ $show }) => !$show && "opacity-0 h-0 fixed -bottom-full"}
`;

export const RecordOrderForm: FC = () => {
  const { watch } = useFormContext<ReceiptCreateReleaseFormValues>();
  const { loading, itemized, hasReleaseRecorded } = useReceipt();
  const { release } = useRelease();
  const { saveDescription, saveInstruction } = useInstruction();
  const projectId = watch("projectId");

  return (
    <Loader loading={loading}>
      <Container>
        <ReceiptOrderHeader />
        <InnerContainer>
          <ViewContainer $show={!itemized}>
            <ReceiptNonItemizedView />
          </ViewContainer>
          <ViewContainer $show={itemized}>
            <ReceiptCreateRelease itemized={itemized} />
          </ViewContainer>
          <If isTrue={(hasReleaseRecorded && release) || !hasReleaseRecorded}>
            <Instructions
              projectId={projectId}
              instruction={
                hasReleaseRecorded ? release?.instructions : undefined
              }
              saveInstruction={saveInstruction}
            >
              <ReleaseDescription
                release={hasReleaseRecorded ? release : undefined}
                directlyEditable
                saveDescriptionCustomMethod={saveDescription}
              />
            </Instructions>
          </If>
        </InnerContainer>
      </Container>
    </Loader>
  );
};
