import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Popover } from "@/common/components/popover/Popover";
import { routes } from "@/config/routes";
import { RfqsRfqFieldsFragment, RfqStatus } from "@/generated/graphql";
import { Link as LinkIcon } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { LinkIconContainer, RfqNumberContainer } from "../Rfqs.styles";

type RfqNumberProps = {
  rfq: RfqsRfqFieldsFragment;
};

const Container = tw.div`w-full`;

const RfqIdContainer = tw.div`
  font-bold
`;
const Description = tw.div`truncate text-[10px] font-normal text-gray-600 leading-[12px]`;

export const RfqNumber: FC<RfqNumberProps> = ({ rfq }) => {
  const chainedVersion = rfq.nextRevision
    ? rfq.nextRevision
    : rfq.previousRevision;

  return (
    <Container>
      <RfqNumberContainer>
        <If
          isTrue={
            !!(rfq.previousRevision && rfq.status === RfqStatus.Draft) ||
            !!rfq.nextRevision
          }
        >
          <LinkIconContainer>
            <Popover
              id={`link-icon-${rfq.id}`}
              $arrow
              position="top"
              element={
                <LinkLike
                  to={
                    rfq.nextRevision
                      ? generatePath(routes.rfqCheckout, {
                          id: chainedVersion?.id || "",
                        })
                      : generatePath(routes.rfqById, {
                          id: chainedVersion?.id || "",
                        })
                  }
                >
                  <LinkIcon color="action" />
                </LinkLike>
              }
            >
              <FormattedMessage
                id={rfq.nextRevision ? "RFQ_REQUESTED_CHANGES" : "RFQ_BASED_ON"}
                values={{ number: chainedVersion?.clientIdentifier }}
              />
            </Popover>
          </LinkIconContainer>
        </If>
        <RfqIdContainer>{rfq.clientIdentifier}</RfqIdContainer>
      </RfqNumberContainer>
      <If isTrue={rfq?.description}>
        <Description>{rfq?.description}</Description>
      </If>
    </Container>
  );
};
