import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { FC } from "react";
import {
  TransactionHistoryItemContainer,
  TransactionHistoryItemDetails,
  TransactionHistoryItemName,
  TransactionHistoryItemNameContainer,
  TransactionHistoryItemOrigin,
} from "../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";
import { TransactionOrigin } from "../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: TransactionOrigin | null;
};

export const TransactionItemOrigin: FC<Props> = ({ item }: Props) => {
  const Icon = item?.icon;

  return (
    <TransactionHistoryItemContainer>
      <TransactionHistoryItemOrigin>
        {Icon ? (
          <Icon className="h-6 w-6 text-black" />
        ) : (
          <OrgLogo
            logoImageUrl={item?.org?.photoUrl}
            name={item?.org?.name || ""}
            width={24}
          />
        )}
        <TransactionHistoryItemNameContainer>
          <TransactionHistoryItemName>{item?.name}</TransactionHistoryItemName>
          <TransactionHistoryItemDetails>
            {item?.typeName}
          </TransactionHistoryItemDetails>
        </TransactionHistoryItemNameContainer>
      </TransactionHistoryItemOrigin>
    </TransactionHistoryItemContainer>
  );
};
