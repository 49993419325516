import Handsontable from "handsontable";
import { COLUMN_TYPE } from "../enums/columnType";
import { useSpreadSheetStore } from "../store/useSpreadSheetStore";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "./getPhysicalRowIndex";

export const setPrefillCell = (
  hotInstance: Handsontable | null | undefined,
  row: number,
  column: COLUMN_TYPE,
  value: string | number | null,
): void => {
  if (!hotInstance) {
    return;
  }
  const physicalRow = getPhysicalRowIndex(hotInstance, row);
  const physicalColumn = getPhysicalColumnIndex(hotInstance, column);
  useSpreadSheetStore
    .getState()
    .applyPrefillChanges([[physicalRow, physicalColumn, value]]);
};
