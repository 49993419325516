import { If } from "@/common/components/if/If";
import { AvatarStyled } from "@/common/components/select/styles/Selector.styles";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { RfqsBuyoutItem, RfqsBuyoutItemVariant } from "./RfqsBuyoutItem";
import { useRfqsBuyouts } from "./useRfqsBuyouts";

export type RfqsBuyoutsProps = {
  rfq: RfqsRfqFieldsFragment;
};

const Container = tw.div`flex items-center gap-2`;
const TooltipContainer = tw.div`grid grid-flow-col auto-cols-max`;

export const RfqsBuyouts = ({ rfq }: RfqsBuyoutsProps) => {
  const { firstBuyout, restBuyouts } = useRfqsBuyouts({ rfq });

  if (!firstBuyout) {
    return <FormattedMessage id="EMPTY_VALUE" />;
  }

  return (
    <Container>
      <RfqsBuyoutItem
        id={firstBuyout.id}
        clientIdentifier={firstBuyout.clientIdentifier}
        poNumber={firstBuyout.poNumber}
        className="px-0"
      />
      <If isTrue={restBuyouts.length}>
        <Tooltip
          id="rfqs-releases"
          element={<AvatarStyled>+{restBuyouts.length}</AvatarStyled>}
          classes={{ tooltip: "max-w-[400px]" }}
        >
          <TooltipContainer
            className={restBuyouts.length > 2 ? "grid grid-cols-3 gap-y-4" : ""}
          >
            {restBuyouts.map((buyout, index) => (
              <RfqsBuyoutItem
                key={buyout.id}
                id={buyout.id}
                clientIdentifier={buyout.clientIdentifier}
                poNumber={buyout.poNumber}
                variant={RfqsBuyoutItemVariant.dark}
                withBorder={
                  (restBuyouts.length > 2 && (index + 1) % 3 !== 0) ||
                  index !== restBuyouts.length - 1
                }
              />
            ))}
          </TooltipContainer>
        </Tooltip>
      </If>
    </Container>
  );
};
