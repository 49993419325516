import { checkRfqStatus } from "@/common/utils/status-checks/checkProjectStatus";
import { RfqStatus } from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useRfq } from "../hooks/useRfq";
import { RfqDescriptionProps } from "./RfqDescription";

export const useRfqDescription = ({
  rfq,
  directlyEditable = false,
  saveDescriptionCustomMethod,
  description,
}: RfqDescriptionProps) => {
  const intl = useIntl();
  const { updateRfq } = useRfq();

  const isDirectlyEditable =
    directlyEditable ||
    (checkRfqStatus(rfq, [RfqStatus.Draft]) && !rfq?.deletedAt);

  const [editedDescription, setEditedDescription] = useState<string>(
    description || rfq?.description || "",
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isEditable = useMemo(
    () => !checkRfqStatus(rfq, [RfqStatus.Cancelled]),
    [rfq],
  );

  const labelOptional = useMemo(() => {
    return `${intl.$t({ id: "RFQ_DESCRIPTION" })} ${intl.$t({
      id: "OPTIONAL_WITH_BRACKETS",
    })}`;
  }, [intl]);

  const tooltipLabel = useMemo(() => {
    return intl.$t({
      id: "RFQ_DESCRIPTION_TOOLTIP",
    });
  }, [intl]);

  const saveDescription = useCallback(
    (description: string) => {
      if (saveDescriptionCustomMethod) {
        saveDescriptionCustomMethod(description);
        return;
      }
      if (rfq && (rfq?.description || "") !== description) {
        updateRfq({
          rfqId: rfq.id,
          description: description || "",
        });
      }
    },
    [rfq, updateRfq, saveDescriptionCustomMethod],
  );

  const onSave = useCallback(() => {
    saveDescription(editedDescription);

    setIsEditing(false);
  }, [editedDescription, saveDescription]);

  const onClose = useCallback(() => {
    if (rfq?.description) {
      setEditedDescription(rfq.description);
    }

    setIsEditing(false);
  }, [rfq?.description, setEditedDescription, setIsEditing]);

  return {
    onClose,
    onSave,
    tooltipLabel,
    labelOptional,
    isEditable,
    isEditing,
    isDirectlyEditable,
    editedDescription,
    setEditedDescription,
    saveDescription,
    setIsEditing,
  };
};
