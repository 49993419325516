import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useAddNoteItems } from "@/common/hooks/add-missing-items-to-order/useAddNoteItems";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useItemizedReleaseProjectBudgetSync } from "@/contractor/pages/home/release/hooks/useItemizedReleaseProjectBudgetSync";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AdditionalChargesFieldsFragment } from "@/generated/graphql";
import { ChevronLeft } from "@mui/icons-material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderFromNote } from "../../../../providers/OrderFromNoteProvider";
import { useCreateOrderFromNoteConfig } from "./CreateOrderFromNote.config";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";
import { CreateOrderFromNoteHeader } from "./CreateOrderFromNoteHeader";
import { useCreateOrderFromNoteDetails } from "./useCreateOrderFromNoteDetails";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const Header = tw.div`grid pl-1 text-base font-medium items-center mb-1`;
const InnerContainer = tw.div`flex flex-col h-full`;
const Footer = tw.div`mt-10 w-full pb-5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const HeaderGroup = tw.div`grid grid-cols-[auto_auto_1fr] w-full place-content-center place-items-center gap-1 mb-4`;
const BackButton = tw.button`grid place-items-center bg-blue-800 rounded-md text-white`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;
const NewOrderHeaderGroup = tw(HeaderGroup)`grid-cols-[auto_1fr]`;

export const CreateOrderFromNoteDetails: FC = () => {
  const {
    handleClick,
    tableItems,
    updateSubtotal,
    total,
    releaseInput,
    handleUpdateRelease,
  } = useCreateOrderFromNoteDetails();
  const { release, loading: releaseLoading } = useRelease();
  const { addNoteItems } = useAddNoteItems();
  const spreadsheetViewColumns = useCreateOrderFromNoteConfig();
  const { loading } = useNoteDocument();
  const { loading: updating } = useOrderFromNote();
  const { saveDescription, saveInstruction } = useInstruction();
  const { watch } = useFormContext<CreateOrderFromNoteFormValues>();
  useItemizedReleaseProjectBudgetSync();

  const projectId = watch("projectId");

  return (
    <Container>
      <If isTrue={release}>
        <HeaderGroup>
          <BackButton onClick={handleClick}>
            <ChevronLeft />
          </BackButton>
          <FormattedMessage id="EDIT_ORDER" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </HeaderGroup>
      </If>
      <If isTrue={!release}>
        <NewOrderHeaderGroup>
          <FormattedMessage id="NEW_RELEASE" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </NewOrderHeaderGroup>
      </If>
      <CreateOrderFromNoteHeader />
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading || updating || releaseLoading}
            height="550px"
            onChanges={updateSubtotal}
          />
        </SpreadSheetView>
      </InnerContainer>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          customPaymentTerm={watch("paymentTerm")?.toString()}
          editableByContractor
          updateRelease={handleUpdateRelease}
          includePaymentTerms
          editablePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
        <Instructions
          projectId={projectId}
          instruction={release?.instructions}
          saveInstruction={saveInstruction}
        >
          <ReleaseDescription
            release={release}
            directlyEditable
            saveDescriptionCustomMethod={saveDescription}
          />
        </Instructions>
      </Footer>
    </Container>
  );
};
