import { useMemo } from "react";
import { ImageInitialsProps } from "../ImageInitials";

export const useImageInitialsSizing = ({
  width,
  height,
}: Pick<ImageInitialsProps, "height" | "width">) => {
  return useMemo(
    () => ({
      width,
      minWidth: width,
      minHeight: height || width,
      height: height || width,
      fontSize: width * 0.35 + "px",
    }),
    [height, width],
  );
};
