import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Loader } from "@/common/components/loader/Loader";
import { NoResultsWithOptions } from "@/common/components/no-results-with-options/NoResultsWithOptions";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { ProjectItemsZonesProvider } from "../../../../project/providers/ProjectItemsZonesProvider";
import { AddEstimatedItemListItems } from "./AddEstimatedItemListItems";
import { BuyoutInfoWithActions } from "./BuyoutInfoWithActions";
import { useProjectItems } from "./useProjectItems";

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

type Props = {
  loading?: boolean;
  columns: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  >;
};

export const ProjectItems = ({ columns, loading }: Props) => {
  const { project, condition } = useProjectItems();

  if (!project) {
    return <Loader loading />;
  }

  return (
    <Container>
      <ProjectItemsZonesProvider
        project={project}
        defaultGroupedByZones={false}
      >
        <BuyoutInfoWithActions project={project} />
        <ListRenderer
          hasItemsCondition={condition}
          emptyList={
            <NoResultsWithOptions options={["PROJECT_EMPTY_PROJECT_LIST"]} />
          }
        >
          <AddEstimatedItemListItems columns={columns} loading={loading} />
        </ListRenderer>
      </ProjectItemsZonesProvider>
    </Container>
  );
};
