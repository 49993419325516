import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useReleaseCacheUpdate } from "@/contractor/pages/home/release/providers/useReleaseCacheUpdate";
import {
  ApproveInvoiceInput,
  CreateInvoicedReleaseItemInput,
  InvoiceDocument,
  InvoiceFieldsFragment,
  InvoiceStatus,
  ReleaseDocument,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateInvoicedReleaseItemInput,
  useInvoiceQuery,
  useRescanInvoiceMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { InvoiceReleaseSortOptionsEnum } from "../enums/InvoiceReleaseSortOptionsEnum";
import { useInvoiceApprove } from "../hooks/useInvoiceApprove";
import { useInvoiceUpdate } from "../hooks/useInvoiceUpdate";

type CreateInvoicedReleaseItemInputWithId = CreateInvoicedReleaseItemInput & {
  id: string;
};

type UpdateInvoiceInputWithReleaseItemId = Omit<
  UpdateInvoiceInput,
  "updatedInvoicedReleaseItems" | "addedInvoicedReleaseItems"
> & {
  updatedInvoicedReleaseItems?: (UpdateInvoicedReleaseItemInput & {
    releaseItemId: string;
    quantitySoFar?: string | null;
  })[];
  addedInvoicedReleaseItems?: CreateInvoicedReleaseItemInputWithId[];
};

type ProviderContextType = {
  invoice: InvoiceFieldsFragment | null;
  updateInvoice: (
    input: UpdateInvoiceInputWithReleaseItemId | UpdateInvoiceInput,
    args?: { includeDocuments?: boolean; bulkUpdate?: boolean },
  ) => Promise<UpdateInvoiceMutation | undefined | null>;
  approveInvoice: (
    input: ApproveInvoiceInput,
    args?: { hasMissingCostCodes: boolean },
  ) => Promise<boolean>;
  approving: boolean;
  footerState: InvoiceFooterState;
  setFooterState: (state: InvoiceFooterState) => void;
  loading: boolean;
  submitUpdates: (args: {
    includeDocuments?: boolean;
    refetchQueries?: boolean;
  }) => Promise<boolean>;
  hasChanges: boolean;
  showOnlyInvoicedItems: boolean;
  setShowOnlyInvoicedItems: (show: boolean) => void;
  markItemsWithoutCostCode: boolean;
  setMarkItemsWithoutCostCode: (mark: boolean) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  rescanInvoice: () => void;
  resetUpdates: () => void;
  sortingOrder: InvoiceReleaseSortOptionsEnum;
  setSortingOrder: (order: InvoiceReleaseSortOptionsEnum) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  invoice: null,
  updateInvoice: NoFunctionUndefinedPromise,
  approveInvoice: NoFunctionBooleanPromise,
  approving: false,
  footerState: InvoiceFooterState.DEFAULT,
  setFooterState: NoFunction,
  loading: false,
  submitUpdates: NoFunctionBooleanPromise,
  hasChanges: false,
  showOnlyInvoicedItems: false,
  setShowOnlyInvoicedItems: NoFunction,
  markItemsWithoutCostCode: false,
  setMarkItemsWithoutCostCode: NoFunction,
  hasError: false,
  setHasError: NoFunction,
  rescanInvoice: NoFunction,
  resetUpdates: NoFunction,
  sortingOrder: InvoiceReleaseSortOptionsEnum.OriginalOrder,
  setSortingOrder: NoFunction,
});

export const InvoiceVerificationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();
  const { settings } = useOrgSettings();
  const { invoiceId } = useParams();
  const updateReleaseCache = useReleaseCacheUpdate();
  const { setError } = useGlobalError();
  const [hasChanges, setHasChanges] = useState(false);
  const [showOnlyInvoicedItems, setShowOnlyInvoicedItems] = useState(false);
  const [footerState, setFooterState] = useState<InvoiceFooterState>(
    InvoiceFooterState.DEFAULT,
  );
  const changes = useRef<UpdateInvoiceInputWithReleaseItemId>();
  const [hasError, setHasError] = useState(false);
  const [markItemsWithoutCostCode, setMarkItemsWithoutCostCode] =
    useState(false);
  const { setSystemAlert } = useSnackbar();
  const [sortingOrder, setSortingOrder] = useState(
    InvoiceReleaseSortOptionsEnum.OriginalOrder,
  );

  const { data, loading, error } = useInvoiceQuery({
    variables: { id: invoiceId || "" },
    skip: !invoiceId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (invoiceId) {
      setHasChanges(false);
      setShowOnlyInvoicedItems(false);
    }
  }, [invoiceId]);

  const { updateInvoice: updateInvoiceMutation } = useInvoiceUpdate();

  const accumulateChanges = (input: UpdateInvoiceInputWithReleaseItemId) => {
    const removedInvoicedReleaseItems =
      input.removedInvoicedReleaseItems?.filter(
        (item) =>
          !changes.current?.addedInvoicedReleaseItems?.some(
            (i) => i.id === item,
          ),
      ) || [];
    const added = mergeChanges(
      changes.current?.addedInvoicedReleaseItems?.filter(
        (i) => !input.removedInvoicedReleaseItems?.includes(i.id),
      ),
      input.addedInvoicedReleaseItems,
      "releaseItemId",
    );
    const removals = [
      ...[
        ...(changes.current?.removedInvoicedReleaseItems || []),
        ...removedInvoicedReleaseItems,
      ].filter(
        (item) => !added.some((i) => i.id === item || i.releaseItemId === item),
      ),
    ];
    let updates = mergeChanges(
      changes.current?.updatedInvoicedReleaseItems?.filter(
        (i) => !input.removedInvoicedReleaseItems?.includes(i.id),
      ),
      input.updatedInvoicedReleaseItems,
      "releaseItemId",
    );

    const allAddedItems = added.filter((i) => !removals?.includes(i.id));
    if (allAddedItems.length > 0) {
      updates = [
        ...updates,
        ...(added.filter((i) => removals.includes(i.id)) || []),
      ];
    }
    changes.current = {
      id: invoiceId || "",
      addedInvoicedReleaseItems:
        allAddedItems.length > 0 ? allAddedItems : undefined,
      updatedInvoicedReleaseItems: updates.length > 0 ? updates : undefined,
      removedInvoicedReleaseItems:
        removals.length > 0 ? Array.from(new Set(removals)) : undefined,
    };

    setHasChanges(
      !!changes.current &&
        (!!changes.current.addedInvoicedReleaseItems?.length ||
          !!changes.current.updatedInvoicedReleaseItems?.length ||
          !!changes.current.removedInvoicedReleaseItems?.length),
    );
  };

  const updateInvoice = async (
    input: UpdateInvoiceInputWithReleaseItemId | UpdateInvoiceInput,
    {
      includeDocuments,
      bulkUpdate = false,
    }: { includeDocuments?: boolean; bulkUpdate?: boolean } = {},
  ): Promise<UpdateInvoiceMutation | undefined | null> => {
    if (bulkUpdate) {
      const addedInvoicedReleaseItems: CreateInvoicedReleaseItemInputWithId[] =
        (
          input.addedInvoicedReleaseItems?.map((i) => ({
            ...i,
            id: i.releaseItemId,
          })) || []
        ).concat(
          (
            input as UpdateInvoiceInputWithReleaseItemId
          ).updatedInvoicedReleaseItems
            ?.filter((i) =>
              changes.current?.addedInvoicedReleaseItems?.some(
                (j) => j.releaseItemId === i.releaseItemId,
              ),
            )
            .map((i) => ({
              releaseItemId: i.releaseItemId,
              id: i.id,
              invoicedStatus: InvoiceStatus.AwaitingApproval,
              quantity: i.quantity || "0",
              unitPrice: i.unitPrice || "0",
              clearUnitPrice: i.clearUnitPrice ?? undefined,
            })) || [],
        );
      const newInput = {
        ...input,
        updatedInvoicedReleaseItems: (
          input as UpdateInvoiceInputWithReleaseItemId
        ).updatedInvoicedReleaseItems?.filter(
          (i) =>
            !changes.current?.addedInvoicedReleaseItems?.some(
              (j) => j.releaseItemId === i.releaseItemId,
            ),
        ),
        addedInvoicedReleaseItems:
          addedInvoicedReleaseItems.length > 0
            ? addedInvoicedReleaseItems
            : undefined,
      };
      accumulateChanges(newInput);
      const invoiceItems = [
        ...(changes.current?.addedInvoicedReleaseItems || []).map((i) => ({
          ...i,
          quantitySoFar: "0",
        })),
        ...(changes.current?.updatedInvoicedReleaseItems?.map((i) => ({
          id: i.id,
          releaseItemId: i.releaseItemId,
          quantity: i.quantity || "0",
          unitPrice: i.unitPrice || "0",
          quantitySoFar: i.quantitySoFar,
          clearUnitPrice: i.clearUnitPrice,
        })) || []),
      ].map((i) => ({
        ...i,
        __typename: "InvoicedReleaseItem" as const,
        invoiceStatus: InvoiceStatus.AwaitingApproval,
      }));

      updateReleaseCache(
        { releaseId: data?.invoice.release?.id || "" },
        undefined,
        {
          invoiceId: input.id,
          invoiceItems,
          removedInvoiceItems:
            changes.current?.removedInvoicedReleaseItems || [],
        },
      );

      return null;
    }
    const result = await updateInvoiceMutation(input, { includeDocuments });
    if (result?.updateInvoice && invoiceId && input.clearRelease) {
      setHasChanges(false);
    }
    return result;
  };

  const submitUpdates = async ({
    includeDocuments,
    refetchQueries,
  }: {
    includeDocuments?: boolean;
    refetchQueries?: boolean;
  }) => {
    if (changes.current && hasChanges) {
      const result = await updateInvoiceMutation(
        {
          ...changes.current,
          releaseId: data?.invoice.release?.id || "",
          updatedInvoicedReleaseItems:
            changes.current?.updatedInvoicedReleaseItems?.map((i) => ({
              quantity: i.quantity,
              id: i.id,
              unitPrice: i.clearUnitPrice ? undefined : i.unitPrice,
              clearUnitPrice: i.clearUnitPrice,
            })),
          addedInvoicedReleaseItems:
            changes.current?.addedInvoicedReleaseItems?.map((i) => ({
              quantity: i.quantity,
              unitPrice: i.unitPrice,
              releaseItemId: i.releaseItemId,
            })) || [],
        },
        {
          includeDocuments,
          refetchQueries,
        },
      );
      if (result) {
        changes.current = undefined;
        setHasChanges(false);
        return true;
      }

      return false;
    }
    return false;
  };

  const { approveInvoice: approveInvoiceMutation, loading: approving } =
    useInvoiceApprove();
  const approveInvoice = async (
    input: ApproveInvoiceInput,
    { hasMissingCostCodes }: { hasMissingCostCodes?: boolean } = {},
  ): Promise<boolean> => {
    if (invoiceId) {
      if (changes.current) {
        await submitUpdates({});
      }

      if (
        settings?.invoices.requireCostCodesDuringApproval &&
        hasMissingCostCodes
      ) {
        setMarkItemsWithoutCostCode(true);
        setSystemAlert(
          intl.$t(
            { id: "INVOICE_APPROVAL_MISSING_COST_CODES_ERROR" },
            { orderNumber: data?.invoice.release?.sequenceNumber },
          ),
          { type: SystemAlertType.ERROR },
        );
        return false;
      }

      return await approveInvoiceMutation(input, data?.invoice.release?.id);
    }
    return false;
  };

  useErrorEffect(error);

  const [rescanInvoiceMutation] = useRescanInvoiceMutation();

  const rescanInvoice = async () => {
    if (invoiceId) {
      const releaseId = data?.invoice?.release?.id;

      try {
        const { data: rescanData, errors: rescanErrors } =
          await rescanInvoiceMutation({
            variables: {
              invoiceId,
            },
            refetchQueries: [
              ...(releaseId
                ? [
                    {
                      query: ReleaseDocument,
                      variables: {
                        id: releaseId,
                        invoiceId,
                      },
                    },
                  ]
                : []),
              {
                query: InvoiceDocument,
                variables: {
                  id: invoiceId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });

        if (rescanData?.rescanInvoice) {
          changes.current = undefined;

          setHasChanges(false);
        }

        if (rescanErrors) {
          setError(rescanErrors);
        }
      } catch (errors) {
        setError(errors);
      }
    }

    return false;
  };

  const resetUpdates = useCallback(() => {
    changes.current = undefined;
    setHasChanges(false);
  }, []);
  return (
    <ProviderContext.Provider
      value={{
        invoice: data?.invoice || null,
        updateInvoice,
        approveInvoice,
        approving,
        footerState,
        setFooterState,
        loading,
        submitUpdates,
        hasChanges,
        showOnlyInvoicedItems,
        setShowOnlyInvoicedItems,
        markItemsWithoutCostCode,
        setMarkItemsWithoutCostCode,
        hasError,
        setHasError,
        rescanInvoice,
        resetUpdates,
        sortingOrder,
        setSortingOrder,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceVerification = (): ProviderContextType =>
  useContext(ProviderContext);
