import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ViewToggle } from "@/common/components/view-toggle/ViewToggle";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useProjectBudget } from "../../../hooks/useProjectBudget";
import { useBudgetStore } from "../../../store/useBudgetStore";

export enum ProjectCostCodesType {
  ProjectLevel = "project-level",
  OrgLevel = "org-level",
}

const toggleOptions = [
  {
    label: <FormattedMessage id="ORG_LEVEL" />,
    viewState: ProjectCostCodesType.OrgLevel,
  },
  {
    label: <FormattedMessage id="PROJECT_SPECIFIC" />,
    viewState: ProjectCostCodesType.ProjectLevel,
  },
];

type Props = {
  type: ProjectCostCodesType;
  readonly?: boolean;
};

export const UseProjectCostCodesSwitch: FC<Props> = ({ type, readonly }) => {
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { project } = useProject();
  const { updateProject } = useProject();
  const { updateBudgetLink, budgetLink, syncBudget } = useProjectBudget();
  const updateProjectStoreData = useProjectStore(
    (state) => state.updateStoreData,
  );
  const updateBudgetStore = useBudgetStore((state) => state.updateStoreData);

  const sourceSystem = useMemo(
    () => project?.mappings?.[0]?.sourceSystem,
    [project?.mappings],
  );

  const handleToggle = useCallback(
    (view: ProjectCostCodesType) => {
      if (
        !project ||
        (view === ProjectCostCodesType.ProjectLevel && !budgetLink)
      ) {
        return;
      }

      openDialog({
        maxWidth: "xs",
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CONFIRM" }),
        icon: <WarningIcon className="border-red-500 text-red-500" />,
        title: intl.$t({
          id:
            view === ProjectCostCodesType.ProjectLevel
              ? "USE_PROJECT_SPECIFIC_COST_CODES"
              : "USE_ORG_LEVEL_COST_CODES",
        }),
        content: intl.$t(
          {
            id:
              view === ProjectCostCodesType.ProjectLevel
                ? "USE_PROJECT_SPECIFIC_COST_CODES_INFO"
                : "USE_ORG_LEVEL_COST_CODES_INFO",
          },
          {
            ...(view === ProjectCostCodesType.ProjectLevel
              ? {
                  sourceSystem: intl.$t({
                    id: `INTEGRATION_${sourceSystem}`,
                  }),
                }
              : {}),
          },
        ),
        handleConfirm: async () => {
          const response = await updateProject({
            id: project.id,
            usesProjectCostCodes: view === ProjectCostCodesType.ProjectLevel,
          });
          if (response) {
            updateProjectStoreData(response);
            updateBudgetStore(response.allowance);
            if (view === ProjectCostCodesType.ProjectLevel && budgetLink?.id) {
              const result = await updateBudgetLink({
                budgetLinkID: budgetLink.id,
                autoSync: true,
              });
              if (result) {
                syncBudget(budgetLink.id);
              }
            }
          }
        },
        classes: { container: "text-center" },
      });
    },
    [
      budgetLink,
      intl,
      openDialog,
      project,
      sourceSystem,
      syncBudget,
      updateBudgetLink,
      updateProject,
      updateProjectStoreData,
      updateBudgetStore,
    ],
  );

  return (
    <ViewToggle
      options={toggleOptions}
      setView={handleToggle}
      tableView={type}
      readonly={readonly}
    />
  );
};
