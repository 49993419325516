import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import {
  TOOLTIP_EVENT_NAME,
  TooltipEvent,
} from "@/common/providers/TooltipProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { Identity } from "@/types/Identity";
import Core from "handsontable/core";
import { CellProperties } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { COLUMN_TYPE } from "../enums/columnType";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "../utils/getPhysicalRowIndex";

const readOnlyCellClasses = "bg-gray-100";
const readOnlyCellHideTextClasses = "bg-gray-100 text-gray-100";

export const useRenderHelpers = () => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const intl = useIntl();

  const isNotEmpty = useCallback((value: string) => {
    return value !== "" && value !== null && value !== undefined;
  }, []);

  const applyClasses = useCallback(
    (td: HTMLTableCellElement, classes: string) => {
      td.className = td.className.includes(classes)
        ? td.className
        : `${td.className} ${classes}`;
    },
    [],
  );

  const applyTooltip = useCallback((element: HTMLElement, value: string) => {
    element.onmouseover = (event: Event) => {
      window.dispatchEvent(
        new CustomEvent(TOOLTIP_EVENT_NAME, {
          detail: {
            content: value,
            event,
          },
        } as TooltipEvent),
      );
    };

    element.onmouseout = (event: Event) => {
      window.dispatchEvent(
        new CustomEvent(TOOLTIP_EVENT_NAME, {
          detail: {
            content: "",
            event,
          },
        } as TooltipEvent),
      );
    };
  }, []);

  const removeTooltip = useCallback((element: HTMLElement) => {
    window.dispatchEvent(
      new CustomEvent(TOOLTIP_EVENT_NAME, {
        detail: {
          content: "",
        },
      } as TooltipEvent),
    );
    element.onmouseover = () => {};
    element.onmouseout = () => {};
  }, []);

  const addIconWithTooltip = useCallback(
    ({
      element,
      tooltipText,
      icon = "fa-circle-info",
      iconClasses = "",
      onClick,
    }: {
      element: HTMLElement;
      tooltipText: string;
      icon?: string;
      iconClasses?: string;
      onClick?: () => void;
    }) => {
      const iconCont = document.createElement("span");
      iconCont.className = `
        absolute inline-grid top-0 right-0.5 place-items-center h-full 2xl:right-2
      `;
      const iconElement = document.createElement("i");
      iconElement.className = `fa-solid text-[15px] ${icon} text-blue-500 ${iconClasses} hover:text-blue-300`;
      iconCont.appendChild(iconElement);
      element.appendChild(iconCont);

      if (onClick) {
        iconElement.onclick = onClick;
      }

      applyTooltip(iconElement, tooltipText);
    },
    [applyTooltip],
  );

  const hideLumpSumItemReadOnlyText = useCallback(
    (
      td: HTMLTableCellElement,
      row: number,
      instance: Core,
      cellProperties: CellProperties,
    ) => {
      if (cellProperties.disabledForLumpSum) {
        const rows = instance?.getData();
        const uomContent =
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
        if (isLumpSumUomText(uomContent)) {
          applyClasses(td, readOnlyCellHideTextClasses);
          applyTooltip(td, intl.$t({ id: "LUMP_SUM_ITEM" }));
          return true;
        }
        applyClasses(td, "");
        removeTooltip(td);
      }
      return false;
    },
    [applyClasses, applyTooltip, intl, removeTooltip],
  );

  const checkReadOnly = useCallback(
    (
      td: HTMLTableCellElement,
      row: number,
      instance: Core,
      cellProperties: CellProperties,
    ) => {
      hideLumpSumItemReadOnlyText(td, row, instance, cellProperties);
      if (!cellProperties.readOnlyFn) {
        return;
      }
      const physicalRow = getPhysicalRowIndex(instance, row);
      const sourceRow = instance.getSourceDataAtRow(
        physicalRow,
      ) as Identity | null;
      const reason = cellProperties.readOnlyFn?.(sourceRow);

      if (!td.className.includes(readOnlyCellClasses)) {
        td.className = `${td.className} ${reason ? readOnlyCellClasses : ""}`;
      }

      if (reason) {
        applyTooltip(td, reason);
      } else {
        removeTooltip(td);
      }
    },
    [applyTooltip, hideLumpSumItemReadOnlyText, removeTooltip],
  );

  const formatPrice = useCallback(
    (row: number, instance: Core, value: string) => {
      const rows = instance?.getData();

      const uom = rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      const quantity =
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)];

      if (isLumpSumUomText(uom)) {
        return formatCurrency(
          new DecimalSafe(isNotEmpty(quantity) ? quantity : 1).toNumber(),
          {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
          },
        );
      } else {
        return isNotEmpty(value)
          ? formatCurrency(value, {
              maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
            })
          : "";
      }
    },
    [formatCurrency, isNotEmpty],
  );

  return {
    isNotEmpty,
    checkReadOnly,
    applyTooltip,
    removeTooltip,
    addIconWithTooltip,
    applyClasses,
    formatPrice,
  };
};
