import { QuickBooksVariantSpecificItems } from "@/contractor/pages/admin/integrations/components/form/components/QuickBooksVariantGenericItems";
import { QuickBooksVariantGenericItems } from "@/contractor/pages/admin/integrations/components/form/components/QuickBooksVariantSpecificItems";
import { QbDesktopGenericItemsListInfo } from "@/contractor/pages/admin/integrations/components/list/components/QbDesktopGenericItemsListInfo";
import { QbDesktopSpecificItemsListInfo } from "@/contractor/pages/admin/integrations/components/list/components/QbDesktopSpecificItemsListInfo";
import { SourceSystem } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { ExternalProjectStatus } from "../types/ExternalProjectStatus";
import { IntegrationConfig } from "../types/IntegrationConfigType";
import { SOURCE_SYSTEM_FEATURES } from "./sourceSystemFeatures";

export const useSourceSystemConfig = (sourceSystem?: SourceSystem) => {
  const intl = useIntl();

  const getSourceSystemConfig = useCallback(
    (
      sourceSystem: SourceSystem | undefined | null,
      connected?: boolean,
    ): IntegrationConfig => {
      const name = intl.$t({
        id: `INTEGRATION_${sourceSystem}`,
      });
      switch (sourceSystem) {
        case SourceSystem.Sage300:
          return {
            name,
            description: intl.$t({ id: "INTEGRATION_TYPE_HOSTED" }),
            agaveSourceSystemName: "sage-300-cre",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage300.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Sage300],
            activeStatuses: [ExternalProjectStatus.InProgress],
            inactiveStatuses: [
              ExternalProjectStatus.Closed,
              ExternalProjectStatus.Unstarted,
            ],
          };
        case SourceSystem.SageIntacct:
          return {
            name,
            agaveSourceSystemName: "sage-intacct",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/SageIntacct.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.SageIntacct],
            activeStatuses: [
              ExternalProjectStatus.InProgressCapitalized,
              ExternalProjectStatus.Active,
            ],
            inactiveStatuses: [
              ExternalProjectStatus.Backlog,
              ExternalProjectStatus.Closed,
            ],
          };
        case SourceSystem.Foundation:
          return {
            name,
            description: intl.$t({ id: "INTEGRATION_TYPE_HOSTED" }),
            agaveSourceSystemName: "foundation-hosted",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Foundation.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/foundation-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Foundation],
            activeStatuses: [
              ExternalProjectStatus.Active,
              ExternalProjectStatus.Bid,
            ],
            inactiveStatuses: [
              ExternalProjectStatus.Inactive,
              ExternalProjectStatus.Closed,
              ExternalProjectStatus.Overhead,
            ],
          };
        case SourceSystem.Cmic:
          return {
            name,
            agaveSourceSystemName: "cmic",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/CMIC.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/CMiC-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Cmic],
          };
        case SourceSystem.Sage100:
          return {
            name,
            agaveSourceSystemName: "sage-100-contractor",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage100.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Sage100],
            activeStatuses: [
              ExternalProjectStatus.Contract,
              ExternalProjectStatus.Current,
            ],
            inactiveStatuses: [
              ExternalProjectStatus.Closed,
              ExternalProjectStatus.Complete,
            ],
          };
        case SourceSystem.Procore:
          return {
            name,
            agaveSourceSystemName: "procore",
            useSandbox: true,
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Procore.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Procore-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Procore],
            activeStatuses: [ExternalProjectStatus.Active],
            inactiveStatuses: [],
          };
        case SourceSystem.Vista:
          return {
            name,
            agaveSourceSystemName: "vista-hosted",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Vista.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Vista-symbol.png",
            },
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.Vista],
            customConfiguration: true,
            activeStatuses: [
              ExternalProjectStatus.Open,
              ExternalProjectStatus.Pending,
            ],
            inactiveStatuses: [
              ExternalProjectStatus.Closed,
              ExternalProjectStatus.SoftClosed,
            ],
          };
        case SourceSystem.QbOnline:
          return {
            name,
            agaveSourceSystemName: "quick-books-online",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/quickbooks.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/quickbooks-small.png",
            },
            useSandbox: true,
            features: SOURCE_SYSTEM_FEATURES[SourceSystem.QbOnline],
          };
        case SourceSystem.QbDesktop:
        case SourceSystem.QbDesktopAlt:
          const configuration = {
            name,
            agaveSourceSystemName: "quick-books-desktop",
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/quickbooks.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/quickbooks-small.png",
            },
            useSandbox: true,
            features: [],
            variations: [
              {
                topLevel: true,
                system: SourceSystem.QbDesktopAlt,
                description: ({ logo }: { logo: string }) => (
                  <QuickBooksVariantGenericItems logo={logo} />
                ),
                features: SOURCE_SYSTEM_FEATURES[SourceSystem.QbDesktopAlt],
                listInfo: <QbDesktopGenericItemsListInfo />,
              },
              {
                system: SourceSystem.QbDesktop,
                description: ({ logo }: { logo: string }) => (
                  <QuickBooksVariantSpecificItems logo={logo} />
                ),
                features: SOURCE_SYSTEM_FEATURES[SourceSystem.QbDesktop],
                listInfo: <QbDesktopSpecificItemsListInfo />,
              },
            ],
          };
          if (connected && sourceSystem) {
            const variation = configuration.variations.find(
              (variation) => variation.system === sourceSystem,
            );
            if (variation) {
              return {
                ...configuration,
                features: variation.features,
              };
            }
          }
          return configuration;

        default:
          throw new Error(`Source system ${sourceSystem} is not configured`);
      }
    },
    [intl],
  );

  const sourceSystemConfig = useMemo(
    () =>
      sourceSystem ? getSourceSystemConfig(sourceSystem, true) : undefined,
    [getSourceSystemConfig, sourceSystem],
  );

  return { getSourceSystemConfig, sourceSystemConfig };
};
