import { FC } from "react";
import tw from "tailwind-styled-components";
import {
  ReceiptOrderDisplay,
  useReceiptOrderDisplay,
} from "../../hooks/useReceiptOrderDisplay";
import { RecordOrderForm } from "./RecordOrderForm";
import { ReceiptMatchedHeader } from "./receipt-release-items/ReceiptMatchedHeader";
import { ReceiptReleaseItems } from "./receipt-release-items/ReceiptReleaseItems";
import { NoReleaseForReceiptInfo } from "./select-order/NoReleaseForReceiptInfo";
import { ReceiptOrderSelectorView } from "./select-order/ReceiptOrderSelectorView";

const MatchedOrderContainer = tw.div`bg-gray-100 rounded-3xl pt-4 h-full overflow-y-scroll`;

type Props = {
  readonly: boolean;
  isEditMode: boolean;
  toggleEdit: () => void;
};

export const ReceiptOrder: FC<Props> = ({
  readonly,
  isEditMode,
  toggleEdit,
}) => {
  const receiptOrderDisplay = useReceiptOrderDisplay({ readonly, isEditMode });

  switch (receiptOrderDisplay) {
    case ReceiptOrderDisplay.CREATE:
      return <RecordOrderForm />;
    case ReceiptOrderDisplay.SELECT:
      return <ReceiptOrderSelectorView />;
    case ReceiptOrderDisplay.INFO:
      return <NoReleaseForReceiptInfo />;
    default:
      return (
        <MatchedOrderContainer>
          <ReceiptMatchedHeader />
          <ReceiptReleaseItems toggleEdit={toggleEdit} />
        </MatchedOrderContainer>
      );
  }
};
