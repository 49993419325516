import { CostCodeSelector } from "@/common/components/cost-code-selector/CostCodeSelector";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  readonly: boolean;
};

export const MaterialCostCodePicker: FC<Props> = ({
  item,
  readonly,
}: Props) => {
  const { projectCostCodes } = useProjectCostCodes();
  const { updateMaterial } = useMaterialList();

  const onChange = useCallback(
    (costCodeId: string | null) => {
      updateMaterial(item.id, {
        updates: [
          {
            ...(costCodeId && { costCodeId }),
            clearCostCode: !costCodeId,
            orgMaterialId: item.id,
          },
        ],
      });
    },
    [updateMaterial, item],
  );
  return (
    <NotNullableRenderer value={!readonly || item.costCode?.id}>
      <CostCodeSelector
        costCodes={projectCostCodes}
        costCode={item.costCode?.id}
        onChange={onChange}
        readonly={readonly}
        disableClearable={false}
        inputProps="py-1.5"
        placeholder=" "
      />
    </NotNullableRenderer>
  );
};
