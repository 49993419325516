import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { sortInventoryItems } from "./sortInventoryItems";

export const getInventoryItemRemainingQuantity = (
  inventoryItem: InventoryItemFieldsFragment,
) => {
  return inventoryItem.state
    .toSorted(sortInventoryItems)
    .reduce(
      (acc, state) => acc.plus(state.remainingQuantity),
      new DecimalSafe(0),
    );
};
