import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useGetPriceBreakdown } from "../../hooks/useGetPriceBreakdown";
import { useInventoryItemRenderer } from "../../inventory-adjustment/components/renderers/useInventoryItemRenderer";
import { useTransferQuantityRenderer } from "../components/renderers/useTransferQuantityRenderer";
import { useInventoryTransferItemsDecorator } from "./useInventoryTransferItemsDecorator";
import { useInventoryTransferTableValidators } from "./useInventoryTransferTableValidators";

export const useInventoryTransferConfig = () => {
  const intl = useIntl();
  const {
    requiredValidator,
    combineValidators,
    positiveNumberValidator,
    positiveNonZeroNumberValidator,
  } = useTableValidators();
  const { extraOptions } = useInventoryTransferItemsDecorator();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();
  const { existingUniqueInventoryItemValidator } =
    useInventoryTransferTableValidators();
  const inventoryItemRenderer = useInventoryItemRenderer();
  const transferQuantityRenderer = useTransferQuantityRenderer();

  const getUnitPriceBreakdown = useGetPriceBreakdown();

  const isPriceDisabled = useCallback(
    (row: Record<string, string>) => {
      if (row && !rowIsEmpty(row)) {
        const item = findInventoryItemByNameAndUom(
          row[COLUMN_TYPE.Material],
          row[COLUMN_TYPE.UOM],
        );
        const quantity = Number(row[COLUMN_TYPE.Quantity]);
        if (item && quantity && quantity > 0) {
          const breakdown = item ? getUnitPriceBreakdown(item, -quantity) : "";
          return breakdown ? breakdown : intl.$t({ id: "READ_ONLY" });
        }
      }
      return intl.$t({ id: "READ_ONLY" });
    },
    [findInventoryItemByNameAndUom, getUnitPriceBreakdown, intl],
  );

  const isExtPriceDisabled = useCallback(
    (row: Record<string, string>) => {
      if (row && !rowIsEmpty(row)) {
        const item = findInventoryItemByNameAndUom(
          row[COLUMN_TYPE.Material],
          row[COLUMN_TYPE.UOM],
        );
        const quantity = Number(row[COLUMN_TYPE.Quantity]);
        if (item && quantity && quantity > 0) {
          const breakdown = item
            ? getUnitPriceBreakdown(item, -quantity, true)
            : "";
          return breakdown ? breakdown : intl.$t({ id: "READ_ONLY" });
        }
      }
      return intl.$t({ id: "READ_ONLY" });
    },
    [findInventoryItemByNameAndUom, getUnitPriceBreakdown, intl],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "name",
        columnType: COLUMN_TYPE.Material,
        options: [],
        extraOptions,
        validator: combineValidators([
          requiredValidator,
          existingUniqueInventoryItemValidator,
        ]),
        renderer: inventoryItemRenderer,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: () => intl.$t({ id: "READ_ONLY" }),
      },
      {
        header: intl.$t({ id: "IN_STOCK" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.ExistingStock,
        validator: positiveNumberValidator,
        readOnlyFn: () => intl.$t({ id: "READ_ONLY" }),
      },
      {
        header: intl.$t({ id: "TRANSFER_QTY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
        validator: combineValidators([
          requiredValidator,
          positiveNonZeroNumberValidator,
        ]),
        renderer: transferQuantityRenderer,
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        validator: positiveNonZeroNumberValidator,
        readOnlyFn: isPriceDisabled,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        columnType: COLUMN_TYPE.ExtPrice,
        readOnlyFn: isExtPriceDisabled,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "notes",
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [
      isPriceDisabled,
      isExtPriceDisabled,
      intl,
      requiredValidator,
      existingUniqueInventoryItemValidator,
      combineValidators,
      extraOptions,
      positiveNumberValidator,
      positiveNonZeroNumberValidator,
      inventoryItemRenderer,
      transferQuantityRenderer,
    ],
  );

  return spreadsheetConfig;
};
