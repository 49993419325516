import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { If } from "@/common/components/if/If";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  InvoiceInternalKickbackEligibleUsersProvider,
  useInvoiceInternalKickbackEligibleUsers,
} from "../../../../../contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceInternalKickbackEligibleUsersProvider";
import { InvoiceCloseButton } from "../common/InvoiceCloseButton";
import { InvoiceIssueAssetUpload } from "../common/InvoiceIssueAssetUpload";
import {
  useInvoiceKickbackForm,
  VENDOR_CONTACTS_FAKE_ID,
} from "./hooks/useInvoiceKickbackForm";

const Container = tw.div`flex flex-col mb-3`;
const TopContainer = tw.div`grid grid-cols-[auto_auto_auto_1fr] gap-2 items-center justify-between mb-2 mt-2`;
const DescriptionContainer = tw.div`grid grid-cols-[3fr_1fr] gap-2 mb-2`;
const Label = tw.div`font-medium text-sm`;
const Info = tw.div`flex items-center gap-2 opacity-75`;
const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;
const Item = tw.div`py-2`;
const ItemLabel = tw.span``;
const ItemDetail = tw.span`text-gray-500 text-sm`;

type Props = {
  invoiceId?: string | null;
  onClose?: () => void;
  hideCloseButton?: boolean;
  hideDetails?: boolean;
  assets?: AssetFieldsFragment[];
};

const InvoiceKickbackFormWithProvider: FC<Props> = ({
  onClose,
  hideCloseButton,
  hideDetails,
}) => {
  const intl = useIntl();
  const { users, loading: loadingUsers } =
    useInvoiceInternalKickbackEligibleUsers();
  const { assets, removeAsset } = useUploadAssets();
  const { setValue } = useFormContext();
  const { invoice } = useInvoiceVerification();

  const { kickbackUserOptions } = useInvoiceKickbackForm({
    invoice,
    users,
  });

  const updateUrls = useCallback(
    (assets: AssetFieldsFragment[]) => {
      setValue(
        "assetUrls",
        assets.map((asset) => asset.url),
      );
    },
    [setValue],
  );

  const remove = useCallback(
    async (asset: AssetFieldsFragment) => {
      const files = await removeAsset(asset);
      updateUrls(files);
    },
    [removeAsset, updateUrls],
  );

  return (
    <Container>
      <TopContainer>
        <FormattedMessage id="REPORT_ISSUE_TO" tagName={Label} />
        <MultiselectControlled
          name="assigneeIds"
          testId="form-team-selector"
          placeholder={intl.$t({ id: "SELECT_PERSON" })}
          options={kickbackUserOptions}
          required
          loading={loadingUsers}
          getLabel={(option) =>
            option.id === VENDOR_CONTACTS_FAKE_ID
              ? `${intl.$t({ id: "VENDOR" })} (${option.label})`
              : option.isOrderWatcher
                ? `${option.label} (${intl.$t({ id: "WATCHER" })})`
                : option.label
          }
          getValue={(option) => option.id}
          noOptionsText={intl.$t({ id: "NO_USERS_FOUND" })}
          disableCloseOnSelect
          includeCheckbox
          className="min-w-60"
          chipSize="small"
          InputLabelProps={{
            className: "bg-white",
          }}
          groupBy={(option) => option.group}
          customRender={(item) => (
            <Item>
              <ItemLabel>
                {item.id === VENDOR_CONTACTS_FAKE_ID
                  ? intl.$t({ id: "VENDOR" })
                  : item.label}
              </ItemLabel>
              <If isTrue={item.id === VENDOR_CONTACTS_FAKE_ID}>
                <ItemDetail>{` (${item.label})`}</ItemDetail>
              </If>
              <If
                isTrue={
                  item.id !== VENDOR_CONTACTS_FAKE_ID && item.isOrderWatcher
                }
              >
                <ItemDetail>{` (${intl.$t({ id: "WATCHER" })})`}</ItemDetail>
              </If>
            </Item>
          )}
          slotProps={{
            popper: {
              sx: {
                "& .MuiListSubheader-root": {
                  backgroundColor: "#EAEAEA",
                  lineHeight: "36px",
                  color: "#000",
                },
              },
            },
          }}
        />
        <If isTrue={hideDetails}>
          <Info>
            <InfoOutlined />
            <FormattedMessage id="INVOICE_FOOTER_KICKBACK_DETAILS" />
          </Info>
        </If>
        <If isTrue={!hideCloseButton}>
          <InvoiceCloseButton onClose={onClose} />
        </If>
      </TopContainer>
      <DescriptionContainer>
        <TextFieldControlled
          name="description"
          multiline
          label={intl.$t({
            id: "ISSUE_DESCRIPTION",
          })}
          required
          minRows={3}
          maxRows={10}
          rules={{ maxLength: 4096 }}
        />
        <InvoiceIssueAssetUpload updateUrls={updateUrls} />
      </DescriptionContainer>
      <Assets>
        {assets?.map((asset, index) => (
          <AssetItem key={index} asset={asset} remove={remove} index={index} />
        ))}
      </Assets>
    </Container>
  );
};

export const InvoiceKickbackForm: FC<Props> = (props) => (
  <UploadAssetProvider initialAssets={props.assets} context={AssetContext.Note}>
    <InvoiceInternalKickbackEligibleUsersProvider invoiceId={props.invoiceId}>
      <InvoiceKickbackFormWithProvider {...props} />
    </InvoiceInternalKickbackEligibleUsersProvider>
  </UploadAssetProvider>
);
