import { If } from "@/common/components/if/If";
import { AvatarStyled } from "@/common/components/select/styles/Selector.styles";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { RfqsReleaseItem, RfqsReleaseItemVariant } from "./RfqsReleaseItem";
import { useRfqsReleases } from "./useRfqsReleases";

export type RfqsReleasesProps = {
  rfq: RfqsRfqFieldsFragment;
};

const Container = tw.div`flex items-center gap-2`;
const TooltipContainer = tw.div`grid grid-flow-col auto-cols-max`;

export const RfqsReleases = ({ rfq }: RfqsReleasesProps) => {
  const { firstRelease, restReleases } = useRfqsReleases({ rfq });

  if (!firstRelease) {
    return <FormattedMessage id="EMPTY_VALUE" />;
  }

  return (
    <Container>
      <RfqsReleaseItem
        id={firstRelease.id}
        sequenceNumber={firstRelease.sequenceNumber}
        poNumber={firstRelease.poNumber}
        className="px-0"
      />
      <If isTrue={restReleases.length}>
        <Tooltip
          id="rfqs-releases"
          element={<AvatarStyled>+{restReleases.length}</AvatarStyled>}
          classes={{ tooltip: "max-w-[400px]" }}
        >
          <TooltipContainer
            className={
              restReleases.length > 2 ? "grid grid-cols-3 gap-y-4" : ""
            }
          >
            {restReleases.map((release, index) => (
              <RfqsReleaseItem
                key={release.id}
                id={release.id}
                sequenceNumber={release.sequenceNumber}
                poNumber={release.poNumber}
                variant={RfqsReleaseItemVariant.dark}
                withBorder={
                  (restReleases.length > 2 && (index + 1) % 3 !== 0) ||
                  index !== restReleases.length - 1
                }
              />
            ))}
          </TooltipContainer>
        </Tooltip>
      </If>
    </Container>
  );
};
