import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ReactElement, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useArchiveInventoryItems } from "../../hooks/useArchiveInventoryItems";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import { useInventoryItemsFilter } from "../../hooks/useInventoryItemsFilter";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

type Props = {
  deleteIcon: ReactElement;
};

export const useCurrentStockSideActions = ({ deleteIcon }: Props) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useInventoryItemSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { filter } = useInventoryItemsFilter();
  const { refetch } = useInventoryItems();
  const { archiveInventoryItems } = useArchiveInventoryItems();

  const deleteItems = useCallback(() => {
    const removableItems = selectedEntities.filter((item) => !item.archivedAt);
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "DELETE_INVENTORY_ITEM_QUESTION" },
          { number: removableItems.length },
        ),
        text: intl.$t(
          { id: "DELETE_INVENTORY_ITEM_MESSAGE" },
          { number: removableItems.length },
        ),
        handleConfirm: async () => {
          if (
            await archiveInventoryItems(
              removableItems.map((release) => release.id),
            )
          ) {
            setSelectedEntities([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedEntities,
    openDialog,
    intl,
    archiveInventoryItems,
    setSelectedEntities,
    refetch,
  ]);

  const actions = useMemo(() => {
    const sideActions = [];

    if (
      selectedEntities.some((item) => !item.archivedAt) &&
      filter?.archived !== true
    ) {
      sideActions.push({
        icon: deleteIcon,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }

    return sideActions;
  }, [selectedEntities, filter?.archived, deleteIcon, deleteItems]);

  return { sequenceIds, actions };
};
