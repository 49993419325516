import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { routes } from "@/config/routes";
import { AddInventoryItemsButton } from "@/contractor/pages/home/inventory-management/pages/current-stock/components/add-inventory-items-button/AddInventoryItemsButton";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useSyncInventoryTransfer } from "../../hooks/useSyncInventoryTransfer";
import { InventoryTransferFormValues } from "../inventory-transfer-form/InventoryTransferForm";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;

export const InventoryTransferFooter = () => {
  const { onSave, saving } = useSyncInventoryTransfer();
  const { handleSubmit } = useFormContext<InventoryTransferFormValues>();
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    navigate(routes.currentStock);
  }, [navigate]);

  return (
    <FloatingFooter>
      <FooterContent>
        <AddInventoryItemsButton />
        <div />
        <ButtonsContainer>
          <OutlinedButton onClick={handleCancel}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButton>
          <LoadingButton
            button={PrimaryButton}
            onClick={handleSubmit(onSave)}
            loading={saving}
          >
            <FormattedMessage id="SAVE" />
          </LoadingButton>
        </ButtonsContainer>
      </FooterContent>
    </FloatingFooter>
  );
};
