import { NoteDocumentProvider } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { CreateOrderFromNoteForm } from "./create-order-from-note/components/CreateOrderFromNoteForm";
import { CreateOrderFromNote } from "./create-order-from-note/CreateOrderFromNote";

export const OrderFromNote = () => {
  return (
    <CreateOrderFromNoteForm>
      <NoteDocumentProvider>
        <CreateOrderFromNote />
      </NoteDocumentProvider>
    </CreateOrderFromNoteForm>
  );
};
