import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { ZoneCategory } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import { ProjectReportType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectMaterialFieldsFragment,
  ProjectReportCostCodeFieldsFragment,
} from "@/generated/graphql";
import { IntlShape } from "react-intl";
import { formatCSVCurrency } from "./csvReportUtils";

const EMPTY_STRING = "-";

const getDetailsForCostCode = (category: CategoryState<ProjectReportType>) => {
  return category as unknown as ProjectReportCostCodeFieldsFragment & {
    id: string;
  };
};

const escapeCSV = (value: string) => {
  return value.replace(/,/g, " ");
};

const HEADER = [
  "ITEMS",
  "PROJECT_BUDGET_HEADER",
  "PROJECT_BUDGET_QUOTED_HEADER",
  "PROJECT_BUDGET_ORDERED_HEADER",
  "PROJECT_BUDGET_RECEIVED_HEADER",
  "PROJECT_BUDGET_INVOICED_HEADER",
  "PROJECT_BUDGET_PAID_HEADER",
  "PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER",
];

export const materialsCSVBudgetReport = (
  csv: string[][],
  intl: IntlShape,
  zones: ZoneCategory[],
  materialsMap: Map<string, ProjectMaterialFieldsFragment>,
) => {
  csv.push(HEADER.map((header) => intl.$t({ id: header })));
  zones.forEach((zone) => {
    if (!(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID)) {
      csv.push([
        escapeCSV(`${zone.name} (${intl.$t({ id: "ZONE" })})`),
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
      ]);
    }
    zone.items.forEach((costCode) => {
      const budget = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.allowance ||
          getDetailsForCostCode(costCode)?.estimated,
        intl,
      );
      const received = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.receivedTotal,
        intl,
      );
      const ordered = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.orderedTotal,
        intl,
      );
      const invoiced = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.invoicedTotal,
        intl,
      );
      const paid = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.paidTotal,
        intl,
      );
      const overUnder = formatCSVCurrency(
        getDetailsForCostCode(costCode)?.overUnder,
        intl,
      );
      csv.push([
        escapeCSV(`${costCode.name} (${intl.$t({ id: "COST_CODE" })})`),
        budget,
        EMPTY_STRING,
        ordered,
        received,
        invoiced,
        paid,
        overUnder,
      ]);
      const items = costCode.items;
      items.forEach((item) => {
        const material = item as ProjectReportType;
        const materialName =
          materialsMap.get(material.id)?.material.name || EMPTY_STRING;
        const received = formatCSVCurrency(material.received, intl);
        const ordered = formatCSVCurrency(material.ordered, intl);
        const invoiced = formatCSVCurrency(material.invoiced, intl);
        const paid = formatCSVCurrency(material.paid, intl);

        csv.push([
          escapeCSV(materialName),
          EMPTY_STRING,
          EMPTY_STRING,
          ordered,
          received,
          invoiced,
          paid,
          EMPTY_STRING,
        ]);
      });
    });
  });
};
