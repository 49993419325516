import Handsontable from "handsontable";

export const getPhysicalRowIndex = (
  hotInstance: Handsontable | null | undefined,
  row: number,
): number => {
  if (hotInstance?.isDestroyed) {
    return -1;
  }
  return hotInstance?.toPhysicalRow(row) ?? -1;
};
