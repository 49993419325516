import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { Switch } from "@/common/components/switch/Switch";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { Card } from "@/common/layout/ResponsiveClasses";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { FormControl } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { FaCalculator } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProjectBudget } from "../../../hooks/useProjectBudget";
import { useBudgetByCostCodes } from "../useBudgetByCostCodes";
import { AutoSyncSwitch } from "./AutoSyncSwitch";
import {
  ProjectCostCodesType,
  UseProjectCostCodesSwitch,
} from "./UseProjectCostCodesSwitch";

const Container = tw(Card)`
  grid grid-flow-col mb-3 BudgetHeaderFormValues justify-between items-center border mt-3 shadow-md
  gap-2 w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
`;
const SwitchContainer = tw.div<{ $disabled?: boolean }>`
  grid grid-flow-col text-base gap-2 items-center
  ${({ $disabled }) => ($disabled ? "text-gray-500" : "")}
`;
const Divider = tw.div`border-l border-dashed h-full border-gray-500 mx-2`;
const LeftContainer = tw.div`grid grid-flow-col gap-2 items-center`;
const IconButtonBorderlessStyled = tw(IconButtonBorderless)`
  -mr-2
`;

type BudgetHeaderProps = {
  readOnly: boolean;
};

export const BudgetHeader = ({ readOnly = false }: BudgetHeaderProps) => {
  const intl = useIntl();
  const { currencySymbol } = useUser();
  const { calculateBudget } = useBudgetByCostCodes();
  const { budgetLink } = useProjectBudget();
  const { zones } = useProjectStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );
  const { project } = useProject();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { connectedSourceSystem, settings } = useOrgSettings();

  const hasBudgetSync = hasFeatureInConnectedSourceSystem(
    IntegrationFeature.SystemFeatureProjectBudget,
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "PROJECT_BUDGET" })}</title>
      </Helmet>
      <Container>
        <LeftContainer>
          <FormControl fullWidth>
            <NumericalInputControlled
              name="allowance"
              label={intl.$t({ id: "OVERALL_BUDGET" })}
              className="bg-white"
              prefix={currencySymbol}
              slotProps={{
                input: {
                  endAdornment: (
                    <Tooltip
                      element={
                        <IconButtonBorderlessStyled
                          onClick={calculateBudget}
                          disabled={readOnly}
                        >
                          <FaCalculator />
                        </IconButtonBorderlessStyled>
                      }
                    >
                      <FormattedMessage id="CALCULATE_BUDGET" />
                    </Tooltip>
                  ),
                },
              }}
              disabled={readOnly}
            />
          </FormControl>
          <If isTrue={zones?.length > 0}>
            <Divider />
            <Tooltip
              element={
                <SwitchContainer
                  $disabled={budgetLink?.autoSync}
                  className="text-sm"
                >
                  <FormattedMessage
                    id="ZONE_SPECIFIC_BUDGET"
                    values={{ br: <br key="zone-specific-budget-br" /> }}
                  />
                  <SwitchControlled
                    name="zoneSpecificBudget"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={budgetLink?.autoSync || readOnly}
                  />
                </SwitchContainer>
              }
              hideTooltip={!budgetLink?.autoSync}
              classes={{ tooltip: "text-center" }}
            >
              <FormattedMessage
                id="PROJECT_LIMITED_TO_COST_CODES"
                values={{
                  sourceSystem: connectedSourceSystem
                    ? intl.$t({
                        id: `INTEGRATION_${connectedSourceSystem}`,
                      })
                    : "",
                }}
              />
            </Tooltip>
          </If>
          <Divider />
          <If isTrue={hasBudgetSync && project?.externalProjects.length}>
            <AutoSyncSwitch readOnly={readOnly} />
            <Divider />
            <If
              isTrue={
                project?.usesProjectCostCodes !==
                settings?.display?.usesProjectCostCodes
              }
            >
              <SwitchContainer className="text-sm" $disabled={true}>
                <FormattedMessage
                  id="COST_CODES"
                  values={{ br: <br key="budget-cost-codes-br" /> }}
                />
                <UseProjectCostCodesSwitch
                  type={
                    project?.usesProjectCostCodes
                      ? ProjectCostCodesType.ProjectLevel
                      : ProjectCostCodesType.OrgLevel
                  }
                  readonly={true}
                />
              </SwitchContainer>
              <Divider />
            </If>
          </If>
          <Tooltip
            element={
              <SwitchContainer
                className="text-sm"
                $disabled={
                  !!budgetLink?.autoSync && project?.usesProjectCostCodes
                }
              >
                <FormattedMessage
                  id="LIMIT_PROJECT_TO_SELECTED_COST_CODES"
                  values={{ br: <br key="limit-cost-codes-br" /> }}
                />
                {budgetLink?.autoSync && project?.usesProjectCostCodes ? (
                  <Switch
                    value={true}
                    onChange={() => {}}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={true}
                  />
                ) : (
                  <SwitchControlled
                    name="restrictCostCodes"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={readOnly}
                  />
                )}
              </SwitchContainer>
            }
            hideTooltip={
              !budgetLink?.autoSync || !project?.usesProjectCostCodes
            }
            classes={{ tooltip: "text-center" }}
          >
            <FormattedMessage
              id="PROJECT_LIMITED_TO_THESE_COST_CODES"
              values={{
                sourceSystem: connectedSourceSystem
                  ? intl.$t({
                      id: `INTEGRATION_${connectedSourceSystem}`,
                    })
                  : "",
              }}
            />
          </Tooltip>
        </LeftContainer>
      </Container>
    </>
  );
};
