import { useInventoryTransactionQuery } from "@/generated/graphql";

type Props = {
  transactionId: string;
};

export const useInventoryTransaction = ({ transactionId }: Props) => {
  const { data, loading } = useInventoryTransactionQuery({
    variables: {
      id: transactionId,
    },
    skip: !transactionId,
  });

  return {
    transaction: data?.inventoryTransaction,
    loading,
  };
};
