import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  CostCodeFieldsFragment,
  ExternalLedgerAccount,
  useAddCostCodeMutation,
  useRemoveCostCodeMutation,
  useUpdateCostCodeMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";

export type CostCode = CostCodeFieldsFragment & {
  isSubmitted: boolean;
} & { ledgerAccount?: ExternalLedgerAccount };

type ProviderContextType = {
  initialCodes: CostCodeFieldsFragment[];
  costCodes: CostCode[];
  setCostCodes: (costCodes: CostCode[]) => void;
  loading: boolean;
  addEmptyCostCode: () => void;
  updateCodeEditState: (id: string, inEditMode: boolean) => void;
  saveCostCode: (id: string) => void;
  deleteCostCode: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  initialCodes: [],
  costCodes: [],
  setCostCodes: NoFunction,
  loading: false,
  addEmptyCostCode: NoFunction,
  updateCodeEditState: NoFunction,
  saveCostCode: NoFunction,
  deleteCostCode: NoFunction,
});

export const CostCodesListProvider: FC<{
  children: React.ReactNode;
  includeUnassigned?: boolean;
}> = ({ children, includeUnassigned = false }) => {
  const [codes, setCodes] = useState<CostCode[]>([]);
  const { costCodes, loading, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      costCodes: state.costCodes,
      loading: state.loading,
      updateStartupData: state.updateStartupData,
    })),
  );
  const { setError } = useGlobalError();

  const [addCostCode] = useAddCostCodeMutation();
  const [updateCostCode] = useUpdateCostCodeMutation();
  const [removeCostCode] = useRemoveCostCodeMutation();
  const { unassignedCostCode } = useUnspecifiedCostCode();

  useEffect(() => {
    setCodes(
      [
        ...(costCodes || []),
        ...(includeUnassigned ? [unassignedCostCode] : []),
      ].map((code) => ({
        ...code,
        description: code.description.trim(),
        code: code.code.trim(),
        isSubmitted: false,
        ledgerAccount: code.ledgerAccount as ExternalLedgerAccount | undefined,
      })),
    );
  }, [includeUnassigned, unassignedCostCode, costCodes]);

  const addEmptyCostCode = () => {
    if (codes.find((c) => c.id === "")) {
      return;
    }
    setCodes([
      {
        id: "",
        code: "",
        description: "",
        isSubmitted: false,
        inUse: false,
        mappings: [],
      },
      ...codes,
    ]);
  };

  const updateCodeEditState = (id: string, inEditMode: boolean) => {
    const code = codes.find((c) => c.id === id);
    if (code) {
      if (!inEditMode && !code.code && !code.description) {
        setCodes(codes.filter((c) => c.id !== id));
      } else {
        setCodes(
          codes.map((c) => (c.id === code.id ? { ...c, inEditMode } : c)),
        );
      }
    }
  };

  const saveCostCode = async (id: string) => {
    const code = codes.find((c) => c.id === id);
    if (code) {
      if (code.id) {
        const result = await updateCostCode({
          variables: {
            input: {
              id: code.id,
              code: code.code,
              description: code.description,
            },
          },
        });
        if (result.data && result.data?.updateCostCode) {
          const updatedCostCode = result.data?.updateCostCode;
          if (updatedCostCode) {
            updateStartupData({
              costCodes: costCodes.map((c) =>
                c.id === code.id ? { ...c, updatedCostCode } : c,
              ),
            });
          }
        }
      } else {
        try {
          const { data, errors } = await addCostCode({
            variables: {
              input: {
                code: code.code,
                description: code.description,
              },
            },
          });
          if (data && data.addCostCode) {
            const allCostCodes = data.addCostCode;
            updateStartupData({
              costCodes: allCostCodes.sort((a, b) =>
                a.description === code.description
                  ? -1
                  : b.description === code.description
                    ? 1
                    : a.description.localeCompare(b.description),
              ),
            });
          }
          setError(errors);
        } catch (e) {
          setError(e);
        }
      }
    }
  };

  const deleteCostCode = async (id: string) => {
    await removeCostCode({
      variables: {
        costCodeID: id,
      },
    });
    updateStartupData({
      costCodes: costCodes.filter((c) => c.id !== id),
    });
    setCodes(codes.filter((c) => c.id !== id));
  };

  return (
    <ProviderContext.Provider
      value={{
        initialCodes: costCodes,
        costCodes: codes,
        setCostCodes: setCodes,
        loading,
        addEmptyCostCode,
        updateCodeEditState,
        saveCostCode,
        deleteCostCode,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useCostCodesList = (): ProviderContextType =>
  useContext(ProviderContext);
