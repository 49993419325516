import { useCurrencies } from "@/common/components/currency-picker/hooks/useCurrencies";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { OrgSettingsFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { encodeItemizationValue } from "./itemization-setting/useItemizationSelector";
import { encodeUseProjectCostCodes } from "./use-projects-cost-codes-selector/useUseProjectsCostCodesSelector";

type Props = PropsWithChildren;

const getFormValues = (
  orgId: string,
  settings: OrgSettingsFieldsFragment | null | undefined,
) => {
  return {
    id: orgId,
    settings: {
      display: {
        enableManufacturers: settings?.display?.enableManufacturers ?? false,
        itemCostTypes: settings?.display?.itemCostTypes ?? false,
        enableAdditionalCharges:
          settings?.display?.enableAdditionalCharges ?? false,
        requireProjectAssignment:
          settings?.display?.requireProjectAssignment ?? false,
        enableTaxVariance: settings?.display?.enableTaxVariance ?? false,
        defaultItemized: encodeItemizationValue(
          settings?.display?.defaultItemized,
        ),
        usesProjectCostCodes: encodeUseProjectCostCodes(
          settings?.display?.usesProjectCostCodes ?? false,
        ),
      },
      releases: {
        poNumbering: settings?.releases?.poNumbering,
        notifications: settings?.releases?.notifications,
        useSourceSystemPOs: settings?.releases?.useSourceSystemPOs,
        defaultWarehouseId: settings?.releases?.defaultWarehouse?.id,
        clearDefaultWarehouse: undefined,
      },
      invoices: {
        defaultPaymentTerm: settings?.invoices?.defaultPaymentTerm,
        requireCostCodesDuringApproval:
          settings?.invoices?.requireCostCodesDuringApproval,
        clearDefaultPaymentTerm: undefined,
      },
    },
  };
};

export type OrgSettingsFormInput = ReturnType<typeof getFormValues>;

export const OrgSettingsFormHookProvider: FC<Props> = ({ children }) => {
  const { settings, orgId } = useOrgSettings();
  const { currencies } = useCurrencies();
  const methods = useForm<OrgSettingsFormInput>({
    defaultValues: getFormValues(orgId, settings),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (orgId) {
      methods.reset(getFormValues(orgId, settings));
    }
  }, [methods, currencies, orgId, settings]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
