import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ConnectInvoicesPanel } from "../../../scanned-invoices/components/export/connect-invoice-panel/ConnectInvoicesPanel";
import { InvoiceExportStatus } from "./InvoiceExportStatus";
type Props = {
  invoice: InvoiceFieldsFragment;
};

export const ConnectInvoiceButton: FC<Props> = ({ invoice }) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  if (!connectedSourceSystem) {
    return;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) =>
        !invoice.link && !invoice.exportedAt ? (
          <OutlinedButton $small onClick={() => togglePanel(true)}>
            {intl.$t({ id: "CONNECT_INVOICE" })}
          </OutlinedButton>
        ) : (
          <InvoiceExportStatus
            invoice={invoice}
            toggleExportPanel={() => togglePanel(true)}
          />
        )
      }
      content={(togglePanel) => (
        <ConnectInvoicesPanel
          invoiceIds={[invoice.id]}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
