import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useUseProjectsCostCodesSelector } from "./useUseProjectsCostCodesSelector";

export const UseProjectsCostCodesSelector = () => {
  const { options } = useUseProjectsCostCodesSelector();

  return (
    <SelectControlled
      name="settings.display.usesProjectCostCodes"
      className="w-56"
      options={options}
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
    />
  );
};
