import { GridTable } from "@/common/components/grid-table/GridTable";
import { InventoryTransactionProps } from "../../../../../types/InventoryTransactionProps";
import { useTransactionHistoryInflowsConfiguration } from "./TransactionHistoryInflowsList.configuration";

export const TransactionHistoryInflowsList = ({
  transaction,
}: InventoryTransactionProps) => {
  const configuration = useTransactionHistoryInflowsConfiguration({
    transaction,
  });
  return (
    <GridTable
      configuration={{ columns: configuration }}
      items={transaction?.items}
    />
  );
};
