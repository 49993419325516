import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { ListEmptyState } from "@/common/components/list-empty-state/ListEmptyState";
import { Loader } from "@/common/components/loader/Loader";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { routes } from "@/config/routes";
import { MessageContextKind, RfqStatus } from "@/generated/graphql";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import tw from "tailwind-styled-components";
import { useSetCurrentProjectId } from "../project/hooks/useSetCurrentProjectId";
import { RfqMessagesButton } from "../rfqs/components/vendors/RfqMessagesButton";
import { ExportQuotesButton } from "./components/export-quotes/ExportQuotesButton";
import { RfqQuoteOptionsButton } from "./components/quote-options/RfqQuoteOptionsButton";
import { TableView } from "./components/table-view/TableView";
import { BidsPricesProvider } from "./providers/BidsPricesProvider";
import {
  RfqQuoteOptionsProvider,
  useRfqQuoteOptions,
} from "./providers/RfqQuoteOptionsProvider";
import {
  OrderQuotesProvider,
  useRfqQuotes,
} from "./providers/RfqQuotesProvider";

const PageContainer = tw.div`
  relative pt-4
`;

const LeftSide = tw.div`
  w-3 h-full bg-white absolute top-0 left-0 z-70 xl:w-16
`;

const ListEmptyStateStyled = tw(ListEmptyState)`
  mt-5
`;

const Container = tw.div`pb-15`;

const RfqQuotesWithProvider: FC = () => {
  const { rfq, loading } = useRfqQuotes();
  const intl = useIntl();
  const { visibleQuotes } = useRfqQuoteOptions();
  const { bidId } = useParams();

  useSetCurrentProjectId(rfq?.project.id);

  const headerActions = () => {
    const actions = [];
    if (bidId && rfq) {
      actions.push(
        <RfqMessagesButton
          quote={{
            id: bidId,
            rfqId: rfq?.id,
            clientIdentifier: rfq?.clientIdentifier,
            projectId: rfq?.project.id,
          }}
          org={rfq.project.location.org}
          text={<MessageButton id={bidId} kind={MessageContextKind.Quote} />}
        />,
      );
    }
    if (rfq && rfq.quotes.length > 0) {
      if (rfq.status !== RfqStatus.Awarded) {
        actions.push(<RfqQuoteOptionsButton key="options" />);
      }
      actions.push(<ExportQuotesButton key="export-quotes" />);
    }
    return actions;
  };

  if (loading || !rfq) {
    return <Loader loading />;
  }

  return (
    <Container>
      <Helmet>
        <title>{intl.$t({ id: "QUOTES_REVIEW_QUOTES" })}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            text: <FormattedMessage id="QUOTES" />,
            link: routes.quotes,
            id: "quotes",
          },
          {
            text: intl.$t(
              {
                id: "RFQ_NUMBER_WITH_SYMBOL",
              },
              { number: rfq.clientIdentifier },
            ),
            link: generatePath(routes.rfqById, { id: rfq.id }),
            id: "rfq",
          },
          {
            text: (
              <FormattedMessage
                id="QUOTES_NO"
                values={{ number: rfq.quotes.length }}
              />
            ),
            id: "quotes",
          },
        ]}
        actions={headerActions()}
      />
      <LeftSide />
      <PageContainer data-testid="bids-page">
        {visibleQuotes.length > 0 ? (
          <TableView />
        ) : (
          <ListEmptyStateStyled messageKey="NO_BIDS_FOUND" />
        )}
      </PageContainer>
    </Container>
  );
};

export const RfqQuotes: FC = () => (
  <OrderQuotesProvider>
    <RfqQuoteOptionsProvider>
      <BidsPricesProvider>
        <RfqQuotesWithProvider />
      </BidsPricesProvider>
    </RfqQuoteOptionsProvider>
  </OrderQuotesProvider>
);
