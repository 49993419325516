import { useUserLocations } from "@/common/hooks/useUserLocations";
import { OrgLocationPermissions } from "@/generated/graphql";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const useLocationSelectorControlled = ({
  permission,
  name,
}: {
  permission?: keyof OrgLocationPermissions;
  name: string;
}) => {
  const { locations } = useUserLocations({ permission });

  const { setValue } = useFormContext();

  useEffect(() => {
    if (locations.length === 1) {
      setTimeout(() => setValue(name, locations[0].id), 0);
    }
  }, [locations, setValue, name]);
};
