import { useMemo } from "react";
import { useReceipt } from "../providers/ReceiptProvider";
import { ReceiptMatchedOrderViewState } from "../types/ReceiptMatchedOrderViewState";

export enum ReceiptOrderDisplay {
  CREATE,
  SELECT,
  INFO,
  MATCHED,
}

type Input = {
  readonly: boolean;
  isEditMode: boolean;
};

export const useReceiptOrderDisplay = ({
  readonly,
  isEditMode,
}: Input): ReceiptOrderDisplay => {
  const { hasReleaseRecorded, matchedOrderViewState } = useReceipt();

  return useMemo(() => {
    if (!readonly || isEditMode || !hasReleaseRecorded) {
      if (
        matchedOrderViewState === ReceiptMatchedOrderViewState.CREATE_ORDER ||
        hasReleaseRecorded
      ) {
        return ReceiptOrderDisplay.CREATE;
      }
      if (matchedOrderViewState === ReceiptMatchedOrderViewState.MATCH_ORDER) {
        return ReceiptOrderDisplay.SELECT;
      }
      return ReceiptOrderDisplay.INFO;
    }
    return ReceiptOrderDisplay.MATCHED;
  }, [readonly, isEditMode, hasReleaseRecorded, matchedOrderViewState]);
};
