import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateTagInput,
  UpdateTagInput,
  UpdateTagsInput,
  useUpdateTagsMutation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

export const useProjectTags = () => {
  const [updateTagsMutation] = useUpdateTagsMutation();
  const { setError } = useGlobalError();
  const {
    tags,
    phaseCodes,
    loading,
    setCurrentProjectId,
    updateStoreData,
    currentProjectId,
    budgetAutoSync,
  } = useProjectStore(
    useShallow((state) => ({
      tags: state.tags,
      phaseCodes: state.phaseCodes,
      loading: state.loading,
      currentProjectId: state.currentProjectId,
      setCurrentProjectId: state.setCurrentProjectId,
      updateStoreData: state.updateStoreData,
      budgetAutoSync: state.budgetLink?.autoSync ?? false,
    })),
  );

  const updateTags = useCallback(
    async (input: UpdateTagsInput) => {
      try {
        const { data, errors } = await updateTagsMutation({
          variables: { input },
        });
        setError(errors);
        if (data?.updateTags) {
          updateStoreData({ tags: data.updateTags });
        }
        return data?.updateTags;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateTagsMutation, setError, updateStoreData],
  );

  const updateTag = useCallback(
    async (tag: UpdateTagInput) => {
      if (!currentProjectId) {
        return false;
      }

      return await updateTags({
        projectId: currentProjectId,
        updates: [tag],
      });
    },
    [updateTags, currentProjectId],
  );

  const addTag = useCallback(
    async (tag: CreateTagInput) => {
      if (!currentProjectId) {
        return false;
      }

      return await updateTags({
        projectId: currentProjectId,
        addedTags: [tag],
      });
    },
    [updateTags, currentProjectId],
  );

  const deleteTag = useCallback(
    async (tagId: string) => {
      if (!currentProjectId) {
        return false;
      }

      return await updateTags({
        projectId: currentProjectId,
        removedTags: [tagId],
      });
    },
    [updateTags, currentProjectId],
  );

  const allTags = useMemo(() => {
    return [...tags, ...phaseCodes];
  }, [tags, phaseCodes]);

  return {
    tags,
    phaseCodes,
    allTags,
    loading,
    addTag,
    updateTag,
    updateTags,
    deleteTag,
    setCurrentProjectId,
    budgetAutoSync,
  };
};
