import { SKIP_SPACE_COLUMNS } from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { sanitizeValue } from "./sanitizeValue";

export const getCellValue = (
  row: Record<string, string | number>,
  column: COLUMN_TYPE | string,
): string => {
  const value = (row && row[column]) ?? "";
  if (typeof value === "number") {
    return value.toString();
  }
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }
  if (typeof value === "string") {
    return sanitizeValue(
      value,
      SKIP_SPACE_COLUMNS.includes(column as COLUMN_TYPE),
    );
  }
  return value;
};
