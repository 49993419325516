import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { CurrentStockItemContainer } from "../../CurrentStockList.styles";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";

export const CurrentStockScheduledInflow = ({
  item,
}: CurrentStockListItemProps) => {
  return (
    <CurrentStockItemContainer>
      <ValueUnit
        value={item?.scheduledInflowTotal}
        classNames={{ item: "text-xs" }}
        minimumFractionDigits={2}
      />
    </CurrentStockItemContainer>
  );
};
