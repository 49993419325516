import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Price } from "@/common/components/price/Price";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  LgFixedContainer,
  MdFixedContainer,
  RequestedItemsContainer,
} from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { InventoryTransactionItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Item = tw.div`flex items-center justify-center text-sm`;

export const useTransactionHistoryAdjustmentsConfiguration = () => {
  const { formatCostCode } = useProjectCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const configuration: Array<GridCol<InventoryTransactionItemFieldsFragment>> =
    useMemo(
      () => [
        {
          wrapper: RequestedItemsContainer,
          item: ({ item, count }) => (
            <ProjectItemMaterialView
              count={count}
              item={{ ...item.item, material: item.item.orgMaterial }}
              includeMaterialInfo={false}
            />
          ),
          position: "none",
          header: <FormattedMessage id="ITEMS_IN_DELIVERY" />,
        },
        {
          wrapper: LgFixedContainer,
          header: <FormattedMessage id="COST_CODE" />,
          position: "center",
          item: ({ item }) => (
            <Item>{formatCostCode(item.item.orgMaterial.costCode?.id)}</Item>
          ),
        },
        {
          wrapper: MdFixedContainer,
          header: (
            <FormattedMessage id="INVENTORY_TRANSACTION_TYPE_ADJUSTMENT" />
          ),
          position: "center",
          item: ({ item }) => (
            <ValueUnit
              value={item.quantity}
              uom={item.item.uom}
              showNegativeOrPositive
            />
          ),
        },
        {
          wrapper: MdFixedContainer,
          header: <FormattedMessage id="UNIT_PRICE" />,
          position: "center",
          item: ({ item }) => (
            <Item>
              <Price price={item.unitPrice} />
            </Item>
          ),
          sortItemFn: (item1, item2) =>
            new DecimalSafe(item1.unitPrice).lessThan(item2.unitPrice) ? -1 : 1,
        },
        {
          wrapper: MdFixedContainer,
          header: <FormattedMessage id="EXT_PRICE" />,
          position: "center",
          item: ({ item }) => (
            <Item>
              <Price price={calcExtPrice(item.quantity, item.unitPrice)} />
            </Item>
          ),
        },
      ],
      [calcExtPrice, formatCostCode],
    );

  return configuration;
};
