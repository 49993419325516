import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useClearPoNumber } from "@/contractor/pages/home/invoices/pages/scanned-invoices/hooks/useClearPoNumber";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { PrimaryButtonStyled } from "@/contractor/pages/home/release/components/connections/Connections.styles";
import { AdminUsersInfo } from "@/contractor/pages/home/release/components/connections/components/common/AdminUsersInfo";
import {
  ConnectionError,
  ValidationType,
} from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import {
  InvoiceFieldsFragment,
  InvoiceToExportFieldsFragment,
  InvoiceValidationError,
  InvoiceValidationWarning,
  SourceSystem,
} from "@/generated/graphql";
import { ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useReceiptValidation } from "../providers/ReceiptValidationProvider";

export const useReceiptReleaseValidation = (
  invoice: InvoiceFieldsFragment | null | InvoiceToExportFieldsFragment,
) => {
  const intl = useIntl();
  const { refetchReceiptValidation, receiptValidation } =
    useReceiptValidation();
  const { connectedSourceSystem } = useOrgSettings();
  const integration = useMemo(
    () =>
      connectedSourceSystem
        ? intl.$t({ id: `INTEGRATION_${connectedSourceSystem}` })
        : "",
    [connectedSourceSystem, intl],
  );
  const { openDialog } = useDialog();
  const { clearPoNumber } = useClearPoNumber();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { formatCostCode } = useProjectCostCodes();

  const clearPo = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      title: intl.$t({ id: "CLEAR_PO_NUMBER" }),
      text: intl.$t(
        { id: "CLEAR_PO_NUMBER_CONFIRMATION" },
        {
          poNumber:
            invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
          invoiceNumber: invoice?.number?.toString() || "",
          releaseNumber: invoice?.release?.sequenceNumber || "",
          br: <br />,
          integration,
        },
      ),
      includeWarningIcon: true,
      handleConfirm: async () => {
        if (!invoice?.id) {
          return;
        }
        await clearPoNumber(invoice?.id);
        refetchReceiptValidation();
      },
    });
  }, [
    openDialog,
    intl,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.sequenceNumber,
    invoice?.number,
    invoice?.id,
    integration,
    clearPoNumber,
    refetchReceiptValidation,
  ]);

  const exportValidations = useMemo(() => {
    if (!receiptValidation) {
      return [];
    }
    return [
      {
        id: "INTEGRATION_NOT_CONFIGURED",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.IntegrationNotConfigured,
        ),
        key: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED",
        toolTipKey: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP",
        adminToolTipKey:
          "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP_ADMIN",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub1: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.connections} />
          ),
          integration,
        },
      },
      {
        id: "VENDOR_NOT_CONNECTED",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.VendorNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_TOOLTIP",
        adminToolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_ADMIN_TOOLTIP",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.vendorsWithId}
              routeParams={{
                vendorId: invoice?.release?.preferredVendor?.id || "",
              }}
            />
          ),
          vendor: invoice?.release?.sellerOrgLocation?.org.name,
          integration,
        },
      },
      {
        id: "PROJECT_NOT_CONNECTED",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.ProjectNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED_TOOLTIP",
        values: {
          projectName: invoice?.release?.project?.name,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.projectDetails}
              routeParams={{ id: invoice?.release?.project?.id || "" }}
            />
          ),
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.AmountMismatch,
        ),
        validationType:
          connectedSourceSystem === SourceSystem.Foundation
            ? ValidationType.Warning
            : ValidationType.Error,
        key: new DecimalSafe(receiptValidation?.po?.amount || 0).greaterThan(
          new DecimalSafe(invoice?.total || 0),
        )
          ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE"
          : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE",
        toolTipKey: new DecimalSafe(
          receiptValidation?.po?.amount || 0,
        ).greaterThan(new DecimalSafe(invoice?.total || 0))
          ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE_TOOLTIP"
          : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE_TOOLTIP",
        values: {
          integration,
          value: formatCurrency(Number(receiptValidation?.po?.amount || 0)),
        },
      },
      {
        id: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES",
        condition: receiptValidation.itemsMissingCostCodes.length > 0,
        key: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES",
        toolTipKey: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
      },
      {
        id: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        condition: receiptValidation.costCodesNotConnected.length > 0,
        key: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        adminToolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_ADMIN_TOOLTIP",
        toolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_TOOLTIP",
        values: {
          integration,
          sub: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.costStructure} />
          ),
        },
      },
      {
        id: "EXPORT_PO_NOT_LINKED",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoNotLinked,
        ),
        key: "EXPORT_PO_NOT_LINKED",
        toolTipKey: "EXPORT_PO_NOT_LINKED_TOOLTIP",
        validationType: ValidationType.Error,
        values: {
          integration,
          br: <br />,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
      },
      {
        id: "EXPORT_PO_NOT_POSTED",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoNotPosted,
        ),
        key: "EXPORT_PO_NOT_POSTED",
        toolTipKey: "EXPORT_PO_NOT_POSTED_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "PO_OUTDATED",
        condition: receiptValidation?.warnings.includes(
          InvoiceValidationWarning.PoOutdated,
        ),
        key: "PO_OUTDATED",
        toolTipKey: "PO_OUTDATED_TOOLTIP",
        values: {
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoNotBasic,
        ),
        key: "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES",
        toolTipKey:
          "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES_TOOLTIP",
        values: {
          integration,
          poNumber: "",
        },
      },
      {
        id: "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO",
        condition: receiptValidation?.costCodesMissingInPO.length > 0,
        key: "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO",
        toolTipKey:
          "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO_TOOLTIP",
        values: {
          count: receiptValidation.costCodesMissingInPO.length,
          codes: receiptValidation.costCodesMissingInPO
            .map((c) => `"${formatCostCode(c.id)}"`)
            .join(", "),
        },
      },
      {
        id: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT",
        condition: receiptValidation?.costCodesExceedingPOAmounts.length > 0,
        key: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT",
        toolTipKey: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoNumberMismatch,
        ),
        key: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO",
        toolTipKey: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO_TOOLTIP",
      },
      {
        id: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.SalesTaxItemMissing,
        ),
        key: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO",
        toolTipKey: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.AdditionalChargesItemMissing,
        ),
        key: "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO",
        toolTipKey:
          "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoAlreadyLinked,
        ),
        key: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE",
        toolTipKey: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE_TOOLTIP",
        values: {
          integration,
          poNumber: "",
          releaseNumber: invoice?.release?.sequenceNumber || "",
        },
      },
      {
        id: "RECEIPT_WILL_BE_POSTED_AS_DIRECT_JOB_COST",
        condition: receiptValidation.warnings.includes(
          InvoiceValidationWarning.Standalone,
        ),
        key: "RECEIPT_WILL_BE_POSTED_AS_DIRECT_JOB_COST",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PoNotFound,
        ),
        key: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
        button: (
          <PrimaryButtonStyled onClick={clearPo}>
            <FormattedMessage id="CLEAR_PO_NUMBER" />
          </PrimaryButtonStyled>
        ),
        values: {
          poNumber: "",
          integration,
        },
      },
      {
        id: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS",
        condition: receiptValidation?.wbsExceedingPOAmounts.length > 0,
        key: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS",
        toolTipKey: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_PHASE_CODES_MISSING_IN_PO",
        condition: receiptValidation?.wbsMissingInPO.length > 0,
        key: "EXPORT_PHASE_CODES_MISSING_IN_PO",
        toolTipKey: "EXPORT_PHASE_CODES_MISSING_IN_PO_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
        },
      },
      {
        id: "EXPORT_ITEMS_MISSING_TAGS",
        condition: receiptValidation?.itemsMissingTags.length > 0,
        key: "EXPORT_ITEMS_MISSING_TAGS",
        toolTipKey: "EXPORT_ITEMS_MISSING_TAGS_TOOLTIP",
        values: {
          values: receiptValidation?.itemsMissingTags
            .map((item) => item.orgMaterial?.material.name)
            .join(", "),
          releaseNumber: invoice?.release?.sequenceNumber,
        },
      },
      {
        id: "EXPORT_PAYMENT_METHODS_MISSING",
        condition: receiptValidation?.errors.includes(
          InvoiceValidationError.PaymentMethodMissing,
        ),
        key: "EXPORT_PAYMENT_METHODS_MISSING",
        toolTipKey: "EXPORT_PAYMENT_METHODS_MISSING_TOOLTIP",
      },
    ];
  }, [
    receiptValidation,
    integration,
    invoice?.release?.sellerOrgLocation?.org.name,
    invoice?.release?.project?.name,
    invoice?.release?.project?.id,
    invoice?.release?.sequenceNumber,
    invoice?.release?.preferredVendor?.id,
    invoice?.release?.id,
    invoice?.total,
    connectedSourceSystem,
    formatCurrency,
    clearPo,
    formatCostCode,
  ]);

  const validations = useMemo(() => {
    if (!connectedSourceSystem) {
      return [];
    }
    let validations: ConnectionError[] = [];
    const poGreaterThanInvoice = new DecimalSafe(
      receiptValidation?.po?.amount || 0,
    ).greaterThanOrEqualTo(new DecimalSafe(invoice?.total || 0));
    switch (connectedSourceSystem) {
      case SourceSystem.Foundation:
        validations = [
          {
            id: "EXPORT_PO_NOT_POSTED",
            condition: receiptValidation?.errors.includes(
              InvoiceValidationError.PoNotPosted,
            ),
            key: "EXPORT_PO_NOT_POSTED",
            toolTipKey: "EXPORT_PO_NOT_POSTED_TOOLTIP",
            values: {
              integration,
            },
          },
          {
            id: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
            condition: receiptValidation?.errors.includes(
              InvoiceValidationError.PoNotFound,
            ),
            key: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
            button: (
              <PrimaryButtonStyled
                onClick={clearPo}
                className="whitespace-nowrap"
              >
                <FormattedMessage id="CLEAR_PO_NUMBER" />
              </PrimaryButtonStyled>
            ),
            values: {
              poNumber:
                invoice?.poNumber ||
                invoice?.release?.poNumber?.toString() ||
                "",
              integration,
            },
          },
          {
            id: "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH",
            condition: receiptValidation?.errors.includes(
              InvoiceValidationError.AmountMismatch,
            ),
            key: poGreaterThanInvoice
              ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE"
              : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE",
            toolTipKey: poGreaterThanInvoice
              ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE_TOOLTIP"
              : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE_TOOLTIP",
            values: {
              integration,
              value: formatCurrency(Number(receiptValidation?.po?.amount || 0)),
            },
            validationType:
              poGreaterThanInvoice ||
              connectedSourceSystem === SourceSystem.Foundation
                ? ValidationType.Warning
                : ValidationType.Error,
          },
          {
            id: "PROJECT_NOT_CONNECTED",
            condition: receiptValidation?.errors.includes(
              InvoiceValidationError.ProjectNotConnected,
            ),
            key: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED",
            toolTipKey: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED_TOOLTIP",
            values: {
              projectName: invoice?.release?.project?.name,
              sub: (chunks: ReactNode) => (
                <LinkChunks
                  chunks={chunks}
                  route={routes.projectDetails}
                  routeParams={{ id: invoice?.release?.project?.id || "" }}
                />
              ),
              integration,
            },
          },
        ];
        break;
      case SourceSystem.Cmic:
      case SourceSystem.Procore:
      case SourceSystem.Vista:
        validations = exportValidations.filter(
          (v) => v.validationType !== ValidationType.Warning,
        );
        break;
      default:
        validations = [];
        break;
    }

    return validations.filter((v) => v.condition);
  }, [
    connectedSourceSystem,
    receiptValidation?.po?.amount,
    receiptValidation?.errors,
    invoice?.total,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.project?.name,
    invoice?.release?.project?.id,
    integration,
    clearPo,
    formatCurrency,
    exportValidations,
  ]);

  return {
    validations,
    exportValidations,
  };
};
