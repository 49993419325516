import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";

export enum RfqsReleaseItemVariant {
  light,
  dark,
}

type RfqReleaseItemProps = {
  id: string;
  sequenceNumber?: number | null;
  poNumber?: string | null;
  variant?: RfqsReleaseItemVariant;
  withBorder?: boolean;
  className?: string;
};

const ReleaseInfo = tw.div`flex flex-col`;
const PoNumber = tw.span`text-gray-600 text-xs`;

export const RfqsReleaseItem = ({
  id,
  sequenceNumber,
  poNumber,
  variant = RfqsReleaseItemVariant.light,
  withBorder = false,
  className = "",
}: RfqReleaseItemProps) => {
  const navigate = useNavigate();

  const linkPath = useMemo(
    () =>
      generatePath(routes.delivery, {
        deliveryId: id,
      }),
    [id],
  );

  return (
    <ReleaseInfo
      className={`px-4 ${withBorder ? "border-r border-dashed border-white" : ""} ${className}`}
    >
      <LinkLike
        onClick={() => navigate(linkPath)}
        to={linkPath}
        className={`whitespace-nowrap ${
          variant === RfqsReleaseItemVariant.light
            ? ""
            : "text-white hover:text-blue-250"
        }`}
      >
        {sequenceNumber ? (
          <FormattedMessage
            id="ORDER_WITH_NUMBER"
            values={{ orderNumber: sequenceNumber }}
          />
        ) : (
          <FormattedMessage id="RELEASE_STATUS_DRAFT" />
        )}
      </LinkLike>
      <PoNumber
        className={
          variant === RfqsReleaseItemVariant.light ? "" : "text-blue-250"
        }
      >
        {poNumber}
      </PoNumber>
    </ReleaseInfo>
  );
};
