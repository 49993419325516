import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import tw from "tailwind-styled-components";
import { useInventoryWizardTransaction } from "../../../hooks/useInventoryWizardTransaction";
import { TransactionHistoryInflowsHeader } from "./components/transaction-history-inflows-header/TransactionHistoryInflowsHeader";
import { TransactionHistoryInflowsList } from "./components/transaction-history-inflows-list/TransactionHistoryInflowsList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryInflows = () => {
  const { loading, transaction } = useInventoryWizardTransaction();

  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <Loader loading={loading}>
        <Container>
          <TransactionHistoryInflowsHeader transaction={transaction} />
          <TransactionHistoryInflowsList transaction={transaction} />
          <If isTrue={transaction?.deliverySlip?.release?.instructions}>
            <Instructions
              instruction={transaction?.deliverySlip?.release?.instructions}
              projectId={transaction?.deliverySlip?.release?.project?.id}
            />
          </If>
          <ReleaseDeliverySlips
            deliverySlips={
              transaction?.deliverySlip?.release?.deliverySlips || []
            }
          />
        </Container>
      </Loader>
    </PaginationProvider>
  );
};
