import { useTransactionHistoryModal } from "@/contractor/pages/home/inventory-management/common/components/transaction-history/hooks/useTransactionHistoryModal";
import { useCallback, useMemo } from "react";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";

export const useCurrentStockInStock = ({ item }: CurrentStockListItemProps) => {
  const { onOpen } = useTransactionHistoryModal();
  const onClick = useCallback(() => {
    if (item) {
      onOpen({ inventoryItem: item });
    }
  }, [item, onOpen]);

  const className = useMemo(() => {
    return !item?.inStock ? "bg-red-150 w-full h-14" : "h-14";
  }, [item?.inStock]);

  const disabled = useMemo(() => !item, [item]);

  return {
    onClick,
    className,
    disabled,
  };
};
