import { SequenceStore } from "@/common/hooks/useSequence";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { GroupedInventoryItems } from "../../../types/GroupedInventoryItems";

export const useInventoryItemSequenceStore = create<
  SequenceStore<GroupedInventoryItems>
>()(
  devtools((set) => ({
    selectedEntities: [],
    step: 0,
    sequenceActive: false,
    sequenceEnabled: false,
    setSelectedEntities: (values: GroupedInventoryItems[]) =>
      set(() => ({
        selectedEntities: values,
      })),
    setStep: (step: number) =>
      set(() => ({
        step,
      })),
    setSequenceActive: (sequenceActive: boolean) =>
      set(() => ({
        sequenceActive,
      })),
    setSequenceEnabled: (sequenceEnabled: boolean) =>
      set(() => ({
        sequenceEnabled,
      })),
  })),
);
