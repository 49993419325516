import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { Group } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const GroupContainer = tw.div`flex items-center gap-2 p-2`;
const UserContainer = tw.div`p-2`;

export const ProjectUsersSelector: FC<ControlledProps> = (props) => {
  const intl = useIntl();
  const { watch, control, trigger } = useFormContext();
  const { viewer } = useUser();
  const { replace } = useFieldArray({
    name: props.name,
    control,
  });

  const locationId = watch("locationId");

  const { users, loading } = useUsers({ requireLocation: true, locationId });

  const projectUsers = watch(props.name) as string[];

  const usersOptions = useMemo(() => {
    return (
      users?.reduce((result: UsersUserFieldsFragment[], user) => {
        const filteredRoles = user.locationRoles.filter(
          (role) => role.orgLocID === locationId,
        );
        if (filteredRoles.length > 0) {
          result.push({ ...user, locationRoles: filteredRoles });
        }
        return result;
      }, []) || []
    );
  }, [users, locationId]);

  useEffect(() => {
    if (locationId && !loading && users) {
      const activeUsers = projectUsers.filter((projectUser) =>
        users.some(
          (user) =>
            user.id === projectUser &&
            user.locationRoles.some((role) => role.orgLocID === locationId),
        ),
      );
      if (
        activeUsers.length === 0 &&
        viewer?.locationRoles.some((role) => role.orgLocID === locationId) &&
        users.some((user) => user.id === viewer?.id)
      ) {
        activeUsers.push(viewer.id);
      }
      if (activeUsers.length || projectUsers.length) {
        replace(activeUsers);
        trigger(props.name);
      }
    }
    //We don't want to react on projectUsers changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, users, viewer, replace, trigger, props.name, loading]);

  const getUserLabel = useCallback(
    (option: UsersUserFieldsFragment) => {
      const fullName = getUserName(option);
      const roles = (option?.locationRoles || []).map((loc) =>
        intl.$t({ id: `USER_ROLE_${loc.role}` }),
      );
      return `${fullName} (${roles.join(", ")})`;
    },
    [intl],
  );

  const roles = useMemo(() => {
    return usersOptions
      .reduce((result: UsersUserFieldsFragment["locationRoles"], user) => {
        user.locationRoles.forEach((role) => {
          if (!result.some((r) => r.role === role.role)) {
            result.push(role);
          }
        });
        return result;
      }, [])
      .map((loc) => ({
        ...loc,
        name: intl.$t({ id: `USER_ROLE_${loc.role}` }),
      }));
  }, [intl, usersOptions]);

  const customRenderer = useCallback(
    (option: UsersUserFieldsFragment) => {
      if (option.id !== option.firstName) {
        return <UserContainer>{getUserLabel(option)}</UserContainer>;
      }
      return (
        <Tooltip
          id="group"
          element={
            <GroupContainer>
              <Group />
              {option.firstName}
            </GroupContainer>
          }
        >
          <FormattedMessage
            id="ADD_ALL_USERS_IN_WITH_ROLE"
            values={{ role: option.firstName }}
          />
        </Tooltip>
      );
    },
    [getUserLabel],
  );

  const creatableFn = useCallback(
    (_: string, text?: string) => {
      if (!text) {
        return;
      }
      const mappedRoles = roles.filter(
        (r) =>
          r.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()) &&
          usersOptions
            .filter((u) => !projectUsers.includes(u.id))
            .some((u) => u.locationRoles.some((loc) => loc.role === r.role)),
      );

      if (mappedRoles.length) {
        return mappedRoles.map((role) => ({
          id: role.name,
          firstName: role.name,
          lastName: "",
          email: "",
          locationRoles: [{ orgLocID: role.orgLocID, role: role.role }],
          orgRoles: [],
          createdAt: 0,
          isEmailValidated: true,
          cellPhone: "",
          org: {
            id: "",
            name: "",
            locations: [],
          },
          enterpriseRoles: [],
        }));
      }
    },
    [roles, projectUsers, usersOptions],
  );

  return (
    <MultiselectControlled
      placeholder={intl.$t({
        id: "PROJECT_USERS_ASSIGNED_TO_THIS_PROJECT",
      })}
      options={usersOptions || []}
      customRender={customRenderer}
      onCustomMultipleChange={(values) => {
        const onlyExistingUsers = values?.filter((value) =>
          usersOptions.some((user) => user.id === value),
        );
        const groupUsers = values?.filter((v) =>
          roles.some((r) => r.name === v),
        );
        const existingUsers = onlyExistingUsers?.filter(
          (v) => !roles.some((r) => r.name === v),
        );
        if (groupUsers?.length) {
          const usersToAdd = usersOptions.filter((user) =>
            user.locationRoles.some((role) =>
              groupUsers.some(
                (group) =>
                  group === roles.find((r) => r.role === role.role)?.name,
              ),
            ),
          );
          const allUsers = existingUsers?.concat(
            usersToAdd.map((user) => user.id),
          );
          replace(allUsers?.filter((v, i, a) => a.indexOf(v) === i));
        } else {
          replace(existingUsers);
        }
      }}
      getLabel={getUserLabel}
      getOptionLabel={getUserName}
      getValue={(option) => option.id}
      disableCloseOnSelect
      includeCheckbox
      rules={{ validate: (data) => data.length > 0 }}
      required
      loading={loading}
      chipSize="small"
      creatable
      creatableFn={creatableFn}
      creatableUseLabel
      creatableFirstOption
      {...props}
    />
  );
};
