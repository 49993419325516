import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useAddInventoryItems } from "../../../hooks/useAddInventoryItems";

type Props = {
  items: InventoryItemFieldsFragment[];
};
export const AddInventoryItemGroupCheckbox: FC<Props> = ({ items }) => {
  const { selectedItems, setSelectedItems } = useAddInventoryItems();

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);
  const checked = useMemo(
    () => itemIds.every((id) => selectedItems.includes(id)),
    [itemIds, selectedItems],
  );

  const onToggle = useCallback(
    (checked: boolean) => {
      setSelectedItems(
        checked
          ? [...selectedItems, ...itemIds]
          : [
              ...selectedItems.filter(
                (item) => !items.some((i) => i.id === item),
              ),
            ],
      );
    },
    [items, itemIds, selectedItems, setSelectedItems],
  );

  return (
    <SelectionCheckbox
      testId="add-inventory-item-group-checkbox"
      checked={checked}
      setSelection={onToggle}
    />
  );
};
