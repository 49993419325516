import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { getInventoryItemRemainingQuantity } from "./getInventoryItemRemainingQuantity";
import { sortInventoryItems } from "./sortInventoryItems";

export const getInventoryStateAllocations = (
  inventoryItem: InventoryItemFieldsFragment,
  requiredQuantity?: number,
) => {
  let requiredQuantityDecimal;
  if (requiredQuantity === undefined || requiredQuantity === 0) {
    requiredQuantityDecimal =
      getInventoryItemRemainingQuantity(inventoryItem).negated();
  } else {
    requiredQuantityDecimal = new DecimalSafe(requiredQuantity);
  }

  const allocations: {
    state: InventoryItemFieldsFragment["state"][number];
    quantityToTake: DecimalSafe;
  }[] = [];

  const sortedStates = inventoryItem.state.toSorted(sortInventoryItems);

  for (const state of sortedStates) {
    if (requiredQuantityDecimal.greaterThanOrEqualTo(new DecimalSafe(0))) {
      break;
    }
    const availableQuantity = new DecimalSafe(state.remainingQuantity);
    const quantityToTake = new DecimalSafe(
      Math.min(
        -requiredQuantityDecimal.toNumber(),
        availableQuantity.toNumber(),
      ),
    );
    allocations.push({ state, quantityToTake });
    requiredQuantityDecimal = requiredQuantityDecimal.plus(quantityToTake);
  }

  return allocations;
};
