import { If } from "@/common/components/if/If";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { EditOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AssignBuyoutProvider } from "../../../providers/AssignBuyoutProvider";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { SubmitReleaseDialog } from "../footer-actions/SubmitReleaseDialog";
import { UpdateReleaseDialog } from "../footer-actions/UpdateReleaseDialog";
import { useSubmitReleaseButton } from "../footer-actions/useSubmitReleaseButton";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";
import { AssignBuyoutModal } from "./AssignBuyoutModal";

const ASSIGN_BUYOUT_ALLOWED_STATUSES = [
  ReleaseStatus.Draft,
  ReleaseStatus.AwaitingApproval,
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
  ReleaseStatus.Rejected,
  ReleaseStatus.Reserved,
];

const Container = tw.div`ml-2 `;
const Label = tw.label`text-xs text-gray-600 top-[-12px] absolute`;

type AssignBuyoutButtonProps = {
  release: ReleaseFieldsFragment;
  className?: string;
};

const ButtonWrapper = tw.div<{ $isDisabled?: boolean }>`
    flex items-center align-middle gap-2 relative
    ${({ $isDisabled }) =>
      $isDisabled
        ? `hover:cursor-normal`
        : `hover:cursor-pointer text-blue-500 hover:text-blue-300`}    
`;

const EditIcon = tw(EditOutlined)`w-5 h-5`;

export const AssignBuyoutButton: FC<AssignBuyoutButtonProps> = ({
  release,
  className,
}) => {
  const intl = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { syncReleaseItems } = useSyncReleaseItems();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const { hasChanges } = useReleaseStore();
  const { setWarningAlert } = useSnackbar();

  const isDisabled = useMemo(() => {
    return (
      !!release && !ASSIGN_BUYOUT_ALLOWED_STATUSES.includes(release.status)
    );
  }, [release]);

  const clientIdentifier = release?.buyout?.clientIdentifier;

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems =
          item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          ) ?? [];

        if (estimatedItems.length === 0 && item.uom) {
          estimatedItems.push({
            id: item.id,
            quantityDecimal: item.quantityDecimal,
            zone: item.zone,
            tags: item.tags,
            uom: item.uom,
          });
        }
        return {
          ...item.projectItem,
          ...item,
          material: {
            ...item.projectItem?.material,
            material: {
              ...item.projectItem?.material.material,
              name: item.name,
            },
          },
          estimatedItems,
          vendorName: item.sellerOrgLocation
            ? vendorLabelFormatter(item.sellerOrgLocation)
            : "",
        } as ExpandedReleaseItem;
      }) ?? [],
    [release?.items],
  );

  const submitButton = useSubmitReleaseButton({
    release,
    items,
    setSubmitDialogVisible,
    setUpdateDialogVisible,
    overrideNavigationFn: () => validateDataAndShowModal(),
  });

  const validateDataAndShowModal = useCallback(async () => {
    const result = await syncReleaseItems();
    if (result) {
      setIsModalVisible(true);
    }
  }, [syncReleaseItems]);

  const handleClick = useCallback(async () => {
    if (isDisabled) {
      return;
    }
    if (hasChanges) {
      setWarningAlert(
        intl.$t({
          id: "YOU_HAVE_UNSAVED_CHANGES",
        }),
      );
      return;
    }

    await validateDataAndShowModal();
  }, [isDisabled, hasChanges, validateDataAndShowModal, setWarningAlert, intl]);

  return (
    <Container className={className}>
      <If isTrue={submitButton}>
        <ButtonWrapper $isDisabled={isDisabled} onClick={handleClick}>
          <Label>
            {clientIdentifier || !isDisabled ? (
              <FormattedMessage id="BUYOUT_LABEL" />
            ) : (
              ""
            )}
          </Label>
          {clientIdentifier
            ? `#${clientIdentifier}`
            : !isDisabled
              ? intl.$t({ id: "ASSIGN" })
              : ""}
          {!isDisabled && <EditIcon />}
        </ButtonWrapper>
        <If isTrue={isModalVisible}>
          <NestedStepperProvider>
            <AssignBuyoutProvider>
              <AssignBuyoutModal
                visible={isModalVisible}
                handleCancel={() => setIsModalVisible(false)}
              />
            </AssignBuyoutProvider>
          </NestedStepperProvider>
        </If>
        <SubmitReleaseDialog
          visible={submitDialogVisible}
          setVisible={setSubmitDialogVisible}
          callback={submitButton?.callback}
        />
        <UpdateReleaseDialog
          visible={updateDialogVisible}
          setVisible={setUpdateDialogVisible}
          callback={submitButton?.callback}
          status={release.status}
          titleKey="SAVE_ORDER_BEFORE_REASSIGN_TITLE"
        />
      </If>
    </Container>
  );
};
