import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { trimCode } from "@/common/utils/cost-codes-and-zones/trimCode";
import { trimDescription } from "@/common/utils/cost-codes-and-zones/trimDescription";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { CostCode, SourceSystem } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";

export type CostCodeType =
  | Pick<CostCode, "code" | "description">
  | string
  | null
  | undefined;

const NonFormattingSourceSystems = [
  SourceSystem.QbOnline,
  SourceSystem.QbDesktop,
];

export const useCostCodes = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { isContractor } = useUser();
  const { costCodes, formattedCostCodes, loading } = useStartupDataStore(
    useShallow((state) => ({
      costCodes: state.costCodes,
      formattedCostCodes: state.formattedCostCodes,
      loading: state.loading,
    })),
  );

  const showCodeWithNumber = useMemo(() => {
    return (
      (!connectedSourceSystem ||
        !NonFormattingSourceSystems.includes(connectedSourceSystem)) &&
      isContractor
    );
  }, [connectedSourceSystem, isContractor]);

  const formatCostCode = useCallback(
    (costCode: CostCodeType) => {
      if (!costCode) {
        return "";
      }
      if (typeof costCode === "string") {
        const code = costCodes?.find(
          (cc) =>
            trimCode(cc.code).toLowerCase() ===
              trimCode(costCode).toLowerCase() ||
            trimDescription(cc.description).toLowerCase() ===
              trimCode(costCode).toLowerCase() ||
            costCode === cc.id,
        );

        return code
          ? showCodeWithNumber
            ? `${trimDescription(code?.description)} (${code?.code})`
            : trimDescription(code?.description)
          : costCode === UNSPECIFIED_COST_CODE_ID
            ? intl.$t({ id: "UNSPECIFIED" })
            : costCode;
      }

      return showCodeWithNumber
        ? `${trimDescription(costCode.description)} (${costCode.code})`
        : trimDescription(costCode.description);
    },
    [costCodes, intl, showCodeWithNumber],
  );

  return {
    costCodes: formattedCostCodes,
    formatCostCode,
    loading,
    showCodeWithNumber,
  };
};
