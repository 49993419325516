import { Base, Card, CardContent } from "@/common/layout/ResponsiveClasses";
import Close from "@mui/icons-material/Close";
import tw from "tailwind-styled-components";
import { Loader } from "../loader/Loader";

export const DefaultContainer = tw.div``;

export const HeaderCardWrapper = tw(Base)`
    sticky pb-1 bg-white z-40 mb-0 lg:block hidden
`;

export const HeaderCard = tw(Card)`
  sticky first:mt-0 bg-gray-200 text-sm z-20 border-gray-200 cursor-default print:static py-0 hidden lg:block 
`;

export const HeaderCardContent = tw(CardContent)`
  px-3 py-1 min-h-8 flex-wrap space-y-3 lg:space-y-0 items-center gap-1
`;

export const CategoryCard = tw(Card)`
  shadow lg:shadow-none border-0 lg:border 
  rounded-b-none lg:border-t sticky bg-yellow-300 z-10 py-0 mb-px overflow-hidden cursor-pointer
`;

export const CategoryCardContent = tw(CardContent)`
    flex md:grid grid-flow-col items-center lg:flex py-1 px-2 gap-1 text-sm
`;

export const SubCategoryCard = tw(Card)`
    shadow lg:shadow-none border-0 lg:border
    first:rounded-t-none sticky bg-yellow-200 z-10 py-0 mb-px overflow-hidden cursor-pointer gap-1
`;

export const SubCategoryCardContent = tw(CardContent)`
    flex md:grid grid-flow-col items-center lg:flex py-1 px-2 gap-1
`;

type ItemCardProps = {
  $hasCardItems?: boolean;
  $hasCursor?: boolean;
};
export const ItemCard = tw(Card)`
    border-0 mt-2 lg:border-0.5 first:pt-1 first:-mt-px shadow lg:shadow-none transition-colors
    group is-row
    ${({ $hasCardItems }: ItemCardProps) =>
      $hasCardItems ? " py-1" : " lg:rounded-none lg:mt-0"}
    ${({ $hasCursor }: ItemCardProps) =>
      $hasCursor && "cursor-pointer hover:bg-blue-100"}
`;
export const ItemCardContent = tw(CardContent)`
    p-3 min-h-12 flex-wrap space-y-2 relative
    lg:space-y-0 lg:flex lg:gap-1 lg:px-2 lg:py-1
    grid grid-cols-[repeat(4,_auto)] md:grid-cols-[repeat(5,_auto)] gap-1 items-center justify-between
`;

export const CategoryContainer = tw.div`
  transition-all origin-top delay-1000 
  space-y-1 lg:space-y-0
  
  ${({ $opened }: { $opened: boolean }) =>
    $opened
      ? "animate-[expand_0.2s_ease-in-out_forwards] h-fit"
      : "animate-[collapse_0.2s_ease-in-out_forwards] overflow-auto "}
`;

export const Category = tw.div`relative`;

export const LoaderStyled = tw(Loader)`relative top-30`;

export const TableContent = tw.div``;

export const HeaderSearch = tw.div`grid grid-flow-col grid-cols-3 items-center gap-4 justify-start p-1 border-b border-gray-300`;

export const CloseStyled = tw(
  Close,
)`cursor-pointer text-blue-500 hover:text-blue-300`;
