import {
  InventoryTransactionFieldsFragment,
  InventoryTransactionType,
} from "@/generated/graphql";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useMemo } from "react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { TbPlusMinus } from "react-icons/tb";

export const useTransactionSymbol = (
  item: Pick<InventoryTransactionFieldsFragment, "type">,
) => {
  const Icon = useMemo(() => {
    switch (item.type) {
      case InventoryTransactionType.Adjustment:
        return TbPlusMinus;
      case InventoryTransactionType.Inflow:
        return ArrowBack;
      case InventoryTransactionType.Transfer:
        return FaArrowRightArrowLeft;
      case InventoryTransactionType.Outflow:
        return ArrowForward;
    }
  }, [item.type]);

  const className = useMemo(() => {
    switch (item.type) {
      case InventoryTransactionType.Inflow:
        return "text-green-600";
      case InventoryTransactionType.Outflow:
        return "text-red-500";
      default:
        return "";
    }
  }, [item.type]);

  return { Icon, className };
};
