import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "../../../hooks/useAddInventoryItems";

const Container = tw.div`grid`;

type Props = {
  item: InventoryItemFieldsFragment;
};

export const AddInventoryItemCheckbox: FC<Props> = ({ item }) => {
  const { selectedItems, setSelectedItems } = useAddInventoryItems();

  const onToggle = useCallback(
    (checked: boolean) => {
      setSelectedItems(
        checked
          ? [...selectedItems, item.id]
          : selectedItems.filter((i) => i !== item.id),
      );
    },
    [selectedItems, item, setSelectedItems],
  );

  const checked = useMemo(
    () => selectedItems.includes(item.id),
    [selectedItems, item],
  );

  return (
    <Container>
      <SelectionCheckbox
        testId="add-inventory-item-checkbox"
        checked={checked}
        setSelection={onToggle}
      />
    </Container>
  );
};
