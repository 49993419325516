import { If } from "@/common/components/if/If";
import {
  ReleaseWithType,
  useOrderTypesConfig,
} from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { ServiceType } from "@/generated/graphql";
import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { InfoHeaderItem } from "./InfoHeaderItem";
import { HeaderPill } from "./InfoHeaderItem.styles";

type FulfillmentProps = {
  $isDelivery?: boolean;
  $isStocking?: boolean;
  $isContractor?: boolean;
};

const ListContent = tw.div`
  grid grid-flow-row w-full text-2xs md:text-sm
`;

const FulfillmentContent = tw(HeaderPill)`
  ${({ $isDelivery, $isStocking, $isContractor }: FulfillmentProps) =>
    $isContractor
      ? $isDelivery || $isStocking
        ? "bg-yellow-600"
        : "bg-green-300"
      : $isDelivery || $isStocking
        ? "bg-green-300"
        : "bg-yellow-600"}
`;

const FulfillmentLabel = tw.span`
  mr-1 font-semibold 
`;

type Props = {
  includeServices: ServiceType[];
  title?: string | React.JSX.Element;
  isContractor?: boolean;
  hideHeader?: boolean;
  release: ReleaseWithType;
};

export const FulfillmentHeaderItem: FC<Props> = ({
  includeServices,
  title,
  isContractor,
  hideHeader = false,
  release,
}) => {
  const { orderTypeConfig } = useOrderTypesConfig({ release });

  const isDelivery = useMemo(() => {
    return includeServices.includes(ServiceType.Delivery) || false;
  }, [includeServices]);

  const isStocking = useMemo(() => {
    return includeServices.includes(ServiceType.Stocking) || false;
  }, [includeServices]);

  return (
    <If
      isTrue={
        orderTypeConfig.features.includeWillCall ||
        orderTypeConfig.features.includeVendorStocking
      }
    >
      <InfoHeaderItem
        testId="release-fulfillment"
        title={
          hideHeader
            ? undefined
            : title || <FormattedMessage id="FULFILLMENT" />
        }
      >
        <ListContent>
          <If isTrue={orderTypeConfig.features.includeWillCall}>
            <FulfillmentContent
              $isDelivery={isDelivery}
              $isContractor={isContractor}
            >
              <FulfillmentLabel>
                <FormattedMessage id="DELIVERY" />:
              </FulfillmentLabel>
              <FormattedMessage
                id={isDelivery ? "VENDOR_DELIVERY" : "CLIENT_DELIVERY"}
              />
            </FulfillmentContent>
          </If>
          <If isTrue={orderTypeConfig.features.includeWillCall && isDelivery}>
            <FulfillmentContent
              $isStocking={isStocking}
              $isContractor={isContractor}
            >
              <FulfillmentLabel>
                <FormattedMessage id="STOCKING" />:
              </FulfillmentLabel>
              <FormattedMessage
                id={isStocking ? "VENDOR_STOCKING" : "CLIENT_STOCKING"}
              />
            </FulfillmentContent>
          </If>
        </ListContent>
      </InfoHeaderItem>
    </If>
  );
};
