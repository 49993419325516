import { MultiWarehouseSelector } from "@/common/components/warehouse-selector/MultiWarehouseSelector";
import { useIntl } from "react-intl";
import { useInventoryItemsFilter } from "../../../../hooks/useInventoryItemsFilter";

export const CurrentStockWarehousesSelector = () => {
  const { filter, setFilter } = useInventoryItemsFilter();
  const intl = useIntl();

  return (
    <MultiWarehouseSelector
      values={filter?.warehouseIds || []}
      onChange={(items: string[] | null) =>
        setFilter({ ...filter, warehouseIds: items })
      }
      limitTags={1}
      className="w-64"
      placeholder={
        !(filter?.warehouseIds || []).length
          ? intl.$t({ id: "ALL_WAREHOUSES" })
          : ""
      }
    />
  );
};
