import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { TransferDatePicker } from "@/contractor/pages/home/inventory-management/pages/current-stock/components/transfer-date-picker/TransferDatePicker";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col mb-6 justify-start border
  gap-4 items-center w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
`;

const BackButton = tw(IconButton)`
  rounded-lg bg-blue-800 text-white text-2xl p-1
`;

export const InventoryAdjustmentHeader = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container>
      <BackButton onClick={handleBack}>
        <ChevronLeftOutlined />
      </BackButton>
      <FormattedMessage id="ADJUSTMENT" />
      <WarehouseSelectorControlled
        name="warehouseId"
        placeholder={intl.$t({ id: "WAREHOUSE" })}
        trackedWarehousesOnly
        required
      />
      <TransferDatePicker
        name="date"
        label={intl.$t({ id: "TRANSACTION_DATE" })}
      />
    </Container>
  );
};
