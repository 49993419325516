import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemSummaryFieldsFragment } from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";

export const updateScheduledInflows = (
  item: InventoryItemSummaryFieldsFragment,
  warehouses: WarehouseInventoryItem[],
) => {
  item.pendingInflows.forEach((inflow) => {
    const warehouse = warehouses.find(
      (w) => w.warehouse.id === inflow.release.sourceWarehouse?.id,
    );
    if (warehouse) {
      warehouse.scheduledInflowTotal = new DecimalSafe(
        warehouse.scheduledInflowTotal,
      )
        .plus(new DecimalSafe(inflow.quantityDecimal))
        .toNumber();
    }
  });
};
