import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useBuyoutFiltersStore } from "@/contractor/pages/home/buyout/components/document/providers/store/useBuyoutFiltersStore";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-cols-[auto_1fr_auto_auto_auto] gap-4 bg-green-300 p-2 rounded-lg mb-1 w-full h-14 sticky top-0 z-70
`;

const InputContainer = tw.div`
  grid w-full
`;

const SwitchContainer = tw.div`
  grid grid-flow-col w-fit place-items-center justify-items-end gap-1 
`;
const LeftSpace = tw.div`w-full`;
const Label = tw.div`mr-1 text-xs`;
const Title = tw.div`text-base font-medium self-start w-fit grid place-items-center h-full mx-2`;

export const BuyoutFilters: FC = () => {
  const intl = useIntl();
  const {
    excludeFullyOrdered,
    setExcludeFullyOrdered,
    selectedCostCodes,
    setSelectedCostCodes,
    selectedTags,
    setSelectedTags,
  } = useBuyoutFiltersStore();
  const { projectCostCodes } = useProjectCostCodes();
  const { buyout } = useContractorBuyout();
  const { allTags } = useProjectTags();
  const { hasPhaseCodes } = useOrgSettings();
  const costCodeOptions = useMemo(() => {
    return projectCostCodes.filter((costCode) =>
      buyout?.items.find((item) => item.costCode?.id === costCode.id),
    );
  }, [buyout?.items, projectCostCodes]);
  const tags = useMemo(() => {
    if (hasPhaseCodes) {
      return (
        buyout?.items
          .map((item) => item.tags.filter((tag) => tag.hasMapping))
          .flat() || []
      );
    }
    return allTags;
  }, [allTags, buyout?.items, hasPhaseCodes]);

  return (
    <Container>
      <FormattedMessage id="SELECT_FROM_BUYOUT" tagName={Title} />
      <LeftSpace />
      <SwitchContainer>
        <Label>
          <FormattedMessage id="EXCLUDE_FULLY_ORDERED_ITEMS" />
        </Label>
        <Switch
          value={excludeFullyOrdered}
          onChange={setExcludeFullyOrdered}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </SwitchContainer>
      <If isTrue={!hasPhaseCodes}>
        <InputContainer>
          <CostCodesSelector
            options={costCodeOptions}
            selectedCostCodes={selectedCostCodes}
            setSelectedCostCodes={setSelectedCostCodes}
            limitTags={1}
          />
        </InputContainer>
      </If>
      <InputContainer>
        <TagsSelector
          options={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          placeholder={intl.$t({ id: hasPhaseCodes ? "PHASE_CODE" : "TAG" })}
          creatable={false}
          limitTags={1}
        />
      </InputContainer>
    </Container>
  );
};
