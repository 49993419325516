import { useCallback, useMemo } from "react";
import { useInventoryItems } from "../../../../hooks/useInventoryItems";
import { useInventoryItemSequence } from "../hooks/useInventoryItemSequence";

export const useInventoryItemAllItemsCheckbox = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useInventoryItemSequence();
  const { groupedItems } = useInventoryItems();

  const nonArchivedItems = useMemo(
    () =>
      groupedItems
        .filter((item) => !item.archivedAt)
        .filter((item) => item.warehouses.every((w) => !w.inStock)),
    [groupedItems],
  );

  const isIncluded = useMemo(
    () => nonArchivedItems.length > 0,
    [nonArchivedItems],
  );

  const checked = useMemo(
    () =>
      nonArchivedItems.length > 0 &&
      nonArchivedItems.every((d) => selectedEntities.includes(d)),
    [nonArchivedItems, selectedEntities],
  );

  const handleChange = useCallback(() => {
    const filteredItems = nonArchivedItems;
    setSelectedEntities(
      checked
        ? selectedEntities.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedEntities.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [nonArchivedItems, setSelectedEntities, checked, selectedEntities]);

  return { handleChange, sequenceIds, checked, isIncluded };
};
