import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResultsWithOptions } from "@/common/components/no-results-with-options/NoResultsWithOptions";
import { EstimatedItemsProvider } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ProjectItemsZonesProvider } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import {
  EstimatedItemFieldsFragment,
  ProjectFieldsFragment,
  ProjectItemFieldsFragment,
  RfqFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { AddEstimatedItemListItems } from "../../../pages/add-item-to-rfq/rfq-items-list/AddEstimatedItemListItems";
import { RfqInfoWithActions } from "../rfq-info-actions/RfqInfoWithActions";

type Props = {
  project: ProjectFieldsFragment;
  loading?: boolean;
  columns: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  >;
};

const ProjectItemsWithProvider: FC<Props> = ({ project, columns, loading }) => {
  const condition = useMemo(
    () => project.items.length > 0,
    [project.items.length],
  );

  return (
    <ListRenderer
      hasItemsCondition={condition}
      emptyList={
        <NoResultsWithOptions options={["PROJECT_EMPTY_PROJECT_LIST"]} />
      }
    >
      <AddEstimatedItemListItems columns={columns} loading={loading} />
    </ListRenderer>
  );
};

export const ProjectItems: FC<
  Pick<Props, "columns"> & { rfq: RfqFieldsFragment; loading?: boolean }
> = ({ rfq, columns, loading }) => (
  <EstimatedItemsProvider>
    <ProjectItemsZonesProvider
      project={rfq.project}
      defaultGroupedByZones={false}
    >
      <RfqInfoWithActions rfq={rfq} />
      <ProjectItemsWithProvider
        columns={columns}
        project={rfq.project}
        loading={loading}
      />
    </ProjectItemsZonesProvider>
  </EstimatedItemsProvider>
);
