import { GridTable } from "@/common/components/grid-table/GridTable";
import { RenderType } from "@/common/components/grid-table/types/RenderType";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { TransactionHistoryModal } from "../../../../common/components/transaction-history/TransactionHistoryModal";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import { useInventoryItemsFilter } from "../../hooks/useInventoryItemsFilter";
import { useCurrentStockConfiguration } from "./CurrentStockList.configuration";

export const CurrentStockList = () => {
  const { groupedItems, loading, totalCount } = useInventoryItems();
  const { isFiltered } = useInventoryItemsFilter();
  const configuration = useCurrentStockConfiguration();

  return (
    <ListRenderer totalCount={totalCount} count={groupedItems.length} paginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-34",
            itemContent: "lg:py-0",
            itemFn: (_item, _category, _index, _editMode, renderType) => ({
              className:
                renderType === RenderType.Details
                  ? "lg:border-t-0 lg:first-child:pt-0"
                  : "!pt-0",
            }),
          },
        }}
        expandedItems={(item) =>
          item.warehouses.slice(1, item.warehouses.length + 1).map((i) => ({
            ...item,
            warehouses: item.warehouses.slice(1, item.warehouses.length + 1),
            id: i.warehouse.id,
          }))
        }
        loading={loading}
        items={groupedItems}
        skipDetailRowsCounterKeyAssignment
        emptyList={
          <NoResults
            filteredTranslationKey="NO_RESULTS_CURRENT_STOCK_FILTERED"
            translationKey="NO_RESULTS_CURRENT_STOCK"
            isFiltered={!!isFiltered}
          />
        }
      />
      <TransactionHistoryModal />
    </ListRenderer>
  );
};
