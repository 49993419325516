import { PaginationArgs } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  InvoiceType,
  InvoicesDocument,
  QueryInvoicesFilter,
  namedOperations,
  useArchiveInvoiceMutation,
} from "@/generated/graphql";

type PaginationProps = {
  previousPage: () => void;
  filter: QueryInvoicesFilter | undefined;
  paginationArgs: PaginationArgs;
  page: number;
};

export const useArchiveInvoice = (
  params?: PaginationProps,
  invoiceType = InvoiceType.Invoice,
) => {
  const { setError } = useGlobalError();

  const [archiveInvoiceMutation] = useArchiveInvoiceMutation();
  const archiveInvoice = async (id: string) => {
    try {
      const { errors } = await archiveInvoiceMutation({
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: params
          ? [
              {
                query: InvoicesDocument,
                variables: {
                  ...params.paginationArgs,
                  filter: {
                    ...params.filter,
                    type: InvoiceType.Invoice,
                  },
                },
              },
            ]
          : [],
        update: (cache) =>
          cleanQuery(
            cache,
            invoiceType
              ? namedOperations.Query.Invoices
              : namedOperations.Query.Receipts,
          ),
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { archiveInvoice };
};
