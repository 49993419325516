import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import { QueryInventoryTransactionsFilter } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryLedgerStore } from "../store/useInventoryLedgerStore";

export const useInventoryLedgerFilter = () => {
  const { filter, setFilter } = useInventoryLedgerStore();
  const { firstPage } = usePagination();
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );
  useEffect(() => {
    const localStorageSettings =
      readValueFromKeys<QueryInventoryTransactionsFilter>([
        [orgId || "", LOCAL_STORAGE_KEYS.INVENTORY_LEDGER_FILTER],
        LOCAL_STORAGE_KEYS.INVENTORY_LEDGER_FILTER,
      ]) as QueryInventoryTransactionsFilter;

    setFilter({
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId, setFilter]);

  const isFiltered = useMemo(() => !!filter, [filter]);
  const setFilterAndReset = useCallback(
    (newFilter: Partial<QueryInventoryTransactionsFilter>) => {
      firstPage();
      const toReset = Object.keys(newFilter).length === 0;
      const updatedFilter = toReset
        ? {}
        : {
            ...filter,
            ...newFilter,
          };
      setFiltersQueryParams(updatedFilter);
      setFilter(updatedFilter);
      removeValue(LOCAL_STORAGE_KEYS.INVENTORY_LEDGER_FILTER);
      setValue(
        [orgId || "", LOCAL_STORAGE_KEYS.INVENTORY_LEDGER_FILTER],
        updatedFilter,
      );
    },
    [filter, firstPage, orgId, setFilter, setFiltersQueryParams],
  );

  return {
    setFilter: setFilterAndReset,
    filter,
    isFiltered,
  };
};
