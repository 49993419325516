import {
  InventoryTransactionSummaryFieldsFragment,
  InventoryTransactionType,
} from "@/generated/graphql";
import { Remove, WarehouseOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { TbCrane } from "react-icons/tb";
import { useIntl } from "react-intl";
import { TransactionOrigin } from "../../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

export const useTransactionOriginTo = (
  item: InventoryTransactionSummaryFieldsFragment,
) => {
  const intl = useIntl();

  const to: TransactionOrigin | null | undefined = useMemo(() => {
    switch (item.type) {
      case InventoryTransactionType.Adjustment:
        return {
          icon: Remove,
          name: intl.$t({ id: "INVENTORY_TRANSACTION_TYPE_ADJUSTMENT" }),
        };

      case InventoryTransactionType.Transfer:
      case InventoryTransactionType.Inflow:
        return {
          typeName: intl.$t({ id: "WAREHOUSE" }),
          name: item.destination?.name,
          icon: WarehouseOutlined,
        };
      case InventoryTransactionType.Outflow:
        return {
          typeName: item.deliverySlip?.project?.jobNumber || "",
          name: item.deliverySlip?.project?.name,
          icon: TbCrane,
        };

      default:
        return null;
    }
  }, [
    item.type,
    item.destination?.name,
    item.deliverySlip?.project?.jobNumber,
    item.deliverySlip?.project?.name,
    intl,
  ]);

  return to;
};
