import { FormattedMessage } from "react-intl";
import {
  TransactionFooterCell,
  TransactionFooterCellHeader,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";

export const TransactionHistoryInflowsTotalLabel = () => {
  return (
    <TransactionFooterCol>
      <TransactionFooterCellHeader />
      <TransactionFooterCell />
      <TransactionFooterCell />
      <TransactionFooterCell />
      <TransactionFooterCell>
        <FormattedMessage id="TOTAL" />
      </TransactionFooterCell>
    </TransactionFooterCol>
  );
};
