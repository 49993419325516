import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { useEffect, useState } from "react";
import { useAddInventoryItems } from "./useAddInventoryItems";

type CostCodeCategory = CategoryState<InventoryItemFieldsFragment>;

export const useAddToInventoryList = () => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { projectCostCodes: allCostCodes } = useProjectCostCodes();
  const { selectedCostCodes, search, filteredInventoryItems } =
    useAddInventoryItems();
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);

  useEffect(() => {
    const costCodeMap = filteredInventoryItems.reduce((acc, item) => {
      const costCode =
        allCostCodes.find(
          (cost) => cost.id === item.orgMaterial.costCode?.id,
        ) ?? unassignedCostCode;
      const key = costCode?.id;

      if (!acc.has(key)) {
        acc.set(key, {
          id: costCode?.id,
          name: costCode?.description,
          isOpened: true,
          items: [],
        });
      }

      acc.get(key)?.items.push(item);
      return acc;
    }, new Map<string, CostCodeCategory>());

    setCostCodes(Array.from(costCodeMap.values()));
  }, [
    filteredInventoryItems,
    allCostCodes,
    unassignedCostCode,
    search,
    selectedCostCodes,
  ]);

  return { costCodes, setCostCodes };
};
