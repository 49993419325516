import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { getUserName } from "@/common/utils/users/getUserName";
import { generateUUID } from "@/common/utils/uuidUtils";
import { routes } from "@/config/routes";
import { AddPackingSlipBtn } from "@/contractor/pages/home/release/pages/specify-details/components/add-packing-slip-btn/AddPackingSlipBtn";
import {
  AssetContext,
  AssetType,
  ReleaseDeliverySlipFieldsFragment,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { DeliverySlipStatusChip } from "../statuses/DeliverySlipStatusChip";

const SlipWrapper = tw.div`grid grid-flow-col gap-2 place-items-center`;
const DetailsContainer = tw.div`grid grid-flow-row items-center h-fit`;
const SlipNumber = tw(LinkLike)`text-sm font-medium`;
const SlipDate = tw.div`text-xs font-medium`;
const CreatedByName = tw.div`text-xs font-light truncate max-w-44`;

type Props = {
  deliverySlips: ReleaseDeliverySlipFieldsFragment[];
};

export const ReleaseDeliverySlips: FC<Props> = ({ deliverySlips }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  const filteredSlips = useMemo(
    () =>
      deliverySlips
        ? deliverySlips.filter((deliverySlip) => !deliverySlip.archivedAt)
        : [],
    [deliverySlips],
  );

  const getImageWrapper = useCallback((id: string, children: ReactNode) => {
    return <SlipWrapper>{children}</SlipWrapper>;
  }, []);

  const onClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(routes.deliverySlipReceiveOrder, {
          deliverySlipId: id,
        }),
      );
      return true;
    },
    [navigate],
  );

  const getDeliverySlipDetails = useCallback(
    (id: string) => {
      const currentSlip = filteredSlips.find((slip) => slip.id === id);

      if (!currentSlip) {
        return null;
      }

      return (
        <DetailsContainer>
          <SlipNumber onClick={() => onClick(id)}>
            <FormattedMessage id="DELIVERY_SLIP" />
          </SlipNumber>
          <DeliverySlipStatusChip
            status={currentSlip.status}
            type="small"
            classes={{ root: "-ml-1" }}
          />
          <SlipDate>
            <FormattedDate value={currentSlip.createdAt} />
          </SlipDate>
          <CreatedByName title={getUserName(currentSlip.createdBy)}>
            <FormattedMessage
              id={currentSlip?.asset ? "SCANNED_BY" : "RECORDED_BY"}
            />{" "}
            {getUserName(currentSlip.createdBy)}
          </CreatedByName>
        </DetailsContainer>
      );
    },
    [filteredSlips, onClick],
  );

  if (!filteredSlips.length) {
    return null;
  }

  return (
    <AssetsCard
      title={intl.$t({ id: "DELIVERY_SLIPS_FOR_THIS_ORDER" })}
      assets={filteredSlips.map((slip) => ({
        id: generateUUID(),
        url: "",
        type: AssetType.Image,
        context: AssetContext.DeliverySlip,
        ...slip.asset,
        parentId: slip.id,
        createdAt: 0,
      }))}
      imageDetails={getDeliverySlipDetails}
      getImageWrapper={getImageWrapper}
    >
      <If isTrue={deliveryId}>
        <AddPackingSlipBtn />
      </If>
    </AssetsCard>
  );
};
