import { PaginationArgs } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { evictCacheById } from "@/common/utils/cacheUtils";
import {
  InvoiceType,
  QueryInvoicesFilter,
  ReceiptsDocument,
  namedOperations,
  useArchiveReceiptMutation,
} from "@/generated/graphql";

type PaginationProps = {
  previousPage: () => void;
  filter: QueryInvoicesFilter | undefined;
  paginationArgs: PaginationArgs;
  page: number;
};

export const useArchiveReceipt = (params?: PaginationProps) => {
  const { setError } = useGlobalError();

  const [archiveReceiptMutation] = useArchiveReceiptMutation();
  const archiveReceipt = async (id: string) => {
    try {
      const { errors } = await archiveReceiptMutation({
        variables: {
          id,
        },
        refetchQueries: params
          ? [
              {
                query: ReceiptsDocument,
                variables: {
                  ...params.paginationArgs,
                  filter: {
                    ...params.filter,
                    type: InvoiceType.Receipt,
                  },
                },
              },
            ]
          : [],
        update: (cache, { data: archiveData }) =>
          evictCacheById(
            cache,
            namedOperations.Query.Receipts,
            archiveData?.archiveReceipt.id,
          ),
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { archiveReceipt };
};
