import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useIntl } from "react-intl";
import { useOrgDefaultFulfillmentLocation } from "../../hooks/useOrgDefaultFulfillmentLocation";

export const OrgFulfillmentLocation = () => {
  const intl = useIntl();
  const { selectedFulfillmentLocation, setDefaultFulfillmentLocationId } =
    useOrgDefaultFulfillmentLocation();

  return (
    <WarehouseSelector
      value={selectedFulfillmentLocation?.id}
      onChange={setDefaultFulfillmentLocationId}
      disableClearable={false}
      placeholder={intl.$t({
        id: selectedFulfillmentLocation
          ? "DEFAULT_FULFILLMENT_LOCATION"
          : "PROJECT_LOCATION_WITH_ADDRESS",
      })}
      required={false}
    />
  );
};
