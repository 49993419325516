import { RowToggle } from "@/common/components/group-toggle/RowToggle";
import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { MaterialView } from "@/common/components/material/MaterialView";
import { useUser } from "@/common/providers/UserProvider";
import {
  AssetFieldsFragment,
  EstimatedItemFieldsFragment,
  ManufacturerFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../../contractor/pages/home/project/providers/EstimatedItemsProvider";
import { useProjectItemsZones } from "../../../contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { CategoryLike } from "../../hooks/useToggleCategory";
import { CountType } from "../circle-counter/CountType";

const Container = tw.div`
  w-full
`;

type NotesAndAttachments = {
  notes?: string | null;
  assets?: AssetFieldsFragment[] | null;
};

type Props = {
  item: Pick<ProjectItemFieldsFragment, "id"> & {
    material: Pick<
      ProjectItemFieldsFragment["material"],
      "material" | "costCode"
    >;
  };
  estimatedItem?: EstimatedItemFieldsFragment | null;
  includeCounter?: boolean;
  className?: string;
  counterInnerClassName?: string;
  zone?: CategoryLike;
  expandable?: boolean;
  manufacturer?: ManufacturerFieldsFragment | null;
  updateDescription?: (description: string) => void;
  readonly?: boolean;
  count?: CountType;
  hasAlternatives?: boolean;
  customDescription?: string;
  showOutsideOfEstimatesInfo?: boolean;
  displayBrand?: boolean;
  contractorNotes?: NotesAndAttachments;
  vendorNotes?: NotesAndAttachments;
  displayRowToggle?: boolean;
  extraDetails?: React.ReactNode;
  bottomExtraDetails?: React.ReactNode;
  extraDetailsPosition?: "right" | "bottom";
  isLocked?: boolean;
  includeMaterialInfo?: boolean;
};

const GroupToggleContainer = tw.div``;

export const ProjectItemMaterialView: FC<Props> = ({
  item,
  includeCounter = true,
  className,
  counterInnerClassName,
  zone,
  expandable: shouldExpand = true,
  manufacturer,
  updateDescription,
  readonly = true,
  contractorNotes,
  vendorNotes,
  count,
  hasAlternatives,
  customDescription,
  showOutsideOfEstimatesInfo,
  displayBrand,
  displayRowToggle = true,
  extraDetails,
  extraDetailsPosition,
  bottomExtraDetails,
  isLocked,
  includeMaterialInfo,
}) => {
  const { groupedByZones } = useProjectItemsZones();
  const { expandedItems, setExpandedItem } = useEstimatedItems();
  const { isContractor } = useUser();
  const intl = useIntl();

  const expandable = useMemo(
    () =>
      (
        (shouldExpand && (item as ProjectItemFieldsFragment).estimatedItems) ||
        []
      ).length > 1 || hasAlternatives,
    [hasAlternatives, item, shouldExpand],
  );

  const customCounter = useMemo(() => {
    if (!expandable || !includeCounter) {
      return;
    }

    return (
      <GroupToggleContainer
        onClick={() =>
          setExpandedItem(item.id, groupedByZones ? zone?.id : undefined)
        }
      >
        <If isTrue={displayRowToggle}>
          <RowToggle
            isOpened={expandedItems.some(
              (i) => i.id === item.id && (!zone || zone.id === i.zoneId),
            )}
            count={
              (item as ProjectItemFieldsFragment).estimatedItems?.length || 0
            }
          />
        </If>
      </GroupToggleContainer>
    );
  }, [
    displayRowToggle,
    expandable,
    expandedItems,
    groupedByZones,
    includeCounter,
    item,
    setExpandedItem,
    zone,
  ]);
  const contractorNotesLabel = useMemo(
    () =>
      intl.$t({
        id: isContractor ? "YOUR_NOTE_LABEL" : "CONTRACTOR_NOTE_LABEL",
      }),
    [intl, isContractor],
  );
  const vendorNotesLabel = useMemo(
    () =>
      intl.$t({
        id: isContractor ? "VENDOR_NOTE_LABEL" : "YOUR_NOTE_LABEL",
      }),
    [intl, isContractor],
  );

  return (
    <Container>
      <MaterialView
        material={item.material.material}
        description={customDescription || item.material.material.name || ""}
        customCounter={customCounter}
        className={className}
        readonly={readonly}
        includeCounter={includeCounter}
        manufacturer={manufacturer}
        updateDescription={updateDescription}
        count={count}
        showOutsideOfEstimatesInfo={showOutsideOfEstimatesInfo}
        displayBrand={displayBrand}
        extraDetails={extraDetails}
        extraDetailsPosition={extraDetailsPosition}
        bottomExtraDetails={bottomExtraDetails}
        isLocked={isLocked}
        counterInnerClassName={counterInnerClassName}
        includeMaterialInfo={includeMaterialInfo}
      />
      <If isTrue={vendorNotes?.notes || vendorNotes?.assets}>
        <LineItemNameNotes
          label={vendorNotesLabel}
          notes={vendorNotes?.notes}
          assets={vendorNotes?.assets}
        />
      </If>
      <If isTrue={contractorNotes?.notes || contractorNotes?.assets}>
        <LineItemNameNotes
          label={contractorNotesLabel}
          notes={contractorNotes?.notes}
          assets={contractorNotes?.assets}
        />
      </If>
    </Container>
  );
};
