import tw from "tailwind-styled-components";

export const Container = tw.div<{ $hideInMobile?: boolean }>`
  ${({ $hideInMobile }) => ($hideInMobile ? "hidden" : "flex")} 
  md:flex 
  flex-col w-full gap-1 border-dotted md:border-none
  border-b-2 border-gray-300 pb-3 pt-3
`;
export const Content = tw.div`
  grid grid-flow-col gap-2 text-sm font-normal w-full
`;

export const InfoHeaderItemTitle = tw.div`text-xs font-light`;

export const Title = tw(
  InfoHeaderItemTitle,
)`pb-0.5 items-center grid-flow-col gap-1 min-h-[18px] justify-start hidden md:grid`;

export const HeaderPill = tw.div`
  grid grid-flow-col justify-center px-5 py-1.5 rounded-md text-center mb-2 w-full`;
