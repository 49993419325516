import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMemo } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useInvoiceValidationConfiguration = () => {
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { invoice } = useInvoiceVerification();

  const enableValidation = useMemo(
    () =>
      (hasFeatureInConnectedSourceSystem(
        IntegrationFeature.InvoiceFileExports,
      ) ||
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceWrites)) &&
      (!invoice?.link || !!invoice.link.failedAt),
    [hasFeatureInConnectedSourceSystem, invoice?.link],
  );

  return {
    enableValidation,
  };
};
