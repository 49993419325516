import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";

export enum RfqsBuyoutItemVariant {
  light,
  dark,
}

type RfqsBuyoutItemProps = {
  id: string;
  clientIdentifier: string;
  poNumber?: string | null;
  variant?: RfqsBuyoutItemVariant;
  withBorder?: boolean;
  className?: string;
};

const BuyoutInfo = tw.div`flex flex-col`;
const PoNumber = tw.span`text-gray-600 text-xs`;

export const RfqsBuyoutItem = ({
  id,
  clientIdentifier,
  poNumber,
  variant = RfqsBuyoutItemVariant.light,
  withBorder = false,
  className = "",
}: RfqsBuyoutItemProps) => {
  const navigate = useNavigate();

  const linkPath = useMemo(
    () =>
      generatePath(routes.buyout, {
        id,
      }),
    [id],
  );

  return (
    <BuyoutInfo
      className={`px-4 ${withBorder ? "border-r border-dashed border-white" : ""} ${className}`}
    >
      <LinkLike
        onClick={() => navigate(linkPath)}
        to={linkPath}
        className={`whitespace-nowrap ${
          variant === RfqsBuyoutItemVariant.light
            ? ""
            : "text-white hover:text-blue-250"
        }`}
      >
        <FormattedMessage id="BUYOUT" values={{ number: clientIdentifier }} />
      </LinkLike>
      <PoNumber
        className={
          variant === RfqsBuyoutItemVariant.light ? "" : "text-blue-250"
        }
      >
        {poNumber}
      </PoNumber>
    </BuyoutInfo>
  );
};
