import Handsontable from "handsontable";
import { CUSTOM_SOURCE } from "../constants/tableConstants";
import { toggleTableReadOnly } from "./toggleTableReadOnly";

export const setTableCells = (
  data: [number, number, string | number | null][],
  hotInstance: Handsontable | undefined | null,
  source: string = CUSTOM_SOURCE,
): void => {
  const filteredData = data.filter((d) => d[0] !== -1 && d[1] !== -1);
  if (filteredData.length > 0) {
    toggleTableReadOnly(hotInstance, true);
    hotInstance?.setDataAtCell(filteredData, source);
    toggleTableReadOnly(hotInstance, false);
  }
};
