import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextField } from "@/common/components/textfield/TextField";
import { Check, Close, EditOutlined } from "@mui/icons-material";
import React, { FC, useEffect, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { Icons, NonEditableView, Title } from "./InvoiceHeaderDetails.styles";

const Container = tw.div`h-10 flex-1 flex items-center ${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const TextContainer = tw.div`w-fit overflow-hidden text-ellipsis text-wrap break-words`;
const Row = tw.div`flex flex-1`;

type Props = {
  title: string;
  text: string;
  isEditing: boolean;
  toggleEditing: () => void;
  onSave: (t: string) => void;
  readonly?: boolean;
  error?: boolean;
  info?: React.ReactNode;
  truncateValue?: number;
  testIds?: { edit?: string; input?: string; check?: string };
};

export const InvoiceHeaderTextEdit: FC<Props> = ({
  title,
  text,
  isEditing,
  toggleEditing,
  onSave,
  readonly = false,
  error = false,
  info,
  truncateValue,
  testIds,
}) => {
  const [value, setValue] = useState(text);

  const renderedValue = useMemo(() => {
    if (truncateValue) {
      return `${value.substring(0, truncateValue)}${value.length > truncateValue ? "..." : ""}`;
    }

    return value;
  }, [truncateValue, value]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <Container $error={error}>
      <Title>{title}:</Title>
      <If isTrue={!isEditing}>
        <NonEditableView>
          <NotNullableRenderer
            value={<TextContainer>{renderedValue}</TextContainer>}
          />
          {info}
          <If isTrue={!readonly}>
            <LinkLike>
              <EditOutlined
                data-testid={testIds?.edit}
                onClick={() => {
                  setValue(text);
                  toggleEditing();
                }}
                fontSize="small"
              />
            </LinkLike>
          </If>
        </NonEditableView>
      </If>
      <If isTrue={isEditing}>
        <Row>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClickEnter={() => onSave(value)}
            InputProps={{ className: "max-h-8 flex-1 mx-1 bg-white" }}
            testId={testIds?.input}
          />
          <Icons>
            <Check
              className="cursor-pointer text-blue-500"
              onClick={() => onSave(value)}
              data-testid={testIds?.check}
            />
            <Close
              className="cursor-pointer text-blue-500"
              onClick={() => {
                setValue(text);
                toggleEditing();
              }}
            />
          </Icons>
        </Row>
      </If>
    </Container>
  );
};
