import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgPreferredVendorsQuery } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntegrationFeatureRequirement } from "../../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";

export const useVendors = (
  { skipQuery }: { skipQuery?: boolean } = { skipQuery: false },
) => {
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const { data, loading, error, refetch } = useOrgPreferredVendorsQuery({
    variables: {
      first: 10000,
    },
    skip: !!skipQuery,
  });

  const vendors = useMemo(
    () =>
      data?.orgPreferredVendorsConnection.edges
        .map((e) => e.node)
        .toSorted((vendorA, vendorB) =>
          vendorA.sellerOrgLocation.org.name.localeCompare(
            vendorB.sellerOrgLocation.org.name,
          ),
        ) ?? [],
    [data],
  );

  const findOrderTypeByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.defaultReleaseType?.id;
    },
    [vendors],
  );

  const findCostCodeIdByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.costCode?.id;
    },
    [vendors],
  );

  const findDefaultPaymentTermByLocationId = useCallback(
    (locationId: string | undefined) => {
      if (!locationId) {
        return undefined;
      }
      return (
        vendors.find((vendor) => vendor.sellerOrgLocation.id === locationId)
          ?.defaultPaymentTerm ?? undefined
      );
    },
    [vendors],
  );

  const shouldShowVendorCode = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.HumanReadableVendorCode,
    );
  }, [hasFeatureInConnectedSourceSystem]);

  const getVendorCode = useCallback(
    (vendor: { externalCode?: string | null }) => {
      return shouldShowVendorCode ? vendor.externalCode : null;
    },
    [shouldShowVendorCode],
  );

  useErrorEffect(error);

  return {
    vendors,
    loading,
    refetch,
    error: !!error,
    findOrderTypeByLocationId,
    findDefaultPaymentTermByLocationId,
    findCostCodeIdByLocationId,
    totalCount: data?.orgPreferredVendorsConnection.totalCount ?? 0,
    shouldShowVendorCode,
    getVendorCode,
  };
};
