import { ORDER_INVOICED_INVOICES_STATUS } from "@/common/const";
import { FC, PropsWithChildren } from "react";
import { Outlet, useParams } from "react-router";
import { ContractorBuyoutProvider } from "../buyout/providers/ContractorBuyoutProvider";
import { EstimatedItemsProvider } from "../project/providers/EstimatedItemsProvider";
import { ReleaseSequenceProvider } from "../releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { DeliverySlipVerificationProvider } from "../releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import { ReleaseActionsProvider } from "./providers/ReleaseActionsProvider";
import { ReleaseProvider, useRelease } from "./providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "./providers/ReleaseUpdateProvider";

const ReleaseWithProvider: FC<PropsWithChildren> = ({ children }) => {
  const { release } = useRelease();

  if (!release) {
    return <Outlet />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      items={release.items}
      project={release.project}
    >
      {children}
    </ReleaseUpdateProvider>
  );
};

export const Release: FC = () => {
  const { deliveryId } = useParams();
  // We need specify key for ReleaseProvider because when navigation occurs from one release to another,
  // we need to re-render ReleaseProvider to fetch latest release and don't keep the old one
  return (
    <ContractorBuyoutProvider>
      <EstimatedItemsProvider>
        <ReleaseProvider
          invoiceStatuses={ORDER_INVOICED_INVOICES_STATUS}
          key={deliveryId}
        >
          <ReleaseActionsProvider>
            <ReleaseWithProvider>
              <ReleaseSequenceProvider>
                <DeliverySlipVerificationProvider>
                  <Outlet />
                </DeliverySlipVerificationProvider>
              </ReleaseSequenceProvider>
            </ReleaseWithProvider>
          </ReleaseActionsProvider>
        </ReleaseProvider>
      </EstimatedItemsProvider>
    </ContractorBuyoutProvider>
  );
};
