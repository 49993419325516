import { FC } from "react";
import tw from "tailwind-styled-components";
import { Tooltip } from "../tooltip/Tooltip";
import { useImageInitials } from "./hooks/useImageInitials";
import { useImageInitialsSizing } from "./hooks/useImageInitialsSizing";
import useInitials from "./hooks/useInitials";

export type ImageInitialsProps = {
  name: string;
  width: number;
  height?: number;
  className?: string;
  includeTooltip?: boolean;
};

const Container = tw.div`rounded-sm flex items-center justify-center shadow-inner`;
const InitialsText = tw.span`font-semibold drop-shadow-sm tracking-wide select-none`;

export const ImageInitials: FC<ImageInitialsProps> = ({
  name,
  height,
  width,
  className,
  includeTooltip,
}) => {
  const initials = useInitials(name);
  const { backgroundColor, color, borderColor } = useImageInitials(name);
  const dimensions = useImageInitialsSizing({ width, height });

  return (
    <Container
      style={{
        backgroundColor,
        color,
        borderColor,
        ...dimensions,
      }}
      className={`${className} ${!initials ? "opacity-0" : ""}`}
    >
      {includeTooltip ? (
        <Tooltip element={<InitialsText>{initials}</InitialsText>}>
          {name}
        </Tooltip>
      ) : (
        <InitialsText>{initials}</InitialsText>
      )}
    </Container>
  );
};
