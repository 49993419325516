import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ExclamationCircleRed } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import {
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
  TransactionKind,
} from "@/generated/graphql";
import { format, isSameDay } from "date-fns";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const DATE_FORMAT = "MMM dd, yyyy";

const Container = tw.div`pl-6 truncate py-2`;
const DifferentDate = tw.div`text-orange-500 relative flex items-center font-semibold`;
const Exclamation = tw(
  ExclamationCircleRed,
)`absolute bg-orange-500 w-4 h-4 -left-5 -top-[3px]`;
const DateValue = tw.div<{ $xs: boolean }>`
  ${({ $xs }) => ($xs ? "text-xs" : "text-2xs")}
font-normal`;
const OnHoldContainer = tw.div`text-center text-xs`;

type Props = {
  item:
    | Pick<ReleaseItemFieldsFragment, "deliveryDate" | "deliveryDateTBD">
    | undefined;
  release:
    | (Pick<ReleaseFieldsFragment, "time" | "timeTBD"> & {
        type: {
          transactionKind: TransactionKind;
        };
      })
    | null
    | undefined;
  xs?: boolean;
};

export const ReleaseItemDeliveryDate: FC<Props> = ({
  item,
  release,
  xs = false,
}) => {
  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const date = useMemo<Date | null>(() => {
    const timestamp =
      item?.deliveryDate ?? (!item?.deliveryDateTBD ? release?.time : null);
    return timestamp ? new Date(timestamp) : null;
  }, [item, release]);

  const isDeliveryDateDifferentThanReleaseTime = useMemo(
    () =>
      item?.deliveryDate && release?.time
        ? !isSameDay(new Date(item?.deliveryDate), new Date(release.time))
        : !!item?.deliveryDate && (release?.timeTBD || release?.time),
    [item?.deliveryDate, release?.time, release?.timeTBD],
  );

  if (!release) {
    return null;
  }

  if (!date) {
    return (
      <OnHoldContainer>
        <FormattedMessage id="TBD" />
      </OnHoldContainer>
    );
  }

  return (
    <Container>
      <DateValue $xs={xs}>
        {hasEndDate ? (
          <NotNullableRenderer value={item?.deliveryDate}>
            {item?.deliveryDate &&
              format(new Date(item?.deliveryDate), DATE_FORMAT)}
          </NotNullableRenderer>
        ) : item?.deliveryDate && isDeliveryDateDifferentThanReleaseTime ? (
          <DifferentDate>
            <Exclamation />
            {format(new Date(item?.deliveryDate), DATE_FORMAT)}
          </DifferentDate>
        ) : (
          format(date, DATE_FORMAT)
        )}
      </DateValue>
    </Container>
  );
};
