import { useDialog } from "@/common/components/dialog/DialogProvider";
import { isOrgCatalogSku } from "@/common/components/material/utils";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useEstimatedPrices } from "@/common/providers/EstimatedPricesProvider";
import { composeMaterialName } from "@/common/utils/composeMaterialName";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { SeededRandom } from "@/common/utils/seedRadom";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ManufacturerFieldsFragment,
  OrgCatalogSku,
  OrgMaterialFieldsFragment,
  TagExtendedFieldsFragment,
  TagFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useGetOrCreateLumpSumMaterial } from "../../import-external-po/hooks/useGetOrCreateLumpSumMaterial";
import { TAG_COLORS } from "../../tag-picker/TagColors";
import { vendorLabelFormatter } from "../../vendor-picker/VendorPickerCustomRender";
import { useVendors } from "../../vendors/hooks/useVendors";
import { getCellValue } from "../utils/getCellValue";
import { getMaterialNameParts } from "../utils/getMaterialNameParts";
import { rowIsChecked } from "../utils/rowIsChecked";
import { sanitizeMaterialName } from "../utils/sanitizeMaterialName";
import { sanitizeValue } from "../utils/sanitizeValue";

export const useTableHelpers = () => {
  const { openDialog } = useDialog();
  const { addZones, zones } = useProjectZones();
  const { buyout } = useContractorBuyout();
  const { release } = useRelease();
  const { tags, updateTags } = useProjectTags();
  const { manufacturers, createManufacturers } = useManufacturers();
  const { materials, updateMaterials } = useMaterials();
  const { vendors, getVendorCode } = useVendors();
  const { globalVendorId, prices } = useVendorPrices();
  const { spreadsheetData, extraColumns, config } = useColumnMapper();
  const { calcExtPrice } = usePriceCalculation();
  const { projectCostCodes } = useProjectCostCodes();
  const { costTypes, formatCostType } = useCostTypes();
  const { getUomByName } = useUomOptions();
  const { estimatedPrices } = useEstimatedPrices();
  const intl = useIntl();
  const { phaseCodeOptions, estimatedItems } = useProjectStore(
    useShallow((state) => ({
      phaseCodeOptions: state.phaseCodes,
      estimatedItems: state.estimatedItems,
    })),
  );
  const { getLumpSumOrgSKU } = useGetOrCreateLumpSumMaterial();

  const getCostCodeId = useCallback(
    (row: Record<string, string>) => {
      const rowValue = getCellValue(row, COLUMN_TYPE.CostCode);
      return projectCostCodes.find((code) => rowValue === code.formatted)?.id;
    },
    [projectCostCodes],
  );

  const getRowTagIds = useCallback(
    (
      row: Record<string, string>,
      newTags: TagExtendedFieldsFragment[] = [],
    ) => {
      const phaseCodeValue = getCellValue(row, COLUMN_TYPE.PhaseCode);
      const phaseCode = phaseCodeOptions.find(
        (phaseCode) => phaseCodeValue === phaseCode.name.trimStart().trimEnd(),
      );
      const rowValue = getCellValue(row, COLUMN_TYPE.Tag);
      const tag = [...newTags, ...tags].find(
        (tag) => rowValue === tag.name && !tag.hasMapping,
      );

      return [...(phaseCode ? [phaseCode.id] : []), ...(tag ? [tag.id] : [])];
    },
    [phaseCodeOptions, tags],
  );

  const getCostTypeId = useCallback(
    (row: Record<string, string>) => {
      const rowValue = getCellValue(row, COLUMN_TYPE.CostType);
      return costTypes.find((type) => rowValue === formatCostType(type))?.id;
    },
    [costTypes, formatCostType],
  );

  const findMaterialByName = useCallback(
    (name: string, customMaterials?: OrgMaterialFieldsFragment[]) => {
      if (!name) {
        return undefined;
      }

      const { namePart, partNumber } = getMaterialNameParts(
        String(sanitizeValue(name)),
      );

      const hasDuplicates =
        (customMaterials ?? materials).filter((mat) => {
          return mat.material.name === namePart;
        }).length > 1;
      return (customMaterials ?? materials).find((m) => {
        const sameName = m.material.name === namePart;
        const samePartNumber =
          (m.material as OrgCatalogSku).customPartNumber ===
          (partNumber ?? null);
        return hasDuplicates ? sameName && samePartNumber : sameName;
      }) as OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      };
    },
    [materials],
  );

  const getPrefilledValue = useCallback(
    (input: {
      material: string;
      vendor?: string;
      manufacturer?: string;
      uom: string;
    }) => {
      const material = findMaterialByName(input.material, materials);

      if (!material) {
        return {
          value: "",
          isVendorPrice: false,
          isEstimatedPrice: false,
        };
      }

      const vendor = vendors.find(
        (v) =>
          vendorLabelFormatter(v.sellerOrgLocation, [], {
            vendorCode: getVendorCode(v),
          }) === input.vendor || v.sellerOrgLocation.id === globalVendorId,
      );

      const manufacturer = manufacturers.find(
        (m) => m.name === input.manufacturer,
      );

      const uom = getUomByName(input.uom);

      const vendorPrice = prices.find((p) => {
        return (
          p.orgMaterialId === material.id &&
          p.uom?.id === uom?.id &&
          (!vendor ||
            p.sellerOrgLocation?.id === vendor?.sellerOrgLocation.id) &&
          (!manufacturer ||
            !p.manufacturer ||
            p.manufacturer?.id === manufacturer?.id)
        );
      });

      const estimatedPrice = !vendorPrice
        ? estimatedPrices
            .find((p) => p.material.id === material.id)
            ?.prices.find(
              (price) =>
                price.uomId === uom?.id &&
                (!manufacturer ||
                  !price.manufacturerId ||
                  price.manufacturerId === manufacturer?.id),
            )
        : undefined;

      return {
        value: vendorPrice?.price || estimatedPrice?.unitPrice || "",
        isVendorPrice: !!vendorPrice,
        isEstimatedPrice: !!estimatedPrice,
      };
    },
    [
      findMaterialByName,
      materials,
      vendors,
      manufacturers,
      getUomByName,
      prices,
      estimatedPrices,
      getVendorCode,
      globalVendorId,
    ],
  );

  const getBuyoutItem = useCallback(
    (buyoutItem: { id: string; material: string; uom: string }) => {
      if (!buyout || !buyoutItem) {
        return null;
      }
      const bItem = buyout.items.find((item) => item.id === buyoutItem.id);
      if (!bItem) {
        return null;
      }
      const material = findMaterialByName(buyoutItem.material, materials);
      const uom = getUomByName(buyoutItem.uom);

      return bItem.description === material?.material.name &&
        bItem.projectItem.estimateUom.id === uom?.id
        ? bItem
        : null;
    },
    [buyout, findMaterialByName, getUomByName, materials],
  );

  const getEstimatedItem = useCallback(
    (estimatedItem: {
      id: string;
      material: string;
      uom: string;
      vendor: string;
    }) => {
      if (!estimatedItems || !estimatedItem) {
        return null;
      }
      const estItem = estimatedItems.find(
        (item) => item.id === estimatedItem.id,
      );
      if (!estItem) {
        return null;
      }
      const material = findMaterialByName(estimatedItem.material, materials);
      const uom = getUomByName(estimatedItem.uom);

      const sellerOrgLocation =
        vendors.find(
          (v) =>
            vendorLabelFormatter(v.sellerOrgLocation, [], {
              vendorCode: getVendorCode(v),
            }) === estimatedItem.vendor,
        )?.sellerOrgLocation ||
        release?.sellerOrgLocation ||
        buyout?.sellerOrgLocation;

      return estItem.material.id === material?.id &&
        estItem.uom.id === uom?.id &&
        (!estItem.sellerOrgLocation ||
          estItem.sellerOrgLocation?.id === sellerOrgLocation?.id)
        ? estItem
        : null;
    },
    [
      estimatedItems,
      findMaterialByName,
      getUomByName,
      materials,
      vendors,
      getVendorCode,
      release,
      buyout,
    ],
  );

  const getCellWithAdditionalData = useCallback(
    (row: Record<string, string>, type: COLUMN_TYPE) => {
      const additionData: string[] = [];
      extraColumns.forEach((addCol) => {
        if (addCol.additional === type && row[addCol.columnType]) {
          additionData.push(`${addCol.header} = ${row[addCol.columnType]}`);
        }
      });
      if (additionData.length > 0) {
        const value = getCellValue(row, type).replace(/,.*(=.*)/, "");
        const valueWithAdditionalData = `${value}, ${additionData.join(", ")}`;
        return valueWithAdditionalData.replace(/\s+/g, " ").trim();
      } else {
        return getCellValue(row, type);
      }
    },
    [extraColumns],
  );

  const confirmRemove = useCallback(
    (
      addedItems: Array<unknown>,
      itemsToRemove: Array<unknown>,
      labels?: { title: string; text: string },
      threshold = 10,
    ) => {
      let resolveFn: (value: boolean | undefined) => void;
      const modalResult: Promise<boolean | undefined> = new Promise(
        (resolve) => {
          resolveFn = resolve;
        },
      );

      if (itemsToRemove.length - addedItems.length > threshold) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          includeWarningIcon: true,
          title: intl.$t({ id: labels?.text ?? "WARNING_REMOVING_MATERIALS" }),
          text: intl.$t(
            { id: labels?.text ?? "WARNING_REMOVING_MATERIALS" },
            {
              number: itemsToRemove.length - addedItems.length,
            },
          ),
          handleConfirm: () => {
            resolveFn(true);
          },
          handleCancel() {
            resolveFn(undefined);
          },
        });
        return modalResult;
      } else {
        return Promise.resolve(true);
      }
    },
    [intl, openDialog],
  );

  const getFormattedMaterialName = useCallback(
    (material: OrgMaterialFieldsFragment | string) => {
      if (!material) {
        return "";
      }
      if (typeof material === "string") {
        return sanitizeMaterialName(material);
      } else if (isOrgCatalogSku(material?.material)) {
        return composeMaterialName(material, materials);
      } else {
        return material.material.name;
      }
    },
    [materials],
  );

  const addMissingMaterials = useCallback(
    async (
      newManufacturers: ManufacturerFieldsFragment[] = [],
      defaultUom?: string,
    ) => {
      const materialNamesSet = new Set(
        materials.map((material) => getFormattedMaterialName(material)),
      );
      const missingMaterialsNames: Record<string, string>[] =
        spreadsheetData.filter((row) => {
          let rowMaterialName = getCellWithAdditionalData(
            row,
            COLUMN_TYPE.Material,
          );
          const rowIsLumpSum = isLumpSumUomText(
            getCellValue(row, COLUMN_TYPE.UOM),
          );
          if (rowIsLumpSum) {
            rowMaterialName = LUMP_SUM_UOM_PLURAL_DESCRIPTION;
          }
          return rowMaterialName && !materialNamesSet.has(rowMaterialName);
        });

      const uniqueMissingMaterialsNames = missingMaterialsNames.filter(
        (row, index) => {
          const mat = getCellWithAdditionalData(row, COLUMN_TYPE.Material);
          return (
            missingMaterialsNames.findIndex(
              (r) => getCellWithAdditionalData(r, COLUMN_TYPE.Material) === mat,
            ) === index
          );
        },
      );
      if (uniqueMissingMaterialsNames.length > 0) {
        const result = await updateMaterials({
          addedMaterials: uniqueMissingMaterialsNames.map((row) => {
            const rowIsLumpSum = isLumpSumUomText(
              getCellValue(row, COLUMN_TYPE.UOM),
            );
            if (rowIsLumpSum) {
              return {
                newOrgCatalogSKU: getLumpSumOrgSKU(),
              };
            }
            return {
              newOrgCatalogSKU: {
                defaultUom:
                  getCellValue(row, COLUMN_TYPE.UOM) || defaultUom || "",
                name: getMaterialNameParts(
                  getCellWithAdditionalData(row, COLUMN_TYPE.Material),
                ).namePart,
                defaultManufacturerId: [
                  ...manufacturers,
                  ...newManufacturers,
                ].find(
                  (m) => m.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
                )?.id,
                customPartNumber: getMaterialNameParts(
                  getCellWithAdditionalData(row, COLUMN_TYPE.Material),
                ).partNumber,
              },
              ...(getCellValue(row, COLUMN_TYPE.CostCode) && {
                costCodeId: getCostCodeId(row),
              }),
            };
          }),
        });
        return result as OrgMaterialFieldsFragment[];
      } else {
        return Promise.resolve(undefined);
      }
    },
    [
      materials,
      spreadsheetData,
      getFormattedMaterialName,
      getCellWithAdditionalData,
      updateMaterials,
      manufacturers,
      getLumpSumOrgSKU,
      getCostCodeId,
    ],
  );

  const addMissingManufacturers = useCallback(() => {
    const missingManufacturerNames = spreadsheetData.reduce<string[]>(
      (acc, row) => {
        const rowManufacturerName = getCellValue(row, COLUMN_TYPE.Manufacturer);
        return acc.concat(
          rowManufacturerName &&
            !manufacturers.some((m) => m.name === rowManufacturerName)
            ? [rowManufacturerName]
            : [],
        );
      },
      [],
    );

    if (missingManufacturerNames.length > 0) {
      return createManufacturers(missingManufacturerNames);
    } else {
      return Promise.resolve(undefined);
    }
  }, [createManufacturers, manufacturers, spreadsheetData]);

  const addMissingZones = useCallback(
    (projectId: string | null) => {
      if (!projectId) {
        return Promise.resolve(undefined);
      }
      const missingZoneNames = spreadsheetData.reduce<string[]>((acc, row) => {
        const rowZoneName = getCellValue(row, COLUMN_TYPE.Zone);
        return acc.concat(
          rowZoneName && !zones?.some((m) => m.name === rowZoneName)
            ? [rowZoneName]
            : [],
        );
      }, []);

      if (missingZoneNames.length > 0) {
        return addZones({
          projectId: projectId,
          names: missingZoneNames,
        });
      } else {
        return Promise.resolve(undefined);
      }
    },
    [addZones, zones, spreadsheetData],
  );

  const addMissingTags = useCallback(
    async (projectId: string | null | undefined) => {
      if (!projectId) {
        return undefined;
      }
      const missingTagNames = spreadsheetData.reduce<string[]>((acc, row) => {
        const rowTagName = getCellValue(row, COLUMN_TYPE.Tag);
        return acc.concat(
          rowTagName && !tags?.some((m) => m.name === rowTagName)
            ? [rowTagName]
            : [],
        );
      }, []);

      if (missingTagNames.length > 0) {
        return await updateTags({
          projectId,
          addedTags: [...new Set(missingTagNames)].map((tagName) => {
            const random = new SeededRandom(tagName || "NA");
            const color = TAG_COLORS[random.nextInt(TAG_COLORS.length)];
            return {
              name: tagName,
              color,
            };
          }),
        });
      } else {
        return undefined;
      }
    },
    [updateTags, tags, spreadsheetData],
  );

  const calcTableTotal = useCallback(
    (data: Record<string, string>[], opts?: { taxable: boolean }) => {
      return data.reduce((acc, row) => {
        const isLumpSum = isLumpSumUomText(getCellValue(row, COLUMN_TYPE.UOM));

        const quantity = Number(
          getCellValue(row, COLUMN_TYPE.Quantity) ||
            getCellValue(row, COLUMN_TYPE.PositiveQuantity),
        );
        const unitPrice = isLumpSum
          ? 1
          : Number(
              getCellValue(row, COLUMN_TYPE.UnitPrice) ||
                getCellValue(row, COLUMN_TYPE.PrefilledPrice),
            );
        const isTaxable = rowIsChecked(row, COLUMN_TYPE.Taxable);
        const extPrice =
          !opts?.taxable || isTaxable ? calcExtPrice(quantity, unitPrice) : 0;
        return acc + extPrice;
      }, 0);
    },
    [calcExtPrice],
  );

  const tableHasColumn = useCallback(
    (column: COLUMN_TYPE) => {
      return config.some((col) => col.columnType === column);
    },
    [config],
  );

  const getFirstTag = useCallback((value: TagFieldsFragment[] | undefined) => {
    const onlyTags = value?.filter((tag) => !tag.hasMapping);
    return onlyTags?.length ? onlyTags[0].name : "";
  }, []);

  const getPhaseCode = useCallback((value: TagFieldsFragment[] | undefined) => {
    const onlyPhaseCodes = value?.filter((tag) => tag.hasMapping);
    return onlyPhaseCodes?.length ? onlyPhaseCodes[0].name : "";
  }, []);

  return {
    getCostCodeId,
    getRowTagIds,
    getCostTypeId,
    getCellWithAdditionalData,
    confirmRemove,
    addMissingMaterials,
    addMissingManufacturers,
    addMissingZones,
    addMissingTags,
    getPrefilledValue,
    findMaterialByName,
    getFormattedMaterialName,
    getBuyoutItem,
    getEstimatedItem,
    calcTableTotal,
    tableHasColumn,
    getFirstTag,
    getPhaseCode,
  };
};
