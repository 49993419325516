import { SeededRandom } from "@/common/utils/seedRadom";
import { darken, getLuminance, lighten } from "@mui/material";
import { useMemo } from "react";
import { TAG_COLORS } from "../../tag-picker/TagColors";

export const useImageInitials = (name: string) => {
  const random = useMemo(() => new SeededRandom(name || "NA"), [name]);

  const selectedColor = useMemo(() => {
    return TAG_COLORS[random.nextInt(TAG_COLORS.length)];
  }, [random]);

  const backgroundColor = useMemo(() => {
    if (selectedColor) {
      return lighten(selectedColor, 0.8 - getLuminance(selectedColor));
    } else {
      return "gray";
    }
  }, [selectedColor]);

  const color = useMemo(() => {
    if (selectedColor) {
      return darken(selectedColor, 0.65);
    } else {
      return "darkgray";
    }
  }, [selectedColor]);

  const borderColor = useMemo(() => {
    if (selectedColor) {
      return darken(selectedColor, 0.3);
    } else {
      return "darkgray";
    }
  }, [selectedColor]);

  return {
    backgroundColor,
    color,
    borderColor,
  };
};
