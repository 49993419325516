import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { EstimatedItemsProvider } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ProjectProvider } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useCallback, useState } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { AddEstimatedItemsDialog } from "../import-to-buyout/AddEstimatedItemsDialog";
import { BuyoutProjectItemsProvider } from "../providers/BuyoutProjectItemsProvider";

export const BuyoutAddItems = () => {
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(false);
  const { buyout } = useContractorBuyout();

  const gotoItemSelection = useCallback(() => {
    setShowEstimatedItemsDialog(true);
  }, []);

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      {showEstimatedItemsDialog && buyout ? (
        <BuyoutProjectItemsProvider>
          <EstimatedItemsProvider>
            <ProjectProvider id={buyout.project.id}>
              <AddEstimatedItemsDialog
                setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
              />
            </ProjectProvider>
          </EstimatedItemsProvider>
        </BuyoutProjectItemsProvider>
      ) : null}
    </>
  );
};
