import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { Identity } from "@/types/Identity";
import Handsontable from "handsontable";
import {
  CellChange,
  ChangeSource,
  ColumnDataGetterSetterFunction,
} from "handsontable/common";
import { useCallback } from "react";
import { CUSTOM_SOURCE } from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";

const getColumnType = (
  hotInstance: Handsontable,
  row: number,
  column: string | number | ColumnDataGetterSetterFunction,
) => {
  const cellMeta = hotInstance.getCellMeta(
    row,
    getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
  );
  return cellMeta.type;
};

export const useCellEditHelpers = () => {
  const capDecimalCount = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, , newVal] = change;
            const columnType = getColumnType(hotInstance, row, column);
            if (
              columnType === "numeric" &&
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "number" || !isNaN(newVal))
            ) {
              const roundedValue = Math.round(Number(newVal) * 1e6) / 1e6;
              change[3] = roundedValue;
            }
          });
      }
    },
    [],
  );

  const preventReadOnlyChanges = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if (["edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, oldValue] = change;
            const cellMeta = hotInstance.getCellMeta(
              row,
              getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
            );
            const physicalRow = hotInstance?.toPhysicalRow(row) || row;
            const sourceRow = hotInstance.getSourceDataAtRow(
              physicalRow,
            ) as Identity;
            if (
              !cellMeta.ignoreReadOnly &&
              cellMeta.readOnlyFn &&
              cellMeta.readOnlyFn(sourceRow)
            ) {
              change[3] = oldValue;
            }
          });
      }
    },
    [],
  );

  const removePriceSymbols = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, , newVal] = change;
            const columnType = getColumnType(hotInstance, row, column);
            if (
              (columnType === "numeric" || columnType === "date") &&
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "string" || isNaN(newVal))
            ) {
              const roundedValue = newVal.replace(/[,$£]/g, "");
              change[3] = roundedValue;
            }
          });
      }
    },
    [],
  );

  const sanitizedInvalidNumbers = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }

            const [row, column, , newVal] = change;

            const columnType = getColumnType(hotInstance, row, column);
            if (
              columnType === "numeric" &&
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "string" || isNaN(newVal))
            ) {
              const sanitizedValue = newVal.replace(/[^0-9.]/g, "");
              change[3] = sanitizedValue;
            }
          });
      }
    },
    [],
  );

  const preventLumpSumChanges = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if (["edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, oldValue] = change;
            const cellMeta = hotInstance.getCellMeta(
              row,
              getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
            );
            const physicalRow = hotInstance?.toPhysicalRow(row) || row;
            const rows = hotInstance?.getData() as Record<string, string>[];
            const uom =
              rows[physicalRow]?.[
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
              ];
            const isLumpSum = isLumpSumUomText(uom);

            if (
              !cellMeta.ignoreReadOnly &&
              isLumpSum &&
              cellMeta.disabledForLumpSum
            ) {
              change[3] = oldValue;
            }
          });
      }
    },
    [],
  );

  const updateActivePriceEditorForLumpSum = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      column: number,
      row: number,
    ) => {
      if (!hotInstance) {
        return;
      }

      const physicalRow = hotInstance?.toPhysicalRow(row) || row;
      const rows = hotInstance?.getData() as Record<string, string>[];
      const uom =
        rows[physicalRow]?.[
          getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
        ];
      const isLumpSum = isLumpSumUomText(uom);

      if (
        isLumpSum &&
        (column ===
          getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.PrefilledPrice) ||
          column === getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UnitPrice))
      ) {
        const quantity =
          rows[row][getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity)];
        hotInstance?.getActiveEditor()?.setValue(quantity);
      }
    },
    [],
  );

  return {
    capDecimalCount,
    preventReadOnlyChanges,
    removePriceSymbols,
    sanitizedInvalidNumbers,
    preventLumpSumChanges,
    updateActivePriceEditorForLumpSum,
  };
};
