import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useTransactionHistoryModal } from "../../../../common/components/transaction-history/hooks/useTransactionHistoryModal";
import { TransactionHistoryModal } from "../../../../common/components/transaction-history/TransactionHistoryModal";
import { useInventoryLedgerFilter } from "../../hooks/useInventoryLedgerFilter";
import { useInventoryTransactions } from "../../hooks/useInventoryTransactions";
import { useInventoryLedgerConfiguration } from "./InventoryLedgerList.configuration";

export const InventoryLedgerList = () => {
  const { inventoryTransactions, loading, totalCount } =
    useInventoryTransactions();
  const { onOpen } = useTransactionHistoryModal();
  const onClick = useCallback(
    (item: InventoryTransactionSummaryFieldsFragment) => {
      if (item) {
        onOpen({ transactionId: item.id }, item.type);
      }
    },
    [onOpen],
  );
  const { isFiltered } = useInventoryLedgerFilter();
  const configuration = useInventoryLedgerConfiguration();

  return (
    <ListRenderer
      totalCount={totalCount}
      count={inventoryTransactions.length}
      paginated
    >
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-34",
          },
          toggle: {
            item: (item) => onClick(item),
          },
        }}
        loading={loading}
        items={inventoryTransactions}
        emptyList={
          <NoResults
            filteredTranslationKey="NO_RESULTS_INVENTORY_LEDGER_FILTERED"
            translationKey="NO_RESULTS_INVENTORY_LEDGER"
            isFiltered={!!isFiltered}
          />
        }
      />
      <TransactionHistoryModal />
    </ListRenderer>
  );
};
