import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptMatchedOrderViewState } from "../../types/ReceiptMatchedOrderViewState";

export const ReceiptOrderBreadcrumb = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { receipt, hasReleaseRecorded, matchedOrderViewState } = useReceipt();

  const breadcrumbLabelId = useMemo(() => {
    if (hasReleaseRecorded) {
      return "RECEIPT_FOR";
    }
    switch (matchedOrderViewState) {
      case ReceiptMatchedOrderViewState.DEFAULT:
        return "PROCESS_RECEIPT";
      case ReceiptMatchedOrderViewState.CREATE_ORDER:
        return "RECORD_ORDER";
      case ReceiptMatchedOrderViewState.MATCH_ORDER:
        return "SELECT_ORDER";
    }
  }, [hasReleaseRecorded, matchedOrderViewState]);

  const showReceiptBreadcrumbStatus = useMemo(
    () =>
      !hasReleaseRecorded &&
      matchedOrderViewState === ReceiptMatchedOrderViewState.DEFAULT &&
      receipt?.status,
    [receipt?.status, matchedOrderViewState, hasReleaseRecorded],
  );
  return (
    <>
      <FormattedMessage id={breadcrumbLabelId} />
      <If isTrue={hasReleaseRecorded}>
        <LinkLike
          onClick={() =>
            navigate(
              generatePath(routes.delivery, {
                deliveryId: receipt?.release?.id,
              }),
            )
          }
          className="ml-1 text-base font-normal"
        >
          {intl.$t(
            { id: "ORDER_WITH_NUMBER" },
            {
              orderNumber: receipt?.release?.sequenceNumber,
            },
          )}
        </LinkLike>
      </If>
      <If isTrue={showReceiptBreadcrumbStatus}>
        <InvoiceStatusChip
          status={receipt?.status}
          type="breadcrumb"
          classes={{
            root: "ml-3",
          }}
        />
      </If>
    </>
  );
};
