import {
  defaultTableItemSelectionClassNames,
  greenTableItemSelectionClassNames,
} from "@/common/components/searchable-list/utils";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { MatchedOrderViewState } from "../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { useInvoiceReleaseConfiguration } from "../components/InvoiceReleaseConfiguration";

export const useInvoiceReleaseItemsTableConfiguration = () => {
  const { release } = useRelease();
  const { showOnlyInvoicedItems, markItemsWithoutCostCode } =
    useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  const itemFn = useCallback(
    (item: ExpandedReleaseItem) => ({
      className: item.invoiceItems?.[0]?.id
        ? matchedOrderViewState === MatchedOrderViewState.EDIT_INVOICE_COVERAGES
          ? defaultTableItemSelectionClassNames
          : greenTableItemSelectionClassNames
        : "bg-gray-100 first:mt-0",
    }),
    [matchedOrderViewState],
  );

  const getMissingCostCodeClassName = useCallback(
    (item: ExpandedReleaseItem, className: string) =>
      (!item.costCode || item.costCode?.id === UNSPECIFIED_COST_CODE_ID) &&
      markItemsWithoutCostCode
        ? className
        : undefined,
    [markItemsWithoutCostCode],
  );

  const items = useMemo(
    () =>
      release?.items
        .filter((i) => !showOnlyInvoicedItems || !!i.invoiceItems?.[0])
        .map((item) => {
          const estimatedItems = item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          );
          return {
            ...{
              ...item.projectItem,
              material: {
                ...item.projectItem?.material,
                material: {
                  ...item.projectItem?.material.material,
                  name: item.name,
                },
              },
              estimatedItems,
            },
            ...item,
          } as ExpandedReleaseItem;
        }) ?? [],
    [release?.items, showOnlyInvoicedItems],
  );

  const tableConfiguration = useInvoiceReleaseConfiguration({
    release,
    readonly:
      matchedOrderViewState !== MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
    items,
    getCounterInnerClassName: (item: ExpandedReleaseItem) =>
      getMissingCostCodeClassName(item, "bg-red-500"),
    getMaterialViewClassName: (item: ExpandedReleaseItem) =>
      getMissingCostCodeClassName(item, "text-red-500"),
  });
  const classNames = useMemo(
    () => ({
      header: "-top-6 bg-gray-100 -ml-1 z-10",
      category: "top-3",
      subCategory: (groupedByZones: boolean) =>
        groupedByZones ? "top-11" : "top-3",
    }),
    [],
  );

  return { classNames, tableConfiguration, items, itemFn };
};
