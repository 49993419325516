import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { clearSpreadSheetUnusedCostCodes } from "@/common/utils/cost-codes-and-zones/clearSpreadSheetUnusedCostCodes";
import { useEffect } from "react";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";
import { useProjectBudget } from "../../project/pages/project-budget/hooks/useProjectBudget";
import { useRfq } from "./useRfq";

export const useRfqProjectBudgetSync = () => {
  const { rfq, loading } = useRfq();
  const { spreadsheetData, handsonInstance } = useColumnMapper();
  const { budgetLink } = useProjectBudget();
  const { projectCostCodes } = useProjectCostCodes();

  useEffect(() => {
    if (!handsonInstance || !budgetLink?.autoSync || rfq || loading) {
      return;
    }
    clearSpreadSheetUnusedCostCodes(
      spreadsheetData,
      handsonInstance,
      projectCostCodes,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetLink, rfq, loading]);
};
