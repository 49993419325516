import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { OrderSelectorFilters } from "@/contractor/pages/home/common/order-selector-filters/OrderSelectorFilters";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { InvoiceSelectModeHeader } from "../../invoice-select-mode-header/InvoiceSelectModeHeader";

const Container = tw.div`w-full`;

export const InvoiceOrderFilters: FC = () => {
  const { invoice } = useInvoiceVerification();
  const {
    selectedRelease,
    setSelectedRelease,
    preselectedPoNumber,
    setPreselectedPoNumber,
  } = useInvoiceMatchedOrder();
  const { setFilter, releases } = useReleasesSelectorData({
    search: preselectedPoNumber,
    statuses: [
      ReleaseStatus.Scheduled,
      ReleaseStatus.Received,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Requested,
      ReleaseStatus.Reserved,
    ],
    closedProjects: false,
  });

  useEffect(() => {
    if (preselectedPoNumber && releases.length > 0) {
      setPreselectedPoNumber("");
      const release = releases.find((r) => r.poNumber === preselectedPoNumber);
      if (release) {
        setSelectedRelease(release);
        setFilter((filter) => ({
          ...filter,
          projectIds: [release.project?.id ?? ""],
          sellerOrgLocationIds: release.sellerOrgLocation?.id
            ? [release.sellerOrgLocation.id]
            : [],
        }));
      }
    }
  }, [
    preselectedPoNumber,
    releases,
    setFilter,
    setPreselectedPoNumber,
    setSelectedRelease,
  ]);

  useEffect(() => {
    return () => setSelectedRelease(null);
  }, [setSelectedRelease]);

  return (
    <Container>
      <InvoiceSelectModeHeader />
      <OrderSelectorFilters
        onOrderChange={(rel) => setSelectedRelease(rel ?? null)}
        initialRelease={selectedRelease}
        initialProjectId={invoice?.project?.id}
      />
    </Container>
  );
};
