import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  InvoiceToExportFieldsFragment,
  InvoiceType,
  useInvoicesByIdsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ValidationInvoice = {
  id: string;
  validated: boolean;
  loading: boolean;
};

type ProviderContextType = {
  loading: boolean;
  invoiceIds: InvoiceToExportFieldsFragment[];
  refetchInvoicesByIds: () => void;
  validatedInvoices: Array<ValidationInvoice>;
  setValidatedInvoice: (v: ValidationInvoice) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  invoiceIds: [],
  loading: false,
  refetchInvoicesByIds: NoFunction,
  setValidatedInvoice: NoFunction,
  validatedInvoices: [],
});

export const InvoicesByIdsProvider: FC<
  PropsWithChildren<{ invoiceIds: string[] }>
> = ({ children, invoiceIds }) => {
  const { data, loading, error, refetch } = useInvoicesByIdsQuery({
    variables: {
      filter: {
        invoiceIds,
        type: InvoiceType.Invoice,
      },
      first: MAX_ITEMS_PER_PAGE,
    },
    fetchPolicy: "no-cache",
  });
  const [validatedInvoices, setValidatedInvoices] = useState<
    Array<ValidationInvoice>
  >(invoiceIds.map((id) => ({ id, validated: false, loading: false })));

  const setValidatedInvoice = useCallback((item: ValidationInvoice) => {
    setValidatedInvoices((prev) => {
      const index = prev.findIndex((r) => r.id === item.id);
      if (index === -1) {
        return prev;
      }
      return [...prev.slice(0, index), item, ...prev.slice(index + 1)];
    });
  }, []);

  const invoices = useMemo(
    () => data?.invoices.edges.map(({ node }) => node) ?? [],
    [data],
  );

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        loading,
        invoiceIds: invoices,
        refetchInvoicesByIds: refetch,
        validatedInvoices,
        setValidatedInvoice,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoicesByIds = (): ProviderContextType =>
  useContext(ProviderContext);
