import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { TransactionItemOrigin } from "../../../TransactionItemOrigin";
import { useTransactionOriginTo } from "../../../hooks/useTransactionOriginTo";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const TransactionOriginTo = ({ item }: Props) => {
  const to = useTransactionOriginTo(item);
  return <TransactionItemOrigin item={to} />;
};
