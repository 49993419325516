import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useArchiveInventoryItemsMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useArchiveInventoryItems = () => {
  const { setError } = useGlobalError();

  const [archiveInventoryItemsMutation, { loading }] =
    useArchiveInventoryItemsMutation();

  const archiveInventoryItems = useCallback(
    async (inventoryItemIds: string[]): Promise<boolean> => {
      try {
        const { data, errors } = await archiveInventoryItemsMutation({
          variables: {
            input: {
              inventoryItemIds,
            },
          },
        });
        setError(errors);
        return Boolean(!!data?.archiveInventoryItems && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [archiveInventoryItemsMutation, setError],
  );

  return { archiveInventoryItems, loading };
};
