import { Dialog } from "@/common/components/dialog/Dialog";
import { InfoOutlineRed } from "@/common/components/import-external-po/import-external-po-wizard/importable-external-pos/items/ExternalPO.styles";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { DialogContent } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  InventoryAdjustmentForm,
  InventoryAdjustmentFormValues,
} from "../../inventory-adjustment/components/inventory-adjustment-form/InventoryAdjustmentForm";

const InfoIcon = tw(InfoOutlineRed)`text-blue-500 text-9xl -mb-10`;
const Title = tw.div`text-2xl font-bold text-center mb-4`;

type SelectWarehouseDialogProps = {
  open: boolean;
  onClose: () => void;
  labelKey: string;
  handleConfirm: (values: InventoryAdjustmentFormValues) => void;
};

const SelectWarehouseDialogWithForm = ({
  open,
  onClose,
  labelKey,
  handleConfirm,
}: SelectWarehouseDialogProps) => {
  const intl = useIntl();
  const { handleSubmit } = useFormContext<InventoryAdjustmentFormValues>();

  const closeDialog = () => {
    onClose();
  };

  return (
    <Dialog
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "PROCEED" })}
      icon={<InfoIcon />}
      handleConfirm={handleSubmit(handleConfirm)}
      handleCancel={closeDialog}
      sx={{
        "& .MuiDialog-paper": {
          width: "420px",
        },
      }}
      show={open}
      content={
        <DialogContent>
          <FormattedMessage id={labelKey} tagName={Title} />
          <WarehouseSelectorControlled
            name="warehouseId"
            placeholder={intl.$t({ id: "WAREHOUSE" })}
            trackedWarehousesOnly
          />
        </DialogContent>
      }
    />
  );
};

export const SelectWarehouseDialog = (props: SelectWarehouseDialogProps) => {
  return (
    <InventoryAdjustmentForm>
      <SelectWarehouseDialogWithForm {...props} />
    </InventoryAdjustmentForm>
  );
};
