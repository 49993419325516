import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { Tooltip } from "../../tooltip/Tooltip";

type Props = {
  invoice:
    | { orgDuplicate: boolean; vendorDuplicate: boolean }
    | null
    | undefined;
  className?: string;
};

const ErrorIcon = tw(InfoOutlined)`text-red-500`;
const Container = tw.div``;

export const isDuplicatedInvoice = (invoice: Props["invoice"]): boolean => {
  return !!invoice?.vendorDuplicate;
};

export const DuplicatedInvoiceInfo: FC<Props> = ({ invoice, className }) => {
  if (!isDuplicatedInvoice(invoice)) {
    return null;
  }
  return (
    <Tooltip
      id={`asset`}
      element={
        <Container className={className}>
          <ErrorIcon />
        </Container>
      }
    >
      <If isTrue={invoice?.vendorDuplicate}>
        <FormattedMessage
          id="INVOICE_WITH_THE_SAME_NUMBER_ALREADY_EXISTS_FOR_VENDOR"
          tagName="div"
        />
      </If>
    </Tooltip>
  );
};
