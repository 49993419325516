import { isUUID } from "@/common/utils/uuidUtils";

const isBooleanLike = (value: string | boolean): boolean => {
  return (
    value === true || value === false || value === "true" || value === "false"
  );
};

export const rowIsEmpty = (row: Record<string, string>): boolean => {
  return Object.values(row).every(
    (cell) => !cell || isUUID(cell) || isBooleanLike(cell),
  );
};
