import { routes } from "@/config/routes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { InvoiceKickback } from "@/generated/graphql";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";

export const useInvoicesCards = () => {
  const { invoice } = useInvoiceVerification();
  const navigate = useNavigate();

  const getInvoiceFromUrl = useCallback(
    (invoiceId: string) =>
      invoice?.release?.invoices.find((i) => i.id === invoiceId),
    [invoice],
  );

  const changeInvoice = useCallback(
    (invoice?: { id: string }) => {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: invoice?.id,
        }),
        {
          replace: true,
        },
      );
    },
    [navigate],
  );

  const hasUnresolvedKickbacks = useCallback(
    (
      invoice:
        | {
            kickbacks:
              | Pick<InvoiceKickback, "resolvedAt" | "deletedAt">[]
              | undefined;
          }
        | undefined,
    ) => {
      return !!invoice?.kickbacks
        ?.filter((kickback) => !kickback.deletedAt)
        .some((kickback) => !kickback.resolvedAt);
    },
    [],
  );

  return { getInvoiceFromUrl, changeInvoice, hasUnresolvedKickbacks };
};
