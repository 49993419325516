import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { routes } from "@/config/routes";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { InventoryAdjustmentFormValues } from "../../inventory-adjustment/components/inventory-adjustment-form/InventoryAdjustmentForm";
import { SelectWarehouseDialog } from "../select-warehouse-dialog/SelectWarehouseDialog";

export const AddTransferButton = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleTransfer = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleConfirm = useCallback(
    (values: InventoryAdjustmentFormValues) => {
      navigate({
        pathname: routes.inventoryTransfer,
        search: `?warehouseId=${values.warehouseId}`,
      });
      setOpen(false);
    },
    [navigate, setOpen],
  );

  return (
    <>
      <OutlinedButton onClick={handleTransfer}>
        <FormattedMessage id="TRANSFER" />
      </OutlinedButton>
      <SelectWarehouseDialog
        open={open}
        onClose={() => setOpen(false)}
        labelKey="TRANSFER_FROM"
        handleConfirm={handleConfirm}
      />
    </>
  );
};
