import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import tw from "tailwind-styled-components";
import { useInventoryWizardTransaction } from "../../../hooks/useInventoryWizardTransaction";
import { TransactionHistoryAdjustmentsHeader } from "./components/transaction-history-adjustments-header/TransactionHistoryAdjustmentsHeader";
import { TransactionHistoryAdjustmentsList } from "./components/transaction-history-adjustments-list/TransactionHistoryAdjustmentsList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryAdjustments = () => {
  const { loading, transaction } = useInventoryWizardTransaction();

  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <Loader loading={loading}>
        <Container>
          <TransactionHistoryAdjustmentsHeader transaction={transaction} />
          <TransactionHistoryAdjustmentsList transaction={transaction} />
        </Container>
      </Loader>
    </PaginationProvider>
  );
};
