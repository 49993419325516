import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useRenderHelpers } from "@/common/components/spreadsheet-table/renderers/useRenderHelpers";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useTransferQuantityRenderer = () => {
  const intl = useIntl();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();
  const { addIconWithTooltip, applyClasses } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      if (value === "" || value === null || value === undefined) {
        return;
      }

      applyClasses(td, "htMiddle relative");

      const material =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
        ];
      const uom =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)
        ];
      const inventoryItem = findInventoryItemByNameAndUom(material, uom);
      if (!inventoryItem) {
        return;
      }

      const remainingQuantity =
        getInventoryItemRemainingQuantity(inventoryItem).toNumber();
      const moreThanRemainingQuantity = Number(value) > remainingQuantity;
      const lessThanOrEqualToZero = Number(value) <= 0;
      if (moreThanRemainingQuantity || lessThanOrEqualToZero) {
        applyClasses(td, "bg-red-200");
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t({
            id: moreThanRemainingQuantity
              ? "QUANTITY_EXCEEDS_STOCK"
              : "QUANTITY_IS_NOT_GREATER_THAN_ZERO",
          }),
          iconClasses: "text-red-500 hover:text-red-300",
          icon: "fa-circle-exclamation",
        });
      }
    },
    [intl, findInventoryItemByNameAndUom, applyClasses, addIconWithTooltip],
  );

  return renderer;
};
