import { QueryInventoryItemTransactionsFilter } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  filter: QueryInventoryItemTransactionsFilter;
  setFilter: (step: QueryInventoryItemTransactionsFilter) => void;
};

export const useInventoryItemTransactionsStore = create<State>()(
  devtools((set) => ({
    filter: {},
    setFilter: (filter: QueryInventoryItemTransactionsFilter) =>
      set(() => ({
        filter,
      })),
  })),
);
