import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "../../../hooks/useAddInventoryItems";

const Container = tw.div`
  grid -ml-2 pr-8 w-5
`;

export const AddAllInventoryItemsCheckbox = () => {
  const { selectedItems, setSelectedItems } = useAddInventoryItems();
  const { filteredInventoryItems } = useAddInventoryItems();

  const itemIds = useMemo(
    () => filteredInventoryItems.map((item) => item.id),
    [filteredInventoryItems],
  );

  const onToggle = useCallback(
    (checked: boolean) => {
      setSelectedItems(checked ? itemIds : []);
    },
    [itemIds, setSelectedItems],
  );

  const checked = useMemo(
    () => itemIds.every((id) => selectedItems.includes(id)),
    [itemIds, selectedItems],
  );

  return (
    <Container>
      <SelectionCheckbox
        testId="add-all-items-checkbox"
        checked={checked}
        setSelection={onToggle}
      />
    </Container>
  );
};
