import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemSummaryFieldsFragment } from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";

export const updateScheduledOutflows = (
  item: InventoryItemSummaryFieldsFragment,
  warehouses: WarehouseInventoryItem[],
) => {
  item.pendingOutflows.forEach((outflow) => {
    const warehouse = warehouses.find(
      (w) => w.warehouse.id === outflow.release.warehouse?.id,
    );
    if (warehouse) {
      warehouse.scheduledOutflowTotal = new DecimalSafe(
        warehouse.scheduledOutflowTotal,
      )
        .plus(new DecimalSafe(outflow.quantityDecimal))
        .toNumber();
    }
  });
};
