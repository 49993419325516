import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { routes } from "@/config/routes";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

export const InventoryAdjustmentBreadcrumbs: FC = () => {
  const intl = useIntl();

  const breadcrumbs = useMemo(() => {
    const items = [
      {
        text: intl.$t({ id: "INVENTORY" }),
        link: routes.currentStock,
        id: "inventory",
      },
      {
        text: intl.$t({ id: "NEW_ADJUSTMENT" }),
        id: "new-adjustment",
      },
    ] as BreadcrumbItem[];

    return items;
  }, [intl]);

  const actions = useMemo(() => {
    return [];
  }, []);

  const appendItems = useMemo(() => {
    return [];
  }, []);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendItems}
      actions={actions}
    />
  );
};
