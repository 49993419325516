import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { CurrentStockItemContainer } from "../../CurrentStockList.styles";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";
import { useCurrentStockInStock } from "./useCurrentStockInStock";

export const CurrentStockInStock = ({ item }: CurrentStockListItemProps) => {
  const { className, onClick, disabled } = useCurrentStockInStock({ item });

  return (
    <CurrentStockItemContainer className={className}>
      <LinkLike onClick={onClick} disabled={disabled}>
        <ValueUnit
          value={item?.inStock}
          classNames={{ item: "text-xs" }}
          minimumFractionDigits={2}
        />
      </LinkLike>
    </CurrentStockItemContainer>
  );
};
