import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelectableCostCodesConfiguration } from "./SelectableCostCodes.configuration";

type Props = {
  selectedCostCodeId: string | null;
  setSelectedCostCodeId: (costCodeId: string | null) => void;
};

export const SelectableCostCodeList: FC<Props> = ({
  selectedCostCodeId,
  setSelectedCostCodeId,
}) => {
  const { loading } = useCostCodes();
  const { projectCostCodes } = useProjectCostCodes();
  const intl = useIntl();
  const { configuration } = useSelectableCostCodesConfiguration({
    selectedItem: selectedCostCodeId,
  });

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      itemSelection={{
        selectedItemId: selectedCostCodeId,
        setSelectedItemId: setSelectedCostCodeId,
        type: "single",
      }}
      searchBarTitle={intl.$t({ id: "SELECT_COST_TYPES_TO_IMPORT" })}
      searchKeys={["code", "description"]}
      items={projectCostCodes}
      loadingItems={loading}
    />
  );
};
