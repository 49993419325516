import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUnspecifiedPhaseCode } from "@/common/hooks/useUnspecifiedPhaseCode";
import { useUnspecifiedZone } from "@/common/hooks/useUnspecifiedZone";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  CostCodeFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { getCostCodesByReleaseItems } from "../cost-codes-and-zones/getCostCodesByReleaseItems";
import { getPhaseCodesByReleaseItems } from "../cost-codes-and-zones/getPhaseCodesByReleaseItems";

type ReleaseItem = ExpandedReleaseItem | DistributorReleaseItemFieldsFragment;

type Props<T extends ReleaseItem[]> = {
  items: T;
  forceUsePhaseCodes?: boolean;
};

export const useReleaseItemsGrouping = <T extends ReleaseItem[]>({
  items,
  forceUsePhaseCodes,
}: Props<T>) => {
  const { hasPhaseCodes } = useOrgSettings();
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { unassignedPhaseCode } = useUnspecifiedPhaseCode();

  const includePhaseCodes = useMemo(
    () => hasPhaseCodes || forceUsePhaseCodes,
    [hasPhaseCodes, forceUsePhaseCodes],
  );

  const getProjectCodes = useCallback(
    (zone: ZoneFieldsFragment) => {
      return includePhaseCodes
        ? getPhaseCodesByReleaseItems(
            items,
            unassignedPhaseCode,
            unassignedZone,
            zone,
          )
        : getCostCodesByReleaseItems(
            items,
            unassignedCostCode,
            unassignedZone,
            zone,
          );
    },
    [
      includePhaseCodes,
      items,
      unassignedPhaseCode,
      unassignedZone,
      unassignedCostCode,
    ],
  );

  const getFilteredProjectItems = useCallback(
    (
      zone: ZoneFieldsFragment,
      costCode: Omit<CostCodeFieldsFragment, "mappings" | "inUse">,
    ) =>
      includePhaseCodes
        ? items.filter(
            (item) =>
              ((!item.zone && zone.id === unassignedZone.id) ||
                item.zone?.id === zone.id) &&
              ((!item.tags.find((tag) => tag.hasMapping) &&
                costCode.id === unassignedCostCode.id) ||
                item.tags.find((tag) => tag.hasMapping)?.id === costCode.id),
          )
        : items.filter(
            (item) =>
              ((!item.zone && zone.id === unassignedZone.id) ||
                item.zone?.id === zone.id) &&
              ((!item.costCode && costCode.id === unassignedCostCode.id) ||
                item.costCode?.id === costCode.id),
          ),
    [includePhaseCodes, items, unassignedCostCode.id, unassignedZone.id],
  );

  return {
    getProjectCodes,
    getFilteredProjectItems,
  };
};
