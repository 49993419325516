import { useUser } from "@/common/providers/UserProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Input = {
  createdById: string | null | undefined;
  users: UsersUserFieldsFragment[];
  hasRelease: boolean;
};

export const useSelectKickbackUsers = ({
  createdById,
  users,
  hasRelease,
}: Input) => {
  const { setValue } = useFormContext();
  const { viewer } = useUser();
  const { release } = useRelease();

  useEffect(() => {
    const watchers = hasRelease ? (release?.watchers ?? []) : [];
    const kickbackToWatchers =
      hasRelease &&
      release?.watchers.length === 1 &&
      !watchers.some((releaseWatcher) => releaseWatcher.id === createdById);
    if (
      createdById &&
      viewer?.id !== createdById &&
      users &&
      users.find((user) => user.id === createdById) &&
      !kickbackToWatchers
    ) {
      setValue("assigneeIds", [createdById]);
    } else if (kickbackToWatchers) {
      setValue(
        "assigneeIds",
        (createdById && viewer?.id === createdById
          ? watchers.filter((watcher) => watcher.id !== createdById)
          : watchers
        ).map((watcher) => watcher.id),
      );
    }
  }, [createdById, setValue, users, viewer?.id, release?.watchers, hasRelease]);
};
