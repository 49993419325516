import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useSelectedInvoiceItems } from "./useSelectedInvoiceItems";

export const useInvoiceFooterTotalsWarning = () => {
  const { invoice } = useInvoiceVerification();
  const { release } = useRelease();
  const {
    selectedInvoiceItemsSubtotal,
    selectedInvoiceItemsTotal,
    selectedInvoiceItemsCharges,
    selectedInvoiceItemsSalesTax,
  } = useSelectedInvoiceItems();
  const { getTaxAmount } = useTaxCalculation();

  const totalsAreNotEqual = useMemo(
    () =>
      !selectedInvoiceItemsTotal
        .toDP(2)
        .equals(new DecimalSafe(invoice?.total || 0).toDP(2)),
    [selectedInvoiceItemsTotal, invoice?.total],
  );

  const subTotalsAreNotEqual = useMemo(
    () =>
      !selectedInvoiceItemsSubtotal
        .toDP(2)
        .equals(new DecimalSafe(invoice?.subtotal || 0).toDP(2)),
    [selectedInvoiceItemsSubtotal, invoice?.subtotal],
  );

  const invoiceSalesTaxGreaterThanOrderSalesTax = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    const taxAmount = getTaxAmount(
      release?.taxRate,
      release?.customTaxAmount,
      release?.netAmount,
      release?.taxVariance,
      (release?.additionalCharges ?? [])
        .reduce((acc, charge) => acc.plus(charge.amount), new DecimalSafe(0))
        .toString(),
    );
    return new DecimalSafe(invoice?.taxAmount || 0)
      .toDP(2)
      .greaterThan(new DecimalSafe(taxAmount || 0).toDP(2));
  }, [invoice?.taxAmount, invoice?.release, release, getTaxAmount]);

  const invoiceChargesGreaterThanOrderCharges = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return new DecimalSafe(invoice?.chargesAmount || 0).greaterThan(
      new DecimalSafe(
        release?.additionalCharges.reduce(
          (acc, charge) => acc.plus(charge.amount),
          new DecimalSafe(0),
        ) || 0,
      ),
    );
  }, [invoice?.chargesAmount, invoice?.release, release]);

  const invoiceSalesTaxNotEqualToInvoicedItemsSalesTax = useMemo(() => {
    return !new DecimalSafe(invoice?.taxAmount || 0)
      .toDP(2)
      .equals(
        new DecimalSafe(selectedInvoiceItemsSalesTax || 0).toDP(
          2,
          Decimal.ROUND_UP,
        ),
      );
  }, [invoice?.taxAmount, selectedInvoiceItemsSalesTax]);

  const invoiceChargesNotEqualToInvoicedItemsCharges = useMemo(() => {
    return !new DecimalSafe(invoice?.chargesAmount || 0)
      .toDP(2)
      .equals(new DecimalSafe(selectedInvoiceItemsCharges || 0).toDP(2));
  }, [invoice?.chargesAmount, selectedInvoiceItemsCharges]);

  const displayTotalErrorIcon = useMemo(
    () =>
      !!invoice &&
      !!release &&
      (totalsAreNotEqual ||
        subTotalsAreNotEqual ||
        invoiceSalesTaxNotEqualToInvoicedItemsSalesTax ||
        invoiceChargesNotEqualToInvoicedItemsCharges),
    [
      invoice,
      release,
      totalsAreNotEqual,
      subTotalsAreNotEqual,
      invoiceSalesTaxNotEqualToInvoicedItemsSalesTax,
      invoiceChargesNotEqualToInvoicedItemsCharges,
    ],
  );

  const displayGrayTotalErrorIcon = useMemo(
    () =>
      !subTotalsAreNotEqual &&
      (invoiceSalesTaxNotEqualToInvoicedItemsSalesTax ||
        invoiceChargesNotEqualToInvoicedItemsCharges),
    [
      subTotalsAreNotEqual,
      invoiceSalesTaxNotEqualToInvoicedItemsSalesTax,
      invoiceChargesNotEqualToInvoicedItemsCharges,
    ],
  );

  const displayTotalGreenIcon = useMemo(
    () => !!invoice && !!release && !displayTotalErrorIcon,
    [invoice, release, displayTotalErrorIcon],
  );

  return {
    displayTotalGreenIcon,
    displayTotalErrorIcon,
    displayGrayTotalErrorIcon,
    subTotalsAreNotEqual,
    invoiceSalesTaxGreaterThanOrderSalesTax,
    invoiceChargesGreaterThanOrderCharges,
  };
};
