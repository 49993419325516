import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { FC } from "react";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
};

export const CostCodesSelectorControlled: FC<Props> = ({
  name,
  label,
  placeholder,
}) => {
  const intl = useIntl();
  const { formatCostCode, projectCostCodes } = useProjectCostCodes();

  return (
    <SelectControlled
      name={name}
      options={projectCostCodes}
      getValue={(costCode) => costCode.id}
      getLabel={formatCostCode}
      label={label}
      placeholder={placeholder || intl.$t({ id: "COST_CODE" })}
      disableClearable={false}
    />
  );
};
