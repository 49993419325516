import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  ColumnType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { getPropertyDrillDown } from "@/common/utils/getPropertyDrillDown";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { Identity } from "@/types/Identity";
import { HotTable } from "@handsontable/react";
import HotTableClass from "@handsontable/react/hotTableClass";
import { format } from "date-fns";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import "handsontable/dist/handsontable.full.min.css";
import { isNumber } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { Permission } from "../org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "../org-roles-wrapper/hasPermissions";
import { GridLoader } from "./components/GridLoader";
import { NewColumnDialog } from "./components/NewColumnDialog";
import {
  ADD_ITEMS_SOURCE,
  EMPTY_STATE_MIN_ROWS,
  MIN_ROWS,
  PREFILL_SOURCE,
  PRICE_RELATED_COLUMNS,
  TRIGGER_UPDATE_SOURCES,
} from "./constants/tableConstants";
import { useCellEditHelpers } from "./hooks/useCellEditHelpers";
import { useColumnConfig } from "./hooks/useColumnConfig";
import { useGridLoadingInfo } from "./hooks/useGridLoadingInfo";
import { usePrefillHelpers } from "./hooks/usePrefillHelpers";
import { useTableHeaders } from "./hooks/useTableHeaders";
import { useTableHelpers } from "./hooks/useTableHelpers";
import "./spread-sheet-table.scss";
import { useSpreadSheetStore } from "./store/useSpreadSheetStore";
import { rowIsEmpty } from "./utils/rowIsEmpty";
import { sanitizeValue } from "./utils/sanitizeValue";
import { setTableCells } from "./utils/setTableCells";

const LinkLikeStyled = tw(LinkLike)`mx-4 my-2 w-fit`;

type RangeType = {
  startRow: number;
  startCol: number;
  endRow: number;
  endCol: number;
};

type Props<T> = {
  items: T[];
  height: string | number;
  columns: ColumnType[];
  saving?: boolean;
  fetchingData?: boolean;
  rowNumber?: number;
  preventUpdatedOnChanges?: boolean;
  onChanges?: (data: Record<string, string>[]) => void;
  includePermissionCheck?: boolean;
  readOnly?: boolean;
  hideAddNewRow?: boolean;
  customPrefillFunctions?: ((
    hotInstance: Handsontable | undefined | null,
    changes: CellChange[] | null,
    source: ChangeSource,
  ) => void)[];
};

type ContainerProps = {
  $saving?: boolean;
};
const Container = tw.div<ContainerProps>`relative
  ${({ $saving }: ContainerProps) =>
    $saving ? "opacity-70 pointer-events-none" : ""}
`;

type IdentityWithAdditional = Identity & {
  position?: number | null;
};

export const SpreadSheetTable = <T extends IdentityWithAdditional>({
  items,
  height,
  columns,
  saving,
  fetchingData = false,
  rowNumber,
  onChanges,
  includePermissionCheck = true,
  readOnly = false,
  hideAddNewRow = false,
  customPrefillFunctions = [],
}: Props<T>) => {
  const { getUomByName } = useUomOptions();
  const { formatCostCode, projectCostCodes } = useProjectCostCodes();
  const { formatCostType, costTypes } = useCostTypes();
  const {
    setSpreadsheetData,
    extraColumns,
    rowHasChanges,
    setExtraColumns,
    setHandsonInstance,
  } = useColumnMapper();
  const getExtendedColumnConfig = useColumnConfig();
  const { getFormattedMaterialName, getFirstTag, getPhaseCode } =
    useTableHelpers();
  const { additionalHeader, headerWithAddButton, regularHeader, rowHeader } =
    useTableHeaders();
  const {
    prefillMaterialFields,
    prefillPrices,
    prefillExtraOptions,
    prefillForLumpSum,
    updateExtPriceDependence,
    prefillTaxableValues,
  } = usePrefillHelpers();
  const {
    capDecimalCount,
    removePriceSymbols,
    preventReadOnlyChanges,
    sanitizedInvalidNumbers,
    preventLumpSumChanges,
    updateActivePriceEditorForLumpSum,
  } = useCellEditHelpers();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { loadingData } = useGridLoadingInfo();

  const ref = useRef<HTMLDivElement>(null);
  const hotTableComponent = useRef<HotTableClass>(null);
  const [loading, setLoading] = useState(true);
  const [showNewColumnDialog, setShowNewColumnDialog] = useState(false);
  const [newColumnSource, setNewColumnSource] = useState<
    COLUMN_TYPE | string
  >();
  const [lastColumn, setLastColumn] = useState(false);

  useEffect(() => {
    const dropdowns = document.querySelectorAll(
      "div .listbox",
    ) as NodeListOf<HTMLDivElement>;
    dropdowns.forEach((dropdown: HTMLDivElement) => {
      dropdown.style.right = lastColumn ? "0px" : "auto";
    });
  }, [lastColumn]);

  const allColumns = useMemo(() => {
    const Columns = [];
    const filteredColumns = columns.filter((c) => {
      return (
        hasPermissions ||
        !includePermissionCheck ||
        !PRICE_RELATED_COLUMNS.includes(c.columnType as COLUMN_TYPE)
      );
    });
    for (let i = 0; i < filteredColumns.length; i++) {
      Columns.push(filteredColumns[i]);
      for (let j = 0; j < extraColumns.length; j++) {
        if (extraColumns[j].additional === filteredColumns[i].columnType) {
          Columns.push(extraColumns[j]);
        }
      }
    }
    return Columns;
  }, [columns, extraColumns, hasPermissions, includePermissionCheck]);

  useEffect(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    if (hotInstance) {
      setHandsonInstance(hotInstance);
    }
  }, [setHandsonInstance, hotTableComponent.current?.hotInstance]);

  const [data, setData] = useState<Record<string, string>[] | undefined>();

  useEffect(() => {
    if (data) {
      const newData = data.map((row) => ({
        ...row,
        [COLUMN_TYPE.CostCode]: formatCostCode(row[COLUMN_TYPE.CostCode]),
        [COLUMN_TYPE.CostType]: formatCostType(row[COLUMN_TYPE.CostType]),
      }));
      setData(newData);
      setSpreadsheetData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCostCodes.length, costTypes.length]);

  useEffect(() => {
    let newData = [...items]
      .sort((a, b) =>
        isNumber(a.position) && isNumber(b.position) && a.position < b.position
          ? -1
          : 1,
      )
      .map((item) => {
        return columnsConfig.reduce(
          (acc: Record<string, string>, col) => {
            const propValue =
              typeof col.columnId === "string"
                ? getPropertyDrillDown(item, col.columnId)
                : getPropertyDrillDown(item, col.columnId(item.id));
            if (col.columnType === COLUMN_TYPE.Material) {
              const materialName = sanitizeValue(propValue);
              acc[col.columnType] =
                getFormattedMaterialName(materialName) ?? materialName;
            } else if (col.columnType === COLUMN_TYPE.CostCode) {
              acc[col.columnType] = formatCostCode(sanitizeValue(propValue));
            } else if (col.columnType === COLUMN_TYPE.CostType) {
              acc[col.columnType] = formatCostType(sanitizeValue(propValue));
            } else if (col.columnType === COLUMN_TYPE.Tag) {
              acc[col.columnType] =
                typeof propValue === "string"
                  ? propValue
                  : getFirstTag(propValue);
            } else if (col.columnType === COLUMN_TYPE.PhaseCode) {
              acc[col.columnType] = getPhaseCode(propValue);
            } else {
              acc[col.columnType] = sanitizeValue(propValue);
            }
            if (col.type === "date" && acc[col.columnType]) {
              acc[col.columnType] = format(
                new Date(acc[col.columnType]),
                "MM/dd/yyyy",
              );
            }
            if (typeof acc[col.columnType] === "string") {
              const skipSpaceReplacement = col.skipSpaceReplacement;
              acc[col.columnType] = skipSpaceReplacement
                ? acc[col.columnType].trim()
                : acc[col.columnType].replace(/\s+/g, " ").trim();
            }
            return acc;
          },
          {
            id: item.id,
          },
        );
      });

    Array(
      rowNumber
        ? Math.max(rowNumber - items.length, EMPTY_STATE_MIN_ROWS)
        : Math.max(MIN_ROWS - items.length, EMPTY_STATE_MIN_ROWS),
    )
      .fill("")
      .forEach(() => {
        newData = newData.concat(
          allColumns.reduce((acc: Record<string, string>, col) => {
            acc[col.columnType] = "";
            return acc;
          }, {}),
        );
      });

    setLoading(false);
    if (
      !data ||
      data.every(rowIsEmpty) ||
      newData.some((row) => rowHasChanges(row)) ||
      // update table data if it has ids that are not in the new data
      data.some((row) => !newData.find((newRow) => newRow.id === row.id)) ||
      // update table data if all incoming ids are different
      data.every((row) => newData.every((newRow) => newRow.id !== row.id))
    ) {
      setData(newData);
      setSpreadsheetData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const columnHeaders = useMemo(() => {
    return allColumns.map((col) => {
      const header = col.header;
      if (col.renderer === "materialRenderer") {
        return headerWithAddButton(String(header));
      } else if (col.additional) {
        return additionalHeader(String(header));
      } else {
        return regularHeader(String(header));
      }
    });
  }, [allColumns, headerWithAddButton, additionalHeader, regularHeader]);

  const rowHeaders = useCallback(
    (index: number) => {
      return rowHeader(String(index + 1));
    },
    [rowHeader],
  );

  const columnsConfig = useMemo(
    () => getExtendedColumnConfig(allColumns),
    [allColumns, getExtendedColumnConfig],
  );

  const beforePaste = (changes: string[][], coords: RangeType[]) => {
    changes.forEach((change) => {
      change.forEach((cell, index) => {
        if (!change[index] || typeof change[index] !== "string") {
          return;
        }
        const columnType =
          columnsConfig[index + coords[0].startCol]?.columnType;
        const skipSpaceReplacement = columnsConfig.find(
          (col) => col.columnType === columnType,
        )?.skipSpaceReplacement;
        switch (columnType) {
          case COLUMN_TYPE.CostCode:
            change[index] = formatCostCode(change[index].trim());
            break;
          case COLUMN_TYPE.CostType:
            change[index] = formatCostType(change[index].trim());
            break;
          case COLUMN_TYPE.UOM:
            change[index] =
              getUomByName(cell)?.pluralDescription ?? change[index].trim();
            break;
          default:
            change[index] = skipSpaceReplacement
              ? change[index].trim()
              : change[index].replace(/\s+/g, " ").trim();
        }
        const type = columnsConfig[index + coords[0].startCol]?.type;
        if (type === "numeric" || type === "date") {
          change[index] = change[index].replace(/[,$£]/g, "");
        }
      });
    });
  };

  const beforeChange = useCallback(
    (changes: (CellChange | null)[], source: ChangeSource) => {
      if (!TRIGGER_UPDATE_SOURCES.includes(source) && changes.length > 0) {
        return;
      }
      const hotInstance = hotTableComponent.current?.hotInstance;
      hotInstance?.batch(() => {
        removePriceSymbols(hotInstance, changes, source);
        capDecimalCount(hotInstance, changes, source);
        preventReadOnlyChanges(hotInstance, changes, source);
        sanitizedInvalidNumbers(hotInstance, changes, source);
        preventLumpSumChanges(hotInstance, changes, source);
      });
    },
    [
      capDecimalCount,
      preventLumpSumChanges,
      preventReadOnlyChanges,
      removePriceSymbols,
      sanitizedInvalidNumbers,
    ],
  );

  const afterChange = useCallback(
    (changes: CellChange[] | null, source: ChangeSource) => {
      if (!TRIGGER_UPDATE_SOURCES.includes(source)) {
        return;
      }
      const hotInstance = hotTableComponent.current?.hotInstance;
      if (data) {
        data.forEach((row) => {
          if (
            Object.values(row).every(
              (cell) => row.id && (!cell || cell === row.id),
            )
          ) {
            delete row.id;
          }
        });

        if (source !== ADD_ITEMS_SOURCE) {
          useSpreadSheetStore.getState().resetPrefillChanges();
          if (customPrefillFunctions.length > 0) {
            customPrefillFunctions.forEach((fn) =>
              fn(hotInstance, changes, source),
            );
          } else {
            prefillExtraOptions(hotInstance, changes, source);
            prefillMaterialFields(hotInstance, changes, source);
            prefillPrices(hotInstance, changes, source);
            prefillForLumpSum(hotInstance, changes, source);
            updateExtPriceDependence(hotInstance, changes, source);
            prefillTaxableValues(hotInstance, changes, source);
          }
          setTableCells(
            useSpreadSheetStore.getState().prefillChanges,
            hotInstance,
            PREFILL_SOURCE,
          );
        }

        if (
          onChanges &&
          changes &&
          changes?.length > 0 &&
          changes.some(
            (change) =>
              change[2] !== change[3] &&
              !(change[3] === "" && change[2] === null),
          )
        ) {
          onChanges?.(data);
        }
        setSpreadsheetData(data);
      }
    },
    [
      data,
      prefillTaxableValues,
      onChanges,
      prefillExtraOptions,
      prefillForLumpSum,
      prefillMaterialFields,
      prefillPrices,
      setSpreadsheetData,
      updateExtPriceDependence,
      customPrefillFunctions,
    ],
  );

  const afterRemoveRow = useCallback(() => {
    if (data) {
      setSpreadsheetData(data);
      if (onChanges) {
        onChanges(data);
      }
    }
  }, [data, onChanges, setSpreadsheetData]);

  const beforeOnCellMouseDown = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const parent = target.parentElement as HTMLElement;
      const eventColumn = allColumns.find((col) =>
        parent.innerText.includes(col.header),
      );
      if (!eventColumn) {
        return;
      }
      if (target.innerText === "+") {
        event.stopImmediatePropagation();
        setNewColumnSource(eventColumn.columnType);
        setShowNewColumnDialog(true);
        const hotInstance = hotTableComponent.current?.hotInstance;
        hotInstance?.deselectCell();
      }
      if (target.innerText === "-") {
        event.stopImmediatePropagation();
        setExtraColumns((prev) => {
          return prev.filter(
            (col) => col.columnType !== eventColumn.columnType,
          );
        });
      }
    },
    [allColumns, setExtraColumns],
  );

  const afterUpdateSettings = useCallback(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    hotInstance?.validateCells();
  }, []);

  const afterBeginEditing = useCallback(
    (row: number, column: number) => {
      const hotInstance = hotTableComponent.current?.hotInstance;
      setLastColumn(
        hotInstance?.getSelectedLast()?.[1] === allColumns.length - 1,
      );
      updateActivePriceEditorForLumpSum(hotInstance, column, row);
    },
    [allColumns.length, updateActivePriceEditorForLumpSum],
  );

  const hiddenColumns = useMemo(() => {
    const hiddenColumns = [] as number[];
    columnsConfig.forEach((col, index) => {
      if (col.hidden) {
        hiddenColumns.push(index);
      }
    });
    return {
      columns: hiddenColumns,
    };
  }, [columnsConfig]);

  const addNewRow = useCallback(() => {
    setData(
      data?.concat(
        allColumns.reduce((acc: Record<string, string>, col) => {
          acc[col.columnType] = "";
          return acc;
        }, {}),
      ),
    );
  }, [allColumns, data]);

  return (
    <Container
      ref={ref}
      $saving={saving || loading || fetchingData || loadingData}
    >
      <HotTable
        ref={hotTableComponent}
        data={data}
        width="100%"
        height={height}
        colHeaders={columnHeaders}
        rowHeaders={rowHeaders}
        columns={columnsConfig}
        filters
        allowInsertRow={!readOnly}
        allowRemoveRow={!readOnly}
        multiColumnSorting
        allowInsertColumn={false}
        allowRemoveColumn={false}
        beforeChange={beforeChange}
        afterChange={afterChange}
        afterRemoveRow={afterRemoveRow}
        afterUpdateSettings={afterUpdateSettings}
        contextMenu
        hiddenColumns={hiddenColumns}
        beforeOnCellMouseDown={beforeOnCellMouseDown}
        viewportRowRenderingOffset={50}
        afterBeginEditing={afterBeginEditing}
        manualColumnResize
        manualColumnMove
        colWidths={allColumns.map((col) => col.width)}
        beforePaste={beforePaste}
        invalidCellClassName="invalid"
        autoWrapRow
        stretchH="all"
        licenseKey="non-commercial-and-evaluation"
        className="items-center"
        readOnly={readOnly}
      />
      <GridLoader saving={saving} loading={loading || loadingData} />
      <If isTrue={!hideAddNewRow && !readOnly}>
        <LinkLikeStyled onClick={addNewRow}>
          <FormattedMessage id="ADD_NEW_ROW" />
        </LinkLikeStyled>
      </If>
      <NewColumnDialog
        visible={showNewColumnDialog}
        setVisible={setShowNewColumnDialog}
        handleConfirm={(column: string) => {
          if (column === "") {
            return;
          }
          setExtraColumns((prev) => [
            ...prev,
            {
              width: 100,
              columnId: "",
              header: column,
              columnType: COLUMN_TYPE.Additional + prev.length,
              additional: newColumnSource,
            },
          ]);
        }}
      />
    </Container>
  );
};
