import { GridTable } from "@/common/components/grid-table/GridTable";
import { InventoryTransactionProps } from "../../../../../types/InventoryTransactionProps";
import { useTransactionHistoryTransfersConfiguration } from "./TransactionHistoryTransfers.configuration";

export const TransactionHistoryTransfersList = ({
  transaction,
}: InventoryTransactionProps) => {
  const configuration = useTransactionHistoryTransfersConfiguration();
  return (
    <GridTable
      configuration={{ columns: configuration }}
      items={transaction?.items}
    />
  );
};
