import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { If } from "@/common/components/if/If";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "../../../hooks/useAddInventoryItems";

const Container = tw.div`
  grid grid-cols-[auto_1fr_auto_auto] justify-between gap-4 bg-green-300 p-2 rounded-lg mb-1 w-full h-14 sticky top-0 z-70
`;

const InputContainer = tw.div`
  grid w-72
`;

const LeftSpace = tw.div`w-full`;
const Title = tw.div`text-base font-medium self-start w-fit grid place-items-center h-full mx-2`;

export const InventoryItemsFilters: FC = () => {
  const intl = useIntl();
  const { search, setSearch, selectedCostCodes, setSelectedCostCodes } =
    useAddInventoryItems();
  const { inventoryItems } = useInventoryItems();
  const { projectCostCodes } = useProjectCostCodes();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { hasPhaseCodes } = useOrgSettings();
  const costCodeOptions = useMemo(() => {
    return projectCostCodes
      .filter((costCode) =>
        inventoryItems?.find(
          (item) => item.orgMaterial.costCode?.id === costCode.id,
        ),
      )
      .concat(unassignedCostCode);
  }, [inventoryItems, projectCostCodes, unassignedCostCode]);

  return (
    <Container>
      <FormattedMessage id="SELECT_WAREHOUSE_ITEMS" tagName={Title} />
      <LeftSpace />
      <If isTrue={!hasPhaseCodes}>
        <InputContainer>
          <CostCodesSelector
            options={costCodeOptions}
            selectedCostCodes={selectedCostCodes}
            setSelectedCostCodes={setSelectedCostCodes}
            limitTags={1}
          />
        </InputContainer>
      </If>
      <InputContainer>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={setSearch}
          value={search}
        />
      </InputContainer>
    </Container>
  );
};
