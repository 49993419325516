import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import { FormattedCostCode } from "@/common/stores/useStartupDataStore";
import Handsontable from "handsontable";
import { hasProperty } from "../objectUtils";

export const clearSpreadSheetUnusedCostCodes = (
  spreadsheetData: Record<string, string>[],
  handsonInstance: Handsontable,
  projectCostCodes: FormattedCostCode[],
) => {
  const prefilledCells = [] as [number, number, string | number][];
  spreadsheetData.forEach((row, index) => {
    if (hasProperty(row, COLUMN_TYPE.CostCode) && !rowIsEmpty(row)) {
      const cellValue = getCellValue(row, COLUMN_TYPE.CostCode);
      if (
        !projectCostCodes.some((costCode) => costCode.formatted === cellValue)
      ) {
        prefilledCells.push([
          index,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.CostCode),
          "",
        ]);
      }
    }
  });
  if (prefilledCells.length) {
    setTableCells(prefilledCells, handsonInstance);
  }
};
