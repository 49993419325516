import { useUserLocations } from "@/common/hooks/useUserLocations";
import { OrgLocationPermissions } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { If } from "../if/If";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { useLocationSelectorControlled } from "./useLocationSelectorControlled";

type Props = {
  name?: string;
  permission?: keyof OrgLocationPermissions;
  placeholder?: string;
  testId?: string;
  disabled?: boolean;
};

export const LocationSelectorControlled: FC<Props> = ({
  name = "locationId",
  permission,
  placeholder,
  testId,
  disabled,
}) => {
  const { locations } = useUserLocations({ permission });
  const intl = useIntl();
  useLocationSelectorControlled({ permission, name });

  return (
    <If isTrue={locations && locations.length !== 1}>
      <SelectControlled
        name={name}
        placeholder={placeholder || intl.$t({ id: "PROJECT_LOCATION" })}
        options={locations || []}
        rules={{ required: true }}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
        disabledTooltip={intl.$t({ id: "ORG_LOCATION_PROJECT_PERMISSION" })}
        testId={testId}
        disabled={disabled}
      />
    </If>
  );
};
