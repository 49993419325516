import { WizardStoreArgs } from "../types/WizardStoreArgs";

export const getWizardArg = <T>(args: WizardStoreArgs, key: string): T => {
  try {
    return args[key] as T;
  } catch (error) {
    console.error(`Failed to get wizard arg: ${key} - ${error}`);
    return null as T;
  }
};
