import { Instructions } from "@/common/components/instructions/Instructions";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useItemizedReleaseProjectBudgetSync } from "@/contractor/pages/home/release/hooks/useItemizedReleaseProjectBudgetSync";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { ReleaseDescription } from "../../../../../../../../../release/components/ReleaseDescription";
import { useInvoiceImportExternalPO } from "../../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceCreateRelease } from "../../../../../../providers/InvoiceCreateReleaseProvider";
import { InvoiceCreateReleaseFormValues } from "../../../InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "../../InvoiceCreateRelease.config";
import { useInvoiceCreateReleaseItemizedView } from "./hooks/useInvoiceCreateReleaseItemizedView";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const InvoiceCreateReleaseItemizedView = () => {
  const { importedTaxCodeId, importedPoIsImmutable, importedDescription } =
    useInvoiceImportExternalPO();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { loading } = useInvoiceCreateRelease();
  const spreadsheetViewColumns = useInvoiceCreateReleaseSpreadsheetConfig();
  const { spreadsheetData } = useColumnMapper();
  const { setGlobalVendorId } = useVendorPrices();
  const { saveDescription, saveInstruction } = useInstruction();
  const { items, updateSubtotal } = useInvoiceCreateReleaseItemizedView();
  useItemizedReleaseProjectBudgetSync();

  const vendorId = watch("vendorId");
  const projectId = watch("projectId");

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  return (
    <SpreadSheetView>
      <SpreadSheetTable
        items={items}
        columns={spreadsheetViewColumns}
        saving={loading}
        height="380px"
        onChanges={updateSubtotal}
        includePermissionCheck={false}
        readOnly={importedPoIsImmutable}
      />
      <Footer>
        <Instructions projectId={projectId} saveInstruction={saveInstruction}>
          <ReleaseDescription
            directlyEditable
            saveDescriptionCustomMethod={saveDescription}
            description={importedDescription || undefined}
          />
        </Instructions>
      </Footer>
    </SpreadSheetView>
  );
};
