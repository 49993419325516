import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { AddressFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const PROJECT_ADDRESS_ID = "project-address";

export type WarehouseOptionsProps = {
  projectAddress?: AddressFieldsFragment;
  trackedWarehousesOnly?: boolean;
  existingWarehouseId?: string | null;
  includeWarehouseInName?: boolean;
};

export const useWarehouseOptions = ({
  projectAddress,
  trackedWarehousesOnly,
  existingWarehouseId,
  includeWarehouseInName,
}: WarehouseOptionsProps) => {
  const intl = useIntl();
  const { warehouses } = useWarehouses();

  const warehouseOptions = useMemo(() => {
    const existingWarehouse = warehouses.find(
      (o) => o.id === existingWarehouseId,
    );

    return [
      ...(projectAddress
        ? [
            {
              id: PROJECT_ADDRESS_ID,
              name: intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" }),
              address: projectAddress,
              trackInventory: false,
            },
          ]
        : []),
      ...(existingWarehouse && existingWarehouse.archivedAt
        ? [
            {
              ...existingWarehouse,
              name: intl.$t(
                { id: "DELETED_WAREHOUSE" },
                { name: existingWarehouse.name },
              ),
            },
          ]
        : []),
      ...warehouses
        .filter((o) => !trackedWarehousesOnly || o.trackInventory)
        .map((o) => ({
          ...o,
          name: includeWarehouseInName
            ? intl.$t({ id: "FORMATTED_WAREHOUSE_NAME" }, { name: o.name })
            : o.name,
        })),
    ];
  }, [
    projectAddress,
    intl,
    existingWarehouseId,
    warehouses,
    trackedWarehousesOnly,
    includeWarehouseInName,
  ]);

  return { warehouseOptions };
};
