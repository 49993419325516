import { FC, Fragment, useEffect, useState } from "react";
import tw from "tailwind-styled-components";

const Tooltip = tw.div`
  absolute bg-blue-500 text-white border border-gray-300
  rounded-lg shadow text-sm p-2 z-70 max-w-64 animate-[fade-in_0.1s_ease-in-out_forwards]
  -translate-y-full -translate-x-1/2 text-center
  before:absolute before:top-[calc(100%-6px)] before:left-1/2 
  before:-translate-x-1/2 before:w-3 before:h-3 before:rotate-45 before:bg-blue-500 before:z-1000
`;

const TOOLTIP_OFFSET_Y = 10;
export const TOOLTIP_EVENT_NAME = "tooltip";

export type TooltipEvent = CustomEvent<{
  content: string;
  event?: MouseEvent;
}>;

type Props = {
  children: React.ReactNode;
};

export const TooltipProvider: FC<Props> = ({ children }) => {
  const [tooltipText, setTooltipText] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [tooltipTarget, setTooltipTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const listener = (evt: TooltipEvent) => {
      setTooltipText(evt.detail.content);
      if (evt.detail.event) {
        const width = (evt.detail.event.target as HTMLElement)?.clientWidth;
        setPosition({
          x:
            evt.detail.event.clientX +
            (width ? width / 2 - evt.detail.event.offsetX : 0),
          y:
            evt.detail.event.clientY -
            evt.detail.event.offsetY -
            TOOLTIP_OFFSET_Y,
        });
        setTooltipTarget(evt.detail.event.target as HTMLElement);
      }
    };
    window.addEventListener(TOOLTIP_EVENT_NAME, listener as EventListener);
    return () => {
      window.removeEventListener(TOOLTIP_EVENT_NAME, listener as EventListener);
    };
  }, []);

  useEffect(() => {
    const listener = () => {
      if (tooltipTarget && !document.body.contains(tooltipTarget)) {
        setTooltipText("");
      }
    };
    window.addEventListener("mousemove", listener);
    return () => {
      window.removeEventListener("mousemove", listener);
    };
  }, [tooltipTarget]);

  return (
    <>
      {children}
      {tooltipText ? (
        <Tooltip
          style={{
            top: position.y,
            left: position.x,
          }}
        >
          {tooltipText.split("\n").map((text, i) => (
            <Fragment key={i}>
              {i > 0 && <br />}
              {text}
            </Fragment>
          ))}
        </Tooltip>
      ) : null}
    </>
  );
};
