import {
  InventoryItemStateSummaryFieldsFragment,
  InventoryItemSummaryFieldsFragment,
} from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";

export const generateWarehouseStateEntry = (
  item: InventoryItemSummaryFieldsFragment,
  state: InventoryItemStateSummaryFieldsFragment,
  extPrice: number,
): WarehouseInventoryItem => {
  return {
    id: item.id,
    warehouse: state.warehouse,
    inStock: state.remainingQuantity ? Number(state.remainingQuantity) : 0,
    totalPrice: extPrice,
    totalQuantity: state.remainingQuantity
      ? Number(state.remainingQuantity)
      : 0,
    scheduledOutflowTotal: 0,
    scheduledInflowTotal: 0,
    orgMaterial: item.orgMaterial,
  };
};
