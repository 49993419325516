import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { Outlet } from "react-router";
import { DeliverySlipVerificationProvider } from "../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipVerificationForm } from "./components/delivery-slip-form/DeliverySlipVerificationForm";

export const DeliverySlipVerification = () => {
  return (
    <NestedStepperProvider>
      <DeliverySlipVerificationForm>
        <DeliverySlipVerificationProvider>
          <Outlet />
        </DeliverySlipVerificationProvider>
      </DeliverySlipVerificationForm>
    </NestedStepperProvider>
  );
};
