import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useInvoiceExtPrices = (item: ReleaseItemFieldsFragment) => {
  const { calcExtPrice } = usePriceCalculation();
  const { invoice } = useInvoiceVerification();
  const { release } = useRelease();
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );

  const extPrice = useMemo(() => {
    if (isLumpSumItem(item)) {
      const hasInvoicedItem = !!invoicedItem;
      if (hasInvoicedItem) {
        return invoicedItem?.quantity;
      }
      const isTheOnlyItemInRelease = release?.items?.length === 1;
      if (isTheOnlyItemInRelease) {
        return invoice?.subtotal?.toString() || "0";
      } else {
        const minReleaseItemVsInvoiceSubtotal = Decimal.min(
          calcExtPrice(item.quantityDecimal, item.unitPrice),
          invoice?.subtotal ?? 0,
        ).toString();
        return minReleaseItemVsInvoiceSubtotal ?? 0;
      }
    } else {
      return calcExtPrice(
        invoicedItem?.quantity ?? 0,
        invoicedItem?.unitPrice ?? item.unitPrice ?? 0,
      ).toString();
    }
  }, [
    item,
    invoicedItem,
    release?.items?.length,
    calcExtPrice,
    invoice?.subtotal,
  ]);

  return { extPrice, invoicedItem };
};
