import { CategoryLike } from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  costCode: Pick<CategoryLike, "id" | "name">;
  zoneId?: string;
  projectId?: string;
};

const CostCodeName = tw.span`
${({ $error }: { $error: boolean }) => ($error ? "text-red-500" : "")}
`;

export const CostCodeRenderer = ({ projectId, costCode, zoneId }: Props) => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { formatCostCode, isNotSupportedCostCode } = useProjectCostCodes({
    selectedCostCodeId: costCode.id,
    zoneId,
  });

  const error = useMemo(
    () =>
      !!projectId &&
      !!isNotSupportedCostCode &&
      costCode.id !== unassignedCostCode.id,
    [costCode.id, isNotSupportedCostCode, projectId, unassignedCostCode.id],
  );

  return (
    <Tooltip
      id="cost-code"
      element={
        <CostCodeName $error={error}>
          {formatCostCode(costCode.id)}
        </CostCodeName>
      }
      hideTooltip={!error}
    >
      <FormattedMessage id="MISSING_COST_CODE_ERROR" />
    </Tooltip>
  );
};
