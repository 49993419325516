import { useSelectKickbackUsers } from "@/common/hooks/useSelectKickbackUsers";
import { getUserName } from "@/common/utils/users/getUserName";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useReceipt } from "../../../providers/ReceiptProvider";

type Input = {
  users: UsersUserFieldsFragment[];
};

export const useReceiptKickbackForm = ({ users }: Input) => {
  const { release } = useRelease();
  const { receipt } = useReceipt();

  useSelectKickbackUsers({
    createdById: receipt?.createdBy?.id,
    users,
    hasRelease: !!receipt?.release,
  });

  const kickbackUserOptions = useMemo(
    () =>
      users.map((user) => {
        const isOrderWatcher = release?.watchers.some(
          (releaseWatcher) => releaseWatcher.id === user.id,
        );
        return {
          id: user.id,
          label: getUserName(user),
          isOrderWatcher,
        };
      }),
    [users, release?.watchers],
  );

  return {
    kickbackUserOptions,
  };
};
