import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { FC, useEffect } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useSearchParams } from "react-router";
import { useShallow } from "zustand/react/shallow";

export type InventoryAdjustmentFormValues = {
  warehouseId: string;
  date: number | null;
};

type InventoryAdjustmentForm = UseFormReturn<
  InventoryAdjustmentFormValues,
  unknown
>;

const inventoryAdjustmentFormDefaultValues: InventoryAdjustmentFormValues = {
  warehouseId: "",
  date: null,
};

export const InventoryAdjustmentForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { warehouses } = useStartupDataStore(
    useShallow((state) => ({
      warehouses: state.warehouses,
    })),
  );
  const { setCurrentWarehouseId, reset } = useInventoryItems();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    reset();
  }, [reset]);

  const inventoryAdjustmentForm: InventoryAdjustmentForm =
    useForm<InventoryAdjustmentFormValues>({
      defaultValues: {
        ...inventoryAdjustmentFormDefaultValues,
        warehouseId: "",
        date: new Date().getTime(),
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const { setValue, watch } = inventoryAdjustmentForm;
  const warehouseId = watch("warehouseId");

  useEffect(() => {
    if (warehouses.length === 1) {
      setValue("warehouseId", warehouses[0].id);
    }
  }, [warehouses, setValue]);

  useEffect(() => {
    if (warehouseId) {
      setCurrentWarehouseId(warehouseId);
    }
  }, [warehouseId, setCurrentWarehouseId]);

  useEffect(() => {
    const warehouseId = searchParams.get("warehouseId");
    if (warehouseId) {
      setValue("warehouseId", warehouseId);
    }
  }, [searchParams, setValue]);

  return <FormProvider {...inventoryAdjustmentForm}>{children}</FormProvider>;
};
