import { LinkLike } from "@/common/components/link-like/LinkLike";
import { LocationSelector } from "@/common/components/location-selector/LocationSelector";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { AssetContext } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceCreation } from "../../../providers/InvoiceCreationProvider";
import { useInvoiceForm } from "./hooks/useInvoiceForm";
import { InvoiceFormProps } from "./types/InvoiceFormProps";
import { InvoiceFormType } from "./types/InvoiceFormType";

const Label = tw.div`flex flex-row justify-between font-medium text-base mb-2`;
const Container = tw.div`flex flex-col gap-4`;

const InvoiceFormWithProvider: FC<InvoiceFormProps> = ({
  onClose,
  type,
  releaseId,
}) => {
  const intl = useIntl();

  const { assets } = useUploadAssets();
  const { locationId, setLocationId } = useInvoiceCreation();
  const { save, saveCorrection } = useInvoiceForm({ releaseId, onClose });

  return (
    <OverlayPanel
      title={intl.$t({ id: "ADD_INVOICE" })}
      onSave={type === InvoiceFormType.ADD ? save : saveCorrection}
      onCancel={onClose}
      saveLabel={intl.$t({ id: "PROCEED" })}
      disableSave={!assets?.length || !locationId}
    >
      <Container>
        <Label>
          <FormattedMessage
            id={
              type === InvoiceFormType.ADD
                ? "NEW_INVOICE_DESCRIPTION"
                : "INVOICE_CORRECTION_DESCRIPTION"
            }
          />
          <Tooltip
            id="new-invoice-description"
            element={
              <LinkLike>
                <InfoOutlined />
              </LinkLike>
            }
          >
            <FormattedMessage id="NEW_INVOICE_DESCRIPTION_TOOLTIP" />
          </Tooltip>
        </Label>
        <LocationSelector
          placeholder={intl.$t({ id: "INVOICE_LOCATION" })}
          locationId={locationId}
          setLocationId={setLocationId}
          permission="createInvoice"
        />
        <UploadAsset
          accept={{
            [PDF_MIME_TYPE]: PDF_EXTENSIONS,
          }}
          multiple={type === InvoiceFormType.ADD}
        >
          <SupportedFormats>
            <FormattedMessage id="INVOICE_SUPPORTED_FORMATS" />
          </SupportedFormats>
        </UploadAsset>
      </Container>
    </OverlayPanel>
  );
};

export const InvoiceForm: FC<InvoiceFormProps> = ({
  onClose,
  type,
  releaseId,
}) => {
  return (
    <UploadAssetProvider context={AssetContext.Invoice}>
      <InvoiceFormWithProvider
        onClose={onClose}
        type={type}
        releaseId={releaseId}
      />
    </UploadAssetProvider>
  );
};
