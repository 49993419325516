import { BaseButton } from "@/common/components/button/BaseButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { getUserName } from "@/common/utils/users/getUserName";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { Popover } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { FaRegUser } from "react-icons/fa6";
import tw from "tailwind-styled-components";
import { Select } from "../select/components/single/Select";
import { WatcherTag } from "./WatcherTag";

const Container = tw.div`
  relative gap-1 h-fit
  ${({ $verticalItems }: { $verticalItems: boolean }) => ($verticalItems ? "flex flex-wrap justify-start self-start" : "grid grid-flow-col self-start")}
`;
const More = tw.span`
  grid place-items-center bg-blue-500 text-white text-2xs p-1 w-6 h-6 rounded-3xl group-hover:hidden
`;
const MoreTagsContainer = tw.div`flex flex-wrap items-center gap-1`;
const MdOutlineAddToPhotosStyled = tw(FaRegUser)`text-xs`;
const IconButton = tw(BaseButton)`
  text-gray-600 border-none w-6 h-6 px-1 py-1 min-h-6 bg-blue-450 hover:bg-blue-500 hover:text-white
`;
const IconContainer = tw.div`w-6`;

type Props = {
  users: UsersUserFieldsFragment[];
  existingWatchers: string[];
  moreTagCount: number;
  onRemove?: (is: string) => void;
  onAdd?: (value: string | null) => void;
  loading?: boolean;
  placeholder?: string;
  verticalItems?: boolean;
  readonly?: boolean;
};

export const WatcherTagPicker = ({
  users,
  existingWatchers,
  moreTagCount,
  onRemove,
  loading = false,
  onAdd,
  placeholder,
  verticalItems = false,
  readonly = false,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const remainingUsers = useMemo(
    () => users.filter((user) => !existingWatchers.includes(user.id)),
    [users, existingWatchers],
  );

  const handleChange = useCallback(
    (value: string | null) => {
      onAdd?.(value);
      setAnchorEl(null);
    },
    [onAdd],
  );

  return (
    <Container $verticalItems={verticalItems}>
      {existingWatchers.slice(0, moreTagCount).map((watcher) => {
        const user = users.find((user) => user.id === watcher);
        if (!user) {
          return null;
        }
        return (
          <WatcherTag
            key={watcher}
            user={user}
            onRemove={onRemove}
            readonly={readonly}
          />
        );
      })}
      {existingWatchers.length > moreTagCount && (
        <Tooltip
          id="watchers-tooltip"
          element={
            <More>+{(existingWatchers?.length || 0) - moreTagCount}</More>
          }
          position="bottom"
        >
          <MoreTagsContainer>
            {existingWatchers.slice(moreTagCount).map((watcher) => {
              const user = users.find((user) => user.id === watcher);
              if (!user) {
                return null;
              }
              return (
                <WatcherTag
                  key={watcher}
                  user={user}
                  onRemove={onRemove}
                  readonly={readonly}
                />
              );
            })}
          </MoreTagsContainer>
        </Tooltip>
      )}
      <IconContainer>
        <If isTrue={!loading && remainingUsers.length > 0 && !readonly}>
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MdOutlineAddToPhotosStyled />
          </IconButton>
        </If>
        <Loader loading={loading} small />
      </IconContainer>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          " .MuiPaper-root": {
            borderRadius: "0.5rem",
            boxShadow: "none",
            padding: "0.5rem",
          },
        }}
      >
        <Select
          options={remainingUsers}
          getLabel={(o) => getUserName(o)}
          getValue={(o) => o.id}
          className="w-full min-w-45"
          placeholder={placeholder}
          onChange={(value) => {
            handleChange(value);
          }}
        />
      </Popover>
    </Container>
  );
};
