import { PDF_FONT } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useSpendingReportItems } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import {
  BUDGET_VIEW_TYPE,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import jsPDF from "jspdf";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { budgetReportHeader } from "./budgetReportHeader";
import { materialsBudgetReport } from "./materialsBudgetReport";
import { vendorsBudgetReport } from "./vendorsBudgetReport";

export const usePrintView = () => {
  const intl = useIntl();
  const { project } = useProject();
  const { zones } = useSpendingReportItems();
  const { viewType, materialsReport, zonesReport, vendorsReport } =
    useSpendingReport();
  const { materialsMap } = useProjectMaps(project);
  const { formatCostCode } = useProjectCostCodes();
  const { currencySymbol } = useUser();

  const selectedReport = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.VENDORS:
        return vendorsReport;
      case BUDGET_VIEW_TYPE.MATERIALS:
        return materialsReport;
      case BUDGET_VIEW_TYPE.ZONES:
        return zonesReport;
      default:
        return zonesReport;
    }
  }, [viewType, materialsReport, zonesReport, vendorsReport]);

  const savePDF = () => {
    if (project) {
      const doc = new jsPDF("landscape").setFont(PDF_FONT);
      budgetReportHeader(doc, intl, currencySymbol, selectedReport);
      switch (viewType) {
        case BUDGET_VIEW_TYPE.VENDORS:
          vendorsBudgetReport(
            doc,
            intl,
            zones,
            10,
            formatCostCode,
            currencySymbol,
          );
          break;
        case BUDGET_VIEW_TYPE.MATERIALS:
        case BUDGET_VIEW_TYPE.ZONES:
          materialsBudgetReport(
            doc,
            intl,
            zones,
            10,
            materialsMap,
            formatCostCode,
            currencySymbol,
          );
          break;
      }
      doc.save(
        intl.$t({ id: "SPENDING_REPORT_PDF_NAME" }, { name: project.name }),
      );
    }
  };

  return {
    savePDF,
  };
};
