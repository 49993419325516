import { InventoryTransactionFieldsFragment } from "@/generated/graphql";
import { useTransactionHeaderOriginFrom } from "../../../hooks/useTransactionHeaderOriginFrom";
import { TransactionHeaderItemOrigin } from "../../../TransactionHeaderItemOrigin";

type Props = {
  item: InventoryTransactionFieldsFragment;
};

export const TransactionHeaderOriginFrom = ({ item }: Props) => {
  const from = useTransactionHeaderOriginFrom({ item });
  return <TransactionHeaderItemOrigin item={from} />;
};
