import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import {
  ExtraOption,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useReceipt } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/ReceiptProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Handsontable from "handsontable";
import {
  CellChange,
  ChangeSource,
  ColumnDataGetterSetterFunction,
} from "handsontable/common";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useVendorOptions } from "../../vendor-picker/useVendorOptions";
import {
  DEFAULT_SOURCES,
  DEFAULT_SOURCES_WITH_CUSTOM,
} from "../constants/tableConstants";
import { getCellValueWithPrefill } from "../utils/getCellValueWithPrefill";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "../utils/getPhysicalRowIndex";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { sanitizeMaterialName } from "../utils/sanitizeMaterialName";
import { setPrefillCell } from "../utils/setPrefillCell";
import { useTableHelpers } from "./useTableHelpers";

const isPartOfPaste = (
  changes: CellChange[] | null,
  row: number,
  column: COLUMN_TYPE,
) => changes?.find((c) => c[0] === row && c[1] === column);

const getPriceColumnType = (config: SpreadSheetConfig[]) => {
  return config.some((c) => c.columnType === COLUMN_TYPE.PrefilledPrice)
    ? COLUMN_TYPE.PrefilledPrice
    : COLUMN_TYPE.UnitPrice;
};

const getQuantityColumnType = (config: SpreadSheetConfig[]) => {
  return config.some((c) => c.columnType === COLUMN_TYPE.PositiveQuantity)
    ? COLUMN_TYPE.PositiveQuantity
    : COLUMN_TYPE.Quantity;
};

const checkColumnType = (
  columns: COLUMN_TYPE[],
  column: string | number | ColumnDataGetterSetterFunction,
) => {
  return columns.some((c) => c === column);
};

export const usePrefillHelpers = () => {
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();
  const { receipt } = useReceipt();
  const { findMaterialByName, getFormattedMaterialName, getPrefilledValue } =
    useTableHelpers();
  const { config } = useColumnMapper();
  const { formatCostCode, projectCostCodes } = useProjectCostCodes();
  const { findCostCodeIdByLocationId, vendorOptions } = useVendorOptions();
  const { projects } = useProjectListOptions();
  const { calcUnitPrice, calcExtPrice } = usePriceCalculation();
  const formContext = useFormContext<{ vendorId: string; projectId: string }>();
  const { getValues } = formContext || {};

  const prefillMaterialFields = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hot || !DEFAULT_SOURCES.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (!checkColumnType([COLUMN_TYPE.Material], column)) {
          return;
        }

        const codePartOfPaste = isPartOfPaste(
          changes,
          row,
          COLUMN_TYPE.CostCode,
        );
        const taxPartOfPaste = isPartOfPaste(changes, row, COLUMN_TYPE.Taxable);
        const uomPartOfPaste = isPartOfPaste(changes, row, COLUMN_TYPE.UOM);
        const manufacturerPartOfPaste = isPartOfPaste(
          changes,
          row,
          COLUMN_TYPE.Manufacturer,
        );

        const material = findMaterialByName(value);
        if (material) {
          const formattedMaterialName = getFormattedMaterialName(material);
          setPrefillCell(hot, row, COLUMN_TYPE.Material, formattedMaterialName);

          const uom = material.defaultEstimateUom?.pluralDescription;
          if (uom && !uomPartOfPaste) {
            setPrefillCell(hot, row, COLUMN_TYPE.UOM, uom);
          }

          const manufacturer = material.manufacturer?.name;
          if (manufacturer && !manufacturerPartOfPaste) {
            setPrefillCell(hot, row, COLUMN_TYPE.Manufacturer, manufacturer);
          }
        } else if (value) {
          setPrefillCell(
            hot,
            row,
            COLUMN_TYPE.Material,
            sanitizeMaterialName(value),
          );
        }

        const vendorCostCode = projectCostCodes.find(
          (costCode) =>
            findCostCodeIdByLocationId(
              release?.sellerOrgLocation?.id ||
                buyout?.sellerOrgLocation?.id ||
                getValues?.("vendorId") ||
                "",
            ) === costCode.id,
        );

        const selectedCostCode =
          release?.costCode ??
          receipt?.costCode ??
          vendorCostCode ??
          material?.costCode;

        const costCodeIsInProject = projectCostCodes.some(
          (costCode) => costCode.id === selectedCostCode?.id,
        );

        if (selectedCostCode && !codePartOfPaste && costCodeIsInProject) {
          const formattedCostCode = formatCostCode(selectedCostCode);
          setPrefillCell(hot, row, COLUMN_TYPE.CostCode, formattedCostCode);
        }
        if (!taxPartOfPaste) {
          const taxableValue =
            release?.project?.taxExempt ||
            release?.preferredVendor?.taxExempt ||
            vendorOptions.find(
              (option) => option.value === getValues?.("vendorId"),
            )?.taxExempt ||
            projects.find((project) => project.id === getValues?.("projectId"))
              ?.taxExempt
              ? "false"
              : "true";

          setPrefillCell(hot, row, COLUMN_TYPE.Taxable, taxableValue);
        }
      });
    },
    [
      findMaterialByName,
      release?.costCode,
      release?.sellerOrgLocation?.id,
      release?.project?.taxExempt,
      release?.preferredVendor?.taxExempt,
      receipt?.costCode,
      projectCostCodes,
      getFormattedMaterialName,
      findCostCodeIdByLocationId,
      buyout?.sellerOrgLocation?.id,
      getValues,
      formatCostCode,
      vendorOptions,
      projects,
    ],
  );

  const prefillPrices = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hot || !DEFAULT_SOURCES_WITH_CUSTOM.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, oldValue] = change;

        const pricePartOfPaste = isPartOfPaste(
          changes,
          row,
          COLUMN_TYPE.PrefilledPrice,
        );
        if (pricePartOfPaste) {
          return;
        }

        if (
          !checkColumnType(
            [
              COLUMN_TYPE.Material,
              COLUMN_TYPE.Vendor,
              COLUMN_TYPE.Manufacturer,
              COLUMN_TYPE.UOM,
            ],
            column,
          )
        ) {
          return;
        }

        const price = getPrefilledValue({
          material: getCellValueWithPrefill(hot, row, COLUMN_TYPE.Material),
          vendor: getCellValueWithPrefill(hot, row, COLUMN_TYPE.Vendor),
          manufacturer: getCellValueWithPrefill(
            hot,
            row,
            COLUMN_TYPE.Manufacturer,
          ),
          uom: getCellValueWithPrefill(hot, row, COLUMN_TYPE.UOM),
        });
        const oldPrice = getPrefilledValue({
          material:
            column === COLUMN_TYPE.Material
              ? oldValue
              : getCellValueWithPrefill(hot, row, COLUMN_TYPE.Material),
          vendor:
            column === COLUMN_TYPE.Vendor
              ? oldValue
              : getCellValueWithPrefill(hot, row, COLUMN_TYPE.Vendor),
          manufacturer:
            column === COLUMN_TYPE.Manufacturer
              ? oldValue
              : getCellValueWithPrefill(hot, row, COLUMN_TYPE.Manufacturer),
          uom:
            column === COLUMN_TYPE.UOM
              ? oldValue
              : getCellValueWithPrefill(hot, row, COLUMN_TYPE.UOM),
        });
        const currentPrice = getCellValueWithPrefill(
          hot,
          row,
          COLUMN_TYPE.PrefilledPrice,
        );
        const isEmpty = !currentPrice || currentPrice === "0";

        const prefilledValue =
          String(
            getCellValueWithPrefill(hot, row, COLUMN_TYPE.PrefilledPrice),
          ) === String(oldPrice.value);
        if (isEmpty || prefilledValue) {
          setPrefillCell(hot, row, COLUMN_TYPE.PrefilledPrice, price.value);
        }
      });
    },
    [getPrefilledValue],
  );

  const prefillExtraOptions = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hot || !DEFAULT_SOURCES_WITH_CUSTOM.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (!checkColumnType([COLUMN_TYPE.Material], column)) {
          return;
        }

        const extraDetails = value?.match(/ ⟮(.*)⟯/);
        const stripedName = value?.replace(/ ⟮(.*)⟯/, "");
        const material = findMaterialByName(stripedName);
        if (material) {
          if (extraDetails) {
            setPrefillCell(
              hot,
              row,
              COLUMN_TYPE.Material,
              getFormattedMaterialName(material),
            );
          }

          const extraOptions = hot.getCellMeta(
            getPhysicalRowIndex(hot, row),
            getPhysicalColumnIndex(hot, COLUMN_TYPE.Material),
          ).extraOptions as ExtraOption[];

          if (extraOptions && extraDetails) {
            const currentOption = extraOptions.find(
              (option) =>
                option.name === getFormattedMaterialName(material) &&
                option.content.outerHTML.includes(extraDetails[1]),
            );
            currentOption?.prefilling?.forEach((option) => {
              if (config.some((c) => c.columnType === option[0] && !c.hidden)) {
                setPrefillCell(
                  hot,
                  row,
                  option[0],
                  option[1] as string | number,
                );
              }
            });
          }
        } else if (stripedName?.length > 0) {
          setPrefillCell(
            hot,
            row,
            COLUMN_TYPE.Material,
            sanitizeMaterialName(stripedName),
          );
        }
      });
    },
    [findMaterialByName, getFormattedMaterialName, config],
  );

  const updateExtPriceDependence = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (
        !hot ||
        !DEFAULT_SOURCES_WITH_CUSTOM.includes(source) ||
        config.every((c) => c.columnType !== COLUMN_TYPE.ExtPrice)
      ) {
        return;
      }

      const priceColumnType = getPriceColumnType(config);
      const quantityColumnType = getQuantityColumnType(config);

      changes?.forEach((change) => {
        const [row, column] = change;

        if (rowIsEmpty(hot.getData()[row])) {
          return;
        }

        if (
          !checkColumnType(
            [COLUMN_TYPE.Quantity, COLUMN_TYPE.UnitPrice, COLUMN_TYPE.ExtPrice],
            column,
          )
        ) {
          return;
        }

        const quantity = getCellValueWithPrefill(hot, row, quantityColumnType);
        const unitPrice = getCellValueWithPrefill(hot, row, priceColumnType);
        const extPrice = getCellValueWithPrefill(
          hot,
          row,
          COLUMN_TYPE.ExtPrice,
        );

        const uom = getCellValueWithPrefill(hot, row, COLUMN_TYPE.UOM);

        if (
          (quantity === null || quantity === undefined) &&
          !isLumpSumUomText(uom)
        ) {
          return;
        }

        if (column === priceColumnType || column === quantityColumnType) {
          const value = calcExtPrice(unitPrice, quantity);
          const absValue = Math.abs(!Number.isNaN(value) ? value : 0);
          setPrefillCell(hot, row, COLUMN_TYPE.ExtPrice, absValue);
        }

        if (column === COLUMN_TYPE.ExtPrice) {
          const value = calcUnitPrice(quantity, extPrice);
          if (isLumpSumUomText(uom)) {
            setPrefillCell(hot, row, quantityColumnType, extPrice);
          } else {
            const absValue = Math.abs(!Number.isNaN(value) ? value : 0);
            setPrefillCell(hot, row, priceColumnType, absValue);
          }
        }
      });
    },
    [calcExtPrice, calcUnitPrice, config],
  );

  const prefillForLumpSum = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hot || !DEFAULT_SOURCES_WITH_CUSTOM.includes(source)) {
        return;
      }

      const priceColumnType = getPriceColumnType(config);

      changes?.forEach((change) => {
        const [row, column, , value] = change;
        const uom = getCellValueWithPrefill(hot, row, COLUMN_TYPE.UOM);
        const quantity = getCellValueWithPrefill(
          hot,
          row,
          COLUMN_TYPE.Quantity,
        );
        const unitPrice = getCellValueWithPrefill(hot, row, priceColumnType);
        const extPrice = calcExtPrice(unitPrice, quantity);

        const cellMeta = hot.getCellMeta(
          row,
          getPhysicalColumnIndex(hot, column as COLUMN_TYPE),
        );

        if (isLumpSumUomText(uom) && !cellMeta.disabledForLumpSum) {
          if (column === priceColumnType) {
            setPrefillCell(
              hot,
              row,
              COLUMN_TYPE.Quantity,
              !Number.isNaN(value) ? value : "",
            );
            setPrefillCell(hot, row, priceColumnType, "1");
          }
          if (column === COLUMN_TYPE.UOM) {
            setPrefillCell(hot, row, COLUMN_TYPE.Quantity, extPrice);
            setPrefillCell(hot, row, priceColumnType, "1");
          }
        }
      });
    },
    [calcExtPrice, config],
  );

  const prefillTaxableValues = useCallback(
    (
      hot: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hot || !DEFAULT_SOURCES.includes(source)) {
        return;
      }
      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (!checkColumnType([COLUMN_TYPE.Taxable], column)) {
          return;
        }

        const taxableValue = value ? "true" : "false";
        setPrefillCell(hot, row, COLUMN_TYPE.Taxable, taxableValue);
      });
    },
    [],
  );

  return {
    prefillMaterialFields,
    prefillPrices,
    prefillExtraOptions,
    updateExtPriceDependence,
    prefillForLumpSum,
    prefillTaxableValues,
  };
};
