import { Dialog } from "@/common/components/dialog/Dialog";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "./hooks/useAddInventoryItems";
import { AddToInventoryList } from "./inventory-items-list/AddToInventoryList";
import { useAddInventoryItemsConfiguration } from "./inventory-items-list/AddToInventoryList.configuration";

interface AddInventoryItemsDialogProps {
  setVisible: (value: boolean) => void;
  visible: boolean;
}

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

export const AddInventoryItemsDialog = ({
  setVisible,
  visible,
}: AddInventoryItemsDialogProps) => {
  const intl = useIntl();
  const configuration = useAddInventoryItemsConfiguration();
  const { selectedItems, addSelectedItems, resetFilters } =
    useAddInventoryItems();

  useEffect(() => {
    if (visible) {
      resetFilters();
    }
  }, [visible, resetFilters]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleConfirm = useCallback(() => {
    addSelectedItems();
    setVisible(false);
  }, [setVisible, addSelectedItems]);

  return (
    <Dialog
      confirmButtonText={intl.$t(
        {
          id: "ADD_ITEMS_WITH_COUNT",
        },
        {
          count: selectedItems.length,
        },
      )}
      handleConfirm={handleConfirm}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      handleCancel={handleCancel}
      maxWidth={false}
      show={visible}
      content={
        <Container>
          <AddToInventoryList columns={configuration} />
        </Container>
      }
    />
  );
};
