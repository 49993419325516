import Handsontable from "handsontable";

export const clearTable = (hotInstance: Handsontable | undefined | null) => {
  if (!hotInstance) {
    return;
  }
  const totalRows = hotInstance.countRows();
  const totalCols = hotInstance.countCols();

  hotInstance.batch((): void => {
    for (let row = 0; row < totalRows; row++) {
      for (let col = 0; col < totalCols; col++) {
        hotInstance.setDataAtCell(row, col, "");
      }
    }
  });
};
