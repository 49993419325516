import {
  isMasterSku,
  isOrgCatalogSku,
} from "@/common/components/material/utils";
import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  CostCodeFieldsFragment,
  Manufacturer,
  ManufacturerFieldsFragment,
  ManufacturersDocument,
  OrgCatalogSku,
  OrgMaterialFieldsFragment,
  OrgMaterialsSummaryDocument,
  Sku,
  StartupDataDocument,
  UomFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type OrgMaterialFieldsFragmentWithManufacturer = OrgMaterialFieldsFragment & {
  manufacturer?: Manufacturer;
};

type MaterialsState = {
  materials: OrgMaterialFieldsFragmentWithManufacturer[];
  materialsMap: Map<string, OrgMaterialFieldsFragmentWithManufacturer>;
  loading: boolean;
  error: Error | null;
  setMaterials: (
    materials: OrgMaterialFieldsFragmentWithManufacturer[],
  ) => void;
  updateMaterialsMap: (
    materials: OrgMaterialFieldsFragmentWithManufacturer[],
  ) => void;
  fetchMaterials: () => Promise<void>;
};

const hydrateMaterials = (
  materials: OrgMaterialFieldsFragmentWithManufacturer[],
  uoms: UomFieldsFragment[],
  manufacturers: ManufacturerFieldsFragment[],
  costCodes: CostCodeFieldsFragment[],
) => {
  const uomMap = new Map<string, UomFieldsFragment>();
  uoms.forEach((uom) => uomMap.set(uom.id, uom));
  const manufacturerMap = new Map<string, ManufacturerFieldsFragment>();
  manufacturers.forEach((manuf) => manufacturerMap.set(manuf.id, manuf));
  const costCodeMap = new Map<string, CostCodeFieldsFragment>();
  costCodes.forEach((code) => costCodeMap.set(code.id, code));

  return materials.map((material) => {
    return {
      ...material,
      defaultEstimateUom: uomMap.get(material.defaultEstimateUom.id),
      costCode: costCodeMap.get(material.costCode?.id ?? ""),
      manufacturer: isMasterSku(material.material)
        ? manufacturerMap.get((material.material as Sku).manufacturer.id)
        : isOrgCatalogSku(material.material)
          ? manufacturerMap.get(
              (material.material as OrgCatalogSku).defaultManufacturer?.id ??
                "",
            )
          : undefined,
    } as OrgMaterialFieldsFragmentWithManufacturer;
  });
};

const createMaterialsMap = (
  materials: OrgMaterialFieldsFragmentWithManufacturer[] | undefined,
): Map<string, OrgMaterialFieldsFragmentWithManufacturer> => {
  return (
    materials?.reduce((acc, material) => {
      return acc.set(material.id, material);
    }, new Map()) ?? new Map()
  );
};

export const useMaterialsStore = create<MaterialsState>()(
  devtools((set, get) => ({
    materials: [],
    materialsMap: new Map(),
    loading: false,
    error: null,
    setMaterials: (materials: OrgMaterialFieldsFragmentWithManufacturer[]) =>
      set(() => ({ materials })),
    updateMaterialsMap(materials) {
      set({ materialsMap: createMaterialsMap(materials) });
    },
    fetchMaterials: async () => {
      if (get().materials.length > 0 || get().loading) {
        return;
      }

      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, error: null });
      try {
        await Promise.all([
          client.query({ query: StartupDataDocument }),
          client.query({
            query: OrgMaterialsSummaryDocument,
            fetchPolicy: "cache-first",
          }),
          client.query({
            query: ManufacturersDocument,
            variables: {
              searchPrefix: "",
            },
          }),
        ]).then(([startupResult, materialsResult, manufacturersResult]) => {
          const hydratedMaterials = hydrateMaterials(
            materialsResult.data.viewer.org.materials,
            startupResult.data.viewer.org.uoms,
            manufacturersResult.data.manufacturers,
            startupResult.data.viewer.org.costCodes,
          );
          set({
            materials: hydratedMaterials,
            materialsMap: createMaterialsMap(hydratedMaterials),
            loading: false,
          });
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
  })),
);
