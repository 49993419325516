import { cloneDeep } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type PrefillChange = [number, number, string | number | null];

type State = {
  prefillChanges: PrefillChange[];
  applyPrefillChanges: (data: PrefillChange[]) => void;
  getStateWithPrefillChanges: (
    currentState: Record<string, string>[],
  ) => Record<string, string>[];
  resetPrefillChanges: () => void;
};

export const useSpreadSheetStore = create<State>()(
  devtools((set, get) => ({
    prefillChanges: [],
    applyPrefillChanges: (data: PrefillChange[]) => {
      set({
        prefillChanges: [...get().prefillChanges, ...data],
      });
    },
    getStateWithPrefillChanges: (currentState: Record<string, string>[]) => {
      const prefillChanges = get().prefillChanges;
      const newState = cloneDeep(currentState);
      prefillChanges.forEach((change) => {
        const [row, column, newValue] = change;
        newState[row][column] = newValue as string;
      });
      return newState;
    },
    resetPrefillChanges: () => {
      set({ prefillChanges: [] });
    },
  })),
);
