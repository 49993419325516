import { QueryInventoryItemsFilter } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  filter: QueryInventoryItemsFilter | undefined;
  setFilter: (step: QueryInventoryItemsFilter) => void;
};

export const useInventoryItemsStore = create<State>()(
  devtools((set) => ({
    filter: {},
    setFilter: (filter: QueryInventoryItemsFilter) =>
      set(() => ({
        filter,
      })),
  })),
);
