import { ReleaseStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { RfqsReleasesProps } from "./RfqsReleases";

export const useRfqsReleases = ({ rfq }: RfqsReleasesProps) => {
  const allReleases = useMemo(
    () =>
      rfq.quotes
        .flatMap((quote) => quote.releases)
        .filter(
          (release) =>
            !release.deletedAt && release.status !== ReleaseStatus.Canceled,
        )
        .toSorted((a, b) => {
          if (a.status === ReleaseStatus.Draft) {
            return 1;
          }
          if (b.status === ReleaseStatus.Draft) {
            return -1;
          }
          return 0;
        }),
    [rfq],
  );

  const firstRelease = useMemo(() => allReleases[0], [allReleases]);

  const restReleases = useMemo(
    () => (allReleases.length ? allReleases.slice(1) : []),
    [allReleases],
  );

  return { allReleases, firstRelease, restReleases };
};
