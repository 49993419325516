import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { Fragment } from "react";
import { useShallow } from "zustand/react/shallow";
import { If } from "../../../if/If";
import { CategoryType } from "../../enums/CategoryType";
import {
  CategoryContainer,
  SubCategoryCard,
  SubCategoryCardContent,
} from "../../GridTable.styles";
import {
  getCountKey,
  useCountGeneration,
} from "../../hooks/useCountGeneration";
import { useGridTableContext } from "../../providers/GridTableProvider";
import { GridTableCategoryProps } from "../../types/GridTableCategoryProps";
import { RenderType } from "../../types/RenderType";
import { generateIndex } from "../../utils/generateIndex";
import { GridItem } from "../grid-item/GridItem";
import { GridTableItem } from "../grid-table-item/GridTableItem";
import { GridTableVirtualizationContainer } from "../grid-virtualization/GridTableVirtualizationContainer";
import { GridTableCategoryHeader } from "./GridTableCategoryHeader";

export const GRID_ERROR_ROW_KEY = "grid-error";

export const GridTableCategory = <T extends Identity, TAdditional>({
  index,
  category,
  configuration,
  readonlyFn,
  expandedItems = () => [],
  expandedDetailItems = () => [],
  showSubdetailsIfNoDetailItems,
  hideFirstDetail = false,
  error = false,
  expandedItemComponent,
  preRowItemComponent,
  setEditing,
}: GridTableCategoryProps<T, TAdditional>) => {
  const {
    categoryType,
    countMap,
    readonly,
    skipDetailRowsCounterKeyAssignment,
  } = useGridTableContext(
    useShallow((state) => ({
      categoryType: state.table.categoryType,
      countMap: state.countMap,
      readonly: state.table.readonly,
      skipDetailRowsCounterKeyAssignment:
        state.table.skipDetailRowsCounterKeyAssignment,
    })),
  );
  const { generateCountAndItemKey } = useCountGeneration(countMap);

  if (categoryType !== undefined && categoryType !== CategoryType.ONE_LEVEL) {
    return (
      <GridTableVirtualizationContainer itemKey={getCountKey(index)}>
        <GridTableCategoryHeader
          category={category}
          configuration={configuration}
          index={index}
          key={index}
        />
        <CategoryContainer $opened={(category as CategoryState<T>).isOpened}>
          {(category as CategoryState<T>).items.map(
            (subCategory, itemIndex) => {
              if (categoryType === CategoryType.THREE_LEVELS) {
                const mappedSubCategory =
                  subCategory as unknown as CategoryState<T>;
                return (
                  <Fragment key={itemIndex}>
                    <SubCategoryCard
                      className={configuration.classNames?.subCategory}
                      data-attr="zone"
                      onClick={() =>
                        configuration.toggle?.subCategory?.(
                          mappedSubCategory.id,
                          category.id,
                        )
                      }
                    >
                      <SubCategoryCardContent>
                        <GridItem
                          className="text-xs md:text-base"
                          columns={configuration.columns}
                          readonly={readonly}
                          category={mappedSubCategory}
                          index={itemIndex}
                          renderType={RenderType.Subgroup}
                          error={error}
                          setEditing={setEditing}
                        />
                      </SubCategoryCardContent>
                    </SubCategoryCard>
                    <CategoryContainer $opened={mappedSubCategory.isOpened}>
                      {mappedSubCategory.items.map((i2, itemIndex2) => {
                        return (
                          <Fragment
                            key={generateIndex([index, itemIndex, itemIndex2])}
                          >
                            <GridTableItem<T, TAdditional>
                              key={itemIndex2}
                              item={i2}
                              index={itemIndex2}
                              configuration={configuration}
                              readonly={readonly}
                              readonlyFn={readonlyFn}
                              inEditModeFn={configuration.inEditModeFn}
                              itemFn={configuration.classNames?.itemFn}
                              onClick={configuration.toggle?.item}
                              hasCardItems={configuration.hasCardItems}
                              className={`${configuration.classNames?.item}`}
                              contentClassName={
                                configuration.classNames?.itemContent
                              }
                              category={mappedSubCategory}
                              error={error}
                              hasRowError={configuration.hasRowError}
                              expandedItemComponent={expandedItemComponent}
                              preRowItemComponent={preRowItemComponent}
                              setEditing={setEditing}
                              {...generateCountAndItemKey({
                                indices: [index, itemIndex, itemIndex2],
                              })}
                            />
                            {expandedItems(
                              i2,
                              category as CategoryState<T>,
                              mappedSubCategory,
                            ).map((detailItem, detailIndex) => {
                              if (detailIndex === 0 && hideFirstDetail) {
                                return null;
                              }
                              return (
                                <Fragment
                                  key={generateIndex([
                                    index,
                                    itemIndex,
                                    itemIndex2,
                                    detailIndex,
                                  ])}
                                >
                                  <GridTableItem<T, TAdditional>
                                    key={detailIndex}
                                    item={detailItem}
                                    index={detailIndex}
                                    configuration={configuration}
                                    readonly={readonly}
                                    readonlyFn={readonlyFn}
                                    inEditModeFn={configuration.inEditModeFn}
                                    itemFn={configuration.classNames?.itemFn}
                                    renderType={RenderType.Details}
                                    hasCardItems={configuration.hasCardItems}
                                    className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
                                    contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
                                    error={error}
                                    hasRowError={configuration.hasRowError}
                                    category={category as CategoryState<T>}
                                    expandedItemComponent={
                                      expandedItemComponent
                                    }
                                    preRowItemComponent={preRowItemComponent}
                                    {...generateCountAndItemKey(
                                      {
                                        indices: [
                                          index,
                                          itemIndex,
                                          itemIndex2,
                                          detailIndex,
                                        ],
                                      },
                                      {
                                        skip: skipDetailRowsCounterKeyAssignment,
                                      },
                                    )}
                                    setEditing={setEditing}
                                  />
                                  {expandedDetailItems(
                                    detailItem,
                                    i2,
                                    category as CategoryState<T>,
                                    mappedSubCategory,
                                  ).map((subdetailItem, subdetailIndex) => {
                                    return (
                                      <GridTableItem<T, TAdditional>
                                        key={subdetailIndex}
                                        item={subdetailItem}
                                        index={subdetailIndex}
                                        configuration={configuration}
                                        readonly={readonly}
                                        readonlyFn={readonlyFn}
                                        inEditModeFn={
                                          configuration.inEditModeFn
                                        }
                                        itemFn={
                                          configuration.classNames?.itemFn
                                        }
                                        renderType={RenderType.Subdetails}
                                        hasCardItems={
                                          configuration.hasCardItems
                                        }
                                        className={`${configuration.classNames?.item} ${configuration.classNames?.subdetails}`}
                                        contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.subdetailsContent}`}
                                        error={error}
                                        hasRowError={configuration.hasRowError}
                                        category={category as CategoryState<T>}
                                        expandedItemComponent={
                                          expandedItemComponent
                                        }
                                        preRowItemComponent={
                                          preRowItemComponent
                                        }
                                        {...generateCountAndItemKey({
                                          indices: [
                                            index,
                                            itemIndex,
                                            itemIndex2,
                                            detailIndex,
                                            subdetailIndex,
                                          ],
                                        })}
                                        setEditing={setEditing}
                                      />
                                    );
                                  })}
                                </Fragment>
                              );
                            })}
                            <If isTrue={showSubdetailsIfNoDetailItems?.(i2)}>
                              {expandedDetailItems(
                                i2,
                                i2,
                                category as CategoryState<T>,
                                mappedSubCategory,
                              ).map((subdetailItem, subdetailIndex) => {
                                return (
                                  <GridTableItem<T, TAdditional>
                                    key={subdetailIndex}
                                    item={subdetailItem}
                                    index={subdetailIndex}
                                    configuration={configuration}
                                    readonly={readonly}
                                    readonlyFn={readonlyFn}
                                    inEditModeFn={configuration.inEditModeFn}
                                    itemFn={configuration.classNames?.itemFn}
                                    renderType={RenderType.Subdetails}
                                    hasCardItems={configuration.hasCardItems}
                                    className={`${configuration.classNames?.item} ${configuration.classNames?.subdetails}`}
                                    contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.subdetailsContent}`}
                                    error={error}
                                    hasRowError={configuration.hasRowError}
                                    category={category as CategoryState<T>}
                                    expandedItemComponent={
                                      expandedItemComponent
                                    }
                                    preRowItemComponent={preRowItemComponent}
                                    {...generateCountAndItemKey({
                                      indices: [
                                        index,
                                        itemIndex,
                                        itemIndex2,
                                        subdetailIndex,
                                      ],
                                    })}
                                    setEditing={setEditing}
                                  />
                                );
                              })}
                            </If>
                          </Fragment>
                        );
                      })}
                    </CategoryContainer>
                  </Fragment>
                );
              }
              return (
                <Fragment key={generateIndex([index, itemIndex])}>
                  <GridTableItem<T, TAdditional>
                    key={itemIndex}
                    {...generateCountAndItemKey({
                      indices: [index, itemIndex],
                    })}
                    item={subCategory}
                    index={itemIndex}
                    configuration={configuration}
                    readonly={readonly}
                    readonlyFn={readonlyFn}
                    inEditModeFn={configuration.inEditModeFn}
                    itemFn={configuration.classNames?.itemFn}
                    onClick={configuration.toggle?.item}
                    hasCardItems={configuration.hasCardItems}
                    className={configuration.classNames?.item}
                    contentClassName={configuration.classNames?.itemContent}
                    category={category as CategoryState<T>}
                    error={error}
                    hasRowError={configuration.hasRowError}
                    expandedItemComponent={expandedItemComponent}
                    preRowItemComponent={preRowItemComponent}
                    setEditing={setEditing}
                  />
                  {expandedItems(subCategory, category as CategoryState<T>).map(
                    (detailItem, detailIndex) => {
                      if (detailIndex === 0 && hideFirstDetail) {
                        return null;
                      }
                      return (
                        <GridTableItem<T, TAdditional>
                          key={detailIndex}
                          item={detailItem}
                          index={detailIndex}
                          configuration={configuration}
                          readonly={readonly}
                          readonlyFn={readonlyFn}
                          inEditModeFn={configuration.inEditModeFn}
                          itemFn={configuration.classNames?.itemFn}
                          renderType={RenderType.Details}
                          hasCardItems={configuration.hasCardItems}
                          category={category as CategoryState<T>}
                          className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
                          contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
                          error={error}
                          hasRowError={configuration.hasRowError}
                          expandedItemComponent={expandedItemComponent}
                          preRowItemComponent={preRowItemComponent}
                          setEditing={setEditing}
                          {...generateCountAndItemKey(
                            {
                              indices: [index, itemIndex, detailIndex],
                            },
                            { skip: skipDetailRowsCounterKeyAssignment },
                          )}
                        />
                      );
                    },
                  )}
                </Fragment>
              );
            },
          )}
        </CategoryContainer>
      </GridTableVirtualizationContainer>
    );
  }
  return (
    <Fragment>
      <GridTableItem<T, TAdditional>
        key={index}
        item={category as T}
        index={index}
        configuration={configuration}
        readonly={readonly}
        readonlyFn={readonlyFn}
        itemFn={configuration.classNames?.itemFn}
        inEditModeFn={configuration.inEditModeFn}
        category={category as CategoryState<T>}
        className={configuration.classNames?.item}
        contentClassName={configuration.classNames?.itemContent || ""}
        onClick={configuration.toggle?.item}
        hasCardItems={configuration.hasCardItems}
        error={error}
        hasRowError={configuration.hasRowError}
        expandedItemComponent={expandedItemComponent}
        preRowItemComponent={preRowItemComponent}
        setEditing={setEditing}
        {...generateCountAndItemKey({ indices: [index] })}
      />
      {expandedItems(category as T, category as CategoryState<T>).map(
        (detailItem, detailIndex) => {
          if (detailIndex === 0 && hideFirstDetail) {
            return null;
          }
          return (
            <GridTableItem<T, TAdditional>
              key={detailIndex}
              item={detailItem}
              index={detailIndex}
              configuration={configuration}
              readonly={readonly}
              readonlyFn={readonlyFn}
              inEditModeFn={configuration.inEditModeFn}
              category={category as CategoryState<T>}
              itemFn={configuration.classNames?.itemFn}
              renderType={RenderType.Details}
              hasCardItems={configuration.hasCardItems}
              className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
              contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
              error={error}
              hasRowError={configuration.hasRowError}
              onClick={configuration.toggle?.item}
              expandedItemComponent={expandedItemComponent}
              preRowItemComponent={preRowItemComponent}
              {...generateCountAndItemKey(
                { indices: [index, detailIndex] },
                { skip: skipDetailRowsCounterKeyAssignment },
              )}
            />
          );
        },
      )}
    </Fragment>
  );
};
