import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useInventoryItemsStore } from "@/contractor/pages/admin/inventory-items/store/useInventoryItemsStore";
import {
  CreateInventoryTransferInput,
  useCreateInventoryTransferMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";

export const useInventoryTransferMutations = () => {
  const { setError } = useGlobalError();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { fetchInventoryItems } = useInventoryItemsStore(
    useShallow((state) => ({
      fetchInventoryItems: state.fetchInventoryItems,
    })),
  );
  const [
    createInventoryTransferMutation,
    { loading: createInventoryTransferLoading },
  ] = useCreateInventoryTransferMutation();

  const createInventoryTransfer = useCallback(
    async (input: CreateInventoryTransferInput) => {
      try {
        const { data, errors } = await createInventoryTransferMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.createInventoryTransfer) {
          setSuccessAlert(
            intl.$t({
              id: "INVENTORY_TRANSFER_COMPLETED_SUCCESSFULLY",
            }),
          );
          fetchInventoryItems(true);
          return true;
        }
      } catch (errors) {
        setError(errors);
      }
      return false;
    },
    [
      createInventoryTransferMutation,
      setError,
      intl,
      setSuccessAlert,
      fetchInventoryItems,
    ],
  );

  return {
    createInventoryTransfer,
    createInventoryTransferLoading,
  };
};
