import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useInventoryItemsStore } from "@/contractor/pages/admin/inventory-items/store/useInventoryItemsStore";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  search: string;
  setSearch: (search: string) => void;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  selectedCostCodes: string[];
  setSelectedCostCodes: (costCodes: string[]) => void;
  filteredInventoryItems: InventoryItemFieldsFragment[];
  filterItems: () => void;
  resetFilters: () => void;
};

export const useInventoryFiltersStore = create<State>()(
  devtools((set, get) => ({
    search: "",
    setSearch: (search) => {
      set({ search });
      get().filterItems();
    },
    selectedCostCodes: [],
    setSelectedCostCodes: (costCodes) => {
      set({ selectedCostCodes: costCodes });
      get().filterItems();
    },
    selectedItems: [],
    setSelectedItems: (items) => {
      set({ selectedItems: items });
    },
    filteredInventoryItems: [],
    filterItems: () => {
      const {
        itemsByWarehouse,
        currentWarehouseId,
        inventoryItems: allInventoryItems,
      } = useInventoryItemsStore.getState();
      const inventoryItems =
        itemsByWarehouse[currentWarehouseId || ""] || allInventoryItems;

      const filteredItems = inventoryItems
        .filter((item) => item.state?.length)
        .filter((item) => {
          return (
            (!get().search ||
              item.orgMaterial.material.name
                .toLowerCase()
                .includes(get().search.toLowerCase())) &&
            (get().selectedCostCodes.length === 0 ||
              get().selectedCostCodes.includes(
                item.orgMaterial.costCode?.id || UNSPECIFIED_COST_CODE_ID,
              ))
          );
        });
      set({ filteredInventoryItems: filteredItems });
    },
    resetFilters: () =>
      set({
        selectedItems: [],
      }),
  })),
);

useInventoryItemsStore.subscribe(() => {
  useInventoryFiltersStore.getState().filterItems();
});
