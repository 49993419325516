import { WarehouseFieldsFragment } from "@/generated/graphql";
import { InputBaseComponentProps } from "@mui/material";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { WarehouseSelectorCustomRenderer } from "./WarehouseSelectorCustomRenderer";
import {
  useWarehouseOptions,
  WarehouseOptionsProps,
} from "./useWarehouseOptions";

type Props = {
  name: string;
  options?: WarehouseFieldsFragment[];
  disabledOptionIds?: string[];
  readonly?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
} & WarehouseOptionsProps;

export const WarehouseSelectorControlled: FC<Props> = ({
  name,
  options,
  projectAddress,
  trackedWarehousesOnly,
  existingWarehouseId,
  includeWarehouseInName,
  disabledOptionIds,
  readonly = false,
  inputProps,
  disableClearable,
  placeholder,
  required = true,
  loading = false,
}) => {
  const intl = useIntl();
  const { warehouseOptions } = useWarehouseOptions({
    projectAddress,
    trackedWarehousesOnly,
    existingWarehouseId,
    includeWarehouseInName,
  });
  const defaultOptions = useMemo(
    () =>
      (options ?? warehouseOptions).filter(
        (option) => !disabledOptionIds?.includes(option.id),
      ),
    [options, warehouseOptions, disabledOptionIds],
  );

  return (
    <SelectControlled
      name={name}
      options={defaultOptions}
      placeholder={placeholder ?? intl.$t({ id: "FULFILLMENT_LOCATION" })}
      inputProps={inputProps}
      getLabel={(option: WarehouseFieldsFragment) => option.name}
      getValue={(option: WarehouseFieldsFragment) => option.id}
      customRender={WarehouseSelectorCustomRenderer}
      required={required}
      rules={{ required }}
      disabled={readonly}
      disableClearable={disableClearable}
      loading={loading}
    />
  );
};
