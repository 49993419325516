import { UomFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useStartupDataStore } from "../stores/useStartupDataStore";

export const useUomOptions = () => {
  const { uoms } = useStartupDataStore(
    useShallow((state) => ({ uoms: state.uoms })),
  );
  const getUomByName = useCallback(
    (uomText: string | null | undefined) => {
      return uoms.find((option) => {
        const lcUomText =
          typeof uomText === "string" ? uomText?.toLowerCase() || "" : "";
        return (
          option.pluralDescription.toLowerCase() === lcUomText ||
          option.singularDescription?.toLowerCase() === lcUomText ||
          option.mnemonic?.toLowerCase() === lcUomText ||
          option.alternativeRefs
            ?.map((m) => m.toLowerCase())
            .includes(lcUomText)
        );
      });
    },
    [uoms],
  );

  const formatUom = useCallback(
    (uom: UomFieldsFragment) => uom?.mnemonic ?? uom?.pluralDescription,
    [],
  );

  return {
    uoms,
    getUomByName,
    formatUom,
  };
};
