import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  FormattedCostCode,
  NonFormattingSourceSystems,
  useStartupDataStore,
} from "@/common/stores/useStartupDataStore";
import { formatCostCode } from "@/common/utils/cost-codes-and-zones/formatCostCode";
import { hasProperty } from "@/common/utils/objectUtils";
import { sortByCode } from "@/common/utils/sorting/sortByCode";
import { sortByDescription } from "@/common/utils/sorting/sortByDescription";
import {
  CostCodeFieldsFragment,
  EstimatedItemFieldsFragment,
  EstimatedItemGroupFieldsFragment,
  OrgLocationFieldsFragment,
  ProjectAllowanceFieldsFragment,
  ProjectBudgetLinkFieldsFragment,
  ProjectCostCodeFieldsFragment,
  ProjectStoreDocument,
  ProjectStoreQuery,
  TagExtendedFieldsFragment,
  ZoneExtendedFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type EstimatedItem = EstimatedItemFieldsFragment & {
  material: {
    id: string;
  };
  uom: {
    id: string;
  };
};

type ProjectStoreUpdateState = Partial<
  Omit<State, "costCodes"> & {
    costCodes: CostCodeFieldsFragment[] | null | undefined;
  }
>;

type State = {
  currentProjectId: string | null | undefined;
  zones: ZoneExtendedFieldsFragment[];
  tags: TagExtendedFieldsFragment[];
  phaseCodes: TagExtendedFieldsFragment[];
  costCodes: FormattedCostCode[] | null | undefined;
  restrictCostCodes: boolean;
  allowance: ProjectAllowanceFieldsFragment;
  budgetLink: ProjectBudgetLinkFieldsFragment | null;
  location: OrgLocationFieldsFragment | null;
  loading: boolean;
  error: Error | null;
  estimatedItems: EstimatedItem[];
  estimatedItemGroups: EstimatedItemGroupFieldsFragment[];
  usesProjectCostCodes: boolean;
  setCurrentProjectId: (projectId: string | null | undefined) => void;
  updateStoreData: (data: ProjectStoreUpdateState) => void;
  resetStore: () => void;
};

const defaultState: Partial<State> = {
  currentProjectId: null,
  tags: [],
  phaseCodes: [],
  zones: [],
  costCodes: null,
  estimatedItems: [],
  estimatedItemGroups: [],
  location: null,
  restrictCostCodes: false,
  allowance: {
    amount: "",
    costCodes: [],
    tags: [],
  },
  budgetLink: null,
  loading: false,
  error: null,
  usesProjectCostCodes: false,
};

const sortByName = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name);

const filterTags = (tags: TagExtendedFieldsFragment[] | undefined) =>
  tags?.filter((t: TagExtendedFieldsFragment) => !t.hasMapping) || [];

const filterPhaseCodes = (tags: TagExtendedFieldsFragment[] | undefined) =>
  tags?.filter((t: TagExtendedFieldsFragment) => t.hasMapping) || [];

const hydrateEstimatedItems = (
  estimatedItemGroups: EstimatedItemGroupFieldsFragment[],
  costCodes: ProjectCostCodeFieldsFragment[],
  tags: TagExtendedFieldsFragment[],
  zones: ZoneExtendedFieldsFragment[],
) => {
  return estimatedItemGroups
    .flatMap((item) => item.estimatedItems)
    .map(
      (item) =>
        ({
          ...item,
          costCode: costCodes.find((cc) => cc.id === item.costCode?.id),
          tags: tags.filter((t) => item.tags.some((tag) => tag.id === t.id)),
          zone: zones.find((z) => z.id === item.zone?.id),
        }) as EstimatedItem,
    );
};

export const useProjectStore = create<State>()(
  devtools((set, get) => ({
    ...defaultState,
    setCurrentProjectId: async (projectId: string | null | undefined) => {
      if (!projectId || projectId === get().currentProjectId) {
        return;
      }
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }
      const { formattedCostCodes } = useStartupDataStore.getState();
      set({
        loading: true,
        currentProjectId: projectId,
        allowance: {
          amount: "",
          costCodes: [],
          tags: [],
        },
      });
      try {
        const result = await client.query<ProjectStoreQuery>({
          query: ProjectStoreDocument,
          variables: { id: projectId },
          fetchPolicy: "cache-first",
        });

        if (!result.data?.project) {
          return;
        }

        const connectedSourceSystem =
          useStartupDataStore.getState().connectedSourceSystem;

        const project = result.data.project;
        const projectCostCodes = result.data.project.costCodes
          ?.map((costCode) =>
            formatCostCode(
              costCode,
              !connectedSourceSystem ||
                !NonFormattingSourceSystems.includes(connectedSourceSystem),
            ),
          )
          .toSorted(
            !connectedSourceSystem ||
              !NonFormattingSourceSystems.includes(connectedSourceSystem)
              ? sortByCode
              : sortByDescription,
          );
        set({
          currentProjectId: projectId,
          zones: project.zones.toSorted(sortByName),
          tags: filterTags(project.tags).toSorted(sortByName),
          phaseCodes: filterPhaseCodes(project.tags).toSorted(sortByName),
          costCodes: projectCostCodes,
          estimatedItemGroups: project.estimatedItemGroups,
          estimatedItems: hydrateEstimatedItems(
            project.estimatedItemGroups,
            projectCostCodes ?? formattedCostCodes,
            project.tags,
            project.zones,
          ),
          restrictCostCodes: project.restrictCostCodes,
          location: project.location,
          allowance: project.allowance,
          budgetLink: project.budgetLink,
          loading: false,
          usesProjectCostCodes: project.usesProjectCostCodes,
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
    updateStoreData: (data: ProjectStoreUpdateState) => {
      const { formattedCostCodes, connectedSourceSystem } =
        useStartupDataStore.getState();

      const projectCostCodes = data.costCodes
        ?.map((costCode) =>
          formatCostCode(
            costCode,
            !connectedSourceSystem ||
              !NonFormattingSourceSystems.includes(connectedSourceSystem),
          ),
        )
        .toSorted(
          !connectedSourceSystem ||
            !NonFormattingSourceSystems.includes(connectedSourceSystem)
            ? sortByCode
            : sortByDescription,
        );
      set((state) => ({
        ...state,
        ...data,
        restrictCostCodes: data.restrictCostCodes ?? state.restrictCostCodes,
        tags: data.tags
          ? filterTags(data.tags).toSorted(sortByName)
          : state.tags,
        phaseCodes: data.tags
          ? filterPhaseCodes(data.tags).toSorted(sortByName)
          : state.phaseCodes,
        zones: (data.zones || state.zones).toSorted(sortByName),
        costCodes: projectCostCodes ?? state.costCodes,
        estimatedItemGroups:
          data.estimatedItemGroups || state.estimatedItemGroups,
        estimatedItems: hydrateEstimatedItems(
          data.estimatedItemGroups || state.estimatedItemGroups,
          projectCostCodes ?? state.costCodes ?? formattedCostCodes,
          data.tags || state.tags,
          data.zones || state.zones,
        ),
        usesProjectCostCodes:
          data?.usesProjectCostCodes ?? state.usesProjectCostCodes,
        budgetLink: hasProperty(data, "budgetLink")
          ? data.budgetLink
          : state.budgetLink,
      }));
    },
    resetStore: () => {
      set(defaultState);
    },
  })),
);
