import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { TransactionHistoryItemContainer } from "../../TransactionHistory.styles";
import { useTransactionIdentifier } from "./useTransactionIdentifier";

const Container = tw(TransactionHistoryItemContainer)`truncate`;

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const TransactionIdentifier = ({ item }: Props) => {
  const { onClick } = useTransactionIdentifier({ item });
  return (
    <Container>
      <LinkLike onClick={onClick}>#{item.number}</LinkLike>
      <If isTrue={item.instructions?.text}>
        <Tooltip
          element={item.instructions?.text}
          className="w-full truncate text-2xs text-gray-600"
        >
          {item.instructions?.text}
        </Tooltip>
      </If>
    </Container>
  );
};
