import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback } from "react";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useReceipt } from "../providers/ReceiptProvider";

export const useIsReceiptMissingCostCodes = () => {
  const { hasPhaseCodes } = useOrgSettings();
  const { receipt } = useReceipt();
  const { release } = useRelease();
  const { itemized } = useReceipt();
  const { findMaterialByName } = useTableHelpers();

  const getIsReceiptMissingCostCodes = useCallback(
    ({
      costCodeId,
      phaseCodeId,
    }: Pick<ReceiptCreateReleaseFormValues, "costCodeId" | "phaseCodeId">) => {
      if (itemized) {
        return (release?.items ?? []).some((releaseItem) => {
          const releaseItemIsMissingCostCode =
            !releaseItem.costCode ||
            releaseItem.costCode.id === UNSPECIFIED_COST_CODE_ID;
          const matchingOrgMaterial = findMaterialByName(
            releaseItem.projectItem?.material.material.name || "",
          );
          return (
            releaseItemIsMissingCostCode &&
            !matchingOrgMaterial?.costCode &&
            !receipt?.costCode
          );
        });
      }

      return !(hasPhaseCodes ? phaseCodeId : costCodeId);
    },
    [
      release?.items,
      hasPhaseCodes,
      itemized,
      receipt?.costCode,
      findMaterialByName,
    ],
  );

  return { getIsReceiptMissingCostCodes };
};
