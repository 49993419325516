import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { CostCode } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Multiselect } from "../select/components/multiple/Multiselect";

type Props = {
  options: Pick<
    CostCode & { disabled?: boolean },
    "id" | "description" | "disabled"
  >[];
  disabled?: boolean;
  selectedCostCodes: string[] | null | undefined;
  setSelectedCostCodes: (items: string[]) => void;
  limitTags?: number;
  className?: string;
  placeholder?: string;
};

export const CostCodesSelector: FC<Props> = ({
  options,
  selectedCostCodes,
  setSelectedCostCodes,
  disabled,
  limitTags,
  className = "",
  placeholder,
}) => {
  const intl = useIntl();
  const { formatCostCode } = useProjectCostCodes();

  return (
    <Multiselect
      placeholder={
        placeholder ||
        intl.$t({
          id: "COST_CODES",
        })
      }
      className={`w-full ${className}`}
      options={options}
      values={selectedCostCodes}
      onMultipleChange={(costCodes) => setSelectedCostCodes(costCodes || [])}
      getLabel={(option) => formatCostCode(option.description)}
      getOptionDisabled={(option) => !!option.disabled}
      getValue={(option) => option.id}
      disabled={disabled}
      disableCloseOnSelect
      includeCheckbox
      chipSize="small"
      limitTags={limitTags}
      moreItems={
        limitTags
          ? options
              .filter((c) =>
                selectedCostCodes
                  ?.slice(limitTags, selectedCostCodes.length)
                  .includes(c.id),
              )
              .map((c) => formatCostCode(c.description))
              .join(", ")
          : undefined
      }
      sx={{
        " .MuiInputBase-root.MuiAutocomplete-inputRoot .MuiAutocomplete-input":
          {
            width: "100%",
          },
        ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
          flexWrap: "nowrap",
        },
      }}
    />
  );
};
