import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { GroupedInventoryItems } from "../../../../types/GroupedInventoryItems";
import { useInventoryItemSequenceStore } from "../../store/useInventoryItemSequenceStore";

export const useInventoryItemSequence = () => {
  return useSequence<GroupedInventoryItems>({
    listPath: routes.currentStock,
    localStorageConfig: LOCAL_STORAGE_KEYS.INVENTORY_ITEM_SEQUENCES,
    useStore: useInventoryItemSequenceStore,
  });
};
