import { AdditionalFiltersOverlay } from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { useInventoryItemsFilter } from "../../../../hooks/useInventoryItemsFilter";
import { useCurrentStockAdditionalFilters } from "./hooks/useCurrentStockAdditionalFilters";

export const CurrentStockAdditionalFilters = () => {
  const { filter, setFilter } = useInventoryItemsFilter();
  const { options } = useCurrentStockAdditionalFilters();

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      setFilter={setFilter}
    />
  );
};
