import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

export const useSetCurrentProjectId = (id: string | undefined) => {
  const { loading } = useStartupDataStore(
    useShallow((state) => ({
      loading: state.loading,
    })),
  );

  const { setCurrentProjectId, resetProjectStore } = useProjectStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
      resetProjectStore: state.resetStore,
    })),
  );

  useEffect(() => {
    if (loading) {
      return;
    }
    if (id) {
      setCurrentProjectId(id);
    } else {
      resetProjectStore();
    }
  }, [id, setCurrentProjectId, loading, resetProjectStore]);

  useEffect(() => {
    return () => {
      resetProjectStore();
    };
  }, [resetProjectStore]);
};
