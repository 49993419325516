import {
  CostCodeFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { getPhaseCodesByReleaseItem } from "./getPhaseCodesByReleaseItem";

export const getPhaseCodesByReleaseItems = (
  releaseItems: (
    | DistributorReleaseItemFieldsFragment
    | ReleaseItemFieldsFragment
  )[],
  unassignedPhaseCode: Omit<CostCodeFieldsFragment, "mappings">,
  unassignedZone: ZoneFieldsFragment,
  zone?: ZoneFieldsFragment,
) => {
  const phaseCodes: Omit<CostCodeFieldsFragment, "mappings">[] = [];

  releaseItems.forEach((releaseItem) => {
    const phaseCode = getPhaseCodesByReleaseItem(
      releaseItem,
      unassignedZone,
      zone,
    );
    if (phaseCode && !phaseCodes.map((c) => c.id).includes(phaseCode.id)) {
      phaseCodes.push(phaseCode);
    }
  });
  phaseCodes.sort((a, b) => a.code.localeCompare(b.code));
  if (
    releaseItems
      .filter(
        (r) =>
          (!r.zone && zone?.id === unassignedZone.id) ||
          zone?.id === r.zone?.id,
      )
      .some((i) => i?.tags.filter((i) => i.hasMapping).length === 0)
  ) {
    phaseCodes.push(unassignedPhaseCode);
  }
  return phaseCodes;
};
