import { Instructions } from "@/common/components/instructions/Instructions";
import { usePrefillHelpers } from "@/common/components/spreadsheet-table/hooks/usePrefillHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { InstructionInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InventoryTransferBreadcrumbs } from "./components/inventory-transfer-breadcrumbs/InventoryTransferBreadcrumbs";
import { InventoryTransferFooter } from "./components/inventory-transfer-footer/InventoryTransferFooter";
import {
  InventoryTransferForm,
  InventoryTransferFormValues,
} from "./components/inventory-transfer-form/InventoryTransferForm";
import { InventoryTransferHeader } from "./components/inventory-transfer-header/InventoryTransferHeader";
import { useInventoryTransferConfig } from "./hooks/useInventoryTransferConfig";
import { useInventoryTransferTableHelpers } from "./hooks/useInventoryTransferTableHelpers";
import { useSyncInventoryTransfer } from "./hooks/useSyncInventoryTransfer";
import { useTransferTableWarehouseListener } from "./hooks/useTransferTableWarehouseListener";

const Container = tw.div``;

const InventoryTransferWithProvider = () => {
  const intl = useIntl();
  const spreadsheetConfig = useInventoryTransferConfig();
  const { prefillStock, prefillTransfer } = useInventoryTransferTableHelpers();
  useTransferTableWarehouseListener();
  const { prefillExtraOptions } = usePrefillHelpers();
  const { setValue } = useFormContext<InventoryTransferFormValues>();
  const { loading } = useInventoryItems();
  const { saving } = useSyncInventoryTransfer();
  const items = useMemo(() => {
    return [];
  }, []);

  const saveInstructions = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  return (
    <Container>
      <InventoryTransferBreadcrumbs />
      <InventoryTransferHeader />
      <SpreadSheetTable
        items={items}
        columns={spreadsheetConfig}
        saving={saving}
        height="calc(100vh - 500px)"
        rowNumber={30}
        fetchingData={loading}
        customPrefillFunctions={[
          prefillExtraOptions,
          prefillStock,
          prefillTransfer,
        ]}
      />
      <Instructions
        saveInstruction={saveInstructions}
        title={intl.$t({ id: "NOTES_AND_DOCUMENTS" })}
      />
      <InventoryTransferFooter />
    </Container>
  );
};

export const InventoryTransfer = () => {
  const spreadsheetConfig = useInventoryTransferConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <InventoryTransferForm>
        <InventoryTransferWithProvider />
      </InventoryTransferForm>
    </ColumnMapperProvider>
  );
};
