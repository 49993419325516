import { ViewType } from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useAddReceiptItems } from "@/common/hooks/add-missing-items-to-order/useAddReceiptItems";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback } from "react";
import { useReceipt } from "../providers/ReceiptProvider";

export const useReceiptOrderHeaderItemizedToggle = () => {
  const { setItemized, receipt } = useReceipt();
  const { addReceiptItems } = useAddReceiptItems();

  const handleItemizedToggleChange = useCallback(
    (view: ViewType) => {
      setItemized(view === ViewType.Itemized);
      if (
        view === ViewType.Itemized &&
        receipt?.release?.status === ReleaseStatus.Reserved &&
        receipt.release.items.length === 0
      ) {
        addReceiptItems();
      }
    },
    [
      setItemized,
      addReceiptItems,
      receipt?.release?.status,
      receipt?.release?.items,
    ],
  );

  return {
    handleItemizedToggleChange,
  };
};
