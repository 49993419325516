import { usePrefillHelpers } from "@/common/components/spreadsheet-table/hooks/usePrefillHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { InventoryAdjustmentBreadcrumbs } from "./components/inventory-adjustment-breadcrumbs/InventoryAdjustmentBreadcrumbs";
import { InventoryAdjustmentFooter } from "./components/inventory-adjustment-footer/InventoryAdjustmentFooter";
import { InventoryAdjustmentForm } from "./components/inventory-adjustment-form/InventoryAdjustmentForm";
import { InventoryAdjustmentHeader } from "./components/inventory-adjustment-header/InventoryAdjustmentHeader";
import { useInventoryAdjustmentConfig } from "./hooks/useInventoryAdjustmentConfig";
import { useInventoryAdjustmentTableHelpers } from "./hooks/useInventoryAdjustmentTableHelpers";
import { useSyncInventoryAdjustment } from "./hooks/useSyncInventoryAdjustment";
import { useTableWarehouseListener } from "./hooks/useTableWarehouseListener";

const Container = tw.div``;

const InventoryAdjustmentWithProvider = () => {
  const spreadsheetConfig = useInventoryAdjustmentConfig();
  const { loading } = useInventoryItems();
  const { prefillStock, prefillNewStock, prefillUomChange } =
    useInventoryAdjustmentTableHelpers();
  const { saving } = useSyncInventoryAdjustment();
  useTableWarehouseListener();
  const { prefillExtraOptions, updateExtPriceDependence } = usePrefillHelpers();

  const items = useMemo(() => {
    return [];
  }, []);

  return (
    <Container>
      <InventoryAdjustmentBreadcrumbs />
      <InventoryAdjustmentHeader />
      <SpreadSheetTable
        items={items}
        columns={spreadsheetConfig}
        saving={saving}
        fetchingData={loading}
        height="calc(100vh - 340px)"
        rowNumber={35}
        customPrefillFunctions={[
          prefillExtraOptions,
          prefillStock,
          prefillNewStock,
          prefillUomChange,
          updateExtPriceDependence,
        ]}
      />
      <InventoryAdjustmentFooter />
    </Container>
  );
};

export const InventoryAdjustment = () => {
  const spreadsheetConfig = useInventoryAdjustmentConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <InventoryAdjustmentForm>
        <InventoryAdjustmentWithProvider />
      </InventoryAdjustmentForm>
    </ColumnMapperProvider>
  );
};
