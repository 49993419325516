import { useCallback, useMemo } from "react";
import { useInventoryItemSequence } from "../hooks/useInventoryItemSequence";
import { InventoryItemCheckboxProps } from "./InventoryItemCheckbox";

export const useInventoryItemCheckbox = ({
  item,
}: InventoryItemCheckboxProps) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useInventoryItemSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, item]
          : selectedEntities.filter((entity) => entity.id !== item.id),
      );
    },
    [item, selectedEntities, setSelectedEntities],
  );

  const isIncluded = useMemo(
    () => !item.archivedAt && item.warehouses.every((w) => !w.inStock),
    [item.archivedAt, item.warehouses],
  );

  return { handleChange, sequenceIds, isIncluded };
};
