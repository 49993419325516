import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { UomFieldsFragment } from "@/generated/graphql";
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "../tooltip/Tooltip";

export const NEW_UOM_ID = "new-uom";

type Props = {
  uom: UomFieldsFragment | null | undefined;
  index?: number;
  readonly?: boolean;
  staticText?: boolean;
  mnemonic?: boolean;
  saveUom: (uom: string | null) => void;
  error?: boolean;
  updating?: boolean;
  testId?: string;
  placeholder?: string;
  centered?: boolean;
  creatable?: boolean;
  getLabel?: (uom: UomFieldsFragment | string) => string;
};

export const UomPicker: FC<Props> = ({
  uom,
  index,
  staticText = false,
  readonly = false,
  mnemonic = true,
  error = false,
  updating = false,
  saveUom,
  placeholder,
  testId,
  centered = false,
  creatable = true,
  getLabel = (opt: UomFieldsFragment | string) =>
    (opt as UomFieldsFragment).id
      ? mnemonic && readonly && (opt as UomFieldsFragment).mnemonic
        ? (opt as UomFieldsFragment).mnemonic || ""
        : (opt as UomFieldsFragment).pluralDescription
      : (opt as string),
}) => {
  const intl = useIntl();
  const { uoms } = useUomOptions();
  const [selectedUom, setSelectedUom] = useState<string | null | undefined>(
    uom?.id,
  );
  const [description, setDescription] = useState<string | null | undefined>(
    uom?.pluralDescription ||
      uoms.find((option) => option.id === uom?.id)?.pluralDescription ||
      "",
  );

  useEffect(() => {
    setSelectedUom(uom?.id);
    setDescription(
      uom?.pluralDescription ||
        uoms.find((option) => option.id === uom?.id)?.pluralDescription,
    );
  }, [uom, uoms]);

  useEffect(() => {
    if (readonly && !updating && uom?.id !== selectedUom) {
      setSelectedUom(uom?.id);
      setDescription(
        uom?.pluralDescription ||
          uoms.find((option) => option.id === uom?.id)?.pluralDescription,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly, uom]);

  const onSave = useCallback(() => {
    if (creatable) {
      saveUom(description || "");
    } else {
      const pluralDescription = uoms.find(
        (option) => option.id === selectedUom,
      )?.pluralDescription;
      if (pluralDescription) {
        saveUom(pluralDescription);
      }
    }
  }, [creatable, description, uoms, saveUom, selectedUom]);

  const onChange = useCallback(
    (newValue: string | null) => {
      setSelectedUom(newValue);
      const item = uoms.find((option) => option.id === newValue);
      if (item) {
        setDescription(item.pluralDescription);
      }
    },
    [uoms],
  );

  const handleInputChange = useCallback(
    (newValue: string | null, event?: SyntheticEvent) => {
      if (
        (event as unknown as KeyboardEvent)?.key === EventKeysConstants.Enter
      ) {
        return;
      }
      if (
        newValue?.indexOf('"') === 0 &&
        newValue.lastIndexOf('"') === newValue.length - 1
      ) {
        setDescription(newValue?.split('"')[1]);
      } else {
        setDescription(newValue);
      }
    },
    [setDescription],
  );

  const filteredOptions = useMemo(() => {
    return uoms.filter(
      (option) => option.mnemonic || description === option.pluralDescription,
    );
  }, [description, uoms]);

  return (
    <Tooltip
      element={
        <StyledSelect<UomFieldsFragment | string>
          className="capitalize"
          options={filteredOptions}
          value={selectedUom}
          placeholder={
            placeholder === undefined
              ? intl.$t({ id: "SELECT_UOM" })
              : placeholder
          }
          getValue={(opt: UomFieldsFragment | string) =>
            (opt as UomFieldsFragment).id
          }
          getLabel={getLabel}
          disabled={readonly}
          staticText={staticText}
          testId={testId}
          error={error}
          centered={centered}
          creatable={creatable}
          onBlur={onSave}
          handleInputChange={handleInputChange}
          onChange={onChange}
          creatableFn={(value) => {
            return {
              id: NEW_UOM_ID,
              pluralDescription: value.substring(1, value.length - 1),
              alternativeRefs: [],
            };
          }}
          size="xs"
          creatableTextKey="UOM_ADD_NEW"
        />
      }
      id={`uom-description-${index}`}
      position="top"
      className="w-full"
    >
      {!readonly ? null : description}
    </Tooltip>
  );
};
