import { getTotalTaxAmount } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { getReleaseAdditionalCharges } from "@/contractor/pages/home/release/store/releaseStoreUtils";
import { useMemo } from "react";
import { useInventoryWizardTransaction } from "../../../../../hooks/useInventoryWizardTransaction";

export const useTransactionHistoryInflowsFooterOrderedPrice = () => {
  const { transaction } = useInventoryWizardTransaction({
    skipStoreUpdates: true,
  });
  const { calcExtPrice } = usePriceCalculation();

  const subtotal = useMemo(
    () =>
      (transaction?.items || []).reduce(
        (acc: DecimalSafe, item) =>
          acc.add(calcExtPrice(item.quantity, item.unitPrice)),
        new DecimalSafe(0),
      ),
    [transaction, calcExtPrice],
  );

  const additionalChargeAmount = useMemo(
    () =>
      getReleaseAdditionalCharges(
        transaction?.deliverySlip?.release?.additionalCharges || [],
      ),
    [transaction],
  );

  const salesTax = useMemo(
    () =>
      getTotalTaxAmount({
        ...transaction?.deliverySlip?.release,
        netAmount: subtotal?.toString(),
      }),
    [subtotal, transaction?.deliverySlip?.release],
  );

  const total = useMemo(
    () => subtotal.add(additionalChargeAmount).add(salesTax),
    [subtotal, additionalChargeAmount, salesTax],
  );

  return { subtotal, additionalChargeAmount, salesTax, total };
};
