import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";
import { useProjectBudget } from "../../project/pages/project-budget/hooks/useProjectBudget";
import { useRelease } from "../providers/ReleaseProvider";

export const useNonItemizedReleaseProjectBudgetSync = () => {
  const { release, loading: releaseLoading } = useRelease();
  const { budgetLink } = useProjectBudget();
  const { setValue, watch } = useFormContext();
  const { projectCostCodes } = useProjectCostCodes();

  const costCodeId = watch("costCodeId");

  useEffect(() => {
    if (!budgetLink?.autoSync || release || releaseLoading) {
      return;
    }
    if (!projectCostCodes.some((costCode) => costCode.id === costCodeId)) {
      setValue("costCodeId", "");
    }
  }, [
    budgetLink,
    setValue,
    release,
    releaseLoading,
    projectCostCodes,
    costCodeId,
  ]);
};
