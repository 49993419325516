import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { useNotificationConfig } from "@/common/components/notifications-settings/config/useNotificationConfig";
import { NotificationsSetting } from "@/common/components/notifications-settings/NotificationsSetting";
import {
  ButtonsContainerStyled,
  Form,
  UserProfileHeader,
} from "@/common/components/notifications-settings/NotificationsSetting.styles";
import { UpdateNotificationsInput } from "@/common/components/notifications-settings/types/UpdateNotificationsInput";
import { mapExcludedEventsToApi } from "@/common/components/notifications-settings/utils/mapExcludedEventsToApi";
import { mapExcludedEventsToArray } from "@/common/components/notifications-settings/utils/mapExcludedEventsToArray";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  BuyoutEvent,
  InvoiceEvent,
  MessageContextKind,
  QuoteEvent,
  ReleaseEvent,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useUpdateOrgSettings } from "../../../org-settings/hooks/useUpdateOrgSettings";
import { useViewerOrgNotifications } from "./hooks/useViewerOrgNotifications";

export const OrgNotificationsSettings = () => {
  const { loading, viewerOrgNotifications, orgId } =
    useViewerOrgNotifications();
  const { updateOrgSettings } = useUpdateOrgSettings();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { config } = useNotificationConfig();

  const methods = useForm<UpdateNotificationsInput>({
    defaultValues: {
      excludedQuoteEvents: mapExcludedEventsToArray(
        viewerOrgNotifications?.excludedQuoteEvents,
        Object.values(QuoteEvent),
      ),
      excludedMessageContexts: mapExcludedEventsToArray(
        viewerOrgNotifications?.excludedMessageContexts,
        Object.values(MessageContextKind),
      ),
      excludedBuyoutEvents: mapExcludedEventsToArray(
        viewerOrgNotifications?.excludedBuyoutEvents,
        Object.values(BuyoutEvent),
      ),
      excludedReleaseEvents: mapExcludedEventsToArray(
        viewerOrgNotifications?.excludedReleaseEvents,
        Object.values(ReleaseEvent),
      ),
      excludedInvoiceEvents: mapExcludedEventsToArray(
        viewerOrgNotifications?.excludedInvoiceEvents,
        Object.values(InvoiceEvent),
      ),
    },
  });

  useEffect(() => {
    if (viewerOrgNotifications) {
      methods.reset({
        excludedQuoteEvents: mapExcludedEventsToArray(
          viewerOrgNotifications.excludedQuoteEvents,
          Object.values(QuoteEvent),
        ),
        excludedMessageContexts: mapExcludedEventsToArray(
          viewerOrgNotifications.excludedMessageContexts,
          Object.values(MessageContextKind),
        ),
        excludedBuyoutEvents: mapExcludedEventsToArray(
          viewerOrgNotifications.excludedBuyoutEvents,
          Object.values(BuyoutEvent),
        ),
        excludedReleaseEvents: mapExcludedEventsToArray(
          viewerOrgNotifications.excludedReleaseEvents,
          Object.values(ReleaseEvent),
        ),
        excludedInvoiceEvents: mapExcludedEventsToArray(
          viewerOrgNotifications.excludedInvoiceEvents,
          Object.values(InvoiceEvent),
        ),
      });
    }
  }, [viewerOrgNotifications, methods]);

  const save = useCallback(
    async (values: UpdateNotificationsInput) => {
      if (!orgId) {
        return;
      }
      const excludedEvents = {
        excludedBuyoutEvents: mapExcludedEventsToApi(
          values.excludedBuyoutEvents,
        ),
        excludedInvoiceEvents: mapExcludedEventsToApi(
          values.excludedInvoiceEvents,
        ),
        excludedMessageContexts: mapExcludedEventsToApi(
          values.excludedMessageContexts,
        ),
        excludedQuoteEvents: mapExcludedEventsToApi(values.excludedQuoteEvents),
        excludedReleaseEvents: mapExcludedEventsToApi(
          values.excludedReleaseEvents,
        ),
      };
      const result = await updateOrgSettings({
        id: orgId,
        notifications: {
          ...excludedEvents,
        },
      });
      if (result) {
        setSuccessAlert(
          intl.$t({ id: "ORG_SETTINGS_NOTIFICATIONS_UPDATE_SUCCESS" }),
        );
      }
    },
    [updateOrgSettings, orgId, setSuccessAlert, intl],
  );

  if (!viewerOrgNotifications) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>{intl.$t({ id: "NOTIFICATIONS_SETTINGS" })}</title>
      </Helmet>
      <FormattedMessage
        id="NOTIFICATIONS_SETTINGS"
        tagName={UserProfileHeader}
      />
      <Form>
        {config.map((category) => (
          <NotificationsSetting key={category.id} category={category} />
        ))}
      </Form>
      <FloatingFooter>
        <ButtonsContainerStyled>
          <If isTrue={methods.formState.isDirty}>
            <OutlinedButton wide onClick={() => methods.reset()}>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
          </If>
          <LoadingButton
            wide
            button={PrimaryButton}
            onClick={methods.handleSubmit(save)}
            loading={loading}
          >
            <FormattedMessage id="SAVE" />
          </LoadingButton>
        </ButtonsContainerStyled>
      </FloatingFooter>
    </FormProvider>
  );
};
