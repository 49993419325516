import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { QueryInventoryItemsFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useInventoryItemTransactionsStore } from "../store/useInventoryItemTransactionsStore";

export const useInventoryItemTransactionsFilter = () => {
  const { filter, setFilter } = useInventoryItemTransactionsStore();
  const { firstPage } = usePagination();

  const setFilterAndReset = useCallback(
    (newFilter: Partial<QueryInventoryItemsFilter>) => {
      firstPage();
      setFilter({
        ...filter,
        ...newFilter,
      });
    },
    [filter, firstPage, setFilter],
  );

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return {
    setFilter: setFilterAndReset,
    filter,
    isFiltered,
  };
};
