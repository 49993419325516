import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useReceipt } from "../providers/ReceiptProvider";
import { ReceiptFooterState } from "../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../types/ReceiptMatchedOrderViewState";

export const useClearOrderFromReceipt = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { receipt, updateReceipt, setMatchedOrderViewState, setFooterState } =
    useReceipt();

  const clearReceiptRelease = useCallback(async () => {
    if (receipt) {
      await updateReceipt({ clearRelease: true, id: receipt.id });
      setMatchedOrderViewState(ReceiptMatchedOrderViewState.MATCH_ORDER);
      setFooterState(ReceiptFooterState.MATCH_ORDER);
    }
  }, [receipt, updateReceipt, setMatchedOrderViewState, setFooterState]);

  const clearReceiptReleaseWithDeletion = useCallback(async () => {
    if (receipt) {
      await updateReceipt({
        clearRelease: true,
        id: receipt.id,
        archiveClearedRelease: true,
      });
      setMatchedOrderViewState(ReceiptMatchedOrderViewState.MATCH_ORDER);
      setFooterState(ReceiptFooterState.MATCH_ORDER);
    }
  }, [receipt, updateReceipt, setMatchedOrderViewState, setFooterState]);

  const clear = useCallback(() => {
    openDialog({
      includeWarningIcon: true,
      content: intl.$t({ id: "RECEIPT_RELEASE_DETACH_CONFIRMATION" }),
      cancelButtonText: intl.$t({ id: "CLOSE" }),
      confirmButtonText: intl.$t({
        id: "INVOICE_RELEASE_DETACH_ONLY",
      }),
      handleConfirm: clearReceiptRelease,
      customButtonText: intl.$t({
        id: "INVOICE_RELEASE_DETACH_WITH_DELETION",
      }),
      handleCustomButtonAction: clearReceiptReleaseWithDeletion,
      closeOnCustom: true,
      closeOnConfirm: true,
    });
  }, [intl, openDialog, clearReceiptRelease, clearReceiptReleaseWithDeletion]);

  return { clearOrder: clear };
};
