import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { TransactionHeaderOriginFrom } from "@/contractor/pages/home/inventory-management/common/components/transaction-origin/components/header/transaction-header-origin-from/TransactionHeaderOriginFrom";
import { TransactionHeaderOriginTo } from "@/contractor/pages/home/inventory-management/common/components/transaction-origin/components/header/transaction-header-origin-to/TransactionHeaderOriginTo";
import { InventoryTransactionProps } from "../../../../../types/InventoryTransactionProps";
import { TransactionHeaderTransactionCreationDetails } from "../../../../common/transaction-header-transaction-creation-details/TransactionHeaderTransactionCreationDetails";
import { TransactionHeaderTransactionFulfillmentDate } from "../../../../common/transaction-header-transaction-fulfillment-date/TransactionHeaderTransactionFulfillmentDate";
import { TransactionHeaderTransactionIdentifier } from "../../../../common/transaction-header-transaction-identifier/TransactionHeaderTransactionIdentifier";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryHeaderItemArrow,
  TransactionHistoryHeaderItemGroup,
} from "../../../transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

export const TransactionHistoryAdjustmentsHeader = ({
  transaction,
}: InventoryTransactionProps) => {
  if (!transaction) {
    return null;
  }
  return (
    <InfoHeader>
      <TransactionHistoryHeaderItem>
        <TransactionHistoryHeaderItemGroup>
          <TransactionHeaderOriginFrom item={transaction} />
          <TransactionHistoryHeaderItemArrow />
          <TransactionHeaderOriginTo item={transaction} />
        </TransactionHistoryHeaderItemGroup>
      </TransactionHistoryHeaderItem>
      <TransactionHeaderTransactionIdentifier transaction={transaction} />
      <TransactionHeaderTransactionCreationDetails transaction={transaction} />
      <TransactionHeaderTransactionFulfillmentDate transaction={transaction} />
    </InfoHeader>
  );
};
