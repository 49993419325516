import { addItemToUnspecifiedCostCode } from "@/common/components/grid-table/utils/grouping-utils/addItemToUnspecifiedCostCode";
import { removeItemFromUnspecifiedCostCode } from "@/common/components/grid-table/utils/grouping-utils/removeItemFromUnspecifiedCostCode";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import { getCostCodesByRfqItems } from "@/common/utils/cost-codes-and-zones/getCostCodesByRfqItems";
import {
  AddToRfqItemInput,
  RfqDocument,
  RfqFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type PartialRfq = Pick<RfqFieldsFragment, "id" | "items">;

type Options = {
  force?: boolean;
};

type AddToRfqExtendedInput = AddToRfqItemInput & {
  orgCatalogSkuName?: string;
  isAddMode?: boolean;
  uomId?: string;
};

type State = {
  rfqGroupedItems: CategoryState<RfqItemFieldsFragment>[] | null;
  setRfqGroupedItems: (
    category: CategoryState<RfqItemFieldsFragment>[],
  ) => void;
  setRfqGroupedItemsFromRfq: (
    rfq: PartialRfq,
    newRfqItem?: AddToRfqExtendedInput | null,
  ) => void;
  newRfqItem?: AddToRfqExtendedInput | null;
  setNewRfqItem: (item: AddToRfqExtendedInput | null) => void;
  loading: boolean;
  error: Error | null;
  fetchRfq: (id: string, options?: Options) => void;
  selectedRfqItemIds: string[];
  setSelectedRfqItemIds: (ids: string[]) => void;
  isRfqWithErrors: boolean;
  setIsRfqWithErrors: (isWithErrors: boolean) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  rfqId: string;
  setRfqId: (id: string) => void;
};

const emptyRfqItem: RfqItemFieldsFragment = {
  id: "",
  description: "",
  quantityDecimal: "",
  projectItem: {
    id: "",
    estimatedItems: [],
    buyoutItems: [],
    estimateUom: { id: "", pluralDescription: "", alternativeRefs: [] },
    material: {
      id: "",
      material: {
        id: "",
        name: "",
        defaultUom: {
          id: "",
          pluralDescription: "",
          alternativeRefs: [],
        },

        uom: {
          id: "",
          pluralDescription: "",
          alternativeRefs: [],
        },
      },
    },
  },
};

const setRfqItem = (
  newRfqItem: AddToRfqExtendedInput | null,
): RfqItemFieldsFragment => {
  return newRfqItem
    ? {
        ...emptyRfqItem,
        projectItem: {
          ...emptyRfqItem.projectItem,
          estimateUom: {
            ...emptyRfqItem.projectItem.estimateUom,
            id: newRfqItem.uomId || "",
          },
        },
      }
    : emptyRfqItem;
};

const groupRfqItems = (
  rfq: PartialRfq,
  newRfqItem?: AddToRfqExtendedInput | null,
) => {
  const projectCostCodes = getCostCodesByRfqItems(
    rfq.items,
    newRfqItem?.isAddMode,
  );
  return projectCostCodes.map((costCode) => {
    const newCostCode = {
      id: costCode.id,
      name: costCode.description,
      isOpened: true,
    };
    return {
      ...newCostCode,
      items: [
        ...rfq.items.filter(
          (i) =>
            (costCode.id === UNSPECIFIED_COST_CODE_ID && !i.costCode) ||
            i.costCode?.id === costCode.id,
        ),
        ...(newRfqItem?.isAddMode && costCode.id === UNSPECIFIED_COST_CODE_ID
          ? [emptyRfqItem]
          : []),
      ],
    };
  });
};

const updateGroupedItems = (
  items: CategoryState<RfqItemFieldsFragment>[] | null,
  item: AddToRfqExtendedInput | null,
) => {
  return (
    items?.map((costCode) => {
      if (costCode.id === UNSPECIFIED_COST_CODE_ID) {
        return {
          ...costCode,
          items: [...costCode.items.filter((i) => i.id), setRfqItem(item)],
        };
      }
      return costCode;
    }) || []
  );
};

export const useRfqGroupedItemsStore = create<State>()(
  devtools((set, get) => ({
    selectedRfqItemIds: [],
    setSelectedRfqItemIds: (selectedRfqItemIds: string[]) =>
      set(() => ({ selectedRfqItemIds })),
    isRfqWithErrors: false,
    setIsRfqWithErrors: (isRfqWithErrors) => set(() => ({ isRfqWithErrors })),
    expandedItems: [],
    setExpandedItems: (expandedItems) => set(() => ({ expandedItems })),
    rfqGroupedItems: null,
    setRfqGroupedItems: (rfqGroupedItems) => set(() => ({ rfqGroupedItems })),
    setRfqGroupedItemsFromRfq: (
      rfq: PartialRfq,
      newRfqItem?: AddToRfqExtendedInput | null,
    ) => {
      const newCostCodes = groupRfqItems(rfq, newRfqItem);
      set({ rfqGroupedItems: newCostCodes, loading: false, rfqId: rfq.id });
    },
    newRfqItem: null,
    rfqId: "",
    setRfqId: (id) => set(() => ({ rfqId: id })),
    setNewRfqItem: (item) => {
      const items = get().rfqGroupedItems || [];
      if (!get().newRfqItem) {
        set({
          newRfqItem: { description: "", isAddMode: true },
          rfqGroupedItems: addItemToUnspecifiedCostCode(items, emptyRfqItem),
        });
        return;
      }
      if (!item) {
        set({
          newRfqItem: null,
          rfqGroupedItems: removeItemFromUnspecifiedCostCode(
            items,
            emptyRfqItem,
          ),
        });
        return;
      }
      set(() => ({
        newRfqItem: item,
        rfqGroupedItems: get().newRfqItem
          ? updateGroupedItems(items, item)
          : get().rfqGroupedItems,
      }));
    },
    loading: false,
    error: null,
    fetchRfq: async (id: string, options?: Options) => {
      if (id !== get().rfqId) {
        set({ rfqGroupedItems: null, rfqId: id });
      }
      if ((get().rfqGroupedItems && !options?.force) || get().loading) {
        return;
      }

      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, error: null });
      try {
        await Promise.all([
          client.query({
            query: RfqDocument,
            variables: { id },
            fetchPolicy: options?.force ? "no-cache" : undefined,
          }),
        ]).then(([result]) => {
          const newCostCodes = groupRfqItems(result.data.rfq, get().newRfqItem);
          set({ rfqGroupedItems: newCostCodes, loading: false, rfqId: id });
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
  })),
);
