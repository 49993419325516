import {
  InventoryTransactionFieldsFragment,
  InventoryTransactionItemFieldsFragment,
} from "@/generated/graphql";

export const getDeliveryItemFromTransaction = (
  item: InventoryTransactionItemFieldsFragment,
  transaction: InventoryTransactionFieldsFragment | undefined,
) =>
  transaction?.deliverySlip?.deliveredReleaseItems?.find((deliveryItem) =>
    (deliveryItem.inventoryTransactionItems || []).find(
      (transactionItem) => transactionItem.id === item.id,
    ),
  );
