import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { getInventoryItemFromTransaction } from "../../../../../../../utils/getInventoryItemFromTransaction";
import { TransactionHistoryItemDetails } from "../../TransactionHistory.styles";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
  inventoryItemId: string;
};

export const TransactionItemNotes = ({ item, inventoryItemId }: Props) => {
  const inventoryItem = useMemo(
    () => getInventoryItemFromTransaction(item, inventoryItemId),
    [inventoryItemId, item],
  );
  return (
    <NotNullableRenderer value={inventoryItem?.notes}>
      <TransactionHistoryItemDetails>
        {inventoryItem?.notes}
      </TransactionHistoryItemDetails>
    </NotNullableRenderer>
  );
};
