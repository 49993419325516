import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { WizardStoreArgs } from "../types/WizardStoreArgs";

type State = {
  visibleIds: string[];
  show: (id: string, args?: WizardStoreArgs, defaultStep?: number) => void;
  hide: (id: string) => void;
  isVisible: (id: string) => boolean;
  args: WizardStoreArgs;
  setArgs: (args: WizardStoreArgs) => void;
  defaultStep?: number;
};

export const isWizardVisible = (id: string, visibleIds: string[]) => {
  return visibleIds.includes(id);
};

export const useWizardStore = create<State>()(
  devtools((set) => ({
    visibleIds: [],
    show: (id: string, args?: WizardStoreArgs, defaultStep?: number) =>
      set((state) => ({
        visibleIds: [...state.visibleIds, id],
        args: args || {},
        defaultStep,
      })),
    hide: (id: string) => {
      set((state) => ({
        visibleIds: state.visibleIds.filter((visibleId) => visibleId !== id),
        args: {},
        defaultStep: undefined,
      }));
    },
    args: {},
    setArgs: (args: WizardStoreArgs) => {
      set((state) => ({
        args: {
          ...state.args,
          ...args,
        },
      }));
    },
  })),
);
