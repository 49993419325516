import { GridTable } from "@/common/components/grid-table/GridTable";
import { FC } from "react";
import { InventoryTransactionProps } from "../../../../../types/InventoryTransactionProps";
import { useTransactionHistoryAdjustmentsConfiguration } from "./TransactionHistoryAdjustments.configuration";

export const TransactionHistoryAdjustmentsList: FC<
  InventoryTransactionProps
> = ({ transaction }) => {
  const configuration = useTransactionHistoryAdjustmentsConfiguration();
  return (
    <GridTable
      configuration={{ columns: configuration }}
      items={transaction?.items}
    />
  );
};
