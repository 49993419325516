import { If } from "@/common/components/if/If";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { FC } from "react";
import {
  TransactionHistoryHeaderItemDetails,
  TransactionHistoryHeaderItemName,
  TransactionHistoryItemContainer,
  TransactionHistoryItemNameContainer,
  TransactionHistoryItemOrigin,
} from "../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";
import { TransactionOrigin } from "../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: TransactionOrigin | null;
};

export const TransactionHeaderItemOrigin: FC<Props> = ({ item }: Props) => {
  const Icon = item?.icon;

  return (
    <TransactionHistoryItemContainer>
      <TransactionHistoryItemOrigin
        className="gap-4"
        $excludeLogo={!Icon && !item?.org}
      >
        <If isTrue={Icon || item?.org}>
          {Icon ? (
            <Icon className="h-10 w-10 text-black" />
          ) : (
            <OrgLogo
              logoImageUrl={item?.org?.photoUrl}
              name={item?.org?.name || ""}
              width={40}
            />
          )}
        </If>
        <TransactionHistoryItemNameContainer>
          <TransactionHistoryHeaderItemDetails>
            {item?.typeName}
          </TransactionHistoryHeaderItemDetails>
          <TransactionHistoryHeaderItemName>
            {item?.name}
          </TransactionHistoryHeaderItemName>
        </TransactionHistoryItemNameContainer>
      </TransactionHistoryItemOrigin>
    </TransactionHistoryItemContainer>
  );
};
