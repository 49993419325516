import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { DEFAULT_UNIT_UOM } from "@/common/const";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { NoteDocumentItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddNoteItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { noteDocument } = useNoteDocument();
  const { findMaterialByName, getPrefilledValue } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useProjectCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const missingItems = useMemo(
    () => noteDocument?.items || [],
    [noteDocument?.items],
  );

  const addAllItems = useCallback(
    (items?: NoteDocumentItemFieldsFragment[]) => {
      const newItems =
        (items || noteDocument?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          let prefilledPrice = "";
          if (material) {
            prefilledPrice = getPrefilledValue({
              material: item.description ?? "",
              uom: item.uom ?? "",
            }).value;
          }
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.UOM]: String(item.uom) || DEFAULT_UNIT_UOM,
            [COLUMN_TYPE.Quantity]: item.quantity || "",
            [COLUMN_TYPE.PrefilledPrice]: item.unitPrice ?? prefilledPrice,
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantity, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
            [COLUMN_TYPE.Taxable]: "true",
          };
        }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      noteDocument?.items,
      handsonInstance,
      findMaterialByName,
      calcExtPrice,
      formatCostCode,
      getPrefilledValue,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addNoteItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = noteDocument?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      noteDocument?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    showModal,
    addAllItems,
    addMissingItems,
    noteDocument?.items.length,
    missingItems,
  ]);

  return {
    addNoteItems,
  };
};
