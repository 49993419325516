import { WatcherTagPicker } from "@/common/components/watchers/WatcherTagPicker";
import { useUsers } from "@/common/hooks/useUsers";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryHeaderItemDetails,
} from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

export const TransactionHeaderTransactionReleaseWatchers = ({
  transaction,
}: InventoryTransactionProps) => {
  const release = transaction?.deliverySlip?.release;
  const intl = useIntl();

  const { users } = useUsers({
    requireLocation: true,
    locationId: release?.project?.location.id,
  });

  const existingWatchers = useMemo(
    () => release?.watchers.map((watcher) => watcher.id) || [],
    [release?.watchers],
  );

  if (!release || !users) {
    return null;
  }

  return (
    <TransactionHistoryHeaderItem>
      <FormattedMessage
        id="WATCHERS"
        tagName={TransactionHistoryHeaderItemDetails}
      />
      <WatcherTagPicker
        users={users}
        existingWatchers={existingWatchers}
        moreTagCount={1}
        placeholder={intl.$t({ id: "WATCHERS" })}
        readonly
      />
    </TransactionHistoryHeaderItem>
  );
};
