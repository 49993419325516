import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import {
  LgFixedContainer,
  SmFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { WarehouseInventoryItem } from "@/contractor/pages/home/inventory-management/pages/current-stock/types/WarehouseInventoryItem";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { TransactionOriginFrom } from "../../../../../../transaction-origin/components/line-item/transaction-origin-from/TransactionOriginFrom";
import { TransactionOriginTo } from "../../../../../../transaction-origin/components/line-item/transaction-origin-to/TransactionOriginTo";
import { TransactionRelease } from "../../../../../../transaction-release/TransactionRelease";
import { TransactionSymbol } from "../../../../../../transaction-symbol/TransactionSymbol";
import { TransactionDate } from "./components/transaction-date/TransactionDate";
import { TransactionExtPrice } from "./components/transaction-ext-price/TransactionExtPrice";
import { TransactionIdentifier } from "./components/transaction-identifier/TransactionIdentifier";
import { TransactionItemNotes } from "./components/transaction-item-notes/TransactionItemNotes";
import { TransactionQuantity } from "./components/transaction-quantity/TransactionQuantity";
import { TransactionUnitPrice } from "./components/transaction-unit-price/TransactionUnitPrice";

export const useTransactionHistoryConfiguration = () => {
  const { args } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
    })),
  );
  const inventoryItem = useMemo(
    () => getWizardArg<WarehouseInventoryItem>(args, "inventoryItem"),
    [args],
  );
  const configuration: Array<
    GridCol<InventoryTransactionSummaryFieldsFragment>
  > = useMemo(() => {
    if (!inventoryItem) {
      return [];
    }
    return [
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <TransactionSymbol item={item} />,
        position: "none",
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <TransactionDate item={item} />,
        position: "none",
        header: <FormattedMessage id="DATE" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <TransactionIdentifier item={item} />,
        position: "center",
        header: <FormattedMessage id="TRANSACTION_ID" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => <TransactionOriginFrom item={item} />,
        header: <FormattedMessage id="FROM" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => <TransactionOriginTo item={item} />,
        header: <FormattedMessage id="TO" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <TransactionRelease item={item} />,
        position: "center",
        header: <FormattedMessage id="ORDER" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <TransactionQuantity item={item} inventoryItemId={inventoryItem.id} />
        ),
        position: "center",
        header: <FormattedMessage id="QUANTITY" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <TransactionUnitPrice
            item={item}
            inventoryItemId={inventoryItem.id}
          />
        ),
        position: "center",
        header: <FormattedMessage id="UNIT_PRICE" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <TransactionExtPrice item={item} inventoryItemId={inventoryItem.id} />
        ),
        position: "center",
        header: <FormattedMessage id="EXT_PRICE" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => (
          <TransactionItemNotes
            item={item}
            inventoryItemId={inventoryItem.id}
          />
        ),
        header: <FormattedMessage id="NOTES" />,
      },
    ];
  }, [inventoryItem]);

  return configuration;
};
