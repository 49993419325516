import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { getInventoryItemFromTransaction } from "../../../../../../../utils/getInventoryItemFromTransaction";
import { TransactionHistoryItemName } from "../../TransactionHistory.styles";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
  inventoryItemId: string;
};

export const TransactionUnitPrice = ({ item, inventoryItemId }: Props) => {
  const inventoryItem = useMemo(
    () => getInventoryItemFromTransaction(item, inventoryItemId),
    [inventoryItemId, item],
  );
  return (
    <NotNullableRenderer value={inventoryItem}>
      <TransactionHistoryItemName>
        <Price price={inventoryItem?.unitPrice} />
      </TransactionHistoryItemName>
    </NotNullableRenderer>
  );
};
