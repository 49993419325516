import { Select } from "@/common/components/select/components/single/Select";
import { InventoryTransactionType } from "@/generated/graphql";
import { useIntl } from "react-intl";
import { useTransactionTypePicker } from "./hooks/useTransactionTypePicker";

type Props = {
  transactionType: InventoryTransactionType | null | undefined;
  setTransactionType: (
    transactionType: InventoryTransactionType | null,
  ) => void;
  skipAdjustments?: boolean;
};

export const TransactionTypePicker = ({
  transactionType,
  setTransactionType,
}: Props) => {
  const { options } = useTransactionTypePicker();
  const intl = useIntl();

  return (
    <Select
      className="lg:min-w-24"
      options={options}
      getLabel={(option) => option.label}
      getValue={(option) => (option.value ? option.value.toString() : "")}
      value={transactionType ? transactionType.toString() : ""}
      onChange={(value) => {
        setTransactionType(value ? (value as InventoryTransactionType) : null);
      }}
      placeholder={intl.$t({ id: "TRANSACTION_TYPE" })}
    />
  );
};
