import { CountType } from "@/common/components/circle-counter/CountType";
import { InvoiceReleaseItemDetails } from "@/common/components/invoices/invoice-release-item-details/InvoiceReleaseItemDetails";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid w-full gap-0.5`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  count: CountType;
};

export const InvoiceReleaseItemMaterial: FC<Props> = ({ item, count }) => {
  if (!item.projectItem) {
    return null;
  }

  return (
    <Container>
      <ProjectItemMaterialView
        item={{
          ...item.projectItem,
          material: {
            ...item.projectItem.material,
            material: {
              ...item.projectItem.material.material,
              name: item.name,
            },
            costCode: item.costCode,
          },
        }}
        count={count}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        manufacturer={item.manufacturer}
        expandable={false}
        displayBrand={false}
      />
      <InvoiceReleaseItemDetails item={item} />
    </Container>
  );
};
