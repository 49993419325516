import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { getInventoryStateAllocations } from "./getInventoryStateAllocations";

export const getInventoryItemUnitPrice = (
  inventoryItem: InventoryItemFieldsFragment,
  requiredQuantity?: number,
) => {
  const allocations = getInventoryStateAllocations(
    inventoryItem,
    requiredQuantity,
  );
  let totalPrice = new DecimalSafe(0);
  let totalQuantityTaken = new DecimalSafe(0);

  for (const { state, quantityToTake } of allocations) {
    totalPrice = totalPrice.plus(
      quantityToTake.times(new DecimalSafe(state.unitPrice ?? 0)),
    );
    totalQuantityTaken = totalQuantityTaken.plus(quantityToTake);
  }

  return totalQuantityTaken.greaterThan(new DecimalSafe(0))
    ? totalPrice.dividedBy(totalQuantityTaken).toNumber()
    : 0;
};
