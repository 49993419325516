import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { WarehouseInventoryItem } from "@/contractor/pages/home/inventory-management/pages/current-stock/types/WarehouseInventoryItem";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryItemTransactions } from "../../../../../hooks/useInventoryItemTransactions";
import { useInventoryItemTransactionsFilter } from "../../../../../hooks/useInventoryItemTransactionsFilter";
import { useTransactionHistoryConfiguration } from "./TransactionHistory.configuration";

export const TransactionHistoryList = () => {
  const { args } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
    })),
  );
  const inventoryItem = useMemo(
    () => getWizardArg<WarehouseInventoryItem>(args, "inventoryItem"),
    [args],
  );
  const { inventoryItemTransactions, totalCount, loading } =
    useInventoryItemTransactions({
      inventoryItem,
    });
  const { isFiltered } = useInventoryItemTransactionsFilter();
  const configuration = useTransactionHistoryConfiguration();
  return (
    <ListRenderer
      totalCount={totalCount}
      count={inventoryItemTransactions.length}
      paginated
    >
      <GridTable
        configuration={{ columns: configuration }}
        loading={loading}
        items={inventoryItemTransactions}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="TRANSACTION_HISTORY_NO_TRANSACTIONS_FILTERED"
            translationKey="TRANSACTION_HISTORY_NO_TRANSACTIONS"
          />
        }
      />
    </ListRenderer>
  );
};
