import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryHeaderItemDetails,
} from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

export const TransactionHeaderTransactionIdentifier: FC<
  InventoryTransactionProps
> = ({ transaction }) => {
  return (
    <TransactionHistoryHeaderItem>
      <FormattedMessage
        id="TRANSACTION_ID"
        tagName={TransactionHistoryHeaderItemDetails}
      />
      #{transaction?.number}
    </TransactionHistoryHeaderItem>
  );
};
