import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { HeaderPill } from "@/common/components/info-header/components/InfoHeaderItem.styles";
import { DateView } from "@/common/utils/dates/DateView";
import { FC } from "react";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryInfoHeaderItemLabel,
} from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";
import { useTransactionHeaderTransactionFulfillmentDate } from "./useTransactionHeaderTransactionFulfillmentDate";

export const TransactionHeaderTransactionFulfillmentDate: FC<
  InventoryTransactionProps
> = ({ transaction }: InventoryTransactionProps) => {
  const { props } = useTransactionHeaderTransactionFulfillmentDate({
    transaction,
  });
  return (
    <TransactionHistoryHeaderItem className="min-w-48">
      <InfoHeaderItem
        title={
          <TransactionHistoryInfoHeaderItemLabel>
            {props.labels?.dateLabel}: <DateView date={props.labels?.date} />
          </TransactionHistoryInfoHeaderItemLabel>
        }
      >
        <HeaderPill className={props.classes?.pill}>
          {props.labels?.info}
        </HeaderPill>
      </InfoHeaderItem>
    </TransactionHistoryHeaderItem>
  );
};
