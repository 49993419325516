import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LocationSelectorControlled } from "@/common/components/location-selector/LocationSelectorControlled";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { OrderOptionCustomRender } from "@/contractor/pages/home/common/order-selector-filters/OrderOptionCustomRender";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { LET_OFFICE_ASSIGN } from "@/contractor/pages/home/releases/pages/delivery-slips/components/new-delivery-slip/NewDeliverySlipFormWithStepper";
import { AssetContext } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceiptForm } from "../../hooks/useReceiptForm";

const Container = tw.div`flex flex-col gap-4`;
const Row = tw.div`flex justify-between`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;
const DropdownOption = tw.div`text-base py-2`;

type Props = {
  onClose: () => void;
};

const ReceiptFormWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { assets } = useUploadAssets();
  const { formatCurrency } = useFormatNumberToCurrency();
  const {
    formMethods,
    save,
    projectOptions,
    releaseOptions,
    submitted,
    requireProjectAssignment,
    selectedProject,
    loadingDeliveries,
    creating,
  } = useReceiptForm({
    onClose: () => {
      onClose();
      setTimeout(() => {
        openDialog({
          content: (
            <SuccessModal
              message={intl.$t({ id: "RECEIPT_SUCCESSFULLY_UPLOADED" })}
            />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
      }, 500);
    },
  });
  const { projectCostCodes } = useProjectCostCodes();

  const { watch } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <OverlayPanel
        title={intl.$t({ id: "ADD_RECEIPT" })}
        onSave={save}
        onCancel={onClose}
        saveLabel={intl.$t({ id: "CREATE" })}
        cancelLabel={intl.$t({ id: "CLOSE" })}
        disableSave={!assets?.length || !watch("locationId")}
        isLoading={creating}
      >
        <Container>
          <SelectControlled
            name="projectId"
            placeholder={intl.$t({
              id: "PROJECT_SELECT_YOUR_PROJECT",
            })}
            testId="receipt-form-projects-selector"
            options={projectOptions}
            getLabel={(option) => option.name}
            getValue={(option) => option.id}
            rules={{
              required: requireProjectAssignment,
            }}
            error={
              submitted &&
              requireProjectAssignment &&
              watch("projectId") === LET_OFFICE_ASSIGN
            }
            noOptionsText={intl.$t({
              id: "NO_PROJECTS_IN_LOCATION",
            })}
          />
          <If isTrue={!selectedProject}>
            <LocationSelectorControlled
              name="locationId"
              placeholder={intl.$t({ id: "RECEIPT_BUSINESS_LOCATION" })}
              permission="createInvoice"
            />
          </If>
          <SelectControlled
            name="releaseId"
            options={releaseOptions}
            label={intl.$t({ id: "SELECT_ORDER_OPTIONAL" })}
            customRender={(item) =>
              item.relItem ? (
                OrderOptionCustomRender(item.relItem)
              ) : (
                <DropdownOption>{item.name}</DropdownOption>
              )
            }
            getLabel={(option) =>
              option.relItem
                ? `${option.relItem.sequenceNumber ? `#${option.relItem.sequenceNumber}` : ""} ${
                    option.relItem.poNumber
                      ? `(${option.relItem.poNumber}) `
                      : ""
                  }${
                    option.relItem.time
                      ? intl.formatDate(option.relItem.time as number)
                      : ""
                  } - (${formatCurrency(Number(option.relItem.total))})`
                : option.name
            }
            classes={{
              option: "text-sm",
            }}
            getValue={(option) => option.id}
            loading={loadingDeliveries}
            className="flex min-w-[260px] flex-1"
          />
          <SelectControlled
            name="costCodeId"
            placeholder={intl.$t({
              id: "SPECIFY_COST_CODE_OPTIONAL",
            })}
            testId="receipt-form-cost-code-selector"
            options={projectCostCodes}
            getLabel={(option) => option.formatted}
            getValue={(option) => option.id}
            disableClearable={false}
          />
          <TextFieldControlled
            name="notes"
            multiline
            minRows={3}
            maxRows={10}
            label={intl.$t({ id: "NOTES_OPTIONAL" })}
            testId="receipt-form-notes"
          />
          <Row>
            <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE" />
            <Row>
              <Tooltip id="receipt-paid-info" element={<InfoIcon />}>
                <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE_INFO" />
              </Tooltip>
              <SwitchControlled
                name="prePaid"
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                className="ml-2"
              />
            </Row>
          </Row>
          <UploadAsset
            accept={{
              [PDF_MIME_TYPE]: PDF_EXTENSIONS,
              [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
            }}
            testId="receipt-uploader"
            limit={1}
          >
            <SupportedFormats>
              <FormattedMessage id="RECEIPTS_SUPPORTED_FORMATS" />
            </SupportedFormats>
          </UploadAsset>
        </Container>
      </OverlayPanel>
    </FormProvider>
  );
};

export const ReceiptForm: FC<Props> = ({ onClose }) => {
  return (
    <UploadAssetProvider context={AssetContext.Invoice}>
      <ReceiptFormWithProvider onClose={onClose} />
    </UploadAssetProvider>
  );
};
