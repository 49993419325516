import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { GridSortDirection } from "@/common/components/grid-table/types/GridSortDirection";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  Base,
  LogoContainer,
  MdContainer,
  MdFixedContainer,
  SmContainer,
  SmdFixedContainer,
  SmFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import {
  InvoiceSummaryFieldsFragment,
  InvoiceType,
  QueryInvoicesSortBy,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceFolders } from "../invoice-folder-picker/hooks/useInvoiceFolders";
import { InvoiceActions } from "./invoice-actions/InvoiceActions";
import { InvoiceAllItemsCheckbox } from "./invoice-all-items-checkbox/InvoiceAllItemsCheckbox";
import { InvoiceCheckbox } from "./invoice-checkbox/InvoiceCheckbox";
import { InvoiceIssueDate } from "./invoice-issue-date/InvoiceIssueDate";
import { InvoiceNumber } from "./invoice-number/InvoiceNumber";
import { InvoiceOrderAndBuyout } from "./invoice-order-and-buyout/InvoiceOrderAndBuyout";
import { InvoiceReceivedDate } from "./invoice-received-date/InvoiceReceivedDate";

const ActionsContainer = tw(Base)`w-48 basis-48`;
const FoldersContainer = tw.div`grid`;
const FolderContainer = tw.div`text-gray-600 text-xs w-full`;

export const useScannedInvoicesConfiguration = ({
  includeTrash = true,
}: {
  includeTrash?: boolean;
}) => {
  const { folders } = useInvoiceFolders(InvoiceType.Invoice);

  const configuration: Array<GridCol<InvoiceSummaryFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XxsFixedContainer,
        position: "center",
        item: ({ item }) => <InvoiceCheckbox invoice={item} />,
        header: <InvoiceAllItemsCheckbox />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={item.folders.length}>
            <FoldersContainer>
              {item.folders.map((folder) => (
                <FolderContainer key={folder.id}>{folder.name}</FolderContainer>
              ))}
            </FoldersContainer>
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="INVOICE_GROUPS" />,
        hidden: folders.length === 0,
      },
      {
        wrapper: SmdFixedContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={item.number}>
            <InvoiceNumber invoice={item} />
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="INVOICE_NUMBER_SYMBOL" />,
        sortKey: QueryInvoicesSortBy.Number,
      },
      {
        wrapper: SmContainer,
        item: ({ item }) => <InvoiceIssueDate invoice={item} />,
        header: <FormattedMessage id="ISSUED" />,
        sortKey: QueryInvoicesSortBy.IssueDate,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: SmContainer,
        item: ({ item }) => <InvoiceReceivedDate invoice={item} />,
        header: <FormattedMessage id="RECEIVED" />,
        sortKey: QueryInvoicesSortBy.CreatedAt,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: SmContainer,
        item: ({ item }) => <Price className="text-sm" price={item.total} />,
        header: <FormattedMessage id="TOTAL" />,
        sortKey: QueryInvoicesSortBy.Total,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: LogoContainer,
        header: <FormattedMessage id="INVOICE_EMAIL_VENDOR" />,
        item: ({ item, index }) => (
          <VendorLogo
            index={index}
            contactName={getVendorContacts(
              item.release?.preferredVendor?.contacts.filter(
                (c) => c.receivesInvoiceNotifications,
              ),
            )}
            {...(item.release?.sellerOrgLocation
              ? {
                  logoImageUrl: item.release.sellerOrgLocation?.org.photoUrl,
                  address: item.release.sellerOrgLocation?.address,
                  orgName: item.release.sellerOrgLocation?.org.name,
                }
              : {
                  logoImageUrl: item.predictedSellerOrgLocation?.org.photoUrl,
                  address: item.predictedSellerOrgLocation?.address,
                  orgName: item.predictedSellerOrgLocation?.org.name,
                })}
          />
        ),
        sortKey: QueryInvoicesSortBy.VendorName,
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="PROJECT" />,
        item: ({ item }) => (
          <NotNullableRenderer
            value={item.release?.project?.name ?? item.project?.name}
          >
            <ProjectNameListDetails
              project={item.release?.project ?? item.project}
              withTeamInfoTooltip
            />
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="MATCHED_ORDER" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.release}>
            <InvoiceOrderAndBuyout item={item} type={InvoiceType.Invoice} />
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <TypeIndicator orderType={item?.release?.type} />,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="INVOICE_STATUS" />,
        item: ({ item }) => <InvoiceStatusChip status={item.status} />,
        position: "center",
      },
      {
        wrapper: ActionsContainer,
        item: ({ item }) => (
          <InvoiceActions invoice={item} includeTrash={includeTrash} />
        ),
        position: "end",
      },
    ],
    [folders.length, includeTrash],
  );

  return configuration;
};
