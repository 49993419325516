import { useMemo } from "react";
import { useIntl } from "react-intl";

enum UseProjectsCostCodes {
  Yes = "yes",
  No = "no",
}

export const encodeUseProjectCostCodes = (value?: boolean | null) => {
  if (value === true) {
    return UseProjectsCostCodes.Yes;
  }
  return UseProjectsCostCodes.No;
};

export const decodeUseProjectCostCodes = (value: UseProjectsCostCodes) => {
  return value === UseProjectsCostCodes.Yes;
};

export const useUseProjectsCostCodesSelector = () => {
  const intl = useIntl();

  const options = useMemo(
    () => [
      {
        value: UseProjectsCostCodes.Yes,
        label: intl.$t({ id: "PROJECT_LEVEL_COST_CODES" }),
      },
      {
        value: UseProjectsCostCodes.No,
        label: intl.$t({ id: "ORG_LEVEL_COST_CODES" }),
      },
    ],
    [intl],
  );
  return { options };
};
