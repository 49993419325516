import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInventoryTransactionsQuery } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { useInventoryLedgerStore } from "../store/useInventoryLedgerStore";

export const useInventoryTransactions = () => {
  const { filter } = useInventoryLedgerStore();
  const { setPageInfo, paginationArgs } = usePagination();

  const { data, loading, error, refetch } = useInventoryTransactionsQuery({
    variables: {
      ...paginationArgs,
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.inventoryTransactions) {
      setPageInfo(data.inventoryTransactions.pageInfo);
    }
  }, [data, setPageInfo]);

  const inventoryTransactions = useMemo(
    () => data?.inventoryTransactions?.edges.map((e) => e.node) ?? [],
    [data],
  );
  useErrorEffect(error);

  return {
    inventoryTransactions,
    loading,
    error: !!error,
    totalCount: data?.inventoryTransactions?.totalCount ?? 0,
    refetch,
  };
};
