import { Dialog } from "@/common/components/dialog/Dialog";
import { Loader } from "@/common/components/loader/Loader";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutProjectItemsWithUpdates } from "../providers/BuyoutProjectItemsProvider";
import { useBuyoutProjectListItemConfiguration } from "./BuyoutProjectListItemConfiguration";
import { ProjectItems } from "./ProjectItems";
import { useAddItemsToBuyoutSpreadsheet } from "./useAddItemsToBuyoutSpreadsheet";

interface AddEstimatedItemsDialogProps {
  setShowEstimatedItemsDialog: (value: boolean) => void;
}

export const AddEstimatedItemsDialog = ({
  setShowEstimatedItemsDialog,
}: AddEstimatedItemsDialogProps) => {
  const intl = useIntl();
  const { updates } = useBuyoutProjectItemsWithUpdates();
  const { buyout, loading } = useContractorBuyout();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { loading: projectLoading } = useProject();
  const { addItemsToBuyoutSpreadsheet } = useAddItemsToBuyoutSpreadsheet();

  const handleOnCancel = useCallback(() => {
    setShowEstimatedItemsDialog(false);
  }, [setShowEstimatedItemsDialog]);

  const handleAddToBuyout = useCallback(() => {
    if (updates.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    addItemsToBuyoutSpreadsheet(updates);

    setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_BUYOUT" }));
    setShowEstimatedItemsDialog(false);
  }, [
    addItemsToBuyoutSpreadsheet,
    intl,
    setShowEstimatedItemsDialog,
    setSuccessAlert,
    setWarningAlert,
    updates,
  ]);

  const configuration = useBuyoutProjectListItemConfiguration({ buyout });

  if (!buyout || loading || projectLoading) {
    return <Loader loading />;
  }

  return (
    <Dialog
      title={<FormattedMessage id={"ADD_ITEMS_TO_BUYOUT_FROM_ESTIMATES"} />}
      confirmButtonText={intl.$t(
        {
          id:
            updates.length === 0
              ? "BUYOUT_ADD_ITEMS"
              : "ADD_TO_BUYOUT_WITH_COUNT",
        },
        { count: updates.length },
      )}
      handleConfirm={handleAddToBuyout}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      handleCancel={handleOnCancel}
      maxWidth={false}
      show={true}
      content={<ProjectItems columns={configuration} loading={loading} />}
    />
  );
};
