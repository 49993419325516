import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { ImageInitials } from "../image-initials/ImageInitials";

const Item = tw.div`py-2 inline-grid grid-flow-col relative gap-1.5
  ${({ $archived }: { $archived: boolean }) => $archived && "text-gray-400"}
`;
const ItemName = tw.span`text-sm`;
const AddressContainer = tw.span`text-2xs relative text-gray-600`;
const Group = tw.div`inline-grid grid-flow-row`;

export const WarehouseSelectorCustomRenderer = (
  item: WarehouseFieldsFragment,
) => {
  return (
    <Item $archived={!!item.archivedAt}>
      <ImageInitials name={item.name} width={30} includeTooltip />
      <Group>
        <ItemName>{item.name}</ItemName>
        <AddressContainer>{addressToString(item.address)}</AddressContainer>
      </Group>
    </Item>
  );
};
