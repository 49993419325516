import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType, InvoiceStatus } from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";
import { ConnectReceiptsPanel } from "./ConnectReceiptsPanel";
import { ExportReceiptsPanel } from "./ExportReceiptsPanel";
const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;

export const ExportReceiptsButton: FC = () => {
  const { connectedIntegrationType } = useOrgSettings();
  const {
    startSequence,
    sequenceActive,
    sequenceIds,
    stopSequence,
    selectedReceipts,
  } = useReceiptSequence();
  const { exportEnabled, setExportEnabled } = useReceipts();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  useEffect(() => {
    if (sequenceActive) {
      setExportEnabled(sequenceActive);
    }
  }, [exportEnabled, sequenceActive, sequenceIds, setExportEnabled]);

  const onClosePanel = useCallback(
    (togglePanel?: (opened: boolean) => void) => {
      togglePanel?.(false);
    },
    [],
  );

  const startExportFlow = useCallback(() => {
    setExportEnabled(true);
  }, [setExportEnabled]);

  const onStartSequenceClicked = useCallback(() => {
    startSequence();
  }, [startSequence]);

  if (!exportEnabled) {
    return (
      <OutlinedButton testId="export-receipts" onClick={startExportFlow}>
        <FormattedMessage id="CREATE_SEQUENCE" />
      </OutlinedButton>
    );
  }

  return (
    <Container>
      <FormattedMessage
        id="USE_CHECKBOXES_TO_SELECT_ITEMS"
        tagName={InfoText}
      />
      <NumberContainer $hasItems={selectedReceipts.length > 0}>
        <FormattedMessage
          id="NUMBER_OF_SELECTED"
          values={{ count: sequenceIds.length }}
        />
      </NumberContainer>
      <DrawerPanel
        anchor={(togglePanel) => (
          <>
            <PrimaryButton
              testId="start-sequence"
              onClick={onStartSequenceClicked}
              disabled={sequenceIds.length === 0}
            >
              <FormattedMessage id="START_SEQUENCE" />
            </PrimaryButton>
            <SourceSystemWrapper
              allowedIntegrationTypes={[IntegrationType.Foundation]}
              allowedFeatures={[
                IntegrationFeature.InvoiceWrites,
                IntegrationFeature.ReceiptsFileBased,
              ]}
            >
              <Tooltip
                element={
                  <PrimaryButton
                    testId="export-multiple-receipts"
                    onClick={() => togglePanel(true)}
                    disabled={
                      sequenceIds.length === 0 ||
                      selectedReceipts.some(
                        (i) => i.status !== InvoiceStatus.Approved,
                      ) ||
                      selectedReceipts.some((i) => i.link) ||
                      selectedReceipts.some((i) => !i.prePaid)
                    }
                  >
                    <FormattedMessage id="EXPORT" />
                  </PrimaryButton>
                }
                id="export-button"
              >
                {selectedReceipts.some(
                  (i) => i.status !== InvoiceStatus.Approved,
                ) ? (
                  <FormattedMessage id="DISABLE_EXPORT_VERIFIED_INVOICES" />
                ) : undefined}
              </Tooltip>
            </SourceSystemWrapper>
          </>
        )}
        content={(togglePanel) =>
          !hasFeatureInConnectedSourceSystem(
            IntegrationFeature.InvoiceWrites,
          ) || connectedIntegrationType ? (
            <ExportReceiptsPanel
              receiptIds={sequenceIds}
              onClose={() => onClosePanel(togglePanel)}
            />
          ) : (
            <ConnectReceiptsPanel
              receiptIds={sequenceIds}
              onClose={() => onClosePanel(togglePanel)}
            />
          )
        }
      />
      <Tooltip
        element={
          <DiscardButton
            onClick={() => {
              stopSequence();
              setExportEnabled(false);
            }}
          />
        }
        id="discard-button"
      >
        <FormattedMessage id="CLEAR_SEQUENCE" />
      </Tooltip>
    </Container>
  );
};
