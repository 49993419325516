import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { InventoryTransactionProps } from "../../../types/InventoryTransactionProps";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryHeaderItemDetails,
} from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";
import { useTransactionHeaderTransactionCreationDetails } from "./useTransactionHeaderTransactionCreationDetails";

export const TransactionHeaderTransactionCreationDetails: FC<
  InventoryTransactionProps
> = ({ transaction }) => {
  const { createdByLabel } = useTransactionHeaderTransactionCreationDetails({
    transaction,
  });
  return (
    <TransactionHistoryHeaderItem>
      <FormattedMessage
        id={createdByLabel}
        tagName={TransactionHistoryHeaderItemDetails}
      />
      {getUserName(transaction?.createdBy)}
      <TransactionHistoryHeaderItemDetails>
        <DateView date={transaction?.createdAt} />
      </TransactionHistoryHeaderItemDetails>
    </TransactionHistoryHeaderItem>
  );
};
