import { FC, memo, useState } from "react";
import tw from "tailwind-styled-components";
import { ImageInitials } from "../image-initials/ImageInitials";

const Logo = tw.img``;

type Props = {
  logoImageUrl?: string | null;
  name: string;
  width: number;
  height?: number;
  className?: string;
  includeTooltip?: boolean;
};

export const OrgLogo: FC<Props> = memo(
  ({ logoImageUrl, name = "", width, height, className }) => {
    const [brokenImage, setBrokenImage] = useState(false);

    return !logoImageUrl || brokenImage ? (
      <ImageInitials
        name={name}
        width={width}
        height={height}
        className={className}
      />
    ) : (
      <Logo
        loading="lazy"
        src={logoImageUrl}
        alt={name}
        width={width}
        height={height || width}
        className={className}
        style={{
          width,
          height: height || width,
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenImage(true);
        }}
      />
    );
  },
);
