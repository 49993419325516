import { useGetOrCreateLumpSumMaterial } from "@/common/components/import-external-po/hooks/useGetOrCreateLumpSumMaterial";
import { LUMP_SUM_UOM } from "@/common/const";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AddToReleaseItemInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";
import { useProjectTags } from "../../project/hooks/useProjectTags";

type FormValues = {
  costCodeId: string | undefined;
  costTypeId?: string;
  phaseCodeId?: string;
  netAmount: string | number | undefined;
};

export const useLumpSumReleaseItems = () => {
  const { projectCostCodes } = useProjectCostCodes();
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectTags();
  const { getOrCreateLumpSumMaterial } = useGetOrCreateLumpSumMaterial();

  const getLumpSumReleaseItem = useCallback(
    async (
      values: FormValues,
      options?: Partial<AddToReleaseItemInput>,
    ): Promise<AddToReleaseItemInput[]> => {
      const costCode = projectCostCodes.find(
        (c) => c.id === values.costCodeId,
      )?.description;
      const phaseCode = hasPhaseCodes
        ? phaseCodes.find((pc) => pc.id === values.phaseCodeId)?.name
        : undefined;
      const lumpSumMaterial = await getOrCreateLumpSumMaterial();
      const items: AddToReleaseItemInput[] = [
        {
          projectItem: {
            estimateUom: LUMP_SUM_UOM,
            orgCatalogSkuId: lumpSumMaterial.material.id,
          },
          quantityDecimal: (values.netAmount || "0").toString(),
          position: 0,
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          costCodeId: !!values.costCodeId ? values.costCodeId : null,
          costTypeId: !!values.costTypeId ? values.costTypeId : null,
          name:
            costCode || phaseCode
              ? intl.$t(
                  { id: "COST_CODE_EXPENSES" },
                  { costCode: !hasPhaseCodes ? costCode : phaseCode },
                )
              : intl.$t({ id: "RECEIPT_EXPENSES" }),
          ...options,
        },
      ];

      return items;
    },
    [
      projectCostCodes,
      getOrCreateLumpSumMaterial,
      hasPhaseCodes,
      intl,
      phaseCodes,
    ],
  );

  return { getLumpSumReleaseItem };
};
