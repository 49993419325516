import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { ProjectCostCodeFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { EditButton } from "../button/EditButton";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";

const Container = tw.div`flex flex-col`;
const Title = tw.div`text-xs text-gray-600`;
const Value = tw.div`flex items-center gap-1`;
const ValueText = tw.div`mt-1`;
const InfoIcon = tw(InfoOutlined)`text-blue-500 text-md`;

type Props = {
  costCode: ProjectCostCodeFieldsFragment | null | undefined;
  containerClassName?: string;
  onCostCodeChange?: (costCodeId: string) => void;
};

export const SpecifyCostCode: FC<Props> = ({
  costCode,
  containerClassName,
  onCostCodeChange,
}) => {
  const intl = useIntl();
  const { projectCostCodes, formatCostCode } = useProjectCostCodes();

  const [editable, setEditable] = useState(false);

  const [selectedCostCode, setSelectedCostCode] = useState<
    ProjectCostCodeFieldsFragment | null | undefined
  >(costCode);

  useEffect(() => {
    setSelectedCostCode(costCode);
  }, [costCode]);

  const onCostCodeSelected = useCallback(
    (costCodeId: string | null) => {
      setSelectedCostCode(
        projectCostCodes.find((costCode) => costCode.id === costCodeId),
      );
      setEditable(false);
      if (costCodeId) {
        onCostCodeChange?.(costCodeId);
      }
    },
    [projectCostCodes, onCostCodeChange],
  );

  if (!costCode) {
    return null;
  }
  return (
    <Container className={containerClassName}>
      <If isTrue={!editable}>
        <FormattedMessage
          id={!editable ? "SPECIFIED_COST_CODE" : "SPECIFY_COST_CODE"}
          tagName={Title}
        />
      </If>
      <Value>
        <If isTrue={!editable}>
          <ValueText>{formatCostCode(selectedCostCode)}</ValueText>
          <If isTrue={onCostCodeChange}>
            <EditButton onClick={() => setEditable(true)} />
          </If>
          <Tooltip element={<InfoIcon />} id="cost-code-info-tooltip">
            <FormattedMessage id="SPECIFY_COST_CODE_DESCRIPTION" />
          </Tooltip>
        </If>
        <If isTrue={editable}>
          <Select
            options={projectCostCodes}
            getValue={(costCode) => costCode.id}
            getLabel={formatCostCode}
            onChange={onCostCodeSelected}
            label={intl.$t({ id: "SPECIFY_COST_CODE" })}
            placeholder={
              formatCostCode(costCode) ?? intl.$t({ id: "SPECIFY_COST_CODE" })
            }
            value={selectedCostCode?.id}
            onClose={() => setEditable(false)}
            className="my-1"
          />
        </If>
      </Value>
    </Container>
  );
};
