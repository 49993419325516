import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInventoryItemAllItemsCheckbox } from "./useInventoryItemAllItemsCheckbox";

const Container = tw.div`
  flex -ml-2
`;

export const InventoryItemAllItemsCheckbox: FC = () => {
  const { sequenceIds, handleChange, checked, isIncluded } =
    useInventoryItemAllItemsCheckbox();

  return (
    <If isTrue={!isIncluded}>
      <Container>
        <SelectionCheckbox
          items={sequenceIds}
          setSelection={handleChange}
          checked={checked}
        />
      </Container>
    </If>
  );
};
