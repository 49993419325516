import { CUSTOM_SOURCE } from "@/common/components/spreadsheet-table/constants/tableConstants";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useTableWarehouseListener = () => {
  const { watch } = useFormContext();
  const warehouseId = watch("warehouseId");
  const { handsonInstance, spreadsheetData } = useColumnMapper();
  const [previousWarehouseId, setPreviousWarehouseId] = useState<string | null>(
    warehouseId,
  );

  useEffect(() => {
    if (warehouseId && warehouseId !== previousWarehouseId) {
      const prefilledCells = [] as [number, number, string | number][];
      spreadsheetData.forEach((row, index) => {
        const physicalRow = handsonInstance?.toPhysicalRow(index) || index;
        const physicalColumn = getPhysicalColumnIndex(
          handsonInstance,
          COLUMN_TYPE.Material,
        );
        prefilledCells.push([
          physicalRow,
          physicalColumn,
          row[COLUMN_TYPE.Material],
        ]);
      });
      setTableCells(prefilledCells, handsonInstance, CUSTOM_SOURCE);
      setPreviousWarehouseId(warehouseId);
    }
  }, [warehouseId, handsonInstance, spreadsheetData, previousWarehouseId]);
};
