import { ChangeSource } from "handsontable/common";
import { COLUMN_TYPE } from "../enums/columnType";

export const CUSTOM_SOURCE = "custom" as ChangeSource;
export const ADD_ITEMS_SOURCE = "add-items" as ChangeSource;
export const PREFILL_SOURCE = "prefill-material" as ChangeSource;

export const SKIP_SPACE_COLUMNS = [
  COLUMN_TYPE.Tag,
  COLUMN_TYPE.PhaseCode,
  COLUMN_TYPE.CustomPartNumber,
];

export const MIN_ROWS = 18;
export const EMPTY_STATE_MIN_ROWS = 17;

export const DEFAULT_SOURCES = [
  "edit",
  "CopyPaste.paste",
  "Autofill.fill",
  "UndoRedo.undo",
  "UndoRedo.redo",
];
export const DEFAULT_SOURCES_WITH_CUSTOM = [...DEFAULT_SOURCES, CUSTOM_SOURCE];
export const TRIGGER_UPDATE_SOURCES = [
  ADD_ITEMS_SOURCE,
  CUSTOM_SOURCE,
  ...DEFAULT_SOURCES,
];

export const PRICE_RELATED_COLUMNS = [
  COLUMN_TYPE.UnitPrice,
  COLUMN_TYPE.ExtPrice,
  COLUMN_TYPE.PrefilledPrice,
];
