import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ProjectDocument,
  UpdateEstimatedItemsInput,
  useRemoveEstimatedItemMutation,
  useUpdateEstimatedItemsTagsMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { gql } from "@apollo/client";
import { FC, createContext, useCallback, useContext, useState } from "react";
import { useProjectEstimatedItems } from "../hooks/useProjectEstimatedItems";

type ExpandedItem = { id: string; zoneId?: string };

type ProviderContextType = {
  expandedItems: Array<ExpandedItem>;
  setExpandedItem: (id: string, zoneId?: string) => void;
  removeEstimatedItem: (id: string) => Promise<boolean>;
  updateEstimatedItemTags: (id: string, tags: string[]) => Promise<boolean>;
  updating: boolean;
  selectedEstimatedItemIds: string[];
  setSelectedEstimatedItemIds: (id: string[]) => void;
  updateEstimatedItems: (input: UpdateEstimatedItemsInput) => Promise<boolean>;
};

type Props = {
  children: React.ReactNode;
  projectId?: string;
};

const ProviderContext = createContext<ProviderContextType>({
  expandedItems: [],
  setExpandedItem: NoFunction,
  removeEstimatedItem: NoFunctionBooleanPromise,
  updateEstimatedItemTags: NoFunctionBooleanPromise,
  updating: false,
  selectedEstimatedItemIds: [],
  setSelectedEstimatedItemIds: NoFunction,
  updateEstimatedItems: NoFunctionBooleanPromise,
});

export const EstimatedItemsProvider: FC<Props> = ({ children, projectId }) => {
  const [updateEstimatedItemTagsMutation] =
    useUpdateEstimatedItemsTagsMutation();
  const [removeEstimatedItemMutation] = useRemoveEstimatedItemMutation();
  const { setError } = useGlobalError();
  const [expandedItems, setExpandedItems] = useState<Array<ExpandedItem>>([]);
  const [selectedEstimatedItemIds, setSelectedEstimatedItemIds] = useState<
    string[]
  >([]);
  const { updateEstimatedItems, updating } = useProjectEstimatedItems();

  const updateEstimatedItemTags = useCallback(
    async (id: string, tags: string[]) => {
      try {
        const { errors } = await updateEstimatedItemTagsMutation({
          variables: {
            input: { id, tags },
          },
          update: (cache, { data }) => {
            data?.updateEstimatedItem.forEach((item) => {
              cache.writeFragment({
                id: `EstimateItem:${item.id}`,
                fragment: gql`
                  fragment EstimatedItemTags on EstimatedItem {
                    id
                    tags {
                      id
                    }
                  }
                `,
                data: {
                  tags: item.tags,
                },
              });
            });
          },
        });
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateEstimatedItemTagsMutation],
  );

  const removeEstimatedItem = async (id: string) => {
    try {
      const { errors } = await removeEstimatedItemMutation({
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: projectId || "", excludePhantoms: true },
          },
        ],
      });
      setError(errors);

      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const setExpandedItem = useCallback(
    (item: string, zoneId?: string) => {
      if (expandedItems.some((i) => item === i.id && i.zoneId === zoneId)) {
        setExpandedItems(
          expandedItems.filter(
            (i) => i.id !== item && (!zoneId || i.zoneId !== zoneId),
          ),
        );
      } else {
        setExpandedItems([...expandedItems, { id: item, zoneId }]);
      }
    },
    [expandedItems],
  );

  return (
    <ProviderContext.Provider
      value={{
        expandedItems,
        setExpandedItem,
        removeEstimatedItem,
        updateEstimatedItemTags,
        selectedEstimatedItemIds,
        setSelectedEstimatedItemIds,
        updating,
        updateEstimatedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useEstimatedItems = (): ProviderContextType =>
  useContext(ProviderContext);
