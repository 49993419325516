import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid px-1 text-sm`;

type Props = {
  item: InventoryItemFieldsFragment;
};
export const InventoryItemName: FC<Props> = ({ item }) => {
  return <Container>{item.orgMaterial.material.name}</Container>;
};
