import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { InstructionInput } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useSearchParams } from "react-router";

export type InventoryTransferFormValues = {
  originWarehouseId: string;
  destinationWarehouseId: string;
  date: number | null;
  instructions: InstructionInput | null;
};

type InventoryTransferForm = UseFormReturn<
  InventoryTransferFormValues,
  unknown
>;

const inventoryTransferFormDefaultValues: InventoryTransferFormValues = {
  originWarehouseId: "",
  destinationWarehouseId: "",
  date: null,
  instructions: null,
};

export const InventoryTransferForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setCurrentWarehouseId, reset } = useInventoryItems();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    reset();
  }, [reset]);

  const inventoryTransferForm: InventoryTransferForm =
    useForm<InventoryTransferFormValues>({
      defaultValues: {
        ...inventoryTransferFormDefaultValues,
        originWarehouseId: "",
        destinationWarehouseId: "",
        date: new Date().getTime(),
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const { watch, setValue } = inventoryTransferForm;
  const originWarehouseId = watch("originWarehouseId");

  useEffect(() => {
    if (originWarehouseId) {
      setCurrentWarehouseId(originWarehouseId);
    }
  }, [originWarehouseId, setCurrentWarehouseId]);

  useEffect(() => {
    if (searchParams.get("warehouseId")) {
      setValue("originWarehouseId", searchParams.get("warehouseId") || "");
    }
  }, [searchParams, setValue]);

  return <FormProvider {...inventoryTransferForm}>{children}</FormProvider>;
};
