import { gql } from "@apollo/client";

export const AGGREGATED_ITEM_FIELDS = gql`
  fragment AggregatedItemFields on AggregatedItem {
    projectItem {
      id
    }
    estimatedItems {
      id
      quantityDecimal
      unitPrice
      manufacturer {
        id
      }
      tags {
        id
      }
      position
      sellerOrgLocation {
        id
      }
      uom {
        id
      }
    }
    buyoutItems {
      id
    }
  }
`;
