import { InvoiceStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptFooterState } from "../../../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../../../types/ReceiptMatchedOrderViewState";

type Input = {
  isEditMode: boolean;
};

export const useReceiptFooterButtons = ({ isEditMode }: Input) => {
  const { receipt, hasReleaseRecorded, footerState, matchedOrderViewState } =
    useReceipt();

  const showReceiptDeleteButton = useMemo(
    () =>
      footerState === ReceiptFooterState.DEFAULT &&
      matchedOrderViewState !== ReceiptMatchedOrderViewState.CREATE_ORDER,
    [matchedOrderViewState, footerState],
  );

  const showOrderUpdateApproveButton = useMemo(
    () =>
      footerState === ReceiptFooterState.DEFAULT &&
      (hasReleaseRecorded ||
        matchedOrderViewState === ReceiptMatchedOrderViewState.CREATE_ORDER ||
        matchedOrderViewState === ReceiptMatchedOrderViewState.MATCH_ORDER),
    [footerState, hasReleaseRecorded, matchedOrderViewState],
  );

  const showSaveOrOrderUpdateApproveButtons = useMemo(
    () =>
      isEditMode ||
      receipt?.status !== InvoiceStatus.Approved ||
      (receipt?.status === InvoiceStatus.Approved && !hasReleaseRecorded),
    [isEditMode, receipt?.status, hasReleaseRecorded],
  );

  const showKickbackButton = useMemo(
    () => receipt?.status !== InvoiceStatus.Paid,
    [receipt?.status],
  );

  return {
    showReceiptDeleteButton,
    showOrderUpdateApproveButton,
    showSaveOrOrderUpdateApproveButtons,
    showKickbackButton,
  };
};
