import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useMaterialRenderer } from "@/common/components/spreadsheet-table/renderers/useMaterialRenderer";
import { useRenderHelpers } from "@/common/components/spreadsheet-table/renderers/useRenderHelpers";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useInventoryItemRenderer = () => {
  const intl = useIntl();
  const { applyTooltip, removeTooltip } = useRenderHelpers();
  const baseRenderer = useMaterialRenderer();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);

      if (!value) {
        removeTooltip(td);
        return;
      }

      const uom =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)
        ];

      const inventoryItem = findInventoryItemByNameAndUom(value, uom);

      if (!inventoryItem) {
        applyTooltip(td, intl.$t({ id: "NOT_FOUND_IN_INVENTORY" }));
        return;
      }

      if (inventoryItem.state.length === 0) {
        applyTooltip(
          td,
          intl.$t(
            { id: "OUT_OF_STOCK_FOR_SELECTED_WAREHOUSE" },
            { material: value },
          ),
        );
      } else {
        removeTooltip(td);
      }
      if (inventoryItem) {
        const inventoryItemText = document.createElement("span");
        const remainingQuantity =
          getInventoryItemRemainingQuantity(inventoryItem);
        inventoryItemText.className = "text-gray-500";
        inventoryItemText.innerText = ` ⟮${remainingQuantity} ${inventoryItem.uom.pluralDescription}⟯`;
        const bubble = document.createElement("i");
        bubble.className = `absolute top-1/2 -translate-y-1/2 right-1 ${remainingQuantity.toNumber() > 0 ? "text-blue-800" : "text-blue-200"} fa-solid fa-circle`;
        applyTooltip(bubble, intl.$t({ id: "INVENTORY_ITEM" }));
        td.appendChild(bubble);
        td.appendChild(inventoryItemText);
      }
    },
    [
      baseRenderer,
      intl,
      findInventoryItemByNameAndUom,
      applyTooltip,
      removeTooltip,
    ],
  );

  return renderer;
};
