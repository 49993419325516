import { KeyboardArrowRight } from "@mui/icons-material";
import tw from "tailwind-styled-components";

export const TransactionHistoryItemContainer = tw.div`flex items-center justify-start text-sm flex-col`;
export const TransactionHistoryItemNameContainer = tw.div`flex flex-col items-left`;
export const TransactionHistoryItemName = tw.div`text-xs`;
export const TransactionHistoryItemDetails = tw.div`text-2xs text-gray-600`;
export const TransactionHistoryHeaderItemName = tw.div`text-sm`;
export const TransactionHistoryHeaderItemDetails = tw.div`text-xs text-gray-600`;
export const TransactionHistoryItemOrigin = tw.div`grid gap-2 items-center
${({ $excludeLogo }: { $excludeLogo?: boolean }) => ($excludeLogo ? "grid-cols-auto" : "grid-cols-[32px_auto]")}`;
export const TransactionHistoryHeaderItem = tw.div`flex items-start justify-center flex-col pr-4 text-sm h-full`;
export const TransactionHistoryInfoHeaderItemLabel = tw.div`text-sm font-medium`;
export const TransactionHistoryHeaderItemGroup = tw.div`flex flex-row gap-1 items-center`;
export const TransactionHistoryHeaderItemArrow = tw(
  KeyboardArrowRight,
)`flex items-center text-2xl mx-4`;
