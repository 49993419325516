import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { useEffect } from "react";
import { RECEIPT_ACCEPTABLE_ORDER_STATUSES } from "../constants";
import { useReceipt } from "../providers/ReceiptProvider";

export const useReceiptOrderFilters = () => {
  const { setSelectedRelease, preselectedPoNumber, setPreselectedPoNumber } =
    useReceipt();

  const { setFilter, releases, filter } = useReleasesSelectorData({
    search: preselectedPoNumber,
    statuses: RECEIPT_ACCEPTABLE_ORDER_STATUSES,
    closedProjects: false,
    hasReceipt: false,
    hasInvoices: false,
  });

  useEffect(() => {
    if (preselectedPoNumber && releases.length > 0) {
      setPreselectedPoNumber("");
      const release = releases.find((r) => r.poNumber === preselectedPoNumber);
      if (release) {
        setSelectedRelease(release);
        setFilter((filter) => ({
          ...filter,
          projectIds: [release.project?.id ?? ""],
          sellerOrgLocationIds: release.sellerOrgLocation?.id
            ? [release.sellerOrgLocation.id]
            : [],
        }));
      }
    }
  }, [
    preselectedPoNumber,
    releases,
    setFilter,
    setPreselectedPoNumber,
    setSelectedRelease,
  ]);

  useEffect(() => {
    return () => setSelectedRelease(null);
  }, [setSelectedRelease]);

  return {
    filter,
  };
};
