import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { InvoiceDocumentType } from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useClearOrderFromReceipt } from "../../../hooks/useClearOrderFromReceipt";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptOrderInputSelector } from "./ReceiptOrderInputSelector";

const Container = tw.div`-mt-6`;
const Header = tw.div`grid grid-cols-3 pt-5 px-4`;
const OrderSelectorGroup = tw.div`grid content-start gap-3 pt-2`;
const OrderNumberHeader = tw.div`grid grid-flow-col justify-start gap-1 place-items-center text-base font-medium`;
const Details = tw.div`text-sm font-normal`;
const ListContent = tw.div`grid grid-flow-row justify-start items-start`;
const OrderSelectorInnerContainer = tw.div`flex flex-row items-center`;

export const ReceiptMatchedHeader: FC = () => {
  const { receipt, refetch } = useReceipt();
  const { clearOrder } = useClearOrderFromReceipt();

  useSetCurrentProjectId(receipt?.project?.id);

  return (
    <Container>
      <Header>
        <OrderSelectorGroup>
          <If isTrue={receipt?.release}>
            <FormattedMessage id="YOUR_ORDER" tagName={OrderNumberHeader} />
          </If>
          <OrderSelectorInnerContainer>
            <ReceiptOrderInputSelector>
              <ReleaseStatusChip
                status={receipt?.release?.status}
                type="small"
                releaseType={receipt?.release?.type}
              />
              <OutlinedButton
                className="h-6 min-w-16"
                $small
                onClick={clearOrder}
              >
                <FormattedMessage id="CLEAR" />
              </OutlinedButton>
            </ReceiptOrderInputSelector>
          </OrderSelectorInnerContainer>
        </OrderSelectorGroup>
        <If isTrue={receipt?.release}>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="VENDOR" />}
            name={
              <VendorList
                contacts={receipt?.release?.preferredVendor?.contacts.filter(
                  (c) => c.receivesInvoiceNotifications,
                )}
              />
            }
            orgName={receipt?.release?.sellerOrgLocation?.org.name}
            logoImageUrl={receipt?.release?.sellerOrgLocation?.org.photoUrl}
            address={receipt?.release?.sellerOrgLocation?.address}
          />
          <InfoHeaderItem title={<FormattedMessage id="PROJECT" />}>
            <ListContent>
              <Details>{receipt?.release?.project?.name}</Details>
              <Details>
                {addressToString(receipt?.release?.project?.address)}
              </Details>
            </ListContent>
          </InfoHeaderItem>
        </If>
      </Header>
      <InvoiceIssues
        invoice={receipt}
        refetchInvoice={refetch}
        documentType={InvoiceDocumentType.RECEIPT}
      />
    </Container>
  );
};
