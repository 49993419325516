import { DateView } from "@/common/utils/dates/DateView";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import {
  TransactionHistoryItemContainer,
  TransactionHistoryItemDetails,
} from "../../TransactionHistory.styles";

type Props = {
  item: Pick<InventoryTransactionSummaryFieldsFragment, "date">;
};

export const TransactionDate = ({ item }: Props) => {
  return (
    <TransactionHistoryItemContainer>
      <DateView date={item.date} />
      <TransactionHistoryItemDetails>
        <DateView date={item.date} onlyTime />
      </TransactionHistoryItemDetails>
    </TransactionHistoryItemContainer>
  );
};
