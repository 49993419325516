import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { getInventoryStateAllocations } from "@/contractor/pages/admin/inventory-items/utils/getInventoryStateAllocations";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useGetPriceBreakdown = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { calcExtPrice } = usePriceCalculation();

  return useCallback(
    (
      inventoryItem: InventoryItemFieldsFragment,
      requiredQuantity?: number,
      isExtPrice?: boolean,
    ) => {
      const allocations = getInventoryStateAllocations(
        inventoryItem,
        requiredQuantity,
      );
      const breakdown = allocations.map(({ state, quantityToTake }) =>
        intl.$t(
          { id: "PRICE_FOR_QUANTITY" },
          {
            quantity: quantityToTake.toNumber(),
            uom: inventoryItem.uom.pluralDescription,
            price: formatCurrency(
              isExtPrice
                ? calcExtPrice(state.unitPrice, quantityToTake.toNumber())
                : (state.unitPrice ?? 0),
            ),
          },
        ),
      );

      return breakdown.join("\n");
    },
    [formatCurrency, intl, calcExtPrice],
  );
};
