import {
  BreadcrumbItem,
  Breadcrumbs,
  DeletedContainer,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { ReleaseMessagesButton } from "@/contractor/pages/home/release/components/ReleaseMessagesButton";
import {
  AuthorizationStatus,
  InvoiceStatus,
  MessageContextKind,
  OrgLocationRole,
} from "@/generated/graphql";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useReceiptSequence } from "../../../receipts/providers/ReceiptsSequenceProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ExportReceiptButton } from "../export/ExportReceiptButton";
import { ReceiptSequenceStepper } from "../sequence-stepper/ReceiptSequenceStepper";
import { ReceiptOrderBreadcrumb } from "./ReceiptOrderBreadcrumb";

export const ReceiptBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { viewer } = useUser();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { receipt } = useReceipt();
  const { sequenceActive } = useReceiptSequence();

  const { hasPermissions: canViewInvoices } = usePermissions([
    Permission.canViewInvoices,
  ]);

  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "RECEIPTS" }),
        link: canViewInvoices && !isForeman ? routes.receipts : undefined,
        id: "receipts",
      },
      {
        text: <ReceiptOrderBreadcrumb />,
        id: "receipts",
      },
    ] as BreadcrumbItem[];
  }, [canViewInvoices, intl, isForeman]);

  const actions = useMemo(() => {
    const bActions = [];

    if (receipt?.release && receipt?.release?.sellerOrgLocation?.org) {
      bActions.push(
        <ReleaseMessagesButton
          key="messages"
          release={receipt?.release}
          org={receipt?.release?.sellerOrgLocation?.org}
          text={
            <QuestionBox>
              <Tooltip
                id="receipt-messages"
                element={
                  <MessageButton
                    id={receipt.release.id}
                    kind={MessageContextKind.Release}
                  />
                }
              >
                <FormattedMessage id="MESSENGER" />
              </Tooltip>
            </QuestionBox>
          }
        />,
      );
    }
    if (
      (receipt?.status === InvoiceStatus.Approved ||
        receipt?.status === InvoiceStatus.Paid) &&
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludePaymentMethods,
      ) &&
      receipt.permissions.export === AuthorizationStatus.Authorized
    ) {
      bActions.push(
        <ExportReceiptButton key="export-receipt" receipt={receipt} />,
      );
    }
    if (sequenceActive) {
      bActions.push(<ReceiptSequenceStepper key="receipt-sequence" />);
    }

    return bActions;
  }, [receipt, sequenceActive, hasFeatureInConnectedSourceSystem]);

  const appendedItems = useMemo(() => {
    const items = [] as ReactElement[];
    if (receipt?.release) {
      items.push(
        <InvoiceStatusChip
          key="status"
          status={receipt?.status}
          type="breadcrumb"
        />,
      );
    }
    if (receipt?.archivedAt) {
      items.push(
        <FormattedMessage
          key="deleted"
          id="DELETED"
          tagName={DeletedContainer}
        />,
      );
    }
    return items;
  }, [receipt]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      actions={actions}
      appendItems={appendedItems}
    />
  );
};
