import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryTransaction } from "../../../../../hooks/useInventoryTransaction";

export const useTransactionHistoryTransfersTotalPrice = () => {
  const { args } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
    })),
  );

  const transactionId = useMemo(
    () => getWizardArg<string>(args, "transactionId"),
    [args],
  );
  const { transaction } = useInventoryTransaction({ transactionId });
  const { calcExtPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      transaction?.items.reduce(
        (acc: DecimalSafe, item) =>
          acc.add(calcExtPrice(item.quantity, item.unitPrice)),
        new DecimalSafe(0),
      ),
    [transaction, calcExtPrice],
  );

  return { total };
};
