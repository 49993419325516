import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../../tooltip/Tooltip";

type Props = {
  invoice: { archivedDuplicates: { id: string }[] } | null | undefined;
  className?: string;
};

const WarningIcon = tw(InfoOutlined)`text-gray-500`;
const Container = tw.div``;

export const ArchivedDuplicateInvoices: FC<Props> = ({
  invoice,
  className,
}) => {
  if (!invoice?.archivedDuplicates?.length) {
    return null;
  }

  return (
    <Tooltip
      id={`asset`}
      element={
        <Container className={className}>
          <WarningIcon />
        </Container>
      }
    >
      <FormattedMessage id="DUPLICATED_INVOICES_TOOLTIP" tagName="div" />
    </Tooltip>
  );
};
