import { DecimalSafe } from "@/common/utils/decimalSafe";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";

export const updateAverageWarehouseEntries = (
  warehouses: WarehouseInventoryItem[],
) => {
  warehouses.forEach((warehouse) => {
    warehouse.averagePrice = new DecimalSafe(warehouse.totalPrice)
      .div(new DecimalSafe(warehouse.totalQuantity))
      .toNumber();
    warehouse.averageExtPrice = new DecimalSafe(warehouse.averagePrice)
      .times(new DecimalSafe(warehouse.inStock))
      .toNumber();
  });
};
