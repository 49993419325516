import { DateTimePickerControlled } from "@/common/components/picker/components/DateTimePickerControlled";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-flow-col gap-2 items-center`;

interface TransferDatePickerProps {
  name: string;
  label: string;
}

export const TransferDatePicker = ({
  name,
  label,
}: TransferDatePickerProps) => {
  return (
    <Container>
      <DateTimePickerControlled
        name={name}
        label={label}
        inputProps={{
          className: "text-sm",
        }}
        rules={{ required: true }}
      />
    </Container>
  );
};
