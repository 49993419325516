import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { TRANSACTION_TYPE_STEP_MAP } from "../../../../../../../hooks/useTransactionHistoryModal";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const useTransactionIdentifier = ({ item }: Props) => {
  const { setNestedStep } = useNestedStepper();
  const { setArgs } = useWizardStore(
    useShallow((state) => ({
      setArgs: state.setArgs,
    })),
  );

  const onClick = useCallback(() => {
    setArgs({ transactionId: item.id });
    setNestedStep(TRANSACTION_TYPE_STEP_MAP[item.type]);
  }, [item.id, item.type, setArgs, setNestedStep]);

  return {
    onClick,
  };
};
