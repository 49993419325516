import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useSpreadSheetWarning } from "@/common/components/spreadsheet-table/hooks/useSpreadSheetWarning";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { ValidatorFn } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import {
  ValidatorContextType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { pascalCaseToUnderscore } from "@/common/utils/stringUtils";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useCallback } from "react";

export const useInventoryAdjustmentTableValidators = () => {
  const { showWarning } = useSpreadSheetWarning();
  const { spreadsheetData } = useColumnMapper();
  const { inventoryItems } = useInventoryItems();
  const { findMaterialByName } = useTableHelpers();

  const checkIfUniqueInventoryItem = useCallback(
    (row: Record<string, string>, rowIndex: number) => {
      const material = row[COLUMN_TYPE.Material];
      const uom = row[COLUMN_TYPE.UOM];

      const hasDuplicate = spreadsheetData.some(
        (otherRow, otherIndex) =>
          otherIndex !== rowIndex &&
          otherRow[COLUMN_TYPE.Material] === material &&
          otherRow[COLUMN_TYPE.UOM] === uom,
      );

      if (hasDuplicate) {
        showWarning(`VALIDATION_ERROR_SHEETS_DUPLICATE_INVENTORY_ITEM`, {
          row: rowIndex + 1,
        });
        return false;
      }

      return true;
    },
    [spreadsheetData, showWarning],
  );

  const checkIfUomAvailable = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const uom = getCellValue(row, column);
      if (uom === "" || uom == null || uom === undefined) {
        return true;
      }

      if (isLumpSumUomText(uom)) {
        showWarning("VALIDATION_ERROR_SHEETS_LUMP_SUM_NOT_SUPPORTED", {
          row: rowIndex + 1,
        });
        return false;
      }

      const quantity = row[COLUMN_TYPE.Quantity];
      if (Number(quantity) >= 0 || !quantity) {
        return true;
      }

      const material = findMaterialByName(row[COLUMN_TYPE.Material]);
      if (!material) {
        return true;
      }

      const uoms = inventoryItems
        .filter((item) => item.orgMaterial.id === material.id)
        .map((item) => item.uom.pluralDescription);
      if (uoms.length > 0 && !uoms.includes(uom)) {
        showWarning(`VALIDATION_ERROR_SHEETS_INVALID_INVENTORY_ITEM_UOM`, {
          row: rowIndex + 1,
          uom,
          uoms: uoms.join(", "),
        });
        return false;
      }

      return true;
    },
    [showWarning, findMaterialByName, inventoryItems],
  );

  const checkIfValueInRange = useCallback(
    (
      row: Record<string, string>,
      rowIndex: number,
      column: COLUMN_TYPE,
      min?: number,
      max?: number,
    ) => {
      const value = Number(getCellValue(row, column));
      if (
        Number.isNaN(value) ||
        (min !== undefined && Number(value) < min) ||
        (max !== undefined && Number(value) > max)
      ) {
        showWarning(
          `VALIDATION_ERROR_SHEETS_INVALID_${pascalCaseToUnderscore(column)}`,
          { row: rowIndex + 1 },
        );
        return false;
      }
      return true;
    },
    [showWarning],
  );

  const checkIfQuantityIsGreaterThanZero = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = Number(getCellValue(row, column));
      if (Number.isNaN(value) || value <= 0) {
        showWarning(
          `VALIDATION_ERROR_SHEETS_${pascalCaseToUnderscore(column)}_IS_NOT_GREATER_THAN_ZERO`,
          {
            row: rowIndex + 1,
          },
        );
        return false;
      }
      return true;
    },
    [showWarning],
  );

  const validateRowValues = useCallback(
    async (columns: COLUMN_TYPE[]) => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      return spreadsheetData.every((row, index) => {
        if (rowIsEmpty(row)) {
          return true;
        }
        return columns.every((column) => {
          switch (column) {
            case COLUMN_TYPE.Material:
              return checkIfUniqueInventoryItem(row, index);
            case COLUMN_TYPE.UnitPrice:
              return checkIfQuantityIsGreaterThanZero(row, index, column);
            case COLUMN_TYPE.NewStock:
              return checkIfValueInRange(row, index, column, 0);
            case COLUMN_TYPE.UOM:
              return checkIfUomAvailable(row, index, column);
            default:
              return true;
          }
        });
      });
    },
    [
      spreadsheetData,
      checkIfUomAvailable,
      checkIfUniqueInventoryItem,
      checkIfQuantityIsGreaterThanZero,
      checkIfValueInRange,
    ],
  );

  const uniqueInventoryItemValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row: rowIndex } = this;
      const currentRow = spreadsheetData[rowIndex];
      const material = currentRow[COLUMN_TYPE.Material];
      const uom = currentRow[COLUMN_TYPE.UOM];

      if (!material || !uom) {
        callback(true);
        return;
      }

      const duplicateRow = spreadsheetData.find((row, index) => {
        if (index === rowIndex) {
          return false;
        }

        return (
          row[COLUMN_TYPE.Material] === material && row[COLUMN_TYPE.UOM] === uom
        );
      });

      callback(!duplicateRow);
    },
    [spreadsheetData],
  );

  const uomAvailableValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      if (value === null || value === undefined || value === "") {
        callback(true);
        return;
      }

      if (isLumpSumUomText(value)) {
        callback(false);
        return;
      }

      const row = spreadsheetData[this.row];
      const materialName = row[COLUMN_TYPE.Material];
      const quantity = row[COLUMN_TYPE.Quantity];

      if (Number(quantity) >= 0 || !quantity) {
        callback(true);
        return;
      }

      const material = findMaterialByName(materialName);

      if (!material) {
        callback(true);
        return;
      }

      const validUoms = inventoryItems
        .filter((item) => item.orgMaterial.id === material.id)
        .map((item) => item.uom.pluralDescription);

      if (validUoms.length === 0) {
        callback(true);
        return;
      }

      callback(validUoms.includes(value));
    },
    [spreadsheetData, findMaterialByName, inventoryItems],
  );

  return {
    validateRowValues,
    uniqueInventoryItemValidator,
    uomAvailableValidator,
  };
};
