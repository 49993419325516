import { Price } from "@/common/components/price/Price";
import {
  TransactionFooterCell,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";
import { useTransactionHistoryTransfersTotalPrice } from "./useTransactionHistoryOutflowsTotalPrice";

export const TransactionHistoryOutflowsTotalPrice = () => {
  const { total } = useTransactionHistoryTransfersTotalPrice();

  return (
    <TransactionFooterCol>
      <TransactionFooterCell>
        <Price price={total} className="text-center" />
      </TransactionFooterCell>
    </TransactionFooterCol>
  );
};
