import { If } from "@/common/components/if/If";
import React, { FC, ReactNode } from "react";
import { Container, Content, Title } from "./InfoHeaderItem.styles";

type InfoHeaderItemProps = {
  title?: string | React.JSX.Element;
  children?: ReactNode;
  hideInMobile?: boolean;
  classes?: {
    container?: string;
    content?: string;
  };
  testId?: string;
};

export const InfoHeaderItem: FC<InfoHeaderItemProps> = ({
  title,
  hideInMobile = false,
  children,
  classes,
  testId,
}) => {
  return (
    <Container
      className={classes?.container}
      $hideInMobile={hideInMobile}
      data-testid={testId || "info-header"}
    >
      <If isTrue={title}>
        <Title>{title}</Title>
      </If>
      <Content className={classes?.content}>{children}</Content>
    </Container>
  );
};
