import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { QUERYSTRING } from "@/common/const";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { routes } from "@/config/routes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { LET_OFFICE_ASSIGN } from "@/contractor/pages/home/releases/pages/delivery-slips/components/new-delivery-slip/NewDeliverySlipFormWithStepper";
import {
  OrgLocationRole,
  ReleaseSelectorFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { createSearchParams, generatePath, useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { RECEIPT_ACCEPTABLE_ORDER_STATUSES } from "../../receipt-record-order/constants";
import { useReceipts } from "../providers/ReceiptsProvider";

type Input = {
  onClose: () => void;
};

type ReceiptForm = {
  notes: string;
  projectId: string | null;
  locationId: string | null;
  releaseId: string | null;
  costCodeId: string | null;
  prePaid: boolean;
};

export const useReceiptForm = ({ onClose }: Input) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { viewer } = useUser();
  const { createReceipt, creating } = useReceipts();
  const { projects } = useProjectListOptions();
  const { assets } = useUploadAssets();
  const { locations } = useUserLocations({
    permission: "createInvoice",
  });
  const {
    setFilter,
    releases: deliveries,
    loading: loadingDeliveries,
  } = useReleasesSelectorData({
    statuses: RECEIPT_ACCEPTABLE_ORDER_STATUSES,
    closedProjects: false,
    hasReceipt: false,
    hasInvoices: false,
  });

  const [submitted, setSubmitted] = useState(false);

  const formMethods = useForm<ReceiptForm>({
    defaultValues: {
      notes: "",
      projectId: null,
      locationId: null,
      releaseId: LET_OFFICE_ASSIGN,
      costCodeId: null,
      prePaid: true,
    },
  });
  const { handleSubmit, setValue } = formMethods;

  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const { requireProjectAssignment } = useStartupDataStore(
    useShallow((state) => ({
      requireProjectAssignment:
        state.settings?.display?.requireProjectAssignment,
    })),
  );

  const projectOptions = useMemo(() => {
    return [
      ...(requireProjectAssignment
        ? []
        : [
            {
              id: LET_OFFICE_ASSIGN,
              name: intl.$t({ id: "LET_OFFICE_ASSIGN_JOB" }),
            },
          ]),
      ...projects.map((project) => {
        return {
          id: project.id,
          name: getProjectSelectorLabel(project),
        };
      }),
    ];
  }, [intl, projects, requireProjectAssignment]);

  const projectId = formMethods.watch("projectId");
  const selectedProject = useMemo(
    () => projects.find((project) => projectId === project.id),
    [projectId, projects],
  );

  useSetCurrentProjectId(
    projectId !== LET_OFFICE_ASSIGN ? (projectId ?? undefined) : undefined,
  );

  const releaseOptions = useMemo(() => {
    return [
      {
        id: LET_OFFICE_ASSIGN,
        name: intl.$t({ id: "N_A" }),
        relItem: undefined as ReleaseSelectorFieldsFragment | undefined,
      },
    ].concat(
      deliveries.map((delivery) => ({
        id: delivery.id,
        name: `#${delivery.sequenceNumber}`,
        relItem: delivery,
      })),
    );
  }, [deliveries, intl]);

  const save = useCallback(() => {
    handleSubmit(
      async ({
        prePaid,
        locationId,
        notes,
        costCodeId,
        projectId,
        releaseId,
      }: ReceiptForm) => {
        setSubmitted(true);
        if (
          !assets ||
          !locationId ||
          (requireProjectAssignment && projectId === LET_OFFICE_ASSIGN)
        ) {
          return;
        }
        const receiptId = await createReceipt({
          assetUrls: assets.map((asset) => asset.url),
          orgLocationId: locationId,
          notes,
          projectId: projectId === LET_OFFICE_ASSIGN ? null : projectId,
          costCodeId: costCodeId === "" ? undefined : costCodeId,
          releaseId: releaseId === LET_OFFICE_ASSIGN ? null : releaseId,
          async: false,
          prePaid,
        });

        if (receiptId) {
          onClose();
          if (!isForeman) {
            navigate({
              pathname: generatePath(routes.processReceipt, {
                receiptId,
              }),
              search:
                releaseId === LET_OFFICE_ASSIGN
                  ? createSearchParams({
                      [QUERYSTRING.CREATE_NEW_ORDER]: "true",
                    }).toString()
                  : undefined,
            });
          }
        }
      },
    )();
  }, [
    handleSubmit,
    requireProjectAssignment,
    assets,
    navigate,
    createReceipt,
    isForeman,
    onClose,
  ]);

  useEffect(() => {
    if (!requireProjectAssignment) {
      setValue("projectId", LET_OFFICE_ASSIGN);
    }
  }, [requireProjectAssignment, setValue]);

  useEffect(() => {
    if (selectedProject) {
      setValue("locationId", selectedProject.location.id);
      return;
    }
    setValue("locationId", locations.length ? locations[0]?.id : "");
  }, [selectedProject, locations, setValue]);

  useEffect(() => {
    if (projectId && projectId !== LET_OFFICE_ASSIGN) {
      setFilter((filter) => ({
        ...filter,
        projectIds: [projectId],
      }));
    }
  }, [projectId, setFilter]);

  return {
    formMethods,
    save,
    projectOptions,
    releaseOptions,
    submitted,
    requireProjectAssignment,
    loadingDeliveries,
    selectedProject,
    creating,
  };
};
