import { CountType } from "@/common/components/circle-counter/CountType";
import { InvoiceReleaseItemDetails } from "@/common/components/invoices/invoice-release-item-details/InvoiceReleaseItemDetails";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseItemMaterial } from "./hooks/useInvoiceReleaseItemMaterial";

const Container = tw.div`grid w-full gap-0.5`;

type Props = {
  item: ExpandedReleaseItem;
  count: CountType;
  extraDetails?: React.ReactNode;
  classes?: {
    counterInner?: string;
    materialView?: string;
  };
};

export const InvoiceReleaseItemMaterial: FC<Props> = ({
  item,
  count,
  extraDetails,
  classes,
}) => {
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { release } = useRelease();
  const { itemPhaseCode } = useInvoiceReleaseItemMaterial(item);

  return (
    <Container>
      <ProjectItemMaterialView
        item={item}
        count={count}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        manufacturer={item.manufacturer}
        expandable={false}
        extraDetailsPosition="right"
        extraDetails={extraDetails}
        counterInnerClassName={classes?.counterInner}
        className={classes?.materialView}
      />
      <InvoiceReleaseItemDetails
        costTypeId={item.costType?.id ?? release?.type.costType?.id}
        costCodeId={groupedByCostCode ? "" : item.costCode?.id}
        zone={groupedByCostCode ? "" : item.zone?.name}
        phaseCode={groupedByCostCode ? "" : itemPhaseCode}
        item={item}
      />
    </Container>
  );
};
