import {
  DEFAULT_SOURCES,
  DEFAULT_SOURCES_WITH_CUSTOM,
} from "@/common/components/spreadsheet-table/constants/tableConstants";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { getCellValueWithPrefill } from "@/common/components/spreadsheet-table/utils/getCellValueWithPrefill";
import { setPrefillCell } from "@/common/components/spreadsheet-table/utils/setPrefillCell";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import { useCallback } from "react";
import { useInventoryHelpers } from "../../../../../../admin/inventory-items/hooks/useInventoryHelpers";

export const useInventoryAdjustmentTableHelpers = () => {
  const { calcExtPrice } = usePriceCalculation();
  const { findMaterialByName } = useTableHelpers();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();

  const prefillStock = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES_WITH_CUSTOM.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (column !== COLUMN_TYPE.Material) {
          return;
        }

        if (value) {
          const uom = getCellValueWithPrefill(
            hotInstance,
            row,
            COLUMN_TYPE.UOM,
          );
          const inventoryItem = findInventoryItemByNameAndUom(value, uom);

          if (!inventoryItem) {
            const material = findMaterialByName(value);
            setPrefillCell(
              hotInstance,
              row,
              COLUMN_TYPE.UOM,
              material?.defaultEstimateUom?.pluralDescription ?? "",
            );
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExistingStock, "0");
            setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, "0");
            return;
          }

          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.UOM,
            inventoryItem.uom.pluralDescription,
          );
          const remainingQty = getInventoryItemRemainingQuantity(inventoryItem);
          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.ExistingStock,
            remainingQty.toString(),
          );

          const quantity = Number(
            getCellValueWithPrefill(hotInstance, row, COLUMN_TYPE.Quantity),
          );

          const newStock = quantity
            ? remainingQty.add(new DecimalSafe(quantity)).toString()
            : remainingQty.toString();
          setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, newStock);

          if (quantity) {
            const unitPrice = getInventoryItemUnitPrice(
              inventoryItem,
              Number(quantity),
            );
            const extPrice = Math.abs(calcExtPrice(unitPrice, quantity));

            setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, unitPrice);
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, extPrice);
          }
        } else {
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UOM, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExistingStock, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, null);
        }
      });
    },
    [findInventoryItemByNameAndUom, findMaterialByName, calcExtPrice],
  );

  const prefillUomChange = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES.includes(source)) {
        return;
      }
      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (column === COLUMN_TYPE.UOM && value) {
          const material = getCellValueWithPrefill(
            hotInstance,
            row,
            COLUMN_TYPE.Material,
          );
          const inventoryItem = findInventoryItemByNameAndUom(material, value);
          const remainingQty = inventoryItem
            ? getInventoryItemRemainingQuantity(inventoryItem).toString()
            : "0";

          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.ExistingStock,
            remainingQty,
          );
          setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, remainingQty);
        }
      });
    },
    [findInventoryItemByNameAndUom],
  );

  const prefillNewStock = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES.includes(source)) {
        return;
      }
      changes?.forEach((change) => {
        const [row, column, , value] = change;
        if (column === COLUMN_TYPE.Quantity) {
          const material = getCellValueWithPrefill(
            hotInstance,
            row,
            COLUMN_TYPE.Material,
          );
          const uom = getCellValueWithPrefill(
            hotInstance,
            row,
            COLUMN_TYPE.UOM,
          );
          const inventoryItem = findInventoryItemByNameAndUom(material, uom);

          if (!inventoryItem) {
            setPrefillCell(
              hotInstance,
              row,
              COLUMN_TYPE.NewStock,
              value?.toString(),
            );
            return;
          }

          const newStock = getInventoryItemRemainingQuantity(inventoryItem)
            .add(new DecimalSafe(value))
            .toString();
          setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, newStock);

          if (value < 0) {
            const unitPrice = getInventoryItemUnitPrice(inventoryItem, value);
            setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, unitPrice);
            const extPrice = calcExtPrice(unitPrice, value);
            const absExtPrice = !Number.isNaN(extPrice)
              ? Math.abs(extPrice)
              : "";
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, absExtPrice);
          } else {
            const unitPrice = Number(
              getCellValueWithPrefill(hotInstance, row, COLUMN_TYPE.UnitPrice),
            );
            const extPrice = calcExtPrice(unitPrice, value);
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, extPrice);
          }
        }
      });
    },
    [findInventoryItemByNameAndUom, calcExtPrice],
  );

  return {
    prefillStock,
    prefillNewStock,
    prefillUomChange,
  };
};
