import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import tw from "tailwind-styled-components";
import { TransactionHistoryHeader } from "./components/transaction-history-header/TransactionHistoryHeader";
import { TransactionHistoryList } from "./components/transaction-history-list/TransactionHistoryList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryStock = () => {
  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <Container>
        <TransactionHistoryHeader />
        <TransactionHistoryList />
      </Container>
    </PaginationProvider>
  );
};
