import { generateWarehouseStateEntry } from "./inventory-items-utils/generateWarehouseStateEntry";
import { updateAverageWarehouseEntries } from "./inventory-items-utils/updateAverageWarehouseEntries";
import { updateScheduledInflows } from "./inventory-items-utils/updateScheduledInflows";
import { updateScheduledOutflows } from "./inventory-items-utils/updateScheduledOutflows";
import { updateWarehouseEntry } from "./inventory-items-utils/updateWarehouseEntry";

export default {
  generateWarehouseStateEntry,
  updateScheduledInflows,
  updateScheduledOutflows,
  updateWarehouseEntry,
  updateAverageWarehouseEntries,
};
