import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { COLUMN_TYPE } from "../enums/columnType";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { useRenderHelpers } from "./useRenderHelpers";

export const useBudgetRenderer = () => {
  const intl = useIntl();
  const { applyClasses, checkReadOnly, formatPrice, applyTooltip } =
    useRenderHelpers();
  const { allowance } = useProjectStore(
    useShallow((state) => ({
      allowance: state.allowance,
    })),
  );
  const { costCodes } = useStartupDataStore(
    useShallow((state) => ({
      costCodes: state.formattedCostCodes,
    })),
  );

  const getCostCodeId = useCallback(
    (formattedCodeName: string) => {
      const costCode = costCodes.find(
        (code) => formattedCodeName === code.formatted,
      )?.id;
      return costCode;
    },
    [costCodes],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = formatPrice(row, instance, value);
      const rows = instance?.getData();

      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );
      applyClasses(td, "htCenter htMiddle relative");
      checkReadOnly(td, row, instance, cellProperties);
      const zoneId = String(prop).split("_")[1];

      const costCodeId = getCostCodeId(
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
      );
      const allowanceCostCode = allowance?.costCodes.find(
        (a) =>
          a.costCode.id === costCodeId &&
          (a.zone?.id === zoneId || (!zoneId && !a.zone?.id)),
      );
      if (
        !rowIsEmpty(rows[row]) &&
        allowanceCostCode &&
        allowanceCostCode.costTypes
      ) {
        const iconCont = document.createElement("span");
        iconCont.className = `absolute inline-grid top-0 left-2 place-items-center h-full 2xl:left-2`;
        const iconElement = document.createElement("i");
        iconElement.className = `fa-solid text-[15px] fa-circle-dollar-to-slot text-blue-500 hover:text-blue-300`;
        iconCont.appendChild(iconElement);
        td.appendChild(iconCont);
        applyTooltip(
          td,
          intl.$t(
            { id: "ALLOWED_COST_TYPES" },
            {
              costTypes: allowanceCostCode.costTypes
                .map((a) => a.description)
                .join(", "),
            },
          ),
        );
      }
    },
    [
      formatPrice,
      applyClasses,
      checkReadOnly,
      allowance,
      applyTooltip,
      intl,
      getCostCodeId,
    ],
  );

  return renderer;
};
