import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useCallback } from "react";

export const useInventoryHelpers = () => {
  const { getUomByName } = useUomOptions();
  const { inventoryItems } = useInventoryItems();
  const { findMaterialByName } = useTableHelpers();

  const findInventoryItemByNameAndUom = useCallback(
    (name: string | undefined, uom: string | undefined) => {
      if (!name) {
        return undefined;
      }
      const uomOption = getUomByName(uom);
      const material = findMaterialByName(name);
      const item = inventoryItems.find(
        (item) =>
          item.orgMaterial.id === material?.id && item.uom.id === uomOption?.id,
      );
      return item;
    },
    [inventoryItems, getUomByName, findMaterialByName],
  );

  return {
    findInventoryItemByNameAndUom,
  };
};
