import { Option } from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { QueryInventoryItemsFilter } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

enum CurrentStockAdditionalFilter {
  NoQuantity = "NO_QUANTITY",
  Archived = "ARCHIVED",
}

export const useCurrentStockAdditionalFilters = () => {
  const intl = useIntl();
  const options = useMemo<Option<QueryInventoryItemsFilter>[]>(() => {
    return [
      {
        id: CurrentStockAdditionalFilter.NoQuantity,
        title: intl.$t({ id: "CURRENT_STOCK_FILTER_ITEM_WITH_0_QTY" }),
        filter: (value) => ({
          depleted: value,
        }),
        value: (currentFilter) => currentFilter?.depleted,
      },
      {
        id: CurrentStockAdditionalFilter.Archived,
        title: intl.$t({ id: "ARCHIVED_ITEMS" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [intl]);

  return {
    options,
  };
};
