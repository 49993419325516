import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Select } from "@/common/components/select/components/single/Select";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { Check, Close, EditOutlined } from "@mui/icons-material";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Container,
  Icons,
  NonEditableView,
  Title,
} from "./InvoiceHeaderDetails.styles";

const Row = tw.div`flex`;
const CostCodeText = tw.div``;

type Props = {
  receipt: ReceiptFieldsFragment;
  onChange: (costCodeId: string | null) => void;
};

export const ReceiptHeaderCostCodeSelect: FC<Props> = ({
  receipt,
  onChange,
}) => {
  const intl = useIntl();
  const { projectCostCodes } = useProjectCostCodes();

  const [costCodeId, setCostCodeId] = useState(receipt?.costCode?.id ?? null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setCostCodeId(receipt.costCode?.id ?? null);
  }, [receipt.costCode?.id]);

  const selectedCostCode = useMemo(
    () => projectCostCodes.find((costCode) => costCode.id === costCodeId),
    [projectCostCodes, costCodeId],
  );

  return (
    <Container>
      <If isTrue={isEdit}>
        <Row className="justify-between">
          <Select
            options={projectCostCodes}
            getValue={(option) => option.id}
            getLabel={(option) => option.formatted}
            value={costCodeId}
            onChange={setCostCodeId}
            disableClearable={false}
            label={intl.$t({ id: "SPECIFY_COST_CODE_OPTIONAL" })}
            InputProps={{
              classes: {
                root: "h-8",
              },
            }}
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.75rem",
              },
            }}
          />
          <Icons>
            <Check
              className="cursor-pointer text-blue-500"
              onClick={() => {
                setIsEdit(false);
                onChange(costCodeId);
              }}
            />
            <Close
              className="cursor-pointer text-blue-500"
              onClick={() => {
                setIsEdit(false);
                setCostCodeId(receipt.costCode?.id ?? null);
              }}
            />
          </Icons>
        </Row>
      </If>
      <If isTrue={!isEdit}>
        <Title>
          <FormattedMessage id="REFERENCE_COST_CODE" />
        </Title>
        <NonEditableView>
          <CostCodeText>{selectedCostCode?.formatted}</CostCodeText>
          <LinkLike>
            <EditOutlined onClick={() => setIsEdit(true)} fontSize="small" />
          </LinkLike>
        </NonEditableView>
      </If>
    </Container>
  );
};
