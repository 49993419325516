import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { useInventoryLedgerStore } from "../../../../store/useInventoryLedgerStore";

export const TransactionHistoryDateRangePicker = () => {
  const { filter, setFilter } = useInventoryLedgerStore();

  return (
    <ListDatesButton
      hideDates
      dates={[
        {
          startDate: filter?.minCreatedAt,
          endDate: filter?.maxCreatedAt,
        },
      ]}
      clearValues={() => {
        setFilter({
          ...filter,
          minCreatedAt: undefined,
          maxCreatedAt: undefined,
        });
      }}
      isDefault={true}
      includeIconLabel={false}
    >
      {(onClose) => (
        <DateRangePicker
          staticView
          onClose={onClose}
          selectedValue={
            filter?.maxCreatedAt || filter?.minCreatedAt
              ? {
                  startDate: filter?.minCreatedAt
                    ? new Date(filter.minCreatedAt)
                    : new Date(),
                  endDate: filter?.maxCreatedAt
                    ? new Date(filter.maxCreatedAt)
                    : new Date(),
                }
              : undefined
          }
          onChange={(dateRange) => {
            setFilter({
              ...filter,
              minCreatedAt: dateRange.startDate?.getTime(),
              maxCreatedAt: dateRange.endDate?.getTime(),
            });
          }}
          onClear={() => {
            setFilter({
              ...filter,
              minCreatedAt: undefined,
              maxCreatedAt: undefined,
            });
          }}
        />
      )}
    </ListDatesButton>
  );
};
