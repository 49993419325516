import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInventoryFiltersStore } from "../store/useInventoryFiltersStore";

export const useAddInventoryItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { findMaterialByName } = useTableHelpers();
  const {
    selectedItems,
    resetFilters,
    setSelectedItems,
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    filteredInventoryItems,
  } = useInventoryFiltersStore(
    useShallow((state) => ({
      selectedItems: state.selectedItems,
      setSelectedItems: state.setSelectedItems,
      resetFilters: state.resetFilters,
      search: state.search,
      setSearch: state.setSearch,
      selectedCostCodes: state.selectedCostCodes,
      setSelectedCostCodes: state.setSelectedCostCodes,
      filteredInventoryItems: state.filteredInventoryItems,
    })),
  );

  const addSelectedItems = useCallback(() => {
    const newItems =
      selectedItems.map((item) => {
        const inventoryItem = filteredInventoryItems.find((i) => i.id === item);
        const material = findMaterialByName(
          inventoryItem?.orgMaterial.material?.name ?? "",
        );
        return {
          [COLUMN_TYPE.Material]: material?.material.name ?? "",
          [COLUMN_TYPE.UOM]: inventoryItem?.uom.pluralDescription ?? "",
          [COLUMN_TYPE.ExistingStock]: inventoryItem
            ? getInventoryItemRemainingQuantity(inventoryItem).toString()
            : "",
          [COLUMN_TYPE.NewStock]: inventoryItem
            ? getInventoryItemRemainingQuantity(inventoryItem).toString()
            : "",
        };
      }) || [];
    appendTableRows(newItems, handsonInstance);
  }, [
    filteredInventoryItems,
    selectedItems,
    findMaterialByName,
    handsonInstance,
  ]);

  return {
    selectedItems,
    setSelectedItems,
    addSelectedItems,
    resetFilters,
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    filteredInventoryItems,
  };
};
