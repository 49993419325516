import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemStateSummaryFieldsFragment } from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";

export const updateWarehouseEntry = (
  warehouse: WarehouseInventoryItem,
  state: InventoryItemStateSummaryFieldsFragment,
  extPrice: number,
) => {
  warehouse.inStock = new DecimalSafe(warehouse.inStock)
    .plus(new DecimalSafe(state.remainingQuantity))
    .toNumber();

  warehouse.totalPrice = new DecimalSafe(warehouse.totalPrice)
    .plus(new DecimalSafe(extPrice))
    .toNumber();
  warehouse.totalQuantity = new DecimalSafe(warehouse.totalQuantity)
    .plus(new DecimalSafe(state.remainingQuantity))
    .toNumber();
};
