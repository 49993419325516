import { If } from "@/common/components/if/If";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { useReleaseAdditionalChargesInput } from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { useNonItemizedReleaseProjectBudgetSync } from "@/contractor/pages/home/release/hooks/useNonItemizedReleaseProjectBudgetSync";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptCreateReleaseFormValues } from "../../ReceiptVerificationForm";

const Container = tw.div`flex flex-col items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-5`;

export const ReceiptNonItemizedView: FC = () => {
  const { handleSubmit, setValue, watch, trigger, ...formProps } =
    useFormContext<ReceiptCreateReleaseFormValues>();
  const { hasReleaseRecorded } = useReceipt();
  const intl = useIntl();
  const projectId = watch("projectId");
  const { projectCostCodes, loading } = useProjectCostCodes();
  const customTaxAmount = watch("customTaxAmount");

  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectTags();
  const { releaseInput } = useReleaseAdditionalChargesInput({
    taxable: false,
  });
  const { total } = useFormPriceCalculation();

  useSetCurrentProjectId(projectId);
  useNonItemizedReleaseProjectBudgetSync();

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "0");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      setValue={setValue}
      watch={watch}
      trigger={trigger}
      {...formProps}
    >
      <Container>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={projectCostCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                disableClearable={false}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="netAmount"
              label={intl.$t({
                id: hasReleaseRecorded ? "ORDER_SUBTOTAL" : "RECEIPT_SUBTOTAL",
              })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ReleaseAdditionalChargesAndTaxes
            classes={{
              tax: "grid-cols-[auto_164px]",
              root: "mr-5",
              total: "-mr-7",
            }}
            total={`${total}`}
            releaseInput={releaseInput}
            editableByContractor
            includeSubtotal={false}
            includeAdditionalCharges={hasReleaseRecorded}
            editableAdditionalCharges={false}
            updateRelease={(values) =>
              handleUpdateRelease({ ...values, version: -1 })
            }
          />
        </Item>
      </Container>
    </FormProvider>
  );
};
