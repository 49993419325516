import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useInventoryItemsFilter } from "../../../../hooks/useInventoryItemsFilter";

export const CurrentStockCostCodesSelector = () => {
  const { projectCostCodes } = useProjectCostCodes();
  const { filter, setFilter } = useInventoryItemsFilter();
  const intl = useIntl();

  const selectedCostCodes: string[] = useMemo(
    () =>
      (filter?.costCodeIds || []).filter(
        (costCodeId): costCodeId is string => !!costCodeId,
      ) || [],
    [filter?.costCodeIds],
  );

  return (
    <CostCodesSelector
      className="w-64"
      placeholder={
        !(filter?.costCodeIds || []).length
          ? intl.$t({ id: "ALL_COST_CODES" })
          : ""
      }
      options={projectCostCodes}
      selectedCostCodes={selectedCostCodes}
      setSelectedCostCodes={(items: string[]) =>
        setFilter({ ...filter, costCodeIds: items })
      }
      limitTags={1}
    />
  );
};
